// Core packages
import { Injectable } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  AbstractControl,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

// Third party packages
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import moment from 'moment';

// Custom packages
import Address from '../interfaces/address.interface';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { ConfigService } from './config.service';
import IItem from '../models/item/item.interface';
import ISite from '../models/site/site.interface';
import IPlant from '../models/plant/plant.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class HelperService {
  /**
   * Class constructor
   */
  constructor(
    private toastrService: ToastrService,
    private authService: AuthService,
    private configService: ConfigService,
  ) {}

  /**
   * Emit a toastr notification with given data
   *
   * @since 1.0.0
   *
   * @param error Emitted error
   * @param ?type If we want to override the error data, here
   * we must provide a toastr notification type 'error' or 'success'
   * @param ?message Override error message
   * @param ?title  Override error title
   * @returns Observable<boolean>
   */
  handleError(
    error: any,
    type?: string,
    message?: string,
    title?: string,
  ): Observable<boolean> {
    console.warn('handleError()', error);
    if (error?.error?.data?.logout) {
      console.warn('DEVO LOGGARE FUORI');
      this.authService.logout();
      location.reload();
      return of(false);
    }
    if (typeof type !== 'undefined' && typeof message !== 'undefined') {
      if (type === 'error') {
        if (typeof title !== 'undefined') {
          this.toastrService.error(message, title);
          return of(false);
        }
        this.toastrService.error(message);
        return of(false);
      }
      if (type === 'success') {
        if (typeof title !== 'undefined') {
          this.toastrService.success(message, title);
          return of(false);
        }
        this.toastrService.success(message);
        return of(false);
      }
    }

    if (error.data) {
      console.warn('1');
      Object.keys(error.data).forEach((key) => {
        this.toastrService.error(error.data[key].msg);
      });
      return of(false);
    }

    if (
      typeof error.error !== 'undefined' &&
      error?.error?.data &&
      Object.keys(error?.error?.data).length > 0
    ) {
      console.warn('2');
      Object.keys(error.error.data).forEach((key) => {
        // this.toastrService.error(error.error.data[key].msg, `Error in ${error.error.data[key].param}`);
        this.toastrService.error(error.error.data[key].msg);
      });
      return of(false);
    }

    if (typeof error.error !== 'undefined' && error.error.message) {
      console.warn('3');
      this.toastrService.error(error.error.message, 'Attenzione');
      return of(false);
    }

    if (error.message) {
      console.warn('4');
      this.toastrService.error(error.message);
      return of(false);
    }

    // Generic error message
    console.warn('5');
    this.toastrService.error(
      "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico",
      'Errore',
    );

    return of(false);
  }

  /**
   * Parse error coming from an httpRequest made
   * with { responseType: blob }
   *
   * @see https://stackoverflow.com/questions/48500822/how-to-handle-error-for-response-type-blob-in-httprequest
   *
   * @since 1.0.0
   */
  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable((observer: any) => {
      reader.onloadend = (e) => {
        if (reader.result) {
          observer.error(JSON.parse(reader.result.toString()));
        }
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  /**
   * Handle back-end errors showing them under the
   * appropriate form-control in given form instance
   *
   * @since 1.0.0
   */
  handleFormError(form: UntypedFormGroup, err: any): void {
    console.warn('handleFormError()', form, err);
    const errors: any[] = [];

    // Check data is defined
    if (
      typeof err.error === 'undefined' ||
      typeof err.error.data === 'undefined'
    ) {
      console.warn('err.error.data is undefined');
      this.handleError(err); // Fall back to toastr
      return;
    }

    // Convert data object to array
    Object.keys(err.error.data).forEach((key) => {
      errors.push(err.error.data[key]);
    });

    // Set form invalid
    errors.forEach((error) => {
      if (typeof form.controls[error.param] !== 'undefined') {
        const field = form.get(error.param);
        if (field) {
          field.markAsTouched();
          field.markAsDirty();
          field.setErrors(error.msg);
        }
      } else {
        // Ok, so form field is undefined, looks like back-end
        // found an error on a field that is missing in the front-end
        // This generally happends when we forgot a field on the
        // front-end. Let's show a nice toastr notification
        this.toastrService.error(`${error.msg}: ${error.param}`);
      }
    });
  }

  /**
   * Handle invalid status of form controls setting
   * dirty and touched class to those form controls
   *
   * @since 1.0.0
   */
  handleFormInvalid(form: UntypedFormGroup): void {
    console.warn('handleFormInvalid()', form);
    Object.keys(form.controls).forEach((controlKey) => {
      const control = form.get(controlKey);
      if (control?.invalid) {
        console.warn(`Make control ${controlKey} dirty()`);
        control.markAsDirty();
        control.markAsTouched();
        control.setErrors({
          'back-end': 'unknown',
        });
      }
    });
  }

  /**
   * Check if given value is in string type or not
   *
   * @since 1.0.0
   */
  isString(x: any): boolean {
    return Object.prototype.toString.call(x) === '[object String]';
  }

  /**
   * Check if given form control is required or not
   *
   * @since 1.0.0
   *
   * @param control form control to check
   * @returns boolean True if it's required, false otherwhise.
   */
  controlIsRequired(control: UntypedFormControl | AbstractControl): boolean {
    if (
      typeof control === 'undefined' ||
      typeof control.validator !== 'function'
    ) {
      return false;
    }
    const validator = control.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }

  /**
   * Generate an appropriate error message starting from given data
   *
   * @since 1.0.0
   */
  getErrorMessage(key: string, value: any): string {
    console.warn(`key: ${key} has error with value`, value);
    let message = '';
    switch (key) {
      case 'required':
        message = 'Campo obbligatorio';
        break;
      case 'email':
        message = 'Email invalida';
        break;
      case 'minlength':
        message = `Minimo ${value.requiredLength} caratteri`;
        break;
      case 'maxlength':
        message = `Massimo ${value.requiredLength} caratteri`;
        break;
      case 'back-end':
        message = value.msg;
        break;
      default:
        message = 'Campo invalido';
        break;
    }

    return message;
  }

  /**
   * Format given address object and return it
   * as a string
   *
   * @since 1.0.0
   */
  getFormattedAddress(address: Address): string {
    if (
      !address ||
      !address.route ||
      !address.streetNumber ||
      !address.postalCode ||
      !address.city ||
      !address.countryCodeTwo
    ) {
      return '';
    }
    const addressString = `${address.route} ${address.streetNumber}, ${address.postalCode} ${address.city}, ${address.countryCodeTwo}`;
    return addressString;
  }

  /**
   * Handle back-end errors coming from a ngx-formly form
   * and set the errors to the form (they will than be showed
   * as mat-error)
   *
   * @since 1.0.0
   */
  handleFormlyError(form: UntypedFormGroup, err: any): void {
    console.log('handleFormlyError(form, err)', form, err);

    const errors = [];

    // Check data is defined
    if (
      typeof err.error === 'undefined' ||
      typeof err.error.data === 'undefined'
    ) {
      console.warn('err.error.data is undefined');
      this.handleError(err); // Fall back to toastr
      return;
    }

    // Set custom back-end errors to the form
    Object.keys(err.error.data).forEach((key: string) => {
      let fieldKey = key;
      const phoneKeys = [
        'phone.prefix',
        'phone.number',
        'mobile.prefix',
        'mobile.number',
        'cell.prefix',
        'cell.number',
        'simPhone.prefix',
        'simPhone.number',
      ];
      if (phoneKeys.includes(key)) {
        const foundPhoneKey = phoneKeys.find((el: string) => el === key);
        fieldKey = foundPhoneKey?.split('.')[0] as string;
      }
      const field = form.get(fieldKey);
      console.log('key', key);
      console.log('fieldKey', fieldKey);
      console.log('field', field);
      if (form && field) {
        // Standard controls
        field.markAsTouched();
        field.markAsDirty();
        console.warn('ERR_TEXT: ', err.error.data[key]);
        const errMsg = err.error.data[key].msg;
        field.setErrors({
          'server-error': errMsg,
        });
      } else {
        // Fall back
        console.warn(
          'fallback',
          `${err.error.data[key].msg}: ${err.error.data[key].param}`,
        );
        // Ok, so form field is undefined, looks like back-end
        // found an error on a field that is missing in the front-end
        // This generally happends when we forgot a field on the
        // front-end. Let's show a nice toastr notification
        this.toastrService.error(
          `${err.error.data[key].msg}: ${err.error.data[key].param}`,
        );
      }
    });
  }

  /**
   * Get filename from given "Content-disposition" header
   *
   * @see https://stackoverflow.com/questions/51960172/set-file-name-while-downloading-via-blob-in-angular-5
   *
   * @siunce 1.0.0
   */
  getFilenameFromContentDisposition(contentDisposition: string | null): string {
    console.log('contentDisposition');
    if (!contentDisposition) {
      return '';
    }
    const filename = contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .replaceAll('"', '')
      .trim();
    return filename.toString();
  }

  /**
   * Helper function used to get the appropriate icon depending on given item.type
   *
   * @deprecated Use "getImageForItem()" insted
   *
   * @since 1.0.0
   */
  getIconForItemType(type: string): string {
    let icon = '';

    switch (type) {
      case 'Server':
        icon = '<i class="fa fa-xl fa-server"></i>';
        break;

      case 'Client':
        icon = '<i class="fa fa-xl fa-mobile-screen"></i>';
        break;

      case 'Software':
        icon = '<i class="fa fa-xl fa-code"></i>';
        break;

      case 'Registratore':
        icon = '<i class="fa fa-xl fa-clapperboard-play"></i>';
        break;

      case 'Encoder':
        icon = '<i class="fa fa-xl fa-clapperboard"></i>';
        break;

      case 'TLCAnalogica':
        icon = '<i class="fa fa-xl fa-camera-cctv"></i>';
        break;

      case 'TLCIP':
        icon = '<i class="fa fa-xl fa-camera-cctv"></i>';
        break;

      case 'SpeakerIP':
        icon = '<i class="fa fa-xl fa-speaker"></i>';
        break;

      case 'PonteRadio':
        icon = '<i class="fa fa-xl fa-tower-cell"></i>';
        break;

      case 'RouterLTE':
        icon = '<i class="fa fa-xl fa-router"></i>';
        break;

      case 'SwitchCampo':
        icon = '<i class="fa fa-xl fa-router"></i>';
        break;

      case 'MediaConverter':
        icon = '<i class="fa fa-xl fa-photo-film-music"></i>';
        break;

      case 'CentraleAllarme':
        icon = '<i class="fa fa-xl fa-sensor-on"></i>';
        break;

      case 'TerminaleIP':
        icon = '<i class="fa fa-xl fa-square-terminal"></i>';
        break;

      default:
        icon = '<i class="fa fa-xl fa-circle-question"></i>';
        break;
    }

    return icon;
  }

  /**
   * Helper function used to get the appropriate name depending on given item.type
   *
   * @since 1.0.0
   */
  getNameForItemType(type: string): string {
    try {
      const foundItem =
        this.configService.settings.modules?.items.typesOptions.find(
          (el: any) => el.value === type,
        );
      if (foundItem) {
        return foundItem.label;
      }
      return type;
    } catch (error) {
      return type;
    }
  }

  /**
   * Return the appropriate image filename depending on given ItemType
   *
   * @since 1.1.0
   */
  getImageNameForItem(type: string): string {
    switch (type) {
      case 'PC':
        return 'pc';

      case 'Monitor':
        return 'monitor';

      case 'Software':
        return 'software';

      case 'Scheda':
        return 'scheda';

      case 'Registratore':
        return 'registratore';

      case 'Trasduttore':
        return 'trasduttore';

      case 'Telecamera':
        return 'telecamera';

      case 'Terminale':
        return 'terminale';

      case 'Speaker':
        return 'speaker';

      case 'Sensore':
        return 'sensore';

      case 'Centrale':
        return 'centrale';

      case 'Networking':
        return 'networking';

      case 'DeviceRadio':
        return 'device-radio';

      case 'Rack':
        return 'rack';

      case 'Alimentazione':
        return 'alimentazione';

      default:
        return '';
    }
  }

  /**
   * Return appropriate item status depending on given item data
   * NB: this is used to build the path of the item image
   *
   * @since 1.1.0
   */
  getItemStatus(item: any): 'on' | 'off' | 'silenced' | 'warning' {
    let status: 'on' | 'off' | 'silenced' | 'warning' = 'on';

    if (item.deadFrom) {
      const deadDate = moment(item.deadFrom);
      if (deadDate.isValid()) {
        const elapsedMinutes = moment().diff(deadDate, 'minutes');
        if (elapsedMinutes > this.configService.maxItemsDowntime) {
          status = 'off';
        } else if (
          ['admin', 'owner'].includes(
            this.authService.loggedUser$?.value?.role as string,
          )
        ) {
          status = 'warning';
        }
      }
    }

    if (item.silenced) {
      status = 'silenced';
    }

    if (item.pingExcluded) {
      status = 'on';
    }
    return status;
  }

  /**
   * Helper function used to get the appropriate image depending on given item status
   *
   * @since 1.0.0
   */
  getImageForItem(item?: IItem<ISite<IPlant | string> | string>): string {
    if (!item) {
      return '';
    }

    const itemImageName = this.getImageNameForItem(item.type);
    const itemStatus = this.getItemStatus(item);
    if (itemStatus === 'on' && item.pingExcluded) {
      return `/assets/img/pin/item/excluded/${itemImageName?.toLowerCase()}.png`;
    }

    return `/assets/img/pin/item/${itemStatus}/${itemImageName?.toLowerCase()}.png`;
  }

  /**
   * Helper function used from HTML to calculate human-readable elapsed time
   * since item has become dead
   *
   * @since 1.1.0
   */
  getItemDownTime(deadFrom?: Date): string {
    if (!deadFrom) {
      return '';
    }

    const deadFromObj = moment(deadFrom);
    if (!deadFromObj.isValid()) {
      return '';
    }

    // const elapsed = moment().diff(deadFromObj, 'minutes');
    const value = deadFromObj.fromNow();

    return value;
  }

  getColorForItemOK() {
    return '#28C76F';
  }
  getColorForItemWarning() {
    return '#FF9F43';
  }
  getColorForItemError() {
    return '#EA5455';
  }

  /**
   * Helper function used to get the appropriate color depending on given item status
   *
   * @since 1.0.0
   */
  getColorForItem(deadFrom?: Date): string {
    if (deadFrom) {
      const deadDate = moment(deadFrom);
      if (deadDate.isValid()) {
        const elapsedMinutes = moment().diff(deadDate, 'minutes');
        if (elapsedMinutes < this.configService.maxItemsDowntime) {
          return this.getColorForItemWarning();
        }
      }
      return this.getColorForItemError();
    }
    return this.getColorForItemOK();
  }

  /**
   * Check if given item is down
   *
   * @since 1.1.0
   */
  itemIsDown(item: IItem): boolean {
    const { deadFrom, silenced, pingExcluded } = item;

    if (silenced || pingExcluded) {
      return false;
    }

    if (deadFrom) {
      const deadDate = moment(deadFrom);
      if (deadDate.isValid()) {
        const elapsedMinutes = moment().diff(deadDate, 'minutes');
        if (elapsedMinutes < this.configService.maxItemsDowntime) {
          // Warning
          return false;
        }
      }

      // Error
      return true;
    }

    // Online
    return false;
  }

  /**
   * Check if given item is silenced
   *
   * @since 1.1.0
   */
  itemIsSilenced(item: IItem): boolean {
    const { silenced } = item;
    return silenced || false;
  }

  /**
   * Check if given item is silenced
   *
   * @since 1.1.0
   */
  itemIsExcluded(item: IItem): boolean {
    const { pingExcluded } = item;
    return pingExcluded || false;
  }
}
