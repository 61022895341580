// Core packages
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// Third party packages
import { Subscription, Observable } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import moment from 'moment';


// Custom packages
import { HelperService } from 'src/app/shared/services/helper.service';
import { UsersService } from '../../users.service';
import { User } from 'src/app/shared/models/user/user.model';
import Response from 'src/app/shared/interfaces/response.interface';
import { ConfigService } from 'src/app/shared/services/config.service';
import IAggregation from 'src/app/shared/models/aggregation/aggregation.interface';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';
import { AggregationsService } from 'src/app/modules/aggregations/aggregations.service';
import IPlant from 'src/app/shared/models/plant/plant.interface';
import { PlantsService } from 'src/app/modules/plants/plants.service';
import { PageService } from 'src/app/shared/services/loading.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import IStaticObjectGroup from 'src/app/shared/models/staticObjectGroup/staticObjectGroup.interface';
import { StaticObjectGroupsService } from 'src/app/modules/staticObjectGroups/staticObjectGroups.service';


/**
 * Script start
 */
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.page.html',
  styleUrls: ['./add-user.page.scss'],
})
export class AddUserComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public user: User = new User();
  private plants: IPlant[] = [];
  public userType: string = '';
  public userSubtype: string = '';

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {
    plantsOptions: [],
  };
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Aggiungi',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  // END - Form

  private staticObjectGroups: any[] = [];

  constructor(
    private userService: UsersService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private configService: ConfigService,
    private router: Router,
    private aggregationsService: AggregationsService,
    private plantsService: PlantsService,
    private pageService: PageService,
    private authService: AuthService,
    private staticObjectGroupsService: StaticObjectGroupsService,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    this.staticObjectGroupsService.getList(0, 100, 'name', 'asc')
    .subscribe((res: Response) => {
      if(res.status) {
        if (res.data && Array.isArray(res.data)) {
          for (const item of res.data) {
            this.staticObjectGroups.push({
              label: item.name,
              value: item._id
            });
          }
        }
      }
    });

    this.initForm();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Set user type
   *
   * @since 1.1.0
   */
  setUserType(type: string): void {
    this.userType = type;
  }

  /**
   * Set user subtype
   *
   * @since 1.1.0
   */
  setUserSubtype(subtype: string): void {
    this.userSubtype = subtype;
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            template: '<strong class="d-block mt-4">Permessi aggiuntivi</strong>',
            hideExpression: () => {
              return this.userType !== "customer";
            },
          },
          {
            className: 'col-12 col-md-4',
            key: 'plafond',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: { 
              label: 'Visualizza plafond' 
            },
            expressionProperties: {
              'templateOptions.disabled': () => {
                return this.userType !== "customer";
              },
            },
            hideExpression: () => {
              return this.userType !== "customer";
            },
          },
          {
            className: 'col-12 col-md-4',
            key: 'technician',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: { 
              label: 'Utente tecnico' 
            },
            expressionProperties: {
              'templateOptions.disabled': () => {
                return this.userType !== "customer";
              },
            },
            hideExpression: () => {
              return this.userType !== "customer";
            },
          },
          {
            className: 'col-12',
            template: '<strong class="d-block mt-4">Oggetti statici</strong>',
            hideExpression: () => {
              return this.userType !== "customer";
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'select',
            key: `staticObjectGroupIds`,
            templateOptions: {
              label: 'Elenco gruppi di oggetti statici che può vedere',
              placeholder: 'Seleziona',
              required: false,
              multiple: true,
              options: this.staticObjectGroups,
            },
            hideExpression: () => {
              return this.userType !== "customer";
            },
          },
          // {
          //   className: 'col-12 col-md-4',
          //   key: 'staticObjects',
          //   type: 'checkbox',
          //   defaultValue: false,
          //   templateOptions: { 
          //     label: 'Elenco gruppi di oggetti statici che può vedere' 
          //   },
          //   expressionProperties: {
          //     'templateOptions.disabled': () => {
          //       return this.userType !== "customer";
          //     },
          //   },
          //   hideExpression: () => {
          //     return this.userType !== "customer";
          //   },
          // },
          {
            className: 'col-12 col-md-4',
            key: 'staticObjectManagement',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: { 
              label: 'Gestione oggetti statici' 
            },
            expressionProperties: {
              'templateOptions.disabled': () => {
                return this.userType !== "customer";
              },
            },
            hideExpression: () => {
              return this.userType !== "customer";
            },
          },
          {
            className: 'col-12',
            template: '<strong class="d-block mt-4">Dati utente</strong>',
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'firstName',
            templateOptions: {
              label: 'Nome',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'lastName',
            templateOptions: {
              label: 'Cognome',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
          {
            className: 'col-12 col-md-4',
          },

          {
            className: 'col-12',
            template: '<strong class="d-block mt-3">Dati di accesso</strong>',
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'email',
            templateOptions: {
              label: 'Email',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'password',
            templateOptions: {
              label: 'Password',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
          {
            className: 'col-12 col-md-4',
          },

          {
            className: 'col-12',
            template:
              '<strong class="d-block mt-3">Monitoraggio impianti</strong>',
            hideExpression: () =>
              this.userType === 'admin' || this.userSubtype !== 'demo',
          },

          {
            className: 'col-12 col-md-4',
            type: 'autocomplete',
            key: `aggregationId`,
            templateOptions: {
              label: 'Aggregazione',
              placeholder: 'Cerca...',
              labelExtractor: (obj: IAggregation): string => {
                return obj.name;
              },
              valueExtractor: (obj: IAggregation): string => {
                return obj._id;
              },
              multiple: false,
              search$: (
                start?: number,
                limit?: number,
                sort?: string,
                sortVersus?: string,
                search?: any,
              ): Observable<ListApiResponse> =>
                this.aggregationsService.getList(
                  start,
                  limit,
                  sort,
                  sortVersus,
                  search,
                ),
              clearIcon: 'fa-trash',
              notFoundText: 'Non trovato',
              loadingText: 'Caricamento...',
              change: (field, newVal) => {
                this.getPlantsOfAggregation(newVal);
              },
            },
            // expressionProperties: {
            //   'templateOptions.required':
            //     "!['admin', 'owner'].includes(model.role)",
            // },
            hideExpression: () => this.userType === 'admin',
          },
          {
            className: 'col-12 col-md-4',
            type: 'select',
            key: `plantIds`,
            templateOptions: {
              label: 'Impianti',
              placeholder: 'Seleziona',
              required: false,
              type: 'string',
              multiple: true,
              options: this.plants || [],
            },
            // hideExpression: `!['admin', 'owner'].includes("${this.authService?.loggedUser$?.value?.role}") || ['admin', 'owner'].includes(model.role)`,
            expressionProperties: {
              'templateOptions.options': 'model.plantsOptions',
            },
            hideExpression: () => this.userType === 'admin',
          },

          {
            className: 'col-12 col-md-4',
          },

          {
            className: 'col-12',
            template: '<strong class="d-block mt-3">Durata</strong>',
            hideExpression: () =>
              this.userType === 'admin' || this.userSubtype !== 'demo',
          },
          {
            className: 'col-12 col-md-4',
            type: 'datepicker',
            key: 'expiryDate',
            templateOptions: {
              label: 'Data di scadenza',
              placeholder: 'Seleziona...',
              required: false,
              type: 'string',
              datepickerOptions: {
                min: moment().add('1', 'day').toDate(),
                max: moment().add('365', 'days').toDate(),
              },
            },
            expressionProperties: {
              'templateOptions.description': () => {
                if (!this.model.expiryDate) {
                  return '';
                }
                return `${moment(this.model.expiryDate).diff(
                  moment(),
                  'days',
                )} giorni`;
              },
              'templateOptions.required': () => this.userSubtype === 'demo',
            },
            hideExpression: () =>
              this.userType === 'admin' || this.userSubtype !== 'demo',
          },
        ],
      },
    ];
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(): void {
    // Prevent invalid form submit
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    // Get form values
    const formValues = this.form.value;

    // Add some static formValues
    formValues.active = true;
    formValues.role = this.userType === 'admin' ? 'owner' : 'customer';
    formValues.profile = this.userSubtype;

    if (this.userSubtype === 'demo') {
      formValues.sections = [
        'InfoCliente',
        'Impianti',
        'Mappa',
        'Ticketing',
        'Segnalazioni',
        'Offerte',
      ];
    }
    if (this.userSubtype === 'light') {
      formValues.sections = ['InfoCliente', 'Ticketing'];
    }
    if (this.userSubtype === 'pro') {
      formValues.sections = [
        'InfoCliente',
        'Impianti',
        'Mappa',
        'Ticketing',
        'Segnalazioni',
        'Offerte',
      ];
    }

    // Prevent double submit caused by double btn click
    if (this.formBtnOptions.active) {
      return;
    }

    // Activate submit button loading status
    this.formBtnOptions = { ...this.formBtnOptions, active: true };

    // Send data to BE
    this.subscriptions.push(
      this.userService
        .create(formValues)
        .pipe(
          finalize(
            () =>
              (this.formBtnOptions = { ...this.formBtnOptions, active: false }),
          ),
        )
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              const title = 'Errore';
              let message =
                res.message ||
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
              this.toastrService.error(message, title);
              return;
            }

            this.toastrService.success(res.message);
            this.router.navigate(['/', 'users', res?.data?._id]);
          },
          (err: Response) => {
            // Choose one of the following error handling method.
            // 1) First one show a message right under the form fields
            //    (if the form is properly setted)
            // 2) Second one is used to show a mat-error under each field
            //    in the dynamic form (is meant to be used only in dynamicForm)
            // 3) Third one show toastr notifications for each error
            // 4) Fourth one is used to show mat-error under each field
            //    in the ngx-formly form (is mean to be used only with ngx-formly)
            // this.helperService.handleFormError(form, err);
            // this.helperService.handleDynamicFormError(form, err);
            // this.helperService.handleError(err);
            this.helperService.handleFormlyError(this.form, err);
          },
        ),
    );
  }

  /**
   * Load a list of plants for given aggregationId
   *
   * @since 1.0.0
   */
  async getPlantsOfAggregation(aggregationId: string): Promise<void> {
    if (!aggregationId) {
      return;
    }

    this.subscriptions.push(
      this.plantsService
        .getListOfAggregation(aggregationId, 0, 100, 'name', 'asc')
        .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (res.status) {
              // Reset previous value
              // if (aggregationId !== this.model.aggregaionId) {
              //   console.log('resetto valori impianti');
              //   this.form.get('plantIds')?.setValue([]);
              //   this.model.plantIds = null;
              // }
              console.log('res', res);

              if (res.data?.length) {
                this.plants = res.data.map((plant: IPlant) => ({
                  label: plant.name,
                  value: plant._id,
                }));
              } else {
                this.plants = [];
              }
              this.model.plantsOptions = this.plants;
            }
          },
          (err: Response) => {
            console.warn('error: ', err);
          },
        ),
    );
  }
}
