<div class="row">
  <div class="col-12">
    <mat-label [ngClass]="{'d-none': !to.label }">{{ to.label }}</mat-label>
  </div> <!-- /.col -->
</div> <!-- /.row -->

<div class="row">
  <div class="col-12 ">
    <div class="file-box-wrapper">
      <div class="file-box" *ngFor="let obj of value">
        <div class="img-preview-box" (click)="onOpenImage(obj)"
          [ngStyle]="{'background-image': 'url(' + getFilePreview(obj.file) + ')'}" [title]="obj.title"
          *ngIf="getTempFileIsImage(obj.file)">
        </div> <!-- /.img-preview-box -->
        <div class="doc-preview-box" (click)="onOpenImage(obj)"
          [ngStyle]="{'background-image': 'url(' + getFilePreview(obj.file) + ')'}" [title]="obj.title"
          *ngIf="!getTempFileIsImage(obj.file)">
        </div> <!-- /.doc-preview-box -->
        
        <div class="file-actions">
          <div>{{getTitle(obj.title)}}</div>
          <div class="file-action remove-action" (click)="onRemove(obj)" title="Rimuovi" *ngIf="to.disabled !== true">
            <mat-icon fontSet="fal" class="fa-lg" fontIcon="fa-trash-alt"></mat-icon>
          </div> <!-- /.file-action -->
        </div> <!-- /.file-actions-->
      </div> <!-- /.file-box -->

      <div class="file-box add-file-box" (click)="onAddFile()" *ngIf="to.disabled !== true">
        <div class="file-preview-box" title="Clicca qui per aggiungere un file">
          <mat-icon fontSet="fal" class="fa-lg" fontIcon="fa-circle-plus"></mat-icon>
        </div> <!-- /.file-preview-box -->
      </div> <!-- /.file-box -->
    </div> <!-- /.file-box-wrapper -->
  </div> <!-- /.col -->
</div> <!-- /.row -->
