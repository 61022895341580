<div class="row" [ngClass]="{'d-none': options?.formState.disabled }">
  <div class="col-12">
    <mat-label class="custom-label" [ngClass]="{'d-none': !to.label }">{{ to.label }}</mat-label>
    <app-file-uploader [upload]="triggerFileUpload" [fileReset]="triggerFileReset"
      [allowedFilesNumber]="to.allowedFilesNumber || 0" [allowedFilesExt]="to.allowedFilesExtensions || []"
      (uploadFiles)="onUploadFiles($event)">
    </app-file-uploader>
  </div> <!-- /.col -->
</div> <!-- /.row -->

<div class="row" *ngIf="thereAreUploadedFiles()">
  <div class="col-12">
    <mat-label class="uploaded-files-label">File caricati</mat-label>
    <div class="d-flex flex-wrap flex-row">
      <ng-container *ngFor="let file of uploadedFiles">
        <div class="img-preview-box" *ngIf="!fileHasToBeUploaded(file)">
          <a mat-mini-fab color="warn" class="d-none d-sm-inline-block remove-button"
            (click)="onRemoveUploadedFile(file)">
            <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
          </a>
          <div class="img-preview-image" (click)="onOpenImage(file)"
            [ngStyle]="{'background-image': 'url(' + getTempFilePreview(file) + ')'}">
          </div> <!-- /.img-preview-image -->
        </div> <!-- /.img-preview-box-->
      </ng-container>
    </div> <!-- /.d-flex -->
  </div> <!-- /.col -->
</div> <!-- /.row -->

<div class="row" *ngIf="filesToUpload && filesToUpload.length">
  <div class="col-12">
    <mat-label class="uploaded-files-label">File in attesa di caricamento</mat-label>
    <div class="d-flex">
      <ng-container *ngFor="let file of filesToUpload">
        <div class="img-preview-box">
          <a mat-mini-fab color="warn" class="d-none d-sm-inline-block remove-button"
            (click)="onRemoveUploadedFile(file)">
            <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
          </a>
          <div class="img-preview-image" (click)="onOpenImage(file)"
            [ngStyle]="{'background-image': 'url(' + getTempFilePreview(file) + ')'}">
          </div> <!-- /.img-preview-image -->
        </div> <!-- /.img-preview-box-->
      </ng-container>
    </div> <!-- /.d-flex -->
  </div> <!-- /.col -->
</div> <!-- /.row -->
