<app-page>
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-10">
                <h2 class="d-flex align-items-center mb-0" *ngIf="customers.length > 0">
                  {{ customers[0].aggregationId.name }}
                </h2>
              </div>
              <!-- /.col -->
              <div class="col-2 d-flex justify-content-end">
                <a class="action-btn me-2 bg-warn warn-bg" style="width: auto" (click)="onViewDeadInstallations()"
                  *ngIf="data?.deadInstallations?.length">
                  <div class="d-flex px-3 justify-content-center align-items-center">
                    <mat-icon fontSet="fal" fontIcon="fa-computer" class="fa-lg me-2"></mat-icon>
                    {{ data?.deadInstallations?.length }}
                  </div>
                </a>

                <a class="action-btn" (click)="onViewMap()" *ngIf="

                    this.authService?.loggedUser$?.value?.sections?.length ===
                      0 ||
                    this.authService?.loggedUser$?.value?.sections?.includes(
                      'Impianti'
                    )
                  "
                >
                  <mat-icon
                    fontSet="fal"
                    fontIcon="fa-location-dot"
                    class="fa-lg"
                  ></mat-icon>
                </a>
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="container mb-3">
              <div class="row">
                <ng-container *ngFor="let customer of customers">
                  <div
                    class="col-12 col-md-6 col-lg-4 d-flex align-items-center mb-3"
                  >
                    <div class="me-3">
                      <img
                        src="{{ getLogo(customer.logo) }}"
                        alt="{{ customer.name }}"
                        width="40"
                        style="
                          border-radius: 40px !important;
                          width: 60px;
                          height: 60px;
                          border: 1px solid rgba(0, 0, 0, 0.6);
                          display: inline-block;
                        "
                      />
                    </div>
                    <strong>{{ customer.name }}</strong>
                  </div>
                  <!-- /.col -->
                </ng-container>
              </div>
              <!-- /.row -->
            </div>
            <!-- /.container -->
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!-- /.row -->

    <div class="row mb-4" *ngIf="notifications.length > 0">
      <div class="col-12">
        <mat-card
          class="mat-elevation-z shadow"
          style="background-color: black; color: white"
        >
          <mat-card-content class="px-1 py-1 d-flex">
            <div class="slider-buttons">
              <button (click)="slidePrev()" class="slider-button">
                <mat-icon
                  fontSet="fa"
                  fontIcon="fa-chevron-left"
                  style="color: white"
                >
                </mat-icon>
              </button>
              <button (click)="slideNext()" class="slider-button">
                <mat-icon
                  fontSet="fa"
                  fontIcon="fa-chevron-right"
                  style="color: white"
                >
                </mat-icon>
              </button>
            </div>

            <swiper
              #swiper
              [config]="config"
              (swiper)="onSwiper($event)"
              (slideChange)="onSlideChange()"
            >
              <ng-container *ngFor="let notification of notifications">
                <ng-template swiperSlide>
                  <div
                    class="row py-2"
                    style="
                      max-height: 100px;
                      padding-left: 40px;
                      padding-right: 40px;
                    "
                  >
                    <a
                      routerLink="/notifications/{{ notification._id }}"
                      style="
                        font-size: 16px;
                        line-height: 22px;
                        margin-bottom: 5px;
                        color: var(--color-main);
                        font-weight: bold;
                      "
                      >{{ notification.title }}</a
                    >
                    <a
                      routerLink="/notifications/{{ notification._id }}"
                      style="color: white"
                    >
                      <span>
                        <span
                          [innerHTML]="
                            notification.description | truncate : [200]
                          "
                        ></span>
                        <!-- <a
                        routerLink="/notifications/{{ notification._id }}"
                        style="margin-left: 5px"
                        >Scopri di più</a
                      > -->
                      </span>
                    </a>
                  </div>
                </ng-template>
              </ng-container>
            </swiper>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->

    <div class="row mb-4">
      <div
        class="col-12 col-md-4"
        *ngIf="
          this.authService?.loggedUser$?.value?.sections?.length === 0 ||
          this.authService?.loggedUser$?.value?.sections?.includes(
            'Impianti'
          ) ||
          this.authService?.loggedUser$?.value?.sections?.includes('Offerte')
        "
      >
        <!-- <mat-card
          class="mat-elevation-z shadow"
          *ngIf="
            this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            this.authService?.loggedUser$?.value?.sections?.includes('Impianti')
          "
        >
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-6 col-md-10 d-flex align-items-center">
                <h2 class="d-flex align-items-center mb-0">
                  <mat-icon fontSet="fal" class="fa-lg me-2" fontIcon="fa-clapperboard-play">
                  </mat-icon>
                  Anomalie NVR
                </h2>
              </div>
              <div class="col-6 col-md-2 d-flex justify-content-end">
                <div
                  style="width: 40px; height: 40px; border-radius: 40px !important; background-color: #EA5455; color: white; display: flex; justify-content: center; align-items: center; font-weight: bold;">
                  {{ data?.totals?.nvrAlarms || '0' }}
                </div>
              </div>
            </div>

            <div class="container">
              <div class="row">
                <div class="col-12 ps-4 mb-3">
                  <a routerLink="/nvr-alarms" class="underline">Visualizza</a>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card> -->

        <mat-card
          class="mat-elevation-z shadow"
          *ngIf="
            this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            this.authService?.loggedUser$?.value?.sections?.includes('Impianti')
          "
        >
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-6 col-md-8 d-flex align-items-center">
                <h2 class="d-flex align-items-center mb-0">
                  <mat-icon
                    fontSet="fal"
                    class="fa-lg me-2"
                    fontIcon="fa-triangle-exclamation"
                  >
                  </mat-icon>
                  Segnalazioni
                </h2>
              </div>
              <!-- /.col -->
              <div class="col-6 col-md-4 d-flex justify-content-end">
                <div
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 40px !important;
                    background-color: #ea5455;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                  "
                >
                  {{ data?.totals?.deadItems || '0' }}
                </div>
              </div>
              <!-- /.col-12 -->
            </div>
            <!-- /.row -->

            <div class="container">
              <div class="row">
                <div class="col-12 ps-4 mb-3">
                  <a routerLink="/dead-items" class="underline">Visualizza</a>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card
          class="mat-elevation-z shadow mt-4"
          *ngIf="
            this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            this.authService?.loggedUser$?.value?.sections?.includes('Impianti')
          "
        >
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-6 col-md-8 d-flex align-items-center">
                <h2 class="d-flex align-items-center mb-0">
                  <mat-icon
                    fontSet="fal"
                    class="fa-lg me-2"
                    fontIcon="fa-circle-xmark"
                  >
                  </mat-icon>
                  Guasti noti
                </h2>
              </div>
              <!-- /.col -->
              <div class="col-6 col-md-4 d-flex justify-content-end">
                <div
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 40px !important;
                    background-color: #6d767b;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                  "
                >
                  {{ data?.totals?.silencedItems || '0' }}
                </div>
              </div>
              <!-- /.col-12 -->
            </div>
            <!-- /.row -->

            <div class="container">
              <div class="row">
                <div class="col-12 ps-4 mb-3">
                  <a routerLink="/silenced-items" class="underline"
                    >Visualizza</a
                  >
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card
          class="mat-elevation-z shadow mt-4"
          *ngIf="
            this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            this.authService?.loggedUser$?.value?.sections?.includes('Offerte')
          "
        >
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-6 col-md-8 d-flex align-items-center">
                <h2 class="d-flex align-items-center mb-0">
                  <mat-icon
                    fontSet="fal"
                    class="fa-lg me-2"
                    fontIcon="fa-files"
                  >
                  </mat-icon>
                  Offerte
                </h2>
              </div>
              <!-- /.col -->
              <div class="col-6 col-md-4 d-flex justify-content-end">
                <div
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 40px !important;
                    background-color: black;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                  "
                >
                  {{ data?.totals?.offers || '0' }}
                </div>
              </div>
              <!-- /.col-12 -->
            </div>
            <!-- /.row -->

            <div class="container">
              <div class="row">
                <div class="col-12 ps-4 mb-3">
                  <a routerLink="/offers" class="underline">Visualizza</a>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- /.col -->

      <div
        class="col-12 col-md-4"
        *ngIf="
          this.authService?.loggedUser$?.value?.sections?.length === 0 ||
          this.authService?.loggedUser$?.value?.sections?.includes('Ticketing')
        "
      >
        <mat-card class="mat-elevation-z shadow">
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-12 col-md-9 d-flex align-items-center">
                <h2 class="mb-0">
                  <mat-icon fontSet="fal" class="fa-lg me-2" fontIcon="fa-bell">
                  </mat-icon>
                  Ticketing
                </h2>
              </div>
              <!-- /.col -->
              <div
                class="col-12 col-md-3 d-flex align-items-center justify-content-end"
              >
                <div
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 40px !important;
                    background-color: #ea5455;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                  "
                >
                  {{ data?.totals?.openTickets || '0' }}
                </div>
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->

            <div
              class="container"
              style="
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                margin-bottom: 15px;
              "
            >
              <div class="row">
                <div class="col-12 ps-3 mb-3">
                  <a routerLink="/tickets/list" class="underline"
                    >Visualizza tutti</a
                  >
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->
            </div>
            <!-- /.container -->

            <div
              class="container"
              style="
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                margin-bottom: 15px;
              "
            >
              <div class="row">
                <div class="col-12 mb-4">
                  <small style="font-size: 0.7em">ULTIMI APERTI</small>
                </div>
              </div>
              <ng-container *ngFor="let ticket of tickets">
                <div class="row mb-4">
                  <div class="col d-flex">
                    <!-- <div
                      style="margin-left: 5px; margin-right: 12px; margin-top: 5px; width: 45px; height: 45px; border-radius: 45px !important;">
                      <img src="{{ helperService.getImageForItem(ticket.items?.[0]) }}" width="45px" />
                    </div> -->
                    <div>
                      <a [routerLink]="'/tickets/' + ticket._id">
                        <h4 class="mb-0 underline">
                          {{ ticket?.subject }}
                        </h4>
                      </a>
                      <span class="d-block" style="color: gray">{{
                        ticket.aggregationId.name
                      }}</span>
                      <div class="d-flex align-items-center mt-1">
                        <div
                          [innerHTML]="getTiketStatus(ticket.status)"
                          class="me-2"
                        ></div>
                        <a [routerLink]="'/tickets/' + ticket._id"
                          >Visualizza ticket</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- /.container -->

            <button
              mat-raised-button
              routerLink="/tickets/add"
              color="primary"
              class="d-block ms-auto me-auto mb-3"
            >
              Apri nuova segnalazione
            </button>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- /.col -->

      <div
        class="col-12 col-md-4"
        *ngIf="
          this.authService?.loggedUser$?.value?.sections?.length === 0 ||
          this.authService?.loggedUser$?.value?.sections?.includes('Impianti')
        "
      >
        <mat-card class="mat-elevation-z shadow">
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-12 col-md-8 d-flex align-items-center">
                <h2 class="mb-0">Info impianto</h2>
              </div>
              <!-- /.col -->
              <div
                class="col-12 col-md-4 d-flex align-items-center justify-content-end"
              >
                <!-- <small>Ultimi 7 giorni</small> -->
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="container">
                    <div class="gauge">
                      <div class="outer">
                        <div class="inner">
                          <div class="value">
                            <span
                              style="
                                font-size: 0.8rem;
                                margin-bottom: 10px;
                                display: inline-block;
                              "
                              >Apparati in funzione</span
                            ><br />
                            {{ getPercentage() | number : '1.0-0' }}%
                          </div>
                        </div>
                        <div
                          class="marker"
                          data-testid="position-element"
                          style="transform: rotate({{ getPercentage() }}deg)"
                        >
                          <div class="circle"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->
            </div>
            <!-- /.container -->

            <div
              class="container"
              style="border-top: 1px solid rgba(0, 0, 0, 0.2)"
            >
              <div class="row">
                <div
                  class="col"
                  style="
                    border-right: 1px solid rgba(0, 0, 0, 0.2);
                    padding-top: 15px;
                    padding-bottom: 15px;
                    text-align: center;
                  "
                >
                  <span style="opacity: 0.8">Totale apparati</span><br />
                  <span style="font-weight: bold">{{
                    data?.totals?.items
                  }}</span>
                </div>
                <div
                  class="col"
                  style="
                    padding-top: 15px;
                    padding-bottom: 15px;
                    text-align: center;
                  "
                >
                  <span style="opacity: 0.8">In funzione</span><br />
                  <span style="font-weight: bold">{{
                    data?.totals?.items -
                      data?.totals?.deadItems -
                      data?.totals?.silencedItems
                  }}</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->

    <div class="row">
      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <div class="card-header">
            <div class="col-12 col-md-9 d-flex align-items-center">
              <mat-card-title>
                <h1>Statistiche di funzionamento delle installazioni</h1>
              </mat-card-title>
            </div>
            <!-- /.col -->
            <div class="col-12 col-md-3 d-flex justify-content-end"></div>
            <!-- /.col-12 -->
          </div>
          <!-- /.card-header -->

          <mat-card-content class="">
            <div *ngIf="!loading" style="width: 100%; height: 400px">
              <!-- style="min-width: 600px;" -->
              <canvas
                baseChart
                width="1000"
                height="400"
                class="linechart"
                [data]="lineChartData"
                [options]="lineChartOptions"
                [type]="'line'"
              >
              </canvas>
            </div>
            <div class="row" *ngIf="loading">
              <div class="col align-items-center justify-content-center d-flex">
                <div class="spinner-container">
                  <mat-spinner
                    strokeWidth="3"
                    width
                    [diameter]="100"
                    mode="indeterminate"
                  ></mat-spinner>
                </div>
                <!-- /.spinner-container -->
              </div>
            </div>

            <br /><br />
            <p>
              Il grafico soprastante riporta l'andamento del funzionamento delle
              installazioni. Per ciascuna installazione è presente una linea nel
              grafico che mostra la percentuale media di funzionamento
              dell'installazione in quel giorno.
            </p>
          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <!-- <div class="row mt-4">
      <div class="col-12">
        <app-customer-dashboard-chart></app-customer-dashboard-chart>
      </div>
    </div> -->
  </div> <!-- /.container -->
</app-page>
