<h1 mat-dialog-title>Dispositivi da attenzionare</h1>

<mat-dialog-content>
  <div class="form-wrapper mt-3 mb-3">
    <div class="loader-wrapper" *ngIf="formLoading">
      <div class="loader">
        <mat-spinner></mat-spinner>
        <p class="mt-4 text-center">Caricamento...</p>
      </div> <!-- /.loader -->
    </div> <!-- /.loader-wrapper -->
    <form [formGroup]="form" (ngSubmit)="onFormSubmit(model)" class="">
      <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options" class="d-block mb-3">
      </formly-form>
    </form>
  </div> <!-- /.form-wrapper -->
</mat-dialog-content>

<mat-dialog-actions>
  <div class="col-12 col-md-4">
    <button role="button" mat-stroked-button color="primary" (click)="onDenial()" class="w-100 w-md-auto">
      Annulla
    </button>
  </div> <!-- /.col -->
  <div class="col-12 col-md-4">
    <button role="button" mat-raised-button color="primary" (click)="onCreateticket()" class="w-100 w-md-auto">
      Crea ticket
    </button>
    <button role="button" mat-raised-button color="primary" (click)="OnSendEmail()" class="w-100 w-md-auto">
      E-mail
    </button>
  </div> <!-- /.col -->
  <div class="col-12 col-md-4 mt-4 mt-md-0 text-right">
    <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate mr-3" (click)="onFormSubmit()">
    </mat-spinner-button>
  </div> <!-- /.col -->
</mat-dialog-actions>
