<mat-label class="custom-label" [ngClass]="{'d-none': !to.label }">{{ to.label }}</mat-label>
<div class="row" *ngIf="!(uploadedFiles && uploadedFiles.length)">
  <div class="col-12">
    <app-file-uploader [upload]="triggerFileUpload" [fileReset]="triggerFileReset"
      [allowedFilesNumber]="to.allowedFilesNumber" [allowedFilesExt]="to.allowedFilesExtensions"
      (uploadFiles)="onUploadFiles($event)" [ngClass]="{'d-none': options?.formState?.disabled || to.disabled }"
      [hint]="to.description">
    </app-file-uploader>
  </div>
</div>

<div class="row mb-3">
  <div class="col-12 justify-content-around">
    <!-- <div class="d-block font-italic" *ngIf="!fileIsUploading && !uploadedFiles.length">
      Nessun file in attesa di caricamento
    </div> -->
    <div class="d-block mt-4" *ngIf="fileIsUploading">
      <span>Caricamento...</span>
    </div>
    <!-- <div class="d-block" *ngIf="uploadedFiles && uploadedFiles.length">
      <strong>
        File:
      </strong>
    </div> -->
    <div *ngFor="let file of uploadedFiles" class="row">
      <div class="img-preview-box">
        <a *ngIf="action !=='show'" mat-mini-fab class="d-none d-sm-inline-block remove-button" (click)="onRemove(file)">
          <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
        </a>
        <div style="position: relative;">
          <div [ngStyle]="{'background-image': 'url(' + getTempFilePreview(file) + ')'}"
            class="img-div"
            *ngIf="getTempFileIsImage(file)">
          </div>
          <div class="btn-download-wrapper">
            <button
              class="btn-download"
              type="button" (click)="onDownloadImage(file)" >
              <i class="fa-sharp fa-regular fa-down-to-bracket"></i>
            </button>
          </div>
        </div>

        <div [ngStyle]="{'background-image': 'url(' + getTempFilePreview(file) + ')'}"
          class="doc-div"
          *ngIf="!getTempFileIsImage(file)">
        </div>
        <!-- <img src="{{ getTempFilePreview(file) }}" alt="{{ file.originalName }}" (click)="onOpenImage(file)"
          style="max-width: 100px; max-height: 100px"> -->
      </div> <!-- /.img-preview-box -->
      <!-- <div class="row" *ngIf="file?.originalName?.split('.')?.pop()?.toLowerCase() === 'pdf'">
        <div class="col-12 file-preview">
          <mat-icon class="mr-2" fontSet="fal" fontIcon="fa-file-pdf"></mat-icon>
          <div class="font-italic">{{ file.originalName }} ({{ getFormattedSize(file.size) }} KB)</div>
          <a *ngIf="action !=='show'" mat-icon-button color="warn" class="d-none d-sm-inline-block" (click)="onRemove(file)">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</div>
