<div class="row">
  <div class="col-12">
    <mat-label [ngClass]="{'d-none': !to.label }">{{ to.label }}</mat-label>
  </div> <!-- /.col -->
</div> <!-- /.row -->

<div class="row">
  <div class="col-12 ">
    <div class="file-box-wrapper">
      <div class="file-box" *ngFor="let file of value">
        <div class="file-preview-box" (click)="onOpenImage(file)"
          [ngStyle]="{'background-image': 'url(' + getFilePreview(file) + ')'}" [title]="file?.originalName">

        </div> <!-- /.file-preview-box -->
        <div class="file-actions">
          <div class="file-action remove-action" (click)="onRemove(file)" title="Rimuovi" *ngIf="to.disabled !== true">
            <mat-icon fontSet="fal" class="fa-lg" fontIcon="fa-trash-alt"></mat-icon>
          </div> <!-- /.file-action -->
        </div> <!-- /.file-actions-->
      </div> <!-- /.file-box -->

      <div class="file-box add-file-box" (click)="onAddFile()" *ngIf="to.disabled !== true">
        <div class="file-preview-box" title="Clicca qui per aggiungere un file">
          <mat-icon fontSet="fal" class="fa-lg" fontIcon="fa-circle-plus"></mat-icon>
        </div> <!-- /.file-preview-box -->
      </div> <!-- /.file-box -->
    </div> <!-- /.file-box-wrapper -->
  </div> <!-- /.col -->
</div> <!-- /.row -->
