// Core packages
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Third party packages
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// Custom packages

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class ApiLoaderService {
  private loaded: boolean = false;
  constructor(private httpClient: HttpClient) {}

  loadApi(): Observable<boolean> {
    // const API_KEY = 'AIzaSyCZekP8ZQRBnXe3VIKVDCf65zKAYaS0VlU'; // PRODUCTION
    // const API_KEY = 'AIzaSyCZekP8ZQRBnXe3VIKVDCf65zKAYaS0VlU'; // DEVELOPMENT
    if (this.loaded) {
      return of(true);
    }

    return this.httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places&language=it`,

        'callback',
      )
      .pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
        map(() => true),
        // map(() => {
        //   this.httpClient
        //     .jsonp(
        //       'https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier/1.0.3/oms.min.js',
        //       '',
        //     )
        //     .pipe(map(() => (this.loaded = true)));
        //   return true;
        // }),
        map(() => (this.loaded = true)),
        catchError((e) => {
          console.log(e);
          return of(false);
        }),
      );
  }
}
