// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

// Custom packages
import { ApiService } from './api.service';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class FileService {
  /**
   * Class constructor
   */
  constructor(private apiService: ApiService) {}

  /**
   * Upload file
   *
   * @since 1.0.0
   *
   * @returns Observable<any>
   */
  uploadTempFile(file: FormData): Observable<any> {
    // We call [GET] /auth to refresh the JWT before the upload
    return new Observable((observer) => {
      this.apiService
        .apiCall('GET', 'auth')
        .pipe(take(1))
        .subscribe(() => {
          this.apiService
            .apiCall('POST', 'temp-files', file, {
              contentType: 'auto',
            })
            .subscribe((res) => {
              return observer.next(res);
            });
        });
    });
  }
}
