<app-page>
  <div class="container-fluid">

    <mat-card class="mb-4 shadow">
      <div class="container-fluid">
        <div class="row align-items-center justify-content-between py-3">


          <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-between align-items-center p-0">
            <div class="c-wrapper">
              <div class="c-1">
                <mat-icon fontSet="fal" class="fa-2x me-2" fontIcon="fa-circle-dot">
                </mat-icon>
                <span>AGGREGAZIONI</span>
              </div>
              <span class="c-2">
                {{ data?.totals?.aggregations }}
              </span>
            </div>
            <div
              style="height: 60px; width: 1px; background-color: rgba(0, 0, 0, 0.2); border-radius: 10px !important;">
            </div>
          </div> <!-- /.col -->

          <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-between align-items-center p-0">
            <div class="c-wrapper">
              <div class="c-1">
                <mat-icon fontSet="fal" class="fa-2x me-2" fontIcon="fa-cabinet-filing">
                </mat-icon>
                <span>CLIENTI</span>
              </div>
              <span class="c-2">
                {{ data?.totals?.customers }}
              </span>
            </div>
            <div
              style="height: 60px; width: 1px; background-color: rgba(0, 0, 0, 0.2); border-radius: 10px !important;">
            </div>
          </div> <!-- /.col -->

          <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-between align-items-center p-0">
            <div class="c-wrapper">
              <div class="c-1">
                <mat-icon fontSet="fal" class="fa-2x me-2" fontIcon="fa-circle-nodes">
                </mat-icon>
                <span>IMPIANTI</span>
              </div>
              <span class="c-2">
                {{ data?.totals?.plants }}
              </span>
            </div>
            <div
              style="height: 60px; width: 1px; background-color: rgba(0, 0, 0, 0.2); border-radius: 10px !important;">
            </div>
          </div> <!-- /.col -->

          <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-between align-items-center p-0">
            <div class="c-wrapper">
              <div class="c-1">
                <mat-icon fontSet="fal" class="fa-2x me-2" fontIcon="fa-tower-broadcast">
                </mat-icon>
                <span>APPARATI</span>
              </div>
              <span class="c-2">
                {{ data?.totals?.items | number }}
              </span>
            </div>
          </div> <!-- /.col -->

        </div> <!-- /.row -->
      </div>
    </mat-card>

    <div class="row">
      <div class="col-12 col-md-4">

        <mat-card class="mat-elevation-z shadow">
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-6 col-md-10 d-flex align-items-center">
                <h2 class="d-flex align-items-center mb-0">
                  <mat-icon fontSet="fal" class="fa-lg me-2" fontIcon="fa-clapperboard-play">
                  </mat-icon>
                  Anomalie NVR
                </h2>
              </div> <!-- /.col -->
              <div class="col-6 col-md-2 d-flex justify-content-end">
                <div
                  style="width: 40px; height: 40px; border-radius: 40px !important; background-color: #EA5455; color: white; display: flex; justify-content: center; align-items: center; font-weight: bold;">
                  {{ data?.totals?.nvrAlarms || '0' }}
                </div>
              </div> <!-- /.col-12 -->
            </div> <!-- /.row -->

            <div class="container">
              <div class="row">
                <div class="col-12 ps-4 mb-3">
                  <a routerLink="/nvr-alarms" class="underline">Visualizza</a>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="mat-elevation-z shadow mt-4">
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-6 col-md-10 d-flex align-items-center">
                <h2 class="d-flex align-items-center mb-0">
                  <mat-icon fontSet="fal" class="fa-lg me-2" fontIcon="fa-computer">
                  </mat-icon>
                  Local App
                </h2>
              </div> <!-- /.col -->
              <div class="col-6 col-md-2 d-flex justify-content-end">
                <div
                  style="width: 40px; height: 40px; border-radius: 40px !important; background-color: #EA5455; color: white; display: flex; justify-content: center; align-items: center; font-weight: bold;">
                  {{ data?.totals?.deadInstallations || '0' }}
                </div>
              </div> <!-- /.col-12 -->
            </div> <!-- /.row -->

            <div class="container">
              <div class="row">
                <div class="col-12 ps-4 mb-3">
                  <a routerLink="/installations" class="underline">Visualizza</a>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="mat-elevation-z shadow mt-4">
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-6 col-md-10 d-flex align-items-center">
                <h2 class="d-flex align-items-center mb-0">
                  <mat-icon fontSet="fal" class="fa-lg me-2" fontIcon="fa-triangle-exclamation">
                  </mat-icon>
                  Segnalazioni
                </h2>
              </div> <!-- /.col -->
              <div class="col-6 col-md-2 d-flex justify-content-end">
                <div
                  style="width: 40px; height: 40px; border-radius: 40px !important; background-color: #EA5455; color: white; display: flex; justify-content: center; align-items: center; font-weight: bold;">
                  {{ data?.totals?.deadItems || '0' }}
                </div>
              </div> <!-- /.col-12 -->
            </div> <!-- /.row -->

            <div class="container">
              <div class="row">
                <div class="col-12 ps-4 mb-3">
                  <a routerLink="/dead-items" class="underline">Visualizza</a>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="mat-elevation-z shadow mt-4">
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-6 col-md-10 d-flex align-items-center">
                <h2 class="d-flex align-items-center mb-0">
                  <mat-icon fontSet="fal" class="fa-lg me-2" fontIcon="fa-circle-xmark">
                  </mat-icon>
                  Guasti noti
                </h2>
              </div> <!-- /.col -->
              <div class="col-6 col-md-2 d-flex justify-content-end">
                <div
                  style="width: 40px; height: 40px; border-radius: 40px !important; background-color: #6D767B; color: white; display: flex; justify-content: center; align-items: center; font-weight: bold;">
                  {{ data?.totals?.silencedItems || '0' }}
                </div>
              </div> <!-- /.col-12 -->
            </div> <!-- /.row -->

            <div class="container">
              <div class="row">
                <div class="col-12 ps-4 mb-3">
                  <a routerLink="/silenced-items" class="underline">Visualizza</a>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

      </div> <!-- /.col -->

      <div class="col-12 col-md-4 mt-4 mt-md-0">
        <mat-card class="mat-elevation-z shadow">
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-12 col-md-9 d-flex align-items-center">
                <h2 class="mb-0">
                  <mat-icon fontSet="fal" class="fa-lg me-2" fontIcon="fa-bell">
                  </mat-icon>
                  Ticketing
                </h2>
              </div> <!-- /.col -->
              <div class="col-12 col-md-3 d-flex align-items-center justify-content-end">
                <div
                  style="width: 40px; height: 40px; border-radius: 40px !important; background-color: #EA5455; color: white; display: flex; justify-content: center; align-items: center; font-weight: bold;">
                  {{ data?.totals?.openTickets || '0' }}
                </div>
              </div> <!-- /.col -->
            </div> <!-- /.row -->

            <div class="container" style="border-bottom: 1px solid rgba(0, 0, 0, 0.2); margin-bottom: 15px">
              <div class="row">
                <div class="col-12 ps-3 mb-3">
                  <a routerLink="/tickets/list" class="underline">Visualizza tutti</a>
                </div> <!-- /.col -->
              </div> <!-- /.row -->
            </div> <!-- /.container -->

            <div class="container">
              <div class="row">
                <div class="col-12 mb-4">
                  <small style="font-size: 0.7em">ULTIMI APERTI</small>
                </div>
              </div>
              <ng-container *ngFor="let ticket of tickets">
                <div class="row mb-4">
                  <div class="col d-flex">
                    <!-- <div
                      style="margin-left: 5px; margin-right: 12px; margin-top: 5px; width: 45px; height: 45px; border-radius: 45px !important;">
                      <img src="{{ helperService.getImageForItem(ticket.items?.[0]) }}" width="45px" />
                    </div> -->
                    <div>
                      <a [routerLink]="'/tickets/' + ticket._id">
                        <h4 class="mb-0 underline">
                          {{ticket?.subject}}
                        </h4>
                      </a>
                      <span class="d-block" style="color: gray">{{ ticket.aggregationId.name }}</span>
                      <div class="d-flex align-items-center mt-1">
                        <div [innerHTML]="getTiketStatus(ticket.status)" class="me-2"></div>
                        <!-- <a [routerLink]="'/tickets/' + ticket._id"  class="underline">Visualizza ticket</a> -->
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div> <!-- /.container -->

          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->

      <div class="col-12 col-md-4 mt-4 mt-md-0">
        <mat-card class="mat-elevation-z shadow">
          <mat-card-content class="p-0">
            <div class="row py-3 px-3">
              <div class="col-12 col-md-8 d-flex align-items-center">
                <h2 class="mb-0">Supporto clienti</h2>
              </div> <!-- /.col -->
              <div class="col-12 col-md-4 d-flex align-items-center justify-content-end">
                <!-- <small>Ultimi 7 giorni</small> -->
              </div> <!-- /.col -->
            </div> <!-- /.row -->

            <div class="container">
              <div class="row">
                <div class="col-12">
                  <!-- <div class="donut-chart chart1">
                    <div class="slice one"></div>
                    <div class="slice two"></div>
                    <div class="chart-center">
                      <span></span>
                    </div>
                  </div> -->

                  <div class="container">
                    <div class="gauge">
                      <div class="outer">
                        <div class="inner">
                          <div class="value">
                            <span style="font-size: 0.8rem; margin-bottom: 10px; display: inline-block;">Ticket
                              completati</span><br>
                            {{ getPercentage() | number: '1.0-0'}}%
                          </div>
                        </div>
                        <div class="marker" data-testid="position-element"
                          style="transform: rotate({{ getPercentage(true) }}deg)">
                          <div class='circle'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> <!-- /.col -->
              </div> <!-- /.row -->
            </div> <!-- /.container -->


            <div class="container" style="border-top: 1px solid rgba(0, 0, 0, 0.2); ">
              <div class="row">
                <div class="col"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.2); padding-top: 15px; padding-bottom: 15px; text-align: center;">
                  <span style="opacity: 0.8">Totali</span><br>
                  <span style="font-weight: bold">{{ data?.tickets?.total }}</span>
                </div>
                <div class="col"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.2); padding-top: 15px; padding-bottom: 15px; text-align: center;">
                  <span style="opacity: 0.8">Nuovi</span><br>
                  <span style="font-weight: bold">{{ data?.tickets?.new }}</span>
                </div>
                <div class="col" style="padding-top: 15px; padding-bottom: 15px; text-align: center;">
                  <span style="opacity: 0.8">Aperti</span><br>
                  <span style="font-weight: bold">{{ data?.tickets?.open }}</span>
                </div>
              </div>
            </div>

          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

  </div> <!-- /.container -->
</app-page>
