// Core packages
import { Component, OnInit, Inject, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Third party packages
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  startWith,
} from "rxjs/operators";
import moment from 'moment';

// Custom packages
import { HelperService } from 'src/app/shared/services/helper.service';
import { HighlightItemsService } from 'src/app/modules/highlightItems/highlightItems.service';
import { CustomersService } from 'src/app/modules/customers/customers.service';
import Response from 'src/app/shared/interfaces/response.interface';
import ISite from 'src/app/shared/models/site/site.interface';
import IItem from 'src/app/shared/models/item/item.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-highlight-item-remove-dialog',
  templateUrl: './highlight-item-remove-dialog.component.html',
  styleUrls: ['./highlight-item-remove-dialog.component.scss'],
})
export class HighlightItemRemoveDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model!: {
    items: any[];
  };
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Conferma',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  // END - Form
  formLoading = true;

  constructor(
    public dialogRef: MatDialogRef<HighlightItemRemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private highlightItemsService: HighlightItemsService,
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    // Get dead item list
    this.subscriptions.push(
      this.highlightItemsService
        .getList(0, 100, 'createdAt', 'desc', { siteId: this.data.siteId })
        // .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Something bad happened
            }
            this.initForm(res.data);
          },
          (err: Response) => {
            this.helperService.handleError(err);
          },
        ),
    );
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(data: any[]): void {
    // Set model
    if (data && Array.isArray(data)) {
      for (const el of data) {
        if (el.highlightDate && moment(el.highlightDate).isValid()) {
          el.check = true;
        }
        const name = this.helperService.getNameForItemType(el.type);
        el.itemName = `${name} - ${el.ip}`;
        el.name = `${name} - ${el.ip}`;
      }
    }
    this.model = {
      items: data,
    };

    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        className: 'col-12',
        key: "items",
        type: "repeat-section",
        fieldArray: {
          fieldGroupClassName: "row",
          fieldGroup: [
            {
              key: "_id",
              defaultValue: "",
              templateOptions: {
                type: "hidden",
              },
            },
            {
              key: 'name',
              templateOptions: {
                type: "hidden",
              }
            },
            {
              className: "col-1",
              type: "checkbox",
              key: "check",
              defaultValue: false,
              templateOptions: {
                required: false,
              },
            },
            {
              className: "col-5",
              type: 'input',
              key: 'itemName',
              templateOptions: {
                disabled: true,
              }
            },
            {
              className: 'col-3',
              type: 'datepicker',
              key: 'highlightDate',
              templateOptions: {
                label: 'Data attenzione',
                placeholder: 'Inserisci qui...',
                required: false,
                type: 'string',
              },
              expressionProperties: {
                "templateOptions.disabled": "!model.check",
                "templateOptions.required": "model.check",
              },
            },
            {
              className: 'col-3',
              type: 'textarea',
              key: 'highlightNote',
              templateOptions: {
                label: 'Inserisci nota',
                placeholder: 'Inserisci qui...',
                required: false,
                type: 'string',
                // rows: 5,
              },
              expressionProperties: {
                "templateOptions.disabled": "!model.check",
              },
            },
          ]
        }
      },
    ];

    this.formLoading = false;
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(model?: any): void {
    model = this.form.getRawValue();

    // Prevent invalid form submit
    if (this.form.invalid) {
      console.warn('this.form', this.form);
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    this.dialogRef.close(model);
  }
}
