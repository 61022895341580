// Core packages
import { Component, OnInit, Inject } from '@angular/core';

// Third party packages
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';

// Custom packages

/**
 * Script start
 */
@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
})
export class WarningDialogComponent implements OnInit {
  messageOnly = false;
  constructor(
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    // Set default icons if none are provided
    if (!this.data.btnOkIcon) {
      this.data.btnOkIcon = 'fa-check-circle';
    }
    if (!this.data.btnCancelIcon) {
      this.data.btnCancelIcon = 'fa-times-circle';
    }

    // Set default button colors if none are provided
    if (!this.data.btnCancelColor) {
      this.data.btnCancelColor = 'primary';
    }
  }

  getDateFromNow(date: Date) {
    return moment(date).fromNow();
  }
  getDateFormat(date: Date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }
}
