// Core packages
import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';

// Third party packages
import { Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  take,
} from 'rxjs/operators';

// Custom packages
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { PageService } from 'src/app/shared/services/loading.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import ICustomer from '../../models/customer/customer.interface';
import { CustomersService } from 'src/app/modules/customers/customers.service';
import Response from '../../interfaces/response.interface';
import { MapDialogComponent } from '../dialogs/map-dialog/map-dialog.component';
import { ApiService } from '../../services/api.service';

/**
 * Script start
 */
export interface SidenavItem {
  name: string; // Name of the item. This will be seen from the user
  icon?: string; // Item's icon, shows before the name
  route?: string; // Route invoked on item's click
  routeBlacklist?: string[]; // Routes not to consider as "active"
  items?: SidenavItem[]; // Children
  permittedRoles: string[]; // List of roles who can see the route
  hidden?: boolean; // Weather if this item should be hidden
  badge?: {
    value: number;
    class?: string;
    style?: string;
  };
}

@HostListener('window:resize', ['$event'])
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @ViewChild('sidenav', { static: false }) public sidenav:
    | MatSidenav
    | undefined;
  sidenavInitialized = false;
  options: any;
  navItems: SidenavItem[] = [];
  activeRoute: string = '';
  screenHeight: any;
  screenWidth: any;
  mobileScreenWidth = 768;
  hdScreenWidth = 991; // 1369;
  public appLoading: boolean = false;
  public currentRole!: string;
  private customerAllowedCustomers: ICustomer[] = [];

  /**
   * Class constructor
   */
  constructor(
    private router: Router,
    private sidenavService: SidenavService,
    private pageService: PageService,
    public configService: ConfigService,
    public authService: AuthService,
    private customersService: CustomersService,
    private dialog: MatDialog,
    private apiService: ApiService,
  ) {
    this.getScreenSize();
    this.currentRole = this.authService.loggedUser$?.value?.role as string;
    this.getCustomers();
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Set sidenav options
    const defaultOpen =
      this.screenWidth <= this.mobileScreenWidth ? false : true;
    this.options = {
      topGap: 0,
      bottomGap: 0,
      fixed: true,
      defaultOpen,
    };

    this.activeRoute = this.router.routerState.snapshot.url;

    // Close sidenav on route select
    this.router.events.subscribe(() => {
      if (window.innerWidth < this.hdScreenWidth) {
        this.close();
      }
    });

    // Subscribe to "app loading" event
    this.subscriptions.push(
      this.pageService.loading$
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((val: boolean) => (this.appLoading = val)),
    );
  }

  /**
   * After view has been initialized
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);

    // Get current route for active status
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.url;
        // Close sidenav on mobile after user
        // clicked on some item
        if (this.screenWidth <= this.mobileScreenWidth) {
          this.close();
        }
      }
    });
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Get the list of customers fo logged user
   * This use used only if logged used is a customer and it's used to
   * decide if we have to show an "Info" button or a "Clienti" button
   *
   * @since 1.0.0
   */
  private async getCustomers(): Promise<void> {
    // This is used only by customers
    if (this.authService?.loggedUser$?.value?.role !== 'customer') {
      if (
        this.authService?.loggedUser$?.value?.role !== 'admin' &&
        (this.authService?.loggedUser$?.value?.role === 'owner' &&
        !this.authService?.loggedUser$?.value?.superOwner)
      ) {
        this.navItems = this.getNavItems()
          .filter((item: SidenavItem) => 
            item.name !== 'Oggetti statici' && 
            item.name !== 'Gruppi' &&
            item.name !== 'Categorie' &&
            item.name !== 'Oggetti'
        );
        
      } else {
        this.navItems = this.getNavItems();
      }
      this.loadBadges();
      return;
    }

    this.subscriptions.push(
      this.customersService
        .getList(0, 10, 'createdAt', 'desc')
        .pipe(
          finalize(() => {
            // Get nav items for sidenav menu
            if (
              this.authService?.loggedUser$?.value?.role === 'customer' &&
              !this.authService?.loggedUser$?.value?.plafond
            ) {
              this.navItems = this.getNavItems()
                .filter((item: SidenavItem) => item.name !== 'Plafond');
            } else if (
              this.authService?.loggedUser$?.value?.role !== 'admin' && 
              (this.authService?.loggedUser$?.value?.role === 'owner' &&
              !this.authService?.loggedUser$?.value?.superOwner)
            ) {
              this.navItems = this.getNavItems()
                .filter((item: SidenavItem) => 
                  item.name !== 'Oggetti statici' && 
                  item.name !== 'Gruppi' &&
                  item.name !== 'Categorie' &&
                  item.name !== 'Oggetti'
              );
              
            } else {
              this.navItems = this.getNavItems();
            }
            this.loadBadges();
          }),
        )
        .subscribe((res: Response) => {
          if (res.status) {
            this.customerAllowedCustomers = res.data;
          }
        }),
    );
  }

  /**
   * Handle sidenav close event closing sidenav
   *
   * @since 1.0.0
   */
  close(): void {
    this.sidenav?.close();
  }

  /**
   * Get nav items
   *
   * @since 1.0.0
   *
   * @returns array of sidenavItems objects
   */
  getNavItems(): SidenavItem[] {
    const items: SidenavItem[] = [
      {
        name: 'Dashboard',
        icon: 'fa-home',
        route: '/',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name: 'Dashboard',
        icon: 'fa-home',
        route: '/dashboard',
        permittedRoles: ['customer'],
      },
      {
        name: 'Anagrafiche',
        permittedRoles: ['admin', 'owner', 'customer'],
      },
      {
        name: 'Aggregazioni',
        icon: 'fa-circle-dot',
        route: '/aggregations',
        routeBlacklist: ['/aggregations/settings'],
        permittedRoles: ['admin', 'owner'],
        // badge: {
        //   value: 10,
        // },
      },
      {
        name:
          this.authService?.loggedUser$?.value?.role === 'customer'
            ? 'Info'
            : 'Clienti',
        icon:
          this.authService?.loggedUser$?.value?.role === 'customer'
            ? 'fa-info-circle'
            : 'fa-cabinet-filing',
        route:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          this.customerAllowedCustomers.length === 1
            ? `/customers/${this?.customerAllowedCustomers?.[0]?._id}`
            : '/customers',
        permittedRoles: ['admin', 'owner', 'customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          (this.customerAllowedCustomers.length === 1 ||
            this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            !this.authService?.loggedUser$?.value?.sections?.includes(
              'InfoCliente',
            )),
      },
      {
        name: 'Info generali',
        icon: 'fa-circle-info',
        route: `/customers/${this?.customerAllowedCustomers?.[0]?._id}`,
        permittedRoles: ['customer'],
        hidden:
          this.customerAllowedCustomers.length !== 1 ||
          this.authService?.loggedUser$?.value?.sections?.length === 0 ||
          !this.authService?.loggedUser$?.value?.sections?.includes(
            'InfoCliente',
          ),
      },
      {
        name: 'Impianti',
        icon: 'fa-circle-nodes',
        route: '/plants',
        permittedRoles: ['admin', 'owner', 'customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          (this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            !this.authService?.loggedUser$?.value?.sections?.includes(
              'Impianti',
            )),
      },
      {
        name: 'Utenti',
        icon: 'fa-users',
        route: '/users',
        permittedRoles: ['admin', 'owner'],
      },

      {
        name: 'Monitoring',
        permittedRoles: ['admin', 'owner', 'customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          (this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            !this.authService?.loggedUser$?.value?.sections?.includes(
              'Impianti',
            )),
      },
      // {
      //   name: 'Anomalie NVR',
      //   icon: 'fa-clapperboard-play',
      //   route: '/nvr-alarms',
      //   permittedRoles: ['admin', 'owner', 'customer'],
      //   hidden:
      //     this.authService?.loggedUser$?.value?.role === 'customer' &&
      //     this.authService?.loggedUser$?.value?.profile === 'light',
      // },
      {
        name: 'Local App',
        icon: 'fa-computer',
        route: '/installations',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name: 'Segnalazioni',
        icon: 'fa-triangle-exclamation',
        route: '/dead-items',
        permittedRoles: ['admin', 'owner', 'customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          (this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            !this.authService?.loggedUser$?.value?.sections?.includes(
              'Impianti',
            )),
      },
      {
        name: 'Avvertimenti',
        icon: 'fa-triangle-exclamation',
        route: '/warning-items',
        permittedRoles: ['admin', 'owner'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer'
      },
      {
        name: 'Attenzionati',
        icon: 'fa-circle-exclamation',
        route: '/highlight-items',
        permittedRoles: ['admin', 'owner'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer'
      },
      {
        name: 'Guasti noti',
        icon: 'fa-circle-xmark',
        route: '/silenced-items',
        permittedRoles: ['admin', 'owner', 'customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          (this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            !this.authService?.loggedUser$?.value?.sections?.includes(
              'Impianti',
            )),
      },
      {
        name: 'Apparati esclusi',
        icon: 'fa-circle-xmark',
        route: '/excluded-items',
        permittedRoles: ['admin', 'owner', 'customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          this.authService?.loggedUser$?.value?.profile === 'light',
      },
      {
        name: 'Ticketing',
        permittedRoles: ['customer'],
      },
      {
        name: 'Nuovo ticket',
        icon: 'fa-bell-on',
        route: '/tickets/add',
        permittedRoles: ['customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          (this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            !this.authService?.loggedUser$?.value?.sections?.includes(
              'Ticketing',
            )),
      },
      {
        name: 'Ticketing',
        icon: 'fa-bell',
        route: '/tickets/list',
        permittedRoles: ['admin', 'owner', 'customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          (this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            !this.authService?.loggedUser$?.value?.sections?.includes(
              'Ticketing',
            )),
      },
      // {
      //   name: 'Info',
      //   icon: 'fa-info-circle',
      //   route: `/customers/${this?.customerAllowedCustomers?.[0]?._id}`,
      //   permittedRoles: ['customer'],
      //   hidden:
      //     this.authService?.loggedUser$?.value?.role !== 'customer' ||
      //     (this.authService?.loggedUser$?.value?.role === 'customer' &&
      //       this.customerAllowedCustomers.length === 1 &&
      //       (this.authService?.loggedUser$?.value?.sections?.length === 0 ||
      //         !this.authService?.loggedUser$?.value?.sections?.includes(
      //           'InfoCliente',
      //         ))),
      // },

      // {
      //   name: 'Pianificazione',
      //   icon: 'fa-calendar',
      //   route: '/users',
      //   permittedRoles: ['admin', 'owner'],
      // },

      // {
      //   name: 'Lavorazioni',
      //   permittedRoles: ['admin', 'owner'],
      // },
      // {
      //   name: 'Commesse',
      //   icon: 'fa-briefcase',
      //   route: '/users',
      //   permittedRoles: ['admin', 'owner'],
      // },
      // {
      //   name: 'Contratti manut.',
      //   icon: 'fa-clipboard-check',
      //   route: '/users',
      //   permittedRoles: ['admin', 'owner'],
      // },
      // {
      //   name: 'Bollettini',
      //   icon: 'fa-clipboard-list',
      //   route: '/users',
      //   permittedRoles: ['admin', 'owner'],
      // },
      {
        name: 'Comunicazioni',
        permittedRoles: ['customer'],
      },

      {
        name: 'Offerte',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name: 'Plafond',
        icon: 'fa-money-bills-simple',
        route: `/customers/plafond/${this?.customerAllowedCustomers?.[0]?._id}`,
        permittedRoles: ['customer'],
      },
      {
        name: 'Nuova offerta',
        icon: 'fa-file-plus',
        route: '/offers/add',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name:
          this.authService?.loggedUser$?.value?.role === 'customer'
            ? 'Offerte'
            : 'Archivio offerte',
        icon: 'fa-files',
        route: '/offers/list',
        permittedRoles: ['admin', 'owner', 'customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          (this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            !this.authService?.loggedUser$?.value?.sections?.includes(
              'Offerte',
            )),
      },

      {
        name: 'Notifiche',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name: 'Plafond',
        icon: 'fa-money-bills-simple',
        route: '/plafonds/list',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name: 'Nuova notifica',
        icon: 'fa-comment-medical',
        route: '/notifications/add',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name:
          this.authService?.loggedUser$?.value?.role === 'customer'
            ? 'Notifiche'
            : 'Archivio notifiche',
        icon: 'fa-folders',
        route: '/notifications/list',
        permittedRoles: ['admin', 'owner', 'customer'],
      },

      {
        name: 'Report',
        permittedRoles: ['admin', 'owner', 'customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          (this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            !this.authService?.loggedUser$?.value?.sections?.includes(
              'Impianti',
            )),
      },
      {
        name: 'Impostazioni',
        icon: 'fa-gear',
        route: '/aggregations/settings',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name: 'Archivio',
        icon: 'fa-arrow-trend-up',
        route: '/reports',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name: 'Report',
        icon: 'fa-box-archive',
        route: '/reports',
        permittedRoles: ['customer'],
        hidden:
          this.authService?.loggedUser$?.value?.role === 'customer' &&
          (this.authService?.loggedUser$?.value?.sections?.length === 0 ||
            !this.authService?.loggedUser$?.value?.sections?.includes(
              'Impianti',
            )),
      },
      {
        name: 'Oggetti statici',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name: 'Gruppi',
        icon: 'fa-group-arrows-rotate',
        route: '/staticObjectGroups',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name: 'Categorie',
        icon: 'fa-list',
        route: '/staticObjectCategories',
        permittedRoles: ['admin', 'owner'],
      },
      // {
      //   name: 'Oggetti',
      //   icon: 'fa-objects-column',
      //   route: '/staticObjects',
      //   permittedRoles: ['admin', 'owner'],
      // },
      {
        name: 'Impostazioni',
        permittedRoles: ['admin'],
      },
      {
        name: 'Traduzioni',
        icon: 'fa-pen-alt',
        route: '/translations',
        permittedRoles: ['admin'],
      },
      {
        name: 'Lingue',
        icon: 'fa-language',
        route: '/languages',
        permittedRoles: ['admin'],
      },
      {
        name: 'Informazioni',
        icon: 'fa-cogs',
        route: '/settings',
        permittedRoles: ['admin'],
      },
      {
        name: 'Log',
        icon: 'fa-list-alt',
        route: '/logs',
        permittedRoles: ['admin'],
      },
      {
        name: 'Ping apparati',
        icon: 'fa-list-alt',
        route: '/item-logs',
        permittedRoles: ['admin'],
      },
      {
        name: 'Ping installazioni',
        icon: 'fa-list-alt',
        route: '/installation-logs',
        permittedRoles: ['admin'],
      },
    ];

    return items;
  }

  /**
   * Get current viewport sizes
   *
   * @since 1.0.0
   */
  getScreenSize(): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  /**
   * Check if riven route is active
   *
   * @since 1.0.0
   */
  routeIsActive(item: SidenavItem): boolean {
    let active = false;
    if (
      (item.route &&
        item.route !== '/' &&
        this.activeRoute.includes(item.route) &&
        !item.routeBlacklist?.includes(this.activeRoute)) ||
      (item.route === '/' && this.activeRoute === item.route)
    ) {
      active = true;
    }
    return active;
  }

  /**
   * Triggered when customer click on "View map" button
   *
   * @since 1.1.0
   */
  onViewMap(): void {
    const aggregationId =
      typeof (this.authService.loggedUser$.value as any)?.aggregationId ===
      'string'
        ? (this.authService.loggedUser$.value as any)?.aggregationId
        : (this.authService.loggedUser$.value as any)?.aggregationId._id;
    const dialogRef = this.dialog.open(MapDialogComponent, {
      data: {
        params: {
          aggregationId: aggregationId,
        },
      },
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
      panelClass: 'md-custom-panel',
      role: 'dialog',
    });

    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }
      });
  }

  async loadBadges(): Promise<void> {
    const loadData = () => {
      const data = {
        nvrAlarms: 0,
        deadInstallations: 0,
        deadItems: 0,
        warningItems: 0,
        silencedItems: 0,
        openTickets: 0,
        offers: 0,
        excludedItems: 0,
        beCarefulItems: 0,
        localAppItems: 0,
      };

      this.subscriptions.push(
        this.apiService
          .apiCall('GET', 'utils/get-sidenav-data')
          .pipe(take(1))
          .subscribe((res: Response) => {
            if (!res.status) {
              return;
            }

            if (
              ['admin', 'owner'].includes(
                this.authService.loggedUser$.value?.role as string,
              )
            ) {
              data.nvrAlarms = res.data.nvrAlarms;
              data.deadInstallations = res.data.deadInstallations;
              data.deadItems = res.data.deadItems;
              data.warningItems = res.data.warningItems;
              data.silencedItems = res.data.silencedItems;
              data.excludedItems = res.data.excludedItems;
              data.openTickets = res.data.openTickets;
              data.beCarefulItems = res.data.beCarefulItems;
              data.localAppItems =res.data.localAppItems;
            } else {
              data.nvrAlarms = res.data.nvrAlarms;
              data.deadItems = res.data.deadItems;
              data.warningItems = res.data.warningItems;
              data.silencedItems = res.data.silencedItems;
              data.excludedItems = res.data.excludedItems;
              data.openTickets = res.data.openTickets;
              data.offers = res.data.offers;
              data.beCarefulItems = res.data.beCarefulItems;
              data.localAppItems =res.data.localAppItems;
            }

            for (const navItem of this.navItems) {
              if (navItem.name === 'Anomalie NVR') {
                navItem.badge = {
                  value: data.nvrAlarms,
                };
              }
              if (navItem.name === 'Segnalazioni') {
                navItem.badge = {
                  value: data.deadItems,
                };
              }
              if (navItem.name === 'Avvertimenti') {
                navItem.badge = {
                  value: data.warningItems,
                  style: 'background: #6D767B;',
                };
              }
              if (navItem.name === 'Installazioni') {
                navItem.badge = {
                  value: data.deadInstallations,
                };
              }
              if (navItem.name === 'Local App') {
                navItem.badge = {
                  value: data.localAppItems,
                  style: 'background: #ea5455;',
                };
              }
              if (navItem.name === 'Attenzionati') {
                navItem.badge = {
                  value: data.beCarefulItems,
                  style: 'background: #6D767B;',
                };
              }
              if (navItem.name === 'Guasti noti') {
                navItem.badge = {
                  value: data.silencedItems,
                  style: 'background: #6D767B;',
                };
              }
              if (navItem.name === 'Apparati esclusi') {
                navItem.badge = {
                  value: data.excludedItems,
                  style: 'background: #6D767B;',
                };
              }
              if (navItem.name === 'Ticketing') {
                navItem.badge = {
                  value: data.openTickets,
                };
              }
              if (navItem.name === 'Offerte') {
                navItem.badge = {
                  value: data.offers,
                  style: 'background: #000000;',
                };
              }
            }
          }),
      );
    };

    loadData();

    setInterval(() => {
      loadData();
    }, 30 * 1000);
  }
}
