// Core packages
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Third party packages
import { Subscription } from 'rxjs';

// Custom packages
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import IUser from '../../models/user/user.interface';
import { AuthService } from 'src/app/modules/auth/auth.service';

/**
 * Script start
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public loggedUser: IUser | undefined;

  /**
   * Class constructor
   */
  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public configService: ConfigService,
    private authService: AuthService,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Subscribe to logged user data
    const loggedUserSubscription = this.authService.loggedUser$.subscribe(
      (user: any) => {
        this.loggedUser = user;
      },
    );
    this.subscriptions.push(loggedUserSubscription);
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Toggle left sidenav
   *
   * @since 1.0.0
   */
  toggleSidenav(): void {
    this.sidenavService.toggle();
  }

  /**
   * Log user out deleting his JWT
   *
   * @since 1.0.0
   */
  onLogout(): void {
    this.authService.logout(true);
  }

  /**
   * Redirect to user detail
   *
   * @since 1.0.0
   */
  onViewProfile(): void {
    const userId = this.loggedUser?._id;
    if (userId) {
      this.router.navigate(['users', userId]);
    } else {
      this.onLogout();
    }
  }

  /**
   * Get logges user full name initials.
   * Example, for 'Mario Rossi' returns 'MR';
   *
   * @since 1.0.0
   */
  get loggedUserInitials(): string {
    if (
      !this.authService?.loggedUser$?.value?.firstName ||
      !this.authService?.loggedUser$?.value?.lastName
    ) {
      return 'X';
    }
    const firstNameFirstLetter =
      this.authService?.loggedUser$?.value?.firstName[0];
    const lastNameFirstLetter =
      this.authService?.loggedUser$?.value?.lastName[0];

    return `${firstNameFirstLetter}${lastNameFirstLetter}`.toUpperCase();
  }
}
