// Core packages
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';

// Third party packaged
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

// Custom packages
import { AuthService } from 'src/app/modules/auth/auth.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import IUser from 'src/app/shared/models/user/user.interface';
import { ThemeService } from 'src/app/shared/services/theme.service';
import Response from 'src/app/shared/interfaces/response.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.page.html',
  styleUrls: ['./recover-password.page.scss'],
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  loggedUser: IUser | undefined;

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: this.translateService.instant('pages.recoverPassword.form.submitBtn'),
    spinnerSize: 19,
    raised: true,
    stroked: true,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    mode: 'indeterminate',
    // buttonIcon: {
    //   fontSet: 'fal',
    //   fontIcon: 'fa-sign-in',
    //   inline: true,
    // },
  };
  otpFormInitialized = false;
  // END - Form
  returnUrl: string | undefined;

  /**
   * Class constructor
   */
  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    public configService: ConfigService,
    private themeService: ThemeService,
    private translateService: TranslateService,
    private cookieService: CookieService,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Update page's DOM
    this.themeService.setPageTitle(
      this.translateService.instant('pages.recoverPassword.meta.title'),
    );
    this.themeService.setPageMetaDescription(
      this.translateService.instant('pages.recoverPassword.meta.description'),
    );
    this.themeService.setClassToBody('wbg');

    if (this.activatedRoute.params) {
      this.subscriptions.push(
        this.activatedRoute.params.subscribe(
          (res: any) => {
            if (res) {
              this.handleRouteParamsChange();
            }
          },
          (err: any) => {
            this.router.navigate(['errors', '404']);
          },
        ),
      );
    }
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());

    // Remove body class
    this.themeService.removeClassToBody('wbg');

    // Remove meta desc
    this.themeService.setPageMetaDescription('');

    // Update meta title
    this.themeService.setPageTitle(
      this.translateService.instant('pages.general.meta.title'),
    );
  }

  /**
   * Init component
   */
  handleRouteParamsChange(): void {
    // Prevent form fields to be rendered twice on language change
    this.fields = [];

    // Initialize form
    this.initForm();

    // Check if user is already logged in
    // with authservice
    // If so, redirect him to to previously requested url
    this.subscriptions.push(
      this.authService.loggedUser$.subscribe((user) => {
        if (user) {
          this.loggedUser = user;
          this.router.navigateByUrl(this.returnUrl || '/');
        }
      }),
    );

    // Check if user is logged in with cookies
    // data. If so, redirect him
    // Prevent check if no cookies
    if (this.cookieService.get('s.id')) {
      // return new Observable((obs) => {
      //   return obs.next({
      //     status: false
      //   });
      // });
      this.subscriptions.push(
        this.authService.checkLogin().subscribe((res) => {
          if (res.status) {
            this.router.navigateByUrl(this.returnUrl || '/');
          }
        }),
      );
    }
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Set model
    this.model = {
      email: '',
      password: '',
    };

    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'input',
            key: 'email',
            defaultValue: '',
            templateOptions: {
              label: this.translateService.instant(
                'pages.recoverPassword.form.email.label',
              ),
              placeholder: this.translateService.instant(
                'pages.recoverPassword.form.email.placeholder',
              ),
              required: true,
              maxLength: 320,
              type: 'email',
              pattern: this.configService.emailPattern,
              attributes: {
                autocomplete: 'username',
              },
            },
          },
          {
            className: 'col-12',
            type: 'formly-password',
            key: 'password',
            defaultValue: '',
            templateOptions: {
              label: this.translateService.instant(
                'pages.recoverPassword.form.password.label',
              ),
              placeholder: this.translateService.instant(
                'pages.recoverPassword.form.password.placeholder',
              ),
              minLength: 6,
              required: true,
              type: 'password',
              attributes: {
                autocomplete: 'new-password',
              },
            },
          },
          {
            className: 'col-12',
            type: 'formly-password',
            key: 'confirmPassword',
            defaultValue: '',
            templateOptions: {
              label: this.translateService.instant(
                'pages.recoverPassword.form.confirmPassword.label',
              ),
              placeholder: this.translateService.instant(
                'pages.recoverPassword.form.confirmPassword.placeholder',
              ),
              minLength: 6,
              required: true,
              type: 'password',
              attributes: {
                autocomplete: 'new-password',
              },
            },
          },
        ],
      },
    ];
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(model: any): void {
    console.log('onFormSubmit()');

    // Prevent invalid form submit
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      return;
    }

    // Prevent double submit caused by double btn click
    if (this.formBtnOptions.active) {
      return;
    }

    // Activate submit button loading status
    this.formBtnOptions = { ...this.formBtnOptions, active: true };

    if (!this.otpFormInitialized) {
      this.subscriptions.push(
        this.authService
          .recoverPassword(model.email)
          .pipe(
            finalize(
              () =>
                (this.formBtnOptions = {
                  ...this.formBtnOptions,
                  active: false,
                }),
            ),
          )
          .subscribe(
            (res: Response) => {
              console.log('res', res);

              if (!res.status) {
                this.toastrService.error(res.message);
                return;
              }

              this.toastrService.success(res.message);

              this.initOTPForm();
            },
            (err: Response) => {
              // Choose one of the following error handling method.
              // 1) First one show a message right under the form fields
              //    (if the form is properly setted)
              // 2) Second one is used to show a mat-error under each field
              //    in the dynamic form (is meant to be used only in dynamicForm)
              // 3) Third one show toastr notifications for each error
              // 4) Fourth one is used to show mat-error under each field
              //    in the ngx-formly form (is mean to be used only with ngx-formly)
              // this.helperService.handleFormError(form, err);
              // this.helperService.handleDynamicFormError(form, err);
              // this.helperService.handleError(err);
              this.helperService.handleFormlyError(this.form, err);
            },
          ),
      );
      return;
    }

    if (this.otpFormInitialized) {
      this.subscriptions.push(
        this.authService
          .resetPassword(
            model.email,
            model.password,
            model.confirmPassword,
            model.otp,
          )
          .pipe(
            finalize(
              () =>
                (this.formBtnOptions = {
                  ...this.formBtnOptions,
                  active: false,
                }),
            ),
          )
          .subscribe(
            (res: Response) => {
              console.log('res', res);

              if (!res.status) {
                this.toastrService.error(res.message);
                return;
              }

              this.toastrService.success(res.message);
              this.form.reset();
              this.router.navigate(['auth', 'login']);
            },
            (err: Response) => {
              // Choose one of the following error handling method.
              // 1) First one show a message right under the form fields
              //    (if the form is properly setted)
              // 2) Second one is used to show a mat-error under each field
              //    in the dynamic form (is meant to be used only in dynamicForm)
              // 3) Third one show toastr notifications for each error
              // 4) Fourth one is used to show mat-error under each field
              //    in the ngx-formly form (is mean to be used only with ngx-formly)
              // this.helperService.handleFormError(form, err);
              // this.helperService.handleDynamicFormError(form, err);
              // this.helperService.handleError(err);
              this.helperService.handleFormlyError(this.form, err);
            },
          ),
      );
    }
  }

  initOTPForm(): void {
    // Change form data
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'input',
            key: 'otp',
            defaultValue: '',
            templateOptions: {
              label: this.translateService.instant(
                'pages.recoverPassword.form.otp.label',
              ),
              placeholder: this.translateService.instant(
                'pages.recoverPassword.form.otp.placeholder',
              ),
              required: true,
              type: 'string',
            },
          },
        ],
      },
    ];
    this.otpFormInitialized = true;
  }
}
