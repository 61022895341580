<button mat-fab color="primary" class="close-button" (click)="onClose()">
  <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
</button>
<div class="preview-box">
  <div class="row">
    <div class="" style="flex: 1 0 0%; max-width: 450px; display: flex; align-items: center;">
      <img src="{{ getDataUrl(true) }}" class="image">
    </div> <!-- /.col -->
    <div class="col">
      <h2>Dati del file</h2>
      <dl>
        <dt>Nome</dt>
        <dd>{{ data.data.originalName }}</dd>
        <dt>Percorso</dt>
        <dd><a href="{{ getDataUrl() }}" target="_blank" title="Apri in un'altra finestra">{{ getDataUrl() }}</a></dd>
        <dt>Peso</dt>
        <dd>{{ getWeight() }} Kb</dd>
        <dt>Formato</dt>
        <dd>{{ data.data.mimetype }}</dd>
        <dt>Caricato il</dt>
        <dd>{{ data.data.createdAt | date:'dd/MM/yyyy HH:mm' }}</dd>
      </dl>
    </div> <!-- /.col -->
  </div> <!-- /.row -->

</div>
