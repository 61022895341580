// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class InstallationsService {
  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
  ) {}

  /**
   * Create a new installation
   *
   * @since 1.1.0
   *
   * @param installation Installation data
   * @returns Observable<any>
   */
  create(installation: any): Observable<any> {
    return this.apiService.apiCall('POST', 'installations', installation);
  }

  /**
   * Get installations list for given query params
   *
   * @since 1.1.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall('GET', 'installations', params);
  }

  /**
   * Get given installationId data from back-end
   *
   * @since 1.1.0
   *
   * @param installationId ID of the installation to get
   */
  get(installationId: string): Observable<any> {
    return this.apiService
      .apiCall('GET', `installations/${installationId}`)
      .pipe(
        catchError((err) => {
          this.helperService.handleError(err);
          return throwError(err);
        }),
      );
  }

  /**
   * Update installation with given installationId with given data
   *
   * @since 1.1.0
   *
   * @param installationId ID of pos to update
   * @param data New installation's data
   */
  update(installationId: string, data: object): Observable<any> {
    return this.apiService.apiCall(
      'PUT',
      `installations/${installationId}`,
      data,
    );
  }

  /**
   * Update installation fields with given installationId with given data
   *
   * @since 1.1.0
   *
   * @param installationId ID of installation to update
   * @param data New installation's data
   */
  updateFields(installationId: string, data: object): Observable<any> {
    return this.apiService.apiCall(
      'PATCH',
      `installations/${installationId}`,
      data,
    );
  }

  /**
   * Delete permanently an installation
   *
   * @since 1.1.0
   *
   * @param installationId ID of the installation
   */
  // delete(installationId: string): Observable<any> {
  //   return this.apiService.apiCall('DELETE', `installations/${installationId}`);
  // }

  /**
   * Get installations stats
   *
   * @since 1.1.0
   */
  getStats(): Observable<ListApiResponse> {
    return this.apiService.apiCall('GET', 'installations/stats').pipe(
      catchError((err) => {
        this.helperService.handleError(err);
        return throwError(err);
      }),
    );
  }
  
  /**
   * Get given installationId data from back-end
   *
   * @since 1.1.0
   *
   * @param installationId ID of the installation to get
   */
  delete(installationId: string): Observable<any> {
    return this.apiService
      .apiCall('DELETE', `installations/${installationId}`)
      .pipe(
        catchError((err) => {
          this.helperService.handleError(err);
          return throwError(err);
        }),
      );
  }
}
