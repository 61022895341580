// Core packages
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

// Third party packages
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ChartConfiguration, ChartOptions, Tick } from 'chart.js';
import { ToastrService } from 'ngx-toastr';

// Custom packages
import { InstallationsService } from 'src/app/modules/installations/installations.service';
import { PageService } from 'src/app/shared/services/loading.service';
import Response from 'src/app/shared/interfaces/response.interface';
import { HelperService } from 'src/app/shared/services/helper.service';

/**
 * Script start
 */
@Component({
  selector: 'app-customer-dashboard-chart',
  templateUrl: './customer-dashboard-chart.component.html',
  styleUrls: ['./customer-dashboard-chart.component.scss'],
})
export class CustomerDashboardChartComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscriptions: Subscription[] = [];

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [],
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
    },
    scales: {
      y: {
        min: 0,
        max: 1,
        ticks: {
          callback: (
            tickValue: string | number,
            index: number,
            ticks: Tick[],
          ): string => {
            return `${parseFloat(tickValue?.toString()) * 100} %`;
          },
        },
        title: {
          display: true,
          text: 'Percentuale di funzionamento',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Giorno',
        },
      },
    },
  };
  public lineChartLegend = true;

  /**
   * Class constructor
   */
  constructor(
    private installationsService: InstallationsService,
    private pageService: PageService,
    private toastrService: ToastrService,
    private helperService: HelperService,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    this.getChartData();
  }

  ngAfterViewInit(): void {}

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Get chart data from back-end
   */
  private getChartData(): void {
    this.subscriptions.push(
      this.installationsService
        .getStats()
        .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Something bad happened
              const title = 'Errore';
              const message =
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
              this.toastrService.error(message, title);
              return;
            }

            // Set labels
            const newLabels = res.data.labels;
            this.lineChartData.labels = newLabels;

            // Set datasets
            const newDatasets = res.data.datasets;
            let i = 0;
            for (const newDataset of newDatasets) {
              const borderColor = this.getRandomColorRGBA(0.8);
              const bgColor = this.changeOpacity(borderColor, 0.3);

              this.lineChartData.datasets.push({
                data: [...newDataset.data],
                label: newDataset.label,
                fill: true,
                tension: 0.5,
                borderColor: borderColor,
                borderWidth: 0.5,
                backgroundColor: bgColor,
                hidden: i !== 0,
                pointBackgroundColor: borderColor,
              });

              i++;
            }
          },
          (err: Response) => {
            this.helperService.handleError(err);

            const title = 'Errore';
            const message =
              "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
            this.toastrService.error(message, title);
          },
        ),
    );
  }

  private getRandomColorRGBA(alpha: number = 1): string {
    const randomChannel = () => Math.floor(Math.random() * 256);

    const red = randomChannel();
    const green = randomChannel();
    const blue = randomChannel();

    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  }

  private changeOpacity(rgba: string, newAlpha: number): string {
    const match = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);
    if (!match) {
      throw new Error('Invalid RGBA format');
    }

    const red = parseInt(match[1], 10);
    const green = parseInt(match[2], 10);
    const blue = parseInt(match[3], 10);
    const currentAlpha = parseFloat(match[4]);

    return `rgba(${red}, ${green}, ${blue}, ${newAlpha})`;
  }
}
