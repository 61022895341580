// Core pakages
import { Injectable } from '@angular/core';

// Third party packages
import { BehaviorSubject } from 'rxjs';

// Custom packages

@Injectable({
  providedIn: 'root',
})
export class PageService {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Class constructor
   */
  constructor() {}
}
