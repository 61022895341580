<div class="close-button" (click)="onClose()">
  <mat-icon fontSet="fal" fontIcon="fa-times" class="white" style="color: white"></mat-icon>
</div>

<div mat-dialog-title class="the-title">
  <h2 class="white">ATTENZIONE</h2>
  <p class="small">
    Le seguenti LocalApp risultano non funzionanti. <br />
    Procedere con il reset manuale della macchina.
  </p>
</div>


<mat-dialog-content>
  <div class="header">
    Aggregazione / Impianto
  </div>
  <div class="content">
    <ng-container *ngFor="let deadInstallation of data.deadInstallations">
      <div class="item">
        <div class="aggregation">
          {{ deadInstallation.aggregationId?.name }}
        </div>
        <div class="plant">
          <ng-container *ngFor="let plant of deadInstallation.plants">
            {{ plant.name }} <br />
          </ng-container>
        </div>
        <div class="time">
          {{ getElapsed(deadInstallation.lastPing) }}
        </div>
        <div class="date">
          {{ deadInstallation.lastPing | date:'dd/MM/yyyy HH:mm' }}
        </div>
      </div>
    </ng-container>
  </div>


</mat-dialog-content> <!-- mat-dialog-content -->
