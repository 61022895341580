<h1 mat-dialog-title>{{titleDialog}}</h1>

<div class="close-button" (click)="onDenial()">
  <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
</div>

<mat-dialog-content class="mb-3">
  <div class="form-wrapper mt-3">
    <form [formGroup]="form" (ngSubmit)="onFormSubmit(model)">
      <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
      <div class="row mt-3" *ngIf="action ==='show'">
        <div class="col-md-6">
          <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate">
          </mat-spinner-button>
        </div> <!-- /.col -->
      </div>

      <div class="row mt-3" *ngIf="action !=='show'">
        <div class="col-md-6">
          <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate">
          </mat-spinner-button>
        </div> <!-- /.col -->
        <div class="col-md-6 mt-5 mt-md-0 text-right">
          <button
            type="button" mat-stroked-button color="warn" (click)="onDenial()" class="w-100 w-md-auto">
            Annulla
          </button>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </form>
  </div> <!-- /.form-wrapper -->
</mat-dialog-content> <!-- mat-dialog-content -->

<!-- <mat-dialog-actions align=" end">
  <a mat-raised-button color="warn" (click)="onDenial()">
    <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
    Annulla
  </a>
  <button mat-raised-button color="primary" (click)="onConfirmation()">
    <mat-icon fontSet="fal" fontIcon="fa-check-circle"></mat-icon>
    Salva
  </button>
</mat-dialog-actions>  -->
