// Core packages
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Third party packages

// Custom packages
import { AppWrapperComponent } from './components/app-wrapper/app-wrapper.component';
import { CustomerDashboardComponent } from './components/customer-dashboard/customer-dashboard.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { SectionGuard } from './shared/guards/section.guard';

/**
 * Script start
 */
const routes: Routes = [
  {
    path: 'auth',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    pathMatch: 'prefix',
    component: AppWrapperComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner'],
        },
      },
      {
        path: 'dashboard',
        pathMatch: 'full',
        component: CustomerDashboardComponent,
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['customer'],
        },
      },

      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then((m) => m.UsersModule),
      },

      {
        path: 'customers',
        loadChildren: () =>
          import('./modules/customers/customers.module').then(
            (m) => m.CustomersModule,
          ),
      },
      {
        path: 'aggregations',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner'],
        },
        loadChildren: () =>
          import('./modules/aggregations/aggregations.module').then(
            (m) => m.AggregationsModule,
          ),
      },
      {
        path: 'plants',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner', 'customer'],
        },
        loadChildren: () =>
          import('./modules/plants/plants.module').then((m) => m.PlantsModule),
      },
      {
        path: 'tickets',
        canActivate: [RoleGuard, SectionGuard],
        data: {
          permittedRoles: ['admin', 'owner', 'customer'],
          requiredSections: ['Ticketing'],
        },
        loadChildren: () =>
          import('./modules/tickets/tickets.module').then(
            (m) => m.TicketsModule,
          ),
      },
      {
        path: 'installations',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner'],
        },
        loadChildren: () =>
          import('./modules/installations/installations.module').then(
            (m) => m.InstallationsModule,
          ),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./modules/notifications/notifications.module').then(
            (m) => m.NotificationsModule,
          ),
      },
      {
        path: 'plafonds',
        // canActivate: [RoleGuard],
        // data: {
        //   permittedRoles: ['admin', 'owner'],
        // },
        loadChildren: () =>
          import('./modules/plafonds/plafonds.module').then(
            (m) => m.PlafondsModule,
          ),
      },
      {
        path: 'nvr-alarms',
        loadChildren: () =>
          import('./modules/nvrAlarms/nvrAlarms.module').then(
            (m) => m.NvrAlarmsModule,
          ),
      },
      {
        path: 'dead-items',
        loadChildren: () =>
          import('./modules/deadItems/deadItems.module').then(
            (m) => m.DeadItemsModule,
          ),
      },
      {
        path: 'warning-items',
        loadChildren: () =>
          import('./modules/warningItems/warningItems.module').then(
            (m) => m.WarningItemsModule,
          ),
      },
      {
        path: 'silenced-items',
        loadChildren: () =>
          import('./modules/silencedItems/silencedItems.module').then(
            (m) => m.SilencedItemsModule,
          ),
      },
      {
        path: 'excluded-items',
        loadChildren: () =>
          import('./modules/excludedItems/excludedItems.module').then(
            (m) => m.ExcludedItemsModule,
          ),
      },
      {
        path: 'offers',
        loadChildren: () =>
          import('./modules/offers/offers.module').then((m) => m.OffersModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./modules/reports/reports.module').then(
            (m) => m.ReportsModule,
          ),
      },

      {
        path: 'staticObjectGroups',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner', 'customer'],
        },
        loadChildren: () =>
          import('./modules/staticObjectGroups/staticObjectGroups.module').then((m) => m.StaticObjectGroupsModule),
      },
      {
        path: 'staticObjectCategories',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner', 'customer'],
        },
        loadChildren: () =>
          import('./modules/staticObjectCategories/staticObjectCategories.module').then((m) => m.StaticObjectCategoriesModule),
      },
      // {
      //   path: 'staticObjects',
      //   canActivate: [RoleGuard],
      //   data: {
      //     permittedRoles: ['admin', 'owner', 'customer'],
      //   },
      //   loadChildren: () =>
      //     import('./modules/staticObjects/staticObject.module').then((m) => m.StaticObjectsModule),
      // },

      {
        path: 'translations',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin'],
        },
        loadChildren: () =>
          import('./modules/translations/translations.module').then(
            (m) => m.TranslationsModule,
          ),
      },
      {
        path: 'languages',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin'],
        },
        loadChildren: () =>
          import('./modules/languages/languages.module').then(
            (m) => m.LanguagesModule,
          ),
      },
      {
        path: 'logs',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin'],
        },
        loadChildren: () =>
          import('./modules/logs/logs.module').then((m) => m.LogsModule),
      },
      {
        path: 'item-logs',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin'],
        },
        loadChildren: () =>
          import('./modules/item-logs/item-logs.module').then(
            (m) => m.ItemLogsModule,
          ),
      },
      {
        path: 'installation-logs',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin'],
        },
        loadChildren: () =>
          import('./modules/installation-logs/installation-logs.module').then(
            (m) => m.InstallationLogsModule,
          ),
      },
      {
        path: 'settings',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner'],
        },
        component: SettingsComponent,
      },
      {
        path: 'errors',
        loadChildren: () =>
          import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
      },
    ],
  },
  { path: '**', redirectTo: 'auth/login' },
  // { path: '**', redirectTo: 'errors/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
