// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
  ) {}

  /**
   * Create a new ticket
   *
   * @since 1.0.0
   *
   * @param ticket Ticket data
   * @returns Observable<any>
   */
  create(ticket: any): Observable<any> {
    return this.apiService.apiCall('POST', 'tickets', ticket);
  }

  /**
   * Get tickets list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall('GET', 'tickets', params);
  }

  /**
   * Get given ticketId data from back-end
   *
   * @since 1.0.0
   *
   * @param ticketId ID of the ticket to get
   */
  get(ticketId: string): Observable<any> {
    return this.apiService.apiCall('GET', `tickets/${ticketId}`).pipe(
      catchError((err) => {
        this.helperService.handleError(err);
        return throwError(err);
      }),
    );
  }

  /**
   * Update ticket with given ticketId with given data
   *
   * @since 1.0.0
   *
   * @param ticketId ID of pos to update
   * @param data New ticket's data
   */
  update(ticketId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PUT', `tickets/${ticketId}`, data);
  }

  /**
   * Update ticket fields with given ticketId with given data
   *
   * @since 1.0.0
   *
   * @param ticketId ID of ticket to update
   * @param data New ticket's data
   */
  updateFields(ticketId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PATCH', `tickets/${ticketId}`, data);
  }

  /**
   * Delete permanently an ticket
   *
   * @since 1.0.0
   *
   * @param ticketId ID of the ticket
   */
  delete(ticketId: string): Observable<any> {
    return this.apiService.apiCall('DELETE', `tickets/${ticketId}`);
  }

  /**
   * Create a new ticket message
   *
   * @since 1.0.0
   *
   * @param ticketId ticket ID
   * @param message ticket message data
   *
   * @returns Observable<any>
   */
  createMessage(ticketId: string, data: any): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `tickets/${ticketId}/messages`,
      data,
    );
  }

  /**
   * Update ticket message with given ticketId and messageid with given data
   *
   * @since 1.0.0
   *
   * @param messageId ID of the message to update
   * @param data New ticket's data
   */
  updateMessage(messageId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PUT', `ticket-messages/${messageId}`, data);
  }

  /**
   * Update ticket message fields with given ticketId & messageId with given data
   *
   * @since 1.0.0
   *
   * @param messageId ID of ticket message to update
   * @param data New ticket's data
   */
  updateMessageFields(messageId: string, data: object): Observable<any> {
    return this.apiService.apiCall(
      'PATCH',
      `ticket-messages/${messageId}`,
      data,
    );
  }

  /**
   * Delete permanently an ticket
   *
   * @since 1.0.0
   *
   * @param messageId ID of the the message
   */
  deleteMessage(messageId: string): Observable<any> {
    return this.apiService.apiCall('DELETE', `ticket-messages/${messageId}`);
  }
}
