<div class="md-wrapper">
  <button class="close-button" (click)="onClose()">
    <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
  </button>

  <button
    class="sidenav-opener"
    [ngClass]="{ closed: sidenavOpen }"
    (click)="sidenavOpen = !sidenavOpen"
  >
    <mat-icon fontSet="fal" fontIcon="fa-list"></mat-icon>
  </button>

  <div class="breadcrumb-wrapper">
    <!-- <div class="breadcrumb" [ngClass]="{'breadcrumb-inactive': userHasPanned}"> -->
    <div class="breadcrumb">
      <ng-container *ngIf="breadcrumbItems && breadcrumbItems[0]">
        <button class="breadcrumb-item" (click)="breadcrumbItems[0].onClick()">
          {{ breadcrumbItems[0].label }}
        </button>
      </ng-container>
      <ng-container *ngIf="breadcrumbItems && breadcrumbItems[1]">
        <button class="breadcrumb-item" (click)="breadcrumbItems[1].onClick()">
          {{ breadcrumbItems[1].label }}
        </button>
      </ng-container>
      <ng-container
        *ngIf="breadcrumbPlantDocs && breadcrumbPlantDocs.label !== ''"
      >
        <ng-container>
          <button class="breadcrumb-file" (click)="onOpenDocsSideBar('plant')">
            <div><i class="fa-sharp fa-regular fa-folder-open"></i></div>
            <div>{{ breadcrumbPlantDocs.label }}</div>
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="breadcrumbItems && breadcrumbItems[2]">
        <button class="breadcrumb-item" (click)="breadcrumbItems[2].onClick()">
          {{ breadcrumbItems[2].label }}
        </button>
      </ng-container>
      <ng-container
        *ngIf="breadcrumbSiteDocs && breadcrumbSiteDocs.label !== ''"
      >
        <ng-container>
          <button class="breadcrumb-file" (click)="onOpenDocsSideBar('site')">
            <div><i class="fa-sharp fa-regular fa-folder-open"></i></div>
            <div>{{ breadcrumbSiteDocs.label }}</div>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="bottom-filters-wrapper">
    <div *ngIf="showStaticObjectsFiltersMenu">
      <button mat-raised-button color="primary" [matMenuTriggerFor]="aboveMenu" style="height: 45px;">
        Oggetti
        <mat-icon fontSet="fal" fontIcon="fa-chevron-down" class="fa-lg me-2"></mat-icon>
      </button>
      <mat-menu #aboveMenu="matMenu" yPosition="above">
        <ng-container *ngFor="let group of staticObjectGroups">
          <div class="objectMenu">
            <strong>{{group.name}}</strong>
            <ng-container *ngFor="let category of getstaticObjectCategories(group._id)">
              <p>
                <mat-checkbox 
                  (change)="onStaticApplyFilter(category._id)"
                  [checked]="getCategoryChecked(category._id)"
                  (click)="$event.stopPropagation()"
                >
                <img src="{{apiHost}}{{category?.icon?.path}}" alt="{{category.name}}" width="16" height="16"/>
                {{category.name}}
                </mat-checkbox>
              </p>
            </ng-container>
          </div>
        </ng-container>
      </mat-menu>
    </div>
    <div class="bottom-filters">
      <div class="bottom-filter">
        <mat-checkbox
          (change)="onApplyFilter('status.regular', !filters.status.regular)"
          [checked]="filters.status.regular"
        >
          <div class="filter-color filter-color-regolari"></div>
          Regolari
        </mat-checkbox>
      </div>
      <div class="bottom-filter">
        <mat-checkbox
          (change)="onApplyFilter('status.dead', !filters.status.dead)"
          [checked]="filters.status.dead"
        >
          <div class="filter-color filter-color-segnalazioni"></div>
          Segnalazioni
        </mat-checkbox>
      </div>
      <div class="bottom-filter">
        <mat-checkbox
          (change)="onApplyFilter('status.silenced', !filters.status.silenced)"
          [checked]="filters.status.silenced"
        >
          <div class="filter-color filter-color-guasti-noti"></div>
          Guasti noti
        </mat-checkbox>
      </div>
      <div class="bottom-filter">
        <mat-checkbox
          (change)="onApplyFilter('status.excluded', !filters.status.excluded)"
          [checked]="filters.status.excluded"
        >
          <div class="filter-color filter-color-no-lan"></div>
          Esclusioni
        </mat-checkbox>
      </div>
      <div class="bottom-filter">
        <mat-checkbox
          (change)="onApplyFilter('status.linked', filters.status.linked)"
          [checked]="filters.status.linked"
        >
          <div class="filter-color filter-color-link"></div>
          Collegamenti
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="md-map-wrapper">
    <!-- Handle loading -->
    <ng-container *ngIf="loading">
      <div style="position: absolute">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>

    <div
      #map
      style="width: 100%; height: 100%"
      [ngStyle]="{ visibility: loading ? 'hidden' : 'visible' }"
    ></div>
  </div>

  <div
    class="md-sidenav-backdrop"
    [ngStyle]="{ display: sidenavOpen ? 'block' : 'none' }"
  ></div>

  <div class="md-sidenav" [ngClass]="{ closed: !sidenavOpen }">
    <div class="md-sidenav-topbar">
      <button class="sidenav-closer" (click)="sidenavOpen = !sidenavOpen">
        <mat-icon fontSet="fal" fontIcon="fa-long-arrow-right"></mat-icon>
      </button>
    </div>

    <div class="md-header container">
      <div class="row">
        <button
          class="md-header-item col-3"
          (click)="sidenavAccordion = 1"
          [ngClass]="{ active: sidenavAccordion === 1 }"
        >
          <mat-icon fontSet="fal" fontIcon="fa-triangle-exclamation"></mat-icon>
          <span>Segnalazioni</span>
        </button>
        <!-- /.col -->
        <button
          class="md-header-item col-3"
          (click)="sidenavAccordion = 2"
          [ngClass]="{ active: sidenavAccordion === 2 }"
        >
          <mat-icon fontSet="fal" fontIcon="fa-circle-xmark"></mat-icon>
          <span>Guasti noti</span>
        </button>
        <!-- /.col -->
        <button
          class="md-header-item col-3"
          (click)="sidenavAccordion = 4"
          [ngClass]="{ active: sidenavAccordion === 4 }"
        >
          <mat-icon fontSet="fal" fontIcon="fa-object-exclude"></mat-icon>
          <span>Esclusi</span>
        </button>
        <!-- /.col -->
        <button
          class="md-header-item col-3"
          (click)="sidenavAccordion = 3"
          [ngClass]="{ active: sidenavAccordion === 3 }"
        >
          <mat-icon fontSet="fal" fontIcon="fa-location-dot"></mat-icon>
          <span>Lista siti</span>
        </button>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.md-header -->

    <div class="md-content container" *ngIf="sidenavOpen">
      <div class="row">
        <div
          class="col-12 pt-3 pb-3 accordion-1"
          [ngClass]="{ 'd-none': sidenavAccordion !== 1 }"
        >
          <button
            class="sorting-box"
            (click)="sidenavAccordion1Sorting = !sidenavAccordion1Sorting"
          >
            <span>Tempo trascorso ({{ getDownItems().length }})</span>
            <mat-icon
              fontSet="fal"
              fontIcon="fa-arrow-up"
              *ngIf="sidenavAccordion1Sorting"
            ></mat-icon>
            <mat-icon
              fontSet="fal"
              fontIcon="fa-arrow-down"
              *ngIf="!sidenavAccordion1Sorting"
            ></mat-icon>
          </button>

          <ng-container *ngIf="true">
            <div class="list-container">
              <ng-container *ngFor="let item of getDownItems()">
                <div class="row mb-3">
                  <div class="col-3" style="width: 50px">
                    <img
                      src="{{ helperService.getImageForItem(item) }} "
                      width="40px"
                    />
                  </div>
                  <!-- /.col -->
                  <div class="col-9">
                    <a
                      class="d-block clickable"
                      style="color: inherit; font-weight: bold"
                      (click)="onFocusItem(item)"
                      >{{ helperService.getNameForItemType(item.type) }}</a
                    >
                    <small class="d-block"
                      >{{ getPlantNameFromItemId(item.siteId) }} |
                      <a
                        style="color: inherit"
                        target="_blank"
                        href="/plants/{{
                          getPlantNameFromItemId(item.siteId, true)
                        }}/sites/{{ item.siteId }}"
                        >{{ getSiteNameFromId(item.siteId) }}</a
                      ></small
                    >
                    <small
                      class="d-block mat-error mat-danger"
                      title="{{ item.deadFrom }}"
                      >{{ helperService.getItemDownTime(item.deadFrom) }}</small
                    >
                    <small class="d-block">
                      <a
                        target="_blank"
                        href="/tickets/add?plantId={{
                          getPlantNameFromItemId(item.siteId, true)
                        }}&itemId={{ item._id }}"
                        >Apri ticket</a
                      >
                    </small>
                  </div>
                  <!-- /.col -->
                </div>
                <!-- /.row -->
              </ng-container>
            </div>
          </ng-container>
        </div>
        <!-- /.col-12 -->

        <div
          class="col-12 pt-3 pb-3 accordion-2"
          [ngClass]="{ 'd-none': sidenavAccordion !== 2 }"
        >
          <button
            class="sorting-box"
            (click)="sidenavAccordion2Sorting = !sidenavAccordion2Sorting"
          >
            <span>Tempo trascorso ({{ silencedItems.length }})</span>
            <mat-icon
              fontSet="fal"
              fontIcon="fa-arrow-up"
              *ngIf="sidenavAccordion2Sorting"
            ></mat-icon>
            <mat-icon
              fontSet="fal"
              fontIcon="fa-arrow-down"
              *ngIf="!sidenavAccordion2Sorting"
            ></mat-icon>
          </button>

          <ng-container *ngFor="let item of silencedItems">
            <div class="row mb-3">
              <div class="col-3" style="width: 50px">
                <img
                  src="{{ helperService.getImageForItem(item) }} "
                  width="40px"
                />
              </div>
              <!-- /.col -->
              <div class="col-9">
                <strong class="d-block">{{
                  helperService.getNameForItemType(item.type)
                }}</strong>
                <small class="d-block"
                  >{{ getPlantNameFromItemId(item.siteId) }} |
                  {{ getSiteNameFromId(item.siteId) }}</small
                >
                <small class="d-block mat-error mat-danger">{{
                  helperService.getItemDownTime(item.deadFrom)
                }}</small>
                <small class="d-block">
                  <a
                    target="_blank"
                    href="/plants/{{
                      getPlantNameFromItemId(item.siteId, true)
                    }}/sites/{{ item.siteId }}/items/{{ item._id }}"
                    >Dettagli</a
                  >
                </small>
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </ng-container>
        </div>
        <!-- /.col-12 -->

        <div
          class="col-12 pt-3 pb-3 accordion-4"
          [ngClass]="{ 'd-none': sidenavAccordion !== 4 }"
        >
          <button
            class="sorting-box"
            (click)="sidenavAccordion4Sorting = !sidenavAccordion4Sorting"
          >
            <span>Tempo trascorso ({{ excludedItems.length }})</span>
            <mat-icon
              fontSet="fal"
              fontIcon="fa-arrow-up"
              *ngIf="sidenavAccordion4Sorting"
            ></mat-icon>
            <mat-icon
              fontSet="fal"
              fontIcon="fa-arrow-down"
              *ngIf="!sidenavAccordion4Sorting"
            ></mat-icon>
          </button>

          <ng-container *ngFor="let item of excludedItems">
            <div class="row mb-3">
              <div class="col-3" style="width: 50px">
                <img
                  src="{{ helperService.getImageForItem(item) }} "
                  width="40px"
                />
              </div>
              <!-- /.col -->
              <div class="col-9">
                <strong class="d-block">{{
                  helperService.getNameForItemType(item.type)
                }}</strong>
                <small class="d-block"
                  >{{ getPlantNameFromItemId(item.siteId) }} |
                  {{ getSiteNameFromId(item.siteId) }}</small
                >
                <small class="d-block mat-error mat-danger">{{
                  helperService.getItemDownTime(item.deadFrom)
                }}</small>
                <small class="d-block">
                  <a
                    target="_blank"
                    href="/plants/{{
                      getPlantNameFromItemId(item.siteId, true)
                    }}/sites/{{ item.siteId }}/items/{{ item._id }}"
                    >Dettagli</a
                  >
                </small>
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </ng-container>
        </div>
        <!-- /.col-12 -->

        <div
          class="col-12 pt-3 pb-3 accordion-3"
          [ngClass]="{ 'd-none': sidenavAccordion !== 3 }"
        >
          <mat-form-field appear style="width: 100%">
            <mat-label>Cerca sito</mat-label>
            <input
              matInput
              placeholder="Scrivi qui..."
              #sidenavAccordion3Input
            />
            <mat-icon
              matSuffix
              fontSet="fal"
              fontIcon="fa-search"
              *ngIf="!sidenavAccordion3Input.value"
            ></mat-icon>
            <mat-icon
              matSuffix
              fontSet="fal"
              fontIcon="fa-times"
              (click)="resetInput()"
              class="reset-input-icon"
              *ngIf="sidenavAccordion3Input.value"
            ></mat-icon>
          </mat-form-field>

          <div class="list-container">
            <ng-container *ngFor="let site of filteredSites">
              <div class="row mb-3">
                <div class="col-12">
                  <a
                    class="d-block clickable"
                    style="color: inherit; font-weight: bold"
                    (click)="onFocusSite(site)"
                    >{{ site.name }}</a
                  >
                  <span>
                    {{ getPlantNameFromItemId(site._id) }}
                  </span>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->
            </ng-container>
          </div>
        </div>
        <!-- /.col-12 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- .md-content -->
  </div>

  <div class="md-sidenav" [ngClass]="{ closed: !sidenavFilesOpen }">
    <div class="md-sidenav-topbar">
      <button
        class="sidenav-closer"
        (click)="sidenavFilesOpen = !sidenavFilesOpen"
      >
        <mat-icon fontSet="fal" fontIcon="fa-xmark"></mat-icon>
      </button>
    </div>
    <div>
      <div class="md-header container">
        <div class="md-file-title">
          <strong>
            <i class="fa-sharp fa-regular fa-folder-open"></i>
            Foto e documenti
          </strong>
        </div>
      </div>

      <div class="md-content container" *ngIf="sidenavFilesOpen">
        <button
          type="button"
          mat-button
          color="primary"
          (click)="sidenavFilesOpen = !sidenavFilesOpen"
          class="w-100 w-md-auto mt-4 px-0 text-start"
        >
          Indietro
        </button>

        <div class="mt-2">
          <strong *ngIf="isViewPlant || isViewSite">{{
            breadcrumbItems[1].label
          }}</strong>
          <div *ngIf="isViewSite">{{ breadcrumbItems[2].label }}</div>
        </div>

        <div *ngIf="isViewPlant">
          <div class="mt-4">
            <strong> FOTO </strong>
          </div>

          <div class="file-box-wrapper">
            <div class="file-box" *ngFor="let obj of imagePlantFiles">
              <div
                class="img-preview-box"
                (click)="onOpenImage(obj)"
                [ngStyle]="{
                  'background-image': 'url(' + getFilePreview(obj.file) + ')'
                }"
                [title]="obj.title"
              ></div>
              {{ getTitle(obj.title) }}
            </div>
          </div>

          <button
            *ngIf="imagePlantFiles && imagePlantFiles.length"
            type="button"
            mat-button
            color="primary"
            (click)="onPlantDownloadAll('images')"
            class="w-100 w-md-auto px-0 text-start"
          >
            <i class="fa-sharp fa-regular fa-down-to-bracket"></i>
            Scarica tutte
          </button>

          <div class="mt-4">
            <strong> DOCUMENTI </strong>
          </div>

          <div class="file-box-wrapper">
            <div class="file-box" *ngFor="let obj of documentPlantFiles">
              <div
                class="doc-preview-box"
                (click)="onOpenDoc(obj)"
                [ngStyle]="{
                  'background-image': 'url(' + getFilePreview(obj.file) + ')'
                }"
                [title]="obj.title"
              ></div>
              {{ getTitle(obj.title) }}
            </div>
          </div>

          <button
            *ngIf="documentPlantFiles && documentPlantFiles.length"
            type="button"
            mat-button
            color="primary"
            (click)="onPlantDownloadAll('documents')"
            class="w-100 w-md-auto mb-5 px-0 text-start"
          >
            <i class="fa-sharp fa-regular fa-down-to-bracket"></i>
            Scarica tutti
          </button>
        </div>

        <div *ngIf="isViewSite">
          <div class="mt-4">
            <strong> FOTO </strong>
          </div>

          <div class="file-box-wrapper">
            <div class="file-box" *ngFor="let obj of imageSiteFiles">
              <div
                class="img-preview-box"
                (click)="onOpenImage(obj)"
                [ngStyle]="{
                  'background-image': 'url(' + getFilePreview(obj.file) + ')'
                }"
                [title]="obj.title"
              ></div>
              {{ getTitle(obj.title) }}
            </div>
          </div>

          <button
            *ngIf="imageSiteFiles && imageSiteFiles.length"
            type="button"
            mat-button
            color="primary"
            (click)="onSiteDownloadAll('images')"
            class="w-100 w-md-auto px-0 text-start"
          >
            <i class="fa-sharp fa-regular fa-down-to-bracket"></i>
            Scarica tutte
          </button>

          <div class="mt-4">
            <strong> DOCUMENTI </strong>
          </div>

          <div class="file-box-wrapper">
            <div class="file-box" *ngFor="let obj of documentSiteFiles">
              <div
                class="doc-preview-box"
                (click)="onOpenDoc(obj)"
                [ngStyle]="{
                  'background-image': 'url(' + getFilePreview(obj.file) + ')'
                }"
                [title]="obj.title"
              ></div>
              {{ getTitle(obj.title) }}
            </div>
          </div>

          <button
            *ngIf="documentSiteFiles && documentSiteFiles.length"
            type="button"
            mat-button
            color="primary"
            (click)="onSiteDownloadAll('documents')"
            class="w-100 w-md-auto mb-5 px-0 text-start"
          >
            <i class="fa-sharp fa-regular fa-down-to-bracket"></i>
            Scarica tutti
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="md-sidenav-left" [ngClass]="{ closed: !sidenavStaticObjectOpen }">
    <div class="md-sidenav-topbar">
      <button
        class="sidenav-closer"
        (click)="sidenavStaticObjectOpen = !sidenavStaticObjectOpen"
      >
        <mat-icon fontSet="fal" fontIcon="fa-xmark"></mat-icon>
      </button>
    </div>
    <div>
      <div class="md-header container">
        <div class="md-file-title">
          <strong>
            <i class="fa-sharp fa-regular fa-folder-open"></i>
            Oggetto statico
          </strong>
        </div>
      </div>

      <div class="md-content container" *ngIf="sidenavStaticObjectOpen">
        <button
          type="button"
          mat-button
          color="primary"
          (click)="sidenavStaticObjectOpen = !sidenavStaticObjectOpen"
          class="w-100 w-md-auto mt-4 px-0 text-start"
        >
          Indietro
        </button>

        <div class="mt-2">
          <h1>{{ sidenavStaticObject.staticObjectCategoryId?.name }}</h1>
        </div>
        <div class="mt-2">
          <h2>{{ sidenavStaticObject.plantId?.name }}</h2>
        </div>
        <div class="mt-2">
          {{ sidenavStaticObject.date | date:'dd/MM/yyyy' }}
        </div>
        <div class="mt-2">
          {{ sidenavStaticObject.notes }}
        </div>

        <div class="mt-4">
          <strong> FOTO </strong>
        </div>

        <div class="file-box-wrapper">
          <div class="file-box" *ngFor="let obj of sidenavStaticObject.photos">
            <div
              class="img-preview-box"
              (click)="onOpenFile(obj)"
              [ngStyle]="{
                'background-image': 'url(' + getFilePreview(obj) + ')'
              }"
              [title]="obj.originalName"
            ></div>
            {{ getTitle(obj.originalName) }}
          </div>
        </div>

        <button
          *ngIf="sidenavStaticObject.photos && sidenavStaticObject.photos.length"
          type="button"
          mat-button
          color="primary"
          (click)="onStaticDownloadAll('images')"
          class="w-100 w-md-auto px-0 text-start"
        >
          <i class="fa-sharp fa-regular fa-down-to-bracket"></i>
          Scarica tutte
        </button>

        <div class="mt-4">
          <strong> DOCUMENTI </strong>
        </div>

        <div class="file-box-wrapper">
          <div class="file-box" *ngFor="let obj of sidenavStaticObject.files">
            <div
              class="doc-preview-box"
              (click)="onOpenFile(obj)"
              [ngStyle]="{
                'background-image': 'url(' + getFilePreview(obj) + ')'
              }"
              [title]="obj.originalName"
            ></div>
            {{ getTitle(obj.originalName) }}
          </div>
        </div>

        <button
          *ngIf="sidenavStaticObject.files && sidenavStaticObject.files.length"
          type="button"
          mat-button
          color="primary"
          (click)="onStaticDownloadAll('documents')"
          class="w-100 w-md-auto mb-5 px-0 text-start"
        >
          <i class="fa-sharp fa-regular fa-down-to-bracket"></i>
          Scarica tutti
        </button>

        <div class="mt-4" *ngIf="enabledToStaticObject" style="border-top: 1px solid rgba(0, 0, 0, 0.1);">
          <div class="row mt-2">
            <div class="col-6">
              <button mat-flat-button color="primary" (click)="onEditStaticObject(sidenavStaticObject)">
                Modifica l'oggetto
              </button>
            </div>
            <div class="col-6">
              <button mat-flat-button color="warn" (click)="onDeleteStaticObject(sidenavStaticObject._id)">
                Elimina l'oggetto
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.md-wrapper -->

  
  <div class="contextMenu" id="contextMenu">
    <ul>
      <li class="contextMenu-item" (click)="onAddStaticObject()">> Aggiungi oggetto statico</li>
    </ul>
  </div>
</div>
