// Core packages
import { DataSource } from '@angular/cdk/table';

// Third party packages
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

// Custom packages
import ListApiResponse from '../../interfaces/listApi.response.interface';
import { HelperService } from '../../services/helper.service';

/**
 * Script start
 *
 * @see https://blog.angular-university.io/angular-material-data-table/
 *
 * NB
 * If you need to change this to make a new DataSource
 * just change the following:
 *
 * - Change The class name in the first line
 * - Change DataSource<T> (use your model type) in first line
 * - Change BehaviourSubject<T> in the second line
 * - Change the service inside the class constructor
 * - Change the Observable<T> used in the connect() line
 * - Change the service name inside loadItems()
 */

export class TableDataSource<DataType> implements DataSource<DataType> {
  private itemsSubject$ = new BehaviorSubject<DataType[]>([]);
  private totalCountSubject$ = new BehaviorSubject<number>(0);
  private loadingSubject$ = new BehaviorSubject<boolean>(true);

  public loading$ = this.loadingSubject$.asObservable();
  public totalCount$ = this.totalCountSubject$.asObservable();

  /**
   * Class constructor
   */
  constructor(
    private service: any,
    private helperService: HelperService,
    private functionName: string = 'getList',
    private itemId?: string,
  ) {}

  /**
   * Connect data table to itemsSubject$
   *
   * @since 1.0.0
   *
   * @param collectionViewer collection viewer object
   * @returns Observable
   */
  connect(): Observable<DataType[]> {
    return this.itemsSubject$.asObservable();
  }

  /**
   * Disconnect data table from subjects
   *
   * @since 1.0.0
   *
   * @param collectionViewer collection viewer object
   */
  disconnect(): void {
    this.itemsSubject$.complete();
    this.totalCountSubject$.complete();
    this.loadingSubject$.complete();
  }

  /**
   * Load items from back-end using service injected in the constructor
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering objects with filtering data
   */
  loadItems(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): void {
    try {
      this.loadingSubject$.next(true); // Start loading
      if (typeof this.service[this.functionName] === 'function') {
        if (this.itemId) {
          this.service[this.functionName](
            this.itemId,
            start,
            limit,
            sort,
            sortVersus,
            search,
          )
            .pipe(
              catchError((err: Response) => {
                console.warn('err', err);
                // If errors, return empty array
                this.helperService.handleError(err);
                return of([]);
              }),
              finalize(() => this.loadingSubject$.next(false)), // Stop loading on finalize
            )
            .subscribe((result: ListApiResponse) => {
              this.itemsSubject$.next(result.data);
              this.totalCountSubject$.next(result.totalCount);
            });
        } else {
          this.service[this.functionName](start, limit, sort, sortVersus, search)
            // .getList(start, limit, sort, sortVersus, search)
            .pipe(
              catchError((err: Response) => {
                console.warn('err', err);
                // If errors, return empty array
                this.helperService.handleError(err);
                return of([]);
              }),
              finalize(() => this.loadingSubject$.next(false)), // Stop loading on finalize
            )
            .subscribe((result: ListApiResponse) => {
              this.itemsSubject$.next(result.data);
              this.totalCountSubject$.next(result.totalCount);
            });
        }
      }
    } catch (error) {
      console.warn('eeerror: ', error);
    }
  }
}
