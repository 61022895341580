// Core packages
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Third party packages
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

// Custom packages
import IAddress from 'src/app/shared/models/address.interface';
import countries from 'src/assets/json/countries.json';

/**
 * Script start
 */
@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
})
export class AddressDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model!: IAddress;
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Aggiorna',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  // END - Form

  constructor(
    public dialogRef: MatDialogRef<AddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastrService: ToastrService,
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    this.initForm();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Set model
    this.model = this.data.address;

    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12 col-md-8',
            type: 'input',
            key: 'route',
            templateOptions: {
              label: 'Indirizzo',
              placeholder: 'Inserisci qui...',
              required: true,
              type: 'string',
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'streetNumber',
            templateOptions: {
              label: 'Civico',
              placeholder: 'Inserisci qui...',
              required: true,
              type: 'string',
              description: 'Se non è presente inserire "NA"',
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'city',
            templateOptions: {
              label: 'Città',
              placeholder: 'Inserisci qui...',
              required: true,
              type: 'string',
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'postalCode',
            templateOptions: {
              label: 'Codice postale',
              placeholder: 'Inserisci qui...',
              required: true,
              type: 'string',
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'administrativeArea3',
            templateOptions: {
              label: 'Area amministrativa 3° livello',
              placeholder: 'Inserisci qui...',
              required: false,
              type: 'string',
              description: 'Comune',
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'administrativeArea2',
            templateOptions: {
              label: 'Area amministrativa 2° livello',
              placeholder: 'Inserisci qui...',
              required: false,
              type: 'string',
              description: 'Inserisci la provincia',
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'administrativeArea1',
            templateOptions: {
              label: 'Area amministrativa 1° livello',
              placeholder: 'Inserisci qui...',
              required: false,
              type: 'string',
              description: 'Inserisci la regione',
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'select',
            key: 'countryCode',
            templateOptions: {
              label: 'Paese',
              placeholder: 'Seleziona',
              required: true,
              type: 'string',
              options: countries.map((el: any) => ({
                value: el.alpha2.toLowerCase(),
                label: el.name,
              })),
            },
          },
          {
            key: 'latitude',
          },
          {
            key: 'longitude',
          },
          {
            className: 'col-12 mb-0',
            type: 'textarea',
            key: 'notes',
            templateOptions: {
              label: 'Note',
              placeholder: 'Inserisci qui...',
              required: false,
              type: 'textarea',
              rows: 2,
            },
          },
          {
            className: 'col-12 mb-0',
            type: 'google-maps',
            templateOptions: {
              address: this.model,
              form: this.form,
              latitude: this.data?.address?.latitude,
              longitude: this.data?.address?.longitude,
              callback: (latitude: number, longitude: number): void => {
                this.form.get('latitude')?.setValue(latitude);
                this.form.get('longitude')?.setValue(longitude);
              },
            },
          },
        ],
      },
    ];
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(model?: any): void {
    // console.log('model', model);
    // console.log('this.model', this.model);
    // console.log('form.values', this.form.getRawValue());

    model = this.form.getRawValue();
    // console.log('model', model);

    // Prevent invalid form submit
    if (this.form.invalid) {
      console.warn('this.form', this.form);
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    // this.dialogRef.close(this.model);
    this.dialogRef.close(model);
  }
}
