// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class UsersService {
  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
  ) {}

  /**
   * Create a new user
   *
   * @since 1.0.0
   *
   * @param user User data
   * @returns Observable<any>
   */
  create(user: any): Observable<any> {
    return this.apiService.apiCall('POST', 'users', user);
  }

  /**
   * Get users list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall('GET', 'users', params);
  }

  /**
   * Get an exportation file of all users
   * role: role of user that has sent the request, admin, operator etc.
   * companyId: company Id of the user that has sent the request. Only for Master and Collaborator
   * usersToExport: users to export, "all" | "temporary". Only for Master and Collaborator
   *
   * @since 1.0.0
   */
  getExport(role: string, usersToExport: string[]): Observable<any> {
    return this.apiService
      .apiCall(
        'GET',
        'users/export',
        { role, usersToExport },
        { responseType: 'blob' },
      )
      .pipe(
        catchError((err) => {
          return this.helperService.parseErrorBlob(err);
        }),
      );
  }

  /**
   * Get given userId data from back-end
   *
   * @since 1.0.0
   *
   * @param userId ID of the user to get
   */
  get(userId: string): Observable<any> {
    return this.apiService.apiCall('GET', `users/${userId}`).pipe(
      catchError((err) => {
        this.helperService.handleError(err);
        return throwError(err);
      }),
    );
  }

  /**
   * Update user with given userId with given user data
   *
   * @since 1.0.0
   *
   * @param userId ID of user to update
   * @param data New user's data
   */
  update(userId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PUT', `users/${userId}`, data);
  }

  /**
   * Update user fields with given userId with given user data
   *
   * @since 1.0.0
   *
   * @param userId ID of user to update
   * @param data New user's data
   */
  updateFields(userId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PATCH', `users/${userId}`, data);
  }

  /**
   * Delete permanently an user
   *
   * @since 1.0.0
   *
   * @param userId ID of the user
   */
  delete(userId: string): Observable<any> {
    return this.apiService.apiCall('DELETE', `users/${userId}`);
  }

  /**
   * Send to user an email with an OTP tha he must use
   * within 3 minutes to reset his email
   *
   * @since 1.0.0
   *
   * @param email email of user
   */
  recoverPassword(email: string): Observable<any> {
    return this.apiService
      .apiCall('POST', `users/recover-password`, { email })
      .pipe(
        catchError((err) => {
          this.helperService.handleError(err);
          return throwError(err);
        }),
      );
  }

  /**
   * Reset the password of a user.
   * Verified with a token that he must use within X seconds
   * (see settings)
   *
   * @since 1.0.0
   */
  resetPassword(
    password: string,
    email: string,
    token: string,
  ): Observable<any> {
    return this.apiService.apiCall('POST', `auth/reset-password/${token}`, {
      password,
      email,
    });
  }

  /**
   * Update user password
   *
   * @since 1.0.0
   *
   * @param userId ID of user to update
   * @param data New user's data
   */
  updatePassword(userId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PATCH', `users/${userId}/password`, data);
  }
}
