// Core packages
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Third party packages
import moment from 'moment';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import IUser from 'src/app/shared/models/user/user.interface';
import {
  environment,
  getAdminPanelCookieSecure,
  getAdminPanelHost,
} from 'src/environments/environment';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService<T = string> {
  loggedUser$ = new BehaviorSubject<IUser<T> | undefined>(undefined);
  loggedUserRequestedRole$ = new BehaviorSubject<string>('');
  loggedUserRequestedRoleType$ = new BehaviorSubject<string>('');
  loggedUserRequestedRoleObj$ = new BehaviorSubject<any>(null);
  loggedUserPermissions$ = new BehaviorSubject<string[]>([]);
  sessionExpiresAt$ = new BehaviorSubject<number>(0);

  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private router: Router,
    private cookieService: CookieService,
  ) {}

  /**
   * Log user in through apiService
   *
   * @since 1.0.0
   *
   * @param email User email
   * @param password User password
   */
  login(email: string, password: string): Observable<any> {
    return this.apiService.apiCall('POST', 'auth', { email, password }).pipe(
      map((result: any) => {
        if (result.status) {
          // Only allow authorized roles
          const decodedToken: any = jwt_decode(result?.data?.token);
          const userCertifiedRole = decodedToken?.role;

          // Prevent unwanted roles
          const allowedRoles = ['owner', 'admin', 'customer'];
          if (!userCertifiedRole || !allowedRoles.includes(userCertifiedRole)) {
            if (
              !userCertifiedRole ||
              !allowedRoles.includes(userCertifiedRole)
            ) {
              this.logout();
              return {
                status: false,
                message:
                  "Non hai il permesso di accedere a quest'area. Il tentativo è stato segnalato.",
              };
            }
          }

          try {
            this.setSession(result.data);
            const expiresAt = moment()
              .add(result.data.expiresIn, 'second')
              .utc();
            this.loggedUser$.next(result.data.user);
            this.sessionExpiresAt$.next(expiresAt.valueOf());
            return result;
          } catch (error) {
            // Something went wrong with session setting, let's logout user
            console.error('Error during session setting:\n', error);
            this.logout();
            return {
              status: false,
            };
          }
        }
        console.error('Error during session setting');
        return {
          status: false,
        };
      }),
    );
  }

  /**
   * Send an email with a reset-password token to the user
   *
   * @since 1.0.0
   *
   * @param email User email
   */
  recoverPassword(email: string): Observable<any> {
    return this.apiService.apiCall('POST', 'auth/recover-password', { email });
  }

  /**
   * Allow user to reset password
   *
   * @since 1.0.0
   *
   * @param email User email
   */
  resetPassword(
    email: string,
    password: string,
    confirmPassword: string,
    otp: string,
  ): Observable<any> {
    return this.apiService.apiCall('POST', 'auth/reset-password', {
      email,
      password,
      confirmPassword,
      otp,
    });
  }

  /**
   * Check if current user is logged in
   *
   * @since 1.0.0
   *
   * @returns Observable<any>
   */
  checkLogin(): Observable<any> {
    return this.apiService.apiCall('GET', 'auth').pipe(
      tap((result: any) => {
        if (result.status) {
          const expiresAt = moment().add(result.expiresIn, 'second');
          this.loggedUser$.next(result.data);
          this.sessionExpiresAt$.next(expiresAt.valueOf());
        }
      }),
    );
  }

  /**
   * Log user out
   *
   * @since 1.0.0
   *
   * @param redirect True if after logout there should be a redirect
   * to login page. Default false.
   * @returns boolean true if user was logged out, false otherwhise
   */
  logout(redirect?: boolean): boolean {
    const withNavigate = redirect ? redirect : false;
    // this.cookieService.delete('s.id', '/', getAdminPanelHost());
    // this.cookieService.delete('s.expiresAt', '/', getAdminPanelHost());
    // this.cookieService.delete('s.refreshToken', '/', getAdminPanelHost());
    // this.cookieService.delete('s.refreshTokenExpiresAt', '/', getAdminPanelHost());
    // this.cookieService.delete('requestedRole', '/', getAdminPanelHost());
    // this.cookieService.delete('requestedCompany', '/', getAdminPanelHost());
    // Delete cookies
    this.cookieService.set(
      's.id',
      '',
      new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
      '/',
      getAdminPanelHost(),
      getAdminPanelCookieSecure(),
      'Strict',
    );
    this.cookieService.set(
      's.expiresAt',
      '',
      new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
      '/',
      getAdminPanelHost(),
      getAdminPanelCookieSecure(),
      'Strict',
    );
    this.cookieService.set(
      's.refreshToken',
      '',
      new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
      '/',
      getAdminPanelHost(),
      getAdminPanelCookieSecure(),
      'Strict',
    );
    this.cookieService.set(
      's.refreshTokenExpiresAt',
      '',
      new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
      '/',
      getAdminPanelHost(),
      getAdminPanelCookieSecure(),
      'Strict',
    );

    this.loggedUser$.next(undefined);
    this.sessionExpiresAt$.next(0);
    if (withNavigate) {
      this.router.navigate(['/', 'auth', 'login']);
    }

    return true;
  }

  /**
   * Refresh current token (s.id) using refresh token
   *
   * @since 1.0.0
   *
   * @returns Observable<any>
   */
  refreshToken(redirectIfLogout = true): Observable<boolean> {
    const refreshTokenExp = +this.cookieService.get('s.refreshTokenExpiresAt');
    const refreshToken = this.cookieService.get('s.refreshToken');

    if (
      typeof this.cookieService.get('s.refreshTokenExpiresAt') ===
        'undefined' ||
      (typeof this.cookieService.get('refreshToken') === 'undefined' &&
        moment.unix(moment.now()) > moment.unix(refreshTokenExp))
    ) {
      this.logout(redirectIfLogout);
      return of(false);
    }

    if (!this.loggedUser$?.value || !this.loggedUser$?.value._id) {
      this.logout(redirectIfLogout);
      return of(false);
    }

    return this.apiService
      .apiCall(
        'POST',
        'auth/refresh',
        {},
        { 'X-Refresh-Token': `Bearer ${refreshToken}` },
      )
      .pipe(
        tap((result: any) => {
          this.setSession(result);
          const expiresAt = moment().add(result.expiresIn, 'second');
          this.sessionExpiresAt$.next(expiresAt.valueOf());
          return of(true);
        }),
        catchError(() => {
          this.logout(redirectIfLogout);
          return of(false);
        }),
      );
  }

  /**
   * Emit new data for current logged user
   * This is often used once user update his profile to other
   * components can know changes in real time
   *
   * @since 1.0.0
   */
  updatedLoggedUserData(): void {
    this.checkLogin().subscribe();
  }

  /**
   * Set given login result data as current session
   *
   * @since 1.0.0
   *
   * @param data Session data coming from back-end
   */
  private setSession(data: any): void {
    console.log('data', data);
    console.log('Cookie secure', getAdminPanelCookieSecure());
    console.log('Host', getAdminPanelHost());
    console.log('environment', environment);
    console.log('sessionData: ', data);
    const expiresAt = moment().add(data.expiresIn, 'second');
    this.cookieService.set(
      's.id',
      data.token,
      undefined,
      '/',
      getAdminPanelHost(),
      getAdminPanelCookieSecure(),
      'Strict',
    );
    this.cookieService.set(
      's.expiresAt',
      JSON.stringify(expiresAt.valueOf()),
      undefined,
      '/',
      getAdminPanelHost(),
      getAdminPanelCookieSecure(),
      'Strict',
    );

    if (data.refreshToken) {
      this.cookieService.set(
        's.refreshToken',
        data.refreshToken,
        undefined,
        '/',
        getAdminPanelHost(),
        getAdminPanelCookieSecure(),
        'Strict',
      );
    }
    if (data.refreshExpiresIn) {
      const refreshExpiresAt = moment().add(data.refreshExpiresIn, 'second');
      this.cookieService.set(
        's.refreshTokenExpiresAt',
        JSON.stringify(refreshExpiresAt.valueOf()),
        undefined,
        '/',
        getAdminPanelHost(),
        getAdminPanelCookieSecure(),
        'Strict',
      );
    }
  }
}
