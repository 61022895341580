// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class StaticObjectGroupsService {
  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
  ) {}

  /**
   * Create a new staticObjectGroup
   *
   * @since 1.0.0
   *
   * @param staticObjectGroup StaticObjectGroup data
   * @returns Observable<any>
   */
  create(staticObjectGroup: any): Observable<any> {
    return this.apiService.apiCall('POST', 'static-object-groups', staticObjectGroup);
  }

  /**
   * Get staticObjectGroups list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall('GET', 'static-object-groups', params);
  }

  /**
   * Get given staticObjectGroupId data from back-end
   *
   * @since 1.0.0
   *
   * @param staticObjectGroupId ID of the staticObjectGroup to get
   */
  get(staticObjectGroupId: string): Observable<any> {
    return this.apiService.apiCall('GET', `static-object-groups/${staticObjectGroupId}`).pipe(
      catchError((err) => {
        this.helperService.handleError(err);
        return throwError(err);
      }),
    );
  }

  /**
   * Update staticObjectGroup with given staticObjectGroupId with given data
   *
   * @since 1.0.0
   *
   * @param staticObjectGroupId ID of pos to update
   * @param data New staticObjectGroup's data
   */
  update(staticObjectGroupId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PUT', `static-object-groups/${staticObjectGroupId}`, data);
  }

  /**
   * Update staticObjectGroup fields with given staticObjectGroupId with given data
   *
   * @since 1.0.0
   *
   * @param staticObjectGroupId ID of staticObjectGroup to update
   * @param data New staticObjectGroup's data
   */
  updateFields(staticObjectGroupId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PATCH', `static-object-groups/${staticObjectGroupId}`, data);
  }

  /**
   * Delete permanently an staticObjectGroup
   *
   * @since 1.0.0
   *
   * @param staticObjectGroupId ID of the staticObjectGroup
   */
  delete(staticObjectGroupId: string): Observable<any> {
    return this.apiService.apiCall('DELETE', `static-object-groups/${staticObjectGroupId}`);
  }

}
