// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable } from 'rxjs';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class DeadItemsService {
  /**
   * Class constructor
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get items list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    params.onlyDead = true;
    return this.apiService.apiCall('GET', 'items', params);
  }
}
