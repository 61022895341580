<mat-card class="stat-card" (click)="onClick()"
  [ngClass]="{'has-link': url, 'mat-elevation-z shadow': shadow, 'mat-elevation-z-2': !shadow}"
  [ngStyle]="{'background-color': backgroundColor}">
  <div class="row first-row">

    <div class="col first-col px-0" *ngIf="type === '1'">
      <div class="icon-wrapper" [ngStyle]="{'background-color': iconBackgroundColor}">
        <mat-icon fontSet="fal" [fontIcon]="icon" [ngStyle]="{'color': color}">
        </mat-icon>
      </div>
      <span class="value">{{ value | number }}</span>
      <span class="descriptor" [innerHTML]="descriptor" [ngStyle]="{'color': color}"></span>
    </div> <!-- /.col -->

    <div class="col first-col px-0" *ngIf="type === '2'">
      <div class="icon-wrapper" style="margin-right: 5px" [ngStyle]="{'background-color': iconBackgroundColor}">
        <mat-icon fontSet="fal" [fontIcon]="icon" [ngStyle]="{'color': color}">
        </mat-icon>
      </div>
      <span class="descriptor" [innerHTML]="descriptor" [ngStyle]="{'color': color}"></span>
      <span class="value" style="margin-right: 0; position: absolute; right: 30px;">{{ value }}</span>
    </div> <!-- /.col -->

    <div class="col first-col px-0" *ngIf="type === '3'">
      <div class="icon-wrapper" [ngStyle]="{'background-color': iconBackgroundColor}">
        <mat-icon fontSet="fal" [fontIcon]="icon" [ngStyle]="{'color': color}">
        </mat-icon>
      </div>
      <span class="value">{{ value | number }}</span>
      <span class="descriptor" [innerHTML]="descriptor" [ngStyle]="{'color': color}"></span>
      <span class="item" [innerHTML]="item" [ngStyle]="{'color': color}"></span>
    </div> <!-- /.col -->
  </div> <!-- /.row -->
</mat-card>