// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { MatSidenav } from '@angular/material/sidenav';

// Custom packages

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private sidenav: MatSidenav | undefined;

  /**
   * Set sidenav object
   *
   * @since 1.0.0
   *
   * @param sidenav Sidenav object
   */
  public setSidenav(sidenav?: MatSidenav): void {
    this.sidenav = sidenav;
  }

  /**
   * Open sidenav
   *
   * @since 1.0.0
   */
  public open(): void {
    this.sidenav?.open();
  }

  /**
   * Close sidenav
   *
   * @since 1.0.0
   */
  public close(): void {
    this.sidenav?.close();
  }

  /**
   * Toggle sidenav state
   *
   * @since 1.0.0
   */
  public toggle(): void {
    this.sidenav?.toggle();
  }
}
