<ng-container>
  <ngx-file-drop dropZoneLabel="Trascina qui i file" [accept]="allowedFilesExtWithDot"
    [multiple]="allowedFilesNumber > 1" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
    (onFileLeave)="fileLeave($event)" [showBrowseBtn]="true" class="import-area">

    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <mat-spinner *ngIf="isUploading" color="primary" class="loader"></mat-spinner>
      <div *ngIf="!isUploading" class="hint-wrapper d-flex align-items-center" (click)="openFileSelector()">
        <div class="hint">
          Carica qui
        </div> <!-- /.hint -->
        <button class="ml-1" type="button" mat-icon-button color="primary">
          <mat-icon fontSet="fal" fontIcon="fa-upload"></mat-icon>
        </button>
      </div> <!-- /.hint-wrapper -->
    </ng-template>
  </ngx-file-drop>
  <small>Estensioni consentite: {{ allowedFilesExt }}</small>
  <mat-hint *ngIf="hint" id="matHintDescription"><br>{{hint}}</mat-hint>
</ng-container>
