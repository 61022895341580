// Core packages
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { HttpClient } from '@angular/common/http'

// Third party packages
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { delay, finalize, take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

// Custom packages
import { ApiLoaderService } from 'src/app/apiLoader.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { ItemsService } from 'src/app/modules/plants/items.service';
import Response from 'src/app/shared/interfaces/response.interface';
import IAggregation from 'src/app/shared/models/aggregation/aggregation.interface';
import ICoordinates from 'src/app/shared/models/coordinates.interface';
import ICustomer from 'src/app/shared/models/customer/customer.interface';
import IItem from 'src/app/shared/models/item/item.interface';
import IPlant from 'src/app/shared/models/plant/plant.interface';
import ISite from 'src/app/shared/models/site/site.interface';
import IPlantFile from 'src/app/shared/models/plantFile/plantFile.interface';
import { ApiService } from 'src/app/shared/services/api.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { getBEUrl } from 'src/environments/environment';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SilenceItemReasonDialogComponent } from '../silence-item-reason-dialog/silence-item-reason-dialog.component';
import mapStyles from './mapStyles';
import { PlantFilesService } from 'src/app/modules/plants/plantFiles.service';
import IFile from 'src/app/shared/interfaces/file.interface';
import { LightboxDialogExtComponent } from '../lightbox-dialog-ext/lightbox-dialog-ext.component';
import { MapStaticObjectDialogComponent } from '../map-static-object-dialog/map-static-object-dialog.component';
import IStaticObject from 'src/app/shared/models/staticObject/staticObject.interface';
import { StaticObjectsService } from 'src/app/modules/staticObjects/staticObject.service';
import IStaticObjectGroup from 'src/app/shared/models/staticObjectGroup/staticObjectGroup.interface';
import IStaticObjectCategory from 'src/app/shared/models/staticObjectCategory/staticObjectCategory.interface';

interface CategorisCheckox {
  id: string;
  selected: boolean;
}
/**
 * Script start
 */
@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss'],
})
export class MapDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public apiHost: string = getBEUrl();
  public loading: boolean = true;
  public breadcrumbItems: {
    label: string;
    onClick: Function;
  }[] = [];
  public isViewPlant: boolean = false;
  public isViewSite: boolean = false;
  public breadcrumbPlantDocs: {
    label: string;
    onClick?: Function;
  } = { label: '' };
  public breadcrumbSiteDocs: {
    label: string;
    onClick?: Function;
  } = { label: '' };
  public filters: {
    status: {
      regular?: boolean;
      dead?: boolean;
      silenced?: boolean;
      excluded?: boolean;
      linked?: boolean;
    };
  } = {
      status: {},
    };
  public polyLinesList: google.maps.Polyline[] = [];
  /// BEGIN - Sidenav
  public sidenavOpen: boolean = false;
  public sidenavAccordion: number = 1;
  public sidenavAccordion1Sorting: boolean = true; // true: asc, false: desc
  public sidenavAccordion2Sorting: boolean = true; // true: asc, false: desc
  public sidenavAccordion4Sorting: boolean = true; // true: asc, false: desc
  @ViewChild('sidenavAccordion3Input', { static: false })
  sidenavAccordion3Input!: ElementRef;
  /// END - Sidenav

  /// BEGIN - Sidenav Files
  public sidenavFilesOpen: boolean = false;
  public imagePlantFiles: IPlantFile[] = [];
  public documentPlantFiles: IPlantFile[] = [];
  public imageSiteFiles: IPlantFile[] = [];
  public documentSiteFiles: IPlantFile[] = [];
  /// END - Sidenav Files

  /// BEGIN - Sidenav StaticObject
  public showStaticObjectsFiltersMenu: boolean = false;
  public sidenavStaticObjectOpen: boolean = false;
  public sidenavStaticObject: any;
  /// END - Sidenav StaticObject

  // BEGIN - Map data
  @ViewChild('map') mapElement: any;
  public center: google.maps.LatLngLiteral = {
    lat: 45.44367,
    lng: 9.19853,
  };
  public apiLoaded!: Observable<boolean>;
  public map!: google.maps.Map;
  public initialZoom = 12;
  public currentZoom = this.initialZoom;
  // NB: always use ".1" beceuse we are not handlig the case of currentZoom === maxSiteZoom. And since you can only zoom with +1 this allow us to be save
  private maxSitesZoom = 18;
  private zoomListener!: google.maps.MapsEventListener;
  private ignoreMapListener: boolean = false; // Used to prevent mapx.fitBounds() to trigger a zoom_changed event
  private panListener!: google.maps.MapsEventListener;
  // public userHasPanned: boolean = false;
  // Construct the polygon.

  public mapOptions: google.maps.MapOptions = {
    zoom: this.currentZoom,
    minZoom: 11,
    styles: mapStyles,
    center: this.center,
    fullscreenControl: false,
    gestureHandling: 'greedy',
  };
  private showItemsMarkers: boolean = false;
  public openWindow!: google.maps.InfoWindow | null;
  public markers: google.maps.Marker[] = [];
  public oms!: any;
  public resultType!: string;
  public aggregations: IAggregation[] = [];
  public customers: ICustomer[] = [];
  public plants: IPlant[] = [];
  public sites: ISite[] = [];
  public items: IItem[] = [];
  public deadItems: IItem[] = [];
  public staticObjects: IStaticObject[] = [];
  public staticObjectGroups: IStaticObjectGroup[] = [];
  public staticObjectCategories: IStaticObjectCategory[] = [];
  public categorisCheckox: CategorisCheckox[] = [];
  public staticObjectMarkers: google.maps.Marker[] = [];
  // END - Map data

  // Multi download of files
  fileCounter = 0;
  filesToDownload: IPlantFile[] = [];

  // Static objects

  enabledToStaticObject = false;
  contextMenuIsopen = false;
  menuBox: HTMLElement | null = null;
  coordinates = {
    lat: 0.0,
    lng: 0.0
  };

  constructor(
    public dialogRef: MatDialogRef<MapDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      params: any;
    },
    public configService: ConfigService,
    private apiLoaderService: ApiLoaderService,
    private apiService: ApiService,
    private toastrService: ToastrService,
    public helperService: HelperService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private itemsService: ItemsService,
    private plantFilesService: PlantFilesService,
    private httpClient: HttpClient,
    private staticObjectsService: StaticObjectsService,
  ) {
    // console.log('data', this.data);
  }

  /**
   * Init component
   *
   * @since 1.1.0
   */
  ngOnInit(): void {
    this.apiLoaded = this.apiLoaderService.loadApi();
    this.apiLoaded.pipe(delay(10)).subscribe(() => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src =
        'https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier/1.0.3/oms.min.js';
      this.renderer.appendChild(this.document.head, script);

      // Now that google is loaded we can customize some settings
      this.mapOptions.mapTypeControl = true;
      (this.mapOptions.mapTypeControlOptions = {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      }),
        this.loadData();
    });

    // set linked filter
    this.filters.status.linked = false;
  }

  /**
   * Handle component destroy
   *
   * @since 1.1.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());

    if (this.panListener) {
      this.panListener.remove();
    }
    if (this.zoomListener) {
      this.zoomListener.remove();
    }
  }

  /**
   * Load data form back-end depending on given dataSettings
   *
   * @since 1.1.0
   */
  loadData(): void {
    console.log('### loadData ###')
    const params: any = {
      ...this.data.params,
    };

    setInterval(() => {
      this.subscriptions.push(
        this.apiService
          .apiCall('GET', 'utils/map-data', params)
          .pipe(take(1))
          .subscribe((res: Response) => {
            if (!res.status) {
              return;
            }

            const {
              resultType,
              aggregations,
              customers,
              plants,
              sites,
              items,
              plantFiles,
              siteFiles,
              staticObjects
            } = res.data;

            // Load data in component
            this.resultType = resultType;
            this.aggregations = aggregations;
            this.customers = customers;
            this.plants = plants;
            this.sites = sites;
            this.items = items.filter((el: IItem) => el.ip);
            this.deadItems = items.filter(
              (el: IItem) =>
                !el.silenced && el?.deadFrom && moment(el.deadFrom).isValid(),
              // && moment().diff(el.deadFrom, 'minutes') >
              //   this.configService.maxItemsDowntime,
            );

            this.staticObjects = staticObjects;
            this.markers.map((el) => el.setMap(null));
            this.createMarkers(false);
            this.runFilters();
          }),
      );
    }, 60 * 1000);

    this.subscriptions.push(
      this.apiService
        .apiCall('GET', 'utils/map-data', params)
        .pipe(take(1))
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Close
              this.onClose();

              this.toastrService.error(
                'Non è stato possibile caricare i dati. Riprova o contatta il supporto tecnico',
              );

              return;
            }

            // this.userHasPanned = false;

            const {
              resultType,
              aggregations,
              customers,
              plants,
              sites,
              items,
              staticObjects,
              staticObjectGroups,
              staticObjectCategories,
            } = res.data;

            // Load data in component
            this.resultType = resultType;
            this.aggregations = aggregations;
            this.customers = customers;
            this.plants = plants;
            this.sites = sites;
            this.items = items.filter((el: IItem) => el.ip);
            this.deadItems = items.filter(
              (el: IItem) =>
                !el.silenced && el?.deadFrom && moment(el.deadFrom).isValid(),
              // && moment().diff(el.deadFrom, 'minutes') >
              //   this.configService.maxItemsDowntime,
            );

            this.staticObjectGroups = staticObjectGroups;
            this.staticObjectCategories = staticObjectCategories;
            for (const category of this.staticObjectCategories) {
              this.categorisCheckox.push({
                id: category._id,
                selected: false,
              })
            }
            this.staticObjects = staticObjects;

            // Reset breadcrumb
            this.breadcrumbItems = [];
            this.breadcrumbPlantDocs.label = '';
            this.breadcrumbSiteDocs.label = '';

            // Handle ONE item result type
            if (resultType === 'item') {
              if (!this.items.length) {
                this.toastrService.error('Non ci sono apparati da mostrare');
              }

              // BEGIN - Set breadcrumb
              if (
                res.data.aggregations &&
                Array.isArray(res.data.aggregations) &&
                res.data.aggregations?.length > 0
              ) {
                this.breadcrumbItems.push({
                  label: res.data.aggregations[0].name,
                  onClick: () => {
                    if (this.map) {
                      this.loading = true;

                      delete this.data.params.aggregationId;
                      delete this.data.params.plantId;
                      delete this.data.params.siteId;
                      delete this.data.params.itemId;

                      this.data.params.aggregationId =
                        res.data.aggregations[0]._id;
                      // this.data.params.plantId = res.data.plants[0]._id;
                      // this.data.params.siteId = res.data.sites[0]._id;
                      // this.data.params.itemId = res.data.items[0]._id;

                      // Close docs area
                      this.sidenavFilesOpen = false;

                      this.loadData();
                    }
                  },
                });
              }

              if (
                res.data.plants &&
                Array.isArray(res.data.plants) &&
                res.data.plants?.length > 0
              ) {
                this.breadcrumbItems.push({
                  label: res.data.plants[0].name,
                  onClick: () => {
                    if (this.map) {
                      this.loading = true;

                      delete this.data.params.aggregationId;
                      delete this.data.params.plantId;
                      delete this.data.params.siteId;
                      delete this.data.params.itemId;

                      // this.data.params.aggregationId =
                      //   res.data.aggregations[0]._id;
                      this.data.params.plantId = res.data.plants[0]._id;
                      // this.data.params.siteId = res.data.sites[0]._id;
                      // this.data.params.itemId = res.data.items[0]._id;

                      // Close docs area
                      this.sidenavFilesOpen = false;

                      this.loadData();
                    }
                  },
                });
              }

              if (
                res.data.sites &&
                Array.isArray(res.data.sites) &&
                res.data.sites?.length > 0
              ) {
                this.breadcrumbItems.push({
                  label: res.data.sites[0].name,
                  onClick: () => {
                    if (this.map) {
                      this.loading = true;

                      delete this.data.params.aggregationId;
                      delete this.data.params.plantId;
                      delete this.data.params.siteId;
                      delete this.data.params.itemId;

                      // this.data.params.aggregationId =
                      //   res.data.aggregations[0]._id;
                      // this.data.params.plantId = res.data.plants[0]._id;
                      this.data.params.siteId = res.data.sites[0]._id;
                      // this.data.params.itemId = res.data.items[0]._id;

                      // Close docs area
                      this.sidenavFilesOpen = false;

                      this.loadData();
                    }
                  },
                });
              }

              if (
                res.data.items &&
                Array.isArray(res.data.items) &&
                res.data.items?.length > 0
              ) {
                this.breadcrumbItems.push({
                  label: `${this.helperService.getNameForItemType(
                    res.data.items[0].type,
                  )} (${res.data.items[0].ip})`,
                  onClick: () => {
                    if (this.map) {
                      // Recenter map
                      this.onCenterMap(
                        res.data.items[0]?.coordinates?.lat,
                        res.data.items[0]?.coordinates?.lng,
                      );

                      // Close docs area
                      this.sidenavFilesOpen = false;

                      // Reset map zoom
                      this.map.setZoom(22);
                      this.runFilters();
                    }
                  },
                });
              }
              // END - Set breadcrumb

              // Center map on item
              if (
                res.data.items &&
                Array.isArray(res.data.items) &&
                res.data.items?.length > 0
              ) {
                this.showItemsMarkers = true;
                this.onCenterMap(
                  res.data.items[0]?.coordinates?.lat,
                  res.data.items[0]?.coordinates?.lng,
                );
              }
            }

            // Handle ONE site result type
            if (resultType === 'site') {
              if (!this.items.length) {
                this.toastrService.error('Non ci sono apparati da mostrare');
              }

              // BEGIN - Set breadcrumb
              if (
                res.data.aggregations &&
                Array.isArray(res.data.aggregations) &&
                res.data.aggregations?.length > 0
              ) {
                this.breadcrumbItems.push({
                  label: res.data.aggregations[0].name,
                  onClick: () => {
                    if (this.map) {
                      this.loading = true;

                      delete this.data.params.aggregationId;
                      delete this.data.params.plantId;
                      delete this.data.params.siteId;
                      delete this.data.params.itemId;

                      this.data.params.aggregationId =
                        res.data.aggregations[0]._id;
                      // this.data.params.plantId = res.data.plants[0]._id;
                      // this.data.params.siteId = res.data.sites[0]._id;
                      // this.data.params.itemId = res.data.items[0]._id;

                      // Close docs area
                      this.sidenavFilesOpen = false;

                      this.loadData();
                    }
                  },
                });
              }

              if (
                res.data.plants &&
                Array.isArray(res.data.plants) &&
                res.data.plants?.length > 0
              ) {
                this.breadcrumbItems.push({
                  label: res.data.plants[0].name,
                  onClick: () => {
                    if (this.map) {
                      this.loading = true;

                      delete this.data.params.aggregationId;
                      delete this.data.params.plantId;
                      delete this.data.params.siteId;
                      delete this.data.params.itemId;

                      // this.data.params.aggregationId =
                      //   res.data.aggregations[0]._id;
                      this.data.params.plantId = res.data.plants[0]._id;
                      // this.data.params.siteId = res.data.sites[0]._id;
                      // this.data.params.itemId = res.data.items[0]._id;

                      // Close docs area
                      this.sidenavFilesOpen = false;

                      this.loadData();
                    }
                  },
                });
              }

              if (
                res.data.sites &&
                Array.isArray(res.data.sites) &&
                res.data.sites?.length > 0
              ) {
                this.breadcrumbItems.push({
                  label: res.data.sites[0].name,
                  onClick: () => {
                    if (this.map) {
                      // Close docs area
                      this.sidenavFilesOpen = false;

                      // Recenter map
                      this.onCenterMap(
                        res.data.sites[0]?.coordinates?.lat,
                        res.data.sites[0]?.coordinates?.lng,
                      );
                      this.runFilters();
                    }
                  },
                });
              }

              if (
                res.data.plantFiles &&
                Array.isArray(res.data.plantFiles) &&
                res.data.plantFiles?.length > 0
              ) {
                this.breadcrumbPlantDocs = {
                  label: res.data.plantFiles?.length,
                  onClick: () => {
                    if (this.map) {
                      this.sidenavFilesOpen = !this.sidenavFilesOpen;
                    }
                  },
                };

                this.imagePlantFiles = res.data.plantFiles.filter(
                  (el: IPlantFile) => el.fileType === 'Image');
                this.documentPlantFiles = res.data.plantFiles.filter(
                  (el: IPlantFile) => el.fileType === 'Document');
              }
              if (
                res.data.siteFiles &&
                Array.isArray(res.data.siteFiles) &&
                res.data.siteFiles?.length > 0
              ) {
                this.breadcrumbSiteDocs = {
                  label: res.data.siteFiles?.length,
                  onClick: () => {
                    if (this.map) {
                      this.sidenavFilesOpen = !this.sidenavFilesOpen;
                    }
                  },
                };

                this.imageSiteFiles = res.data.siteFiles.filter(
                  (el: IPlantFile) => el.fileType === 'Image');
                this.documentSiteFiles = res.data.siteFiles.filter(
                  (el: IPlantFile) => el.fileType === 'Document');
              }
              // END - Set breadcrumb

              // Center map on site
              if (
                res.data.sites &&
                Array.isArray(res.data.sites) &&
                res.data.sites?.length > 0
              ) {
                this.showItemsMarkers = false;
                this.onCenterMap(
                  res.data.sites[0]?.coordinates?.lat,
                  res.data.sites[0]?.coordinates?.lng,
                );
              }
            }

            // Handle ONE plant result type
            if (resultType === 'plant') {
              if (!this.items.length) {
                this.toastrService.error('Non ci sono apparati da mostrare');
              }

              // BEGIN - Set breadcrumb
              if (
                res.data.aggregations &&
                Array.isArray(res.data.aggregations) &&
                res.data.aggregations?.length > 0
              ) {
                this.breadcrumbItems.push({
                  label: res.data.aggregations[0].name,
                  onClick: () => {
                    if (this.map) {
                      this.loading = true;

                      delete this.data.params.aggregationId;
                      delete this.data.params.plantId;
                      delete this.data.params.siteId;
                      delete this.data.params.itemId;

                      this.data.params.aggregationId =
                        res.data.aggregations[0]._id;
                      // this.data.params.plantId = res.data.plants[0]._id;
                      // this.data.params.siteId = res.data.sites[0]._id;
                      // this.data.params.itemId = res.data.items[0]._id;

                      // Close docs area
                      this.sidenavFilesOpen = false;

                      this.loadData();
                    }
                  },
                });
              }

              if (
                res.data.plants &&
                Array.isArray(res.data.plants) &&
                res.data.plants?.length > 0
              ) {
                this.breadcrumbItems.push({
                  label: res.data.plants[0].name,
                  onClick: () => {
                    if (this.map) {
                      // Recenter map
                      this.onCenterMap(
                        res.data.plants[0]?.coordinates?.lat,
                        res.data.plants[0]?.coordinates?.lng,
                      );

                      // Close docs area
                      this.sidenavFilesOpen = false;

                      // Reset map zoom
                      this.map.setZoom(this.initialZoom);
                      this.runFilters();
                    }
                  },
                });
              }

              if (
                res.data.plantFiles &&
                Array.isArray(res.data.plantFiles) &&
                res.data.plantFiles?.length > 0
              ) {
                this.breadcrumbPlantDocs = {
                  label: res.data.plantFiles?.length,
                  onClick: () => {
                    if (this.map) {
                      this.sidenavFilesOpen = !this.sidenavFilesOpen;
                    }
                  },
                };

                this.imagePlantFiles = res.data.plantFiles.filter(
                  (el: IPlantFile) => el.fileType === 'Image');
                this.documentPlantFiles = res.data.plantFiles.filter(
                  (el: IPlantFile) => el.fileType === 'Document');
              }

              if (
                res.data.siteFiles &&
                Array.isArray(res.data.siteFiles) &&
                res.data.siteFiles?.length > 0
              ) {
                this.breadcrumbSiteDocs = {
                  label: res.data.siteFiles?.length,
                  onClick: () => {
                    if (this.map) {
                      this.sidenavFilesOpen = !this.sidenavFilesOpen;
                    }
                  },
                };

                this.imageSiteFiles = res.data.siteFiles.filter(
                  (el: IPlantFile) => el.fileType === 'Image');
                this.documentSiteFiles = res.data.siteFiles.filter(
                  (el: IPlantFile) => el.fileType === 'Document');
              }
              // END - Set breadcrumb

              // Center map on plant
              if (
                res.data.plants &&
                Array.isArray(res.data.plants) &&
                res.data.plants?.length > 0
              ) {
                this.showItemsMarkers = false;
                this.onCenterMap(
                  res.data.plants[0]?.coordinates?.lat,
                  res.data.plants[0]?.coordinates?.lng,
                );
              }
            }

            // Handle ONE aggregation result type
            if (resultType === 'aggregation') {
              if (!this.items.length) {
                this.toastrService.error('Non ci sono apparati da mostrare');
              }

              // BEGIN - Set breadcrumb
              if (
                res.data.aggregations &&
                Array.isArray(res.data.aggregations) &&
                res.data.aggregations?.length > 0
              ) {
                this.breadcrumbItems.push({
                  label: res.data.aggregations[0].name,
                  onClick: () => {
                    // Close docs area
                    this.sidenavFilesOpen = false;

                    this.loadData();
                  },
                });
              }

              this.showItemsMarkers = false;

              // Center map to fit all items

              // if (
              //   res.data.plants &&
              //   Array.isArray(res.data.plants) &&
              //   res.data.plants?.length > 0
              // ) {
              //   this.onCenterMap(
              //     res.data.plants[0]?.coordinates?.lat,
              //     res.data.plants[0]?.coordinates?.lng,
              //   );
              // }
            }

            this.initMap();
            this.createMarkers(true);
            this.runFilters();

            // Show objects
            for (const group of this.staticObjectGroups) {
              for (const category of this.getstaticObjectCategories(group._id)) {
                const categoryId = category._id;
                
                if (this.getCategoryChecked(categoryId)) {
                  this.staticObjects.forEach((item: any) => {
                    if (item.staticObjectCategoryId._id === categoryId) {
                      if (item.coordinates?.lat && item.coordinates.lng) {
                        let marker: any;
                        let url = '';
                        if (
                          item.staticObjectCategoryId &&
                          item.staticObjectCategoryId.icon &&
                          item.staticObjectCategoryId.icon.path
                        ) {
                          url = `${getBEUrl()}${item.staticObjectCategoryId.icon.path}`;
                        }
                        marker = new google.maps.Marker({
                          position: item.coordinates as ICoordinates,
                          icon: {
                            url,
                            scaledSize: new google.maps.Size(20, 20),
                            anchor: new google.maps.Point(25, 25),
                          },
                          title: item.staticObjectCategoryId.name,
                        });
              
                        marker.data = item;
                        marker.addListener('click', (e: any) => {
                          if (marker.data) {
                            this.sidenavStaticObjectOpen = !this.sidenavStaticObjectOpen;
                            this.sidenavStaticObject = marker.data;
                          }
                        });
              
                        this.staticObjectMarkers.push(marker);
                      }
                    }
                  });
              
                  this.staticObjectMarkers.forEach((item: google.maps.Marker | any) => {
                    if (item.data.staticObjectCategoryId._id === categoryId) {
                      item.setMap(this.map);
                    }
                  });
                }
              }
            }
          },
          (err: any) => {
            // Close
            this.onClose();

            this.toastrService.error(
              'Non è stato possibile caricare i dati. Riprova o contatta il supporto tecnico',
            );
          },
        ),
    );
  }

  /**
   * Init map with current settings
   *
   * @since 1.1.0
   */
  initMap(): void {
    this.menuBox = document.getElementById('contextMenu');
    let polygon: google.maps.Polygon;

    // console.log('INIT MAP');
    const userLoggedData = this.authService?.loggedUser$.value;
    if (
      userLoggedData &&
      (
        userLoggedData.role === 'admin' || 
        userLoggedData.superOwner || 
        userLoggedData.staticObjectManagement ||
        (
          userLoggedData.staticObjectGroupIds && 
          Array.isArray(userLoggedData.staticObjectGroupIds) &&
          userLoggedData.staticObjectGroupIds.length
        )
      )
    ) {
      this.showStaticObjectsFiltersMenu = true;
    }

    if (this.map) {
      this.map.unbindAll();
    }

    if (this.zoomListener) {
      this.zoomListener.remove();
    }

    this.map = new google.maps.Map(
      this.mapElement.nativeElement,
      this.mapOptions,
    );

    if (this.customers.length) {
      for (const customer of this.customers) {
        if (customer.polygon) {
          try {
            const polygonData = JSON.parse(customer.polygon);
            polygon = new google.maps.Polygon({
              paths: polygonData,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 1.5,
              fillColor: '#FF0000',
              fillOpacity: 0.05,
            });

            polygon.setMap(this.map);

            // Set event rigthclick on polygon to handle Static object modal
            if (
              userLoggedData &&
              (userLoggedData.role === 'admin' || userLoggedData.superOwner || userLoggedData.staticObjectManagement)
            ) {
              this.enabledToStaticObject = true;

              // Avoid to create static object from Aggregation
              if (this.data.params.hasOwnProperty('plantId')) {
                google.maps.event.addListener(polygon, 'contextmenu', (mapsMouseEvent: any ) => {
                  // console.log("QUI 2 - rightclick")
                  mapsMouseEvent.domEvent.preventDefault();
                  mapsMouseEvent.domEvent.stopPropagation();
                  if (mapsMouseEvent) {
                    const mouseEvent = mapsMouseEvent.domEvent as MouseEvent;

                    if (this.menuBox) {
                      this.menuBox.style.left = mouseEvent.clientX + 'px';
                      this.menuBox.style.top = mouseEvent.clientY + 'px';
                      this.menuBox.style.display = 'block';

                      this.contextMenuIsopen = true;
                      if (mapsMouseEvent.latLng) {
                        
                        this.coordinates = {
                          lat: mapsMouseEvent.latLng.lat(),
                          lng: mapsMouseEvent.latLng.lng()
                        };
                      }
                    }
                  }
                });

                google.maps.event.addListener(polygon, 'click', (mapsMouseEvent: any) => {
                  mapsMouseEvent.domEvent.preventDefault();
                  mapsMouseEvent.domEvent.stopPropagation();
                  if (this.contextMenuIsopen) {
                    if (this.menuBox) {
                      this.menuBox.style.display = 'none';

                      this.contextMenuIsopen = false;
                      this.coordinates = {
                        lat: 0.0,
                        lng: 0.0
                      };
                    }
                  }
                });
              }
            }

          } catch (error) {
            console.error('error on polygon: ', error);
          }
        }
      }
    }

    // console.log('SET ZOOM LISTENERS');
    this.zoomListener = this.map.addListener('zoom_changed', (e: any) => {
      // Block callback if ignoreMapListener has been setted
      if (this.ignoreMapListener) {
        return;
      }

      const newZoom = this.map.getZoom() as number;

      if (newZoom > this.currentZoom) {
        // Sta zoommando
        if (this.currentZoom > this.maxSitesZoom) {
          // Stava già vedendo gli items
          // Non devo ricalcolare nulla
          // if (!this.showItemsMarkers) {
          // }
        } else {
          if (newZoom > this.maxSitesZoom) {
            // Stava vedendo i siti, ora devo far vedere gli items
            // console.log('passo da "siti" ad "apparati"');
            this.showItemsMarkers = true;
            this.createMarkers(false);
          }
        }
      } else {
        // Sta de-zommando
        if (
          this.currentZoom > this.maxSitesZoom &&
          newZoom <= this.maxSitesZoom
        ) {
          // Stava vedendo gli item, ora deve vedere gli siti
          // console.log('passo da "apparati" a "siti"');
          this.showItemsMarkers = false;
          this.createMarkers(false);
        } else {
          if (
            this.currentZoom > this.maxSitesZoom &&
            newZoom >= this.maxSitesZoom &&
            !this.showItemsMarkers
          ) {
            // console.log('Sto mostrando siti ma devo mostrare apparati');
            this.showItemsMarkers = true;
            this.createMarkers(false);
          }
        }
      }

      this.currentZoom = newZoom;
    });

    if (this.panListener) {
      this.panListener.remove();
    }

    // Check user Admin or enable to Static object
    if (
      userLoggedData &&
      (userLoggedData.role === 'admin' || userLoggedData.superOwner || userLoggedData.staticObjectManagement)
    ) {
      this.enabledToStaticObject = true;

      // Avoid to create static object fro Aggregation
      if (this.data.params.hasOwnProperty('plantId')) {
        this.map.addListener('contextmenu', (mapsMouseEvent: google.maps.MapMouseEvent) => {
          // console.log("QUI 1 - rightclick")
          mapsMouseEvent.domEvent.preventDefault();
          mapsMouseEvent.domEvent.stopPropagation();
          if (mapsMouseEvent) {
            const mouseEvent = mapsMouseEvent.domEvent as MouseEvent;

            if (this.menuBox) {
              this.menuBox.style.left = mouseEvent.clientX + 'px';
              this.menuBox.style.top = mouseEvent.clientY + 'px';
              this.menuBox.style.display = 'block';

              this.contextMenuIsopen = true;
              if (mapsMouseEvent.latLng) {
                this.coordinates = {
                  lat: mapsMouseEvent.latLng.lat(),
                  lng: mapsMouseEvent.latLng.lng()
                };
              }
            }
          }
        });

        this.map.addListener('click', (mapsMouseEvent: google.maps.MapMouseEvent) => {
          mapsMouseEvent.domEvent.preventDefault();

          if (this.contextMenuIsopen) {
            if (this.menuBox) {
              this.menuBox.style.display = 'none';

              this.contextMenuIsopen = false;
              this.coordinates = {
                lat: 0.0,
                lng: 0.0
              };
            }
          }
        });
      }
    }
    // // Start map pan listener
    // this.panListener = this.map.addListener('center_changed', () => {
    //   // Block callback if ignoreMapListener has been setted
    //   if (this.ignoreMapListener) {
    //     return;
    //   }
    //   this.userHasPanned = true;
    // });
  }

  /**
   * Center map on give item's coordinates
   *
   * @since 1.1.0
   */
  onCenterMap(
    lat?: string | number,
    lng?: string | number,
    shouldZoom: boolean = true,
  ): void {
    if (!lat || !lng) {
      return;
    }

    if (!this.map) {
      this.mapOptions.center = {
        lat: lat as number,
        lng: lng as number,
      };
      return;
    }

    this.map.setCenter({
      lat: lat as number,
      lng: lng as number,
    });

    if (shouldZoom) {
      this.map.setZoom(22);
    }
  }

  /**
   * Create map markers based on loaded items
   *
   * @since 1.0.0
   */
  createMarkers(fitToBounds: boolean = true): void {
    console.log('### createMarkers ###')

    // Handle poly lines
    this.drawItemsLines();

    // console.log('CREATE MARKERS!');
    if (!this.items.length) {
      // console.warn('load items before creating markers!');
      return;
    }

    // Set map if this is the first load
    if (!this.map) {
      // console.warn('create map before creating markers!');
      return;
    }

    // Clear previous listeners
    this.oms?.clearListeners();

    if (!this.showItemsMarkers) {
      // console.log('MOSTRO MARKER SITI', this.sites.length);
      // Remove all previous markers
      this.oms?.removeAllMarkers();

      this.markers = this.sites
        .map((item: ISite) => {
          if (item.coordinates?.lat && item.coordinates.lng) {
            let marker: any;
            let svg;

            const siteItems = this.items.filter((el) => el.siteId === item._id);

            const downItems = siteItems
              .map((el) => this.helperService.itemIsDown(el))
              .filter((isDown) => isDown);

            const silencedItems = siteItems
              .map((el) => this.helperService.itemIsSilenced(el))
              .filter((isDown) => isDown);

            const excludedItems = siteItems
              .map((el) => this.helperService.itemIsExcluded(el))
              .filter((isDown) => isDown);

            const allItemsAreSilenced =
              this.items.length > 0 && siteItems.length === silencedItems.length;
            const allItemsAreDown =
              this.items.length > 0 && siteItems.length === downItems.length;
            const allItemsAreExcluded =
              this.items.length > 0 && siteItems.length === excludedItems.length;

            // console.group('__', item.name);
            // console.log('items', this.items.length);
            // console.log('siteItems', siteItems.length);

            // console.log('downItems', downItems.length);
            // console.log('silencedItems', silencedItems.length);
            // console.log('excludedItems', excludedItems.length);

            // console.log('allItemsAreSilenced', allItemsAreSilenced);
            // console.log('allItemsAreDown', allItemsAreDown);
            // console.groupEnd();

            if (allItemsAreDown) {
              // Create svg
              svg = window.btoa(`
              <svg width="50px" height="50px" viewBox="0 0 65 65" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <circle id="path-1" cx="28" cy="28" r="26"></circle>
                  <filter x="-26.7%" y="-23.3%" width="153.3%" height="153.3%" filterUnits="objectBoundingBox"
                    id="filter-2">
                    <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix"
                      in="shadowBlurOuter1"></feColorMatrix>
                  </filter>
                </defs>
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(4, 3)">
                    <g id="Oval-2">
                      <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                      <use fill="#EA5455" fill-rule="evenodd" xlink:href="#path-1"></use>
                    </g>
                    <g id="adam-icon" transform="translate(13, 10)" fill="#FFFFFF">
                      <path
                        d="M4.61999261,26.6050296 L6.35571118,22.8831682 C5.29301715,21.241951 4.38979479,19.5188754 3.94877001,17.5889047 C3.40098029,15.1898221 3.65775672,12.8501093 4.6470217,10.6102459 C5.8128768,7.97143496 7.73419518,6.04281361 10.3735866,4.84732024 C11.9412743,4.13713062 13.5693269,3.78540909 15.2365718,3.83758262 L15.2654028,3.77551411 L15.2951348,3.84163056 C15.815445,3.86052098 16.3389085,3.91584291 16.8655254,4.01524247 C21.3397421,4.85901431 24.3521351,7.47128875 25.7972909,11.7576137 C26.6860977,14.3941758 26.5243736,17.0451306 25.4062699,19.6268206 C24.940018,20.7022249 24.3899758,21.7214077 23.7777668,22.6987618 L25.478798,26.4795433 C26.6523113,24.872059 27.7019413,23.1845153 28.5416453,21.3570926 C29.8210226,18.5712063 30.3093483,15.6697286 29.8034537,12.6423148 C29.1295281,8.61190996 27.1500972,5.33982033 23.8376813,2.91734958 C20.5356265,0.502524943 16.8164226,-0.441995774 12.7760229,0.19173265 C8.18242772,0.912267026 4.59972078,3.28166471 2.20674464,7.26439373 C-0.316872135,11.4657118 -0.669601863,15.9031601 1.11567006,20.4840856 C1.98105168,22.7046088 3.21538048,24.7083421 4.61999261,26.6050296"
                        id="Fill-58"></path>
                      <polygon id="Fill-59"
                        points="17.8243827 29.9195327 14.998941 32.5453003 12.2622448 29.982051 10.5909456 33.3737799 14.9998419 37.2948902 19.433515 33.3638849">
                      </polygon>
                      <polygon id="Fill-60"
                        points="15.1793152 20.4515221 21.5014212 33.0069011 27.3969179 35.1914426 15.2324724 9.61382155 2.62474964 35.1860453 8.56304247 33.0042025">
                      </polygon>
                    </g>
                  </g>
                </g>
              </svg>
              `);
            } else if (allItemsAreSilenced) {
              // Create svg
              svg = window.btoa(`
              <svg width="50px" height="50px" viewBox="0 0 65 65" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <circle id="path-1" cx="28" cy="28" r="26"></circle>
                  <filter x="-26.7%" y="-23.3%" width="153.3%" height="153.3%" filterUnits="objectBoundingBox"
                    id="filter-2">
                    <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix"
                      in="shadowBlurOuter1"></feColorMatrix>
                  </filter>
                </defs>
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(4, 3)">
                    <g id="Oval-2">
                      <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                      <use fill="#6D767B" fill-rule="evenodd" xlink:href="#path-1"></use>
                    </g>
                    <g id="adam-icon" transform="translate(13, 10)" fill="#FFFFFF">
                      <path
                        d="M4.61999261,26.6050296 L6.35571118,22.8831682 C5.29301715,21.241951 4.38979479,19.5188754 3.94877001,17.5889047 C3.40098029,15.1898221 3.65775672,12.8501093 4.6470217,10.6102459 C5.8128768,7.97143496 7.73419518,6.04281361 10.3735866,4.84732024 C11.9412743,4.13713062 13.5693269,3.78540909 15.2365718,3.83758262 L15.2654028,3.77551411 L15.2951348,3.84163056 C15.815445,3.86052098 16.3389085,3.91584291 16.8655254,4.01524247 C21.3397421,4.85901431 24.3521351,7.47128875 25.7972909,11.7576137 C26.6860977,14.3941758 26.5243736,17.0451306 25.4062699,19.6268206 C24.940018,20.7022249 24.3899758,21.7214077 23.7777668,22.6987618 L25.478798,26.4795433 C26.6523113,24.872059 27.7019413,23.1845153 28.5416453,21.3570926 C29.8210226,18.5712063 30.3093483,15.6697286 29.8034537,12.6423148 C29.1295281,8.61190996 27.1500972,5.33982033 23.8376813,2.91734958 C20.5356265,0.502524943 16.8164226,-0.441995774 12.7760229,0.19173265 C8.18242772,0.912267026 4.59972078,3.28166471 2.20674464,7.26439373 C-0.316872135,11.4657118 -0.669601863,15.9031601 1.11567006,20.4840856 C1.98105168,22.7046088 3.21538048,24.7083421 4.61999261,26.6050296"
                        id="Fill-58"></path>
                      <polygon id="Fill-59"
                        points="17.8243827 29.9195327 14.998941 32.5453003 12.2622448 29.982051 10.5909456 33.3737799 14.9998419 37.2948902 19.433515 33.3638849">
                      </polygon>
                      <polygon id="Fill-60"
                        points="15.1793152 20.4515221 21.5014212 33.0069011 27.3969179 35.1914426 15.2324724 9.61382155 2.62474964 35.1860453 8.56304247 33.0042025">
                      </polygon>
                    </g>
                  </g>
                </g>
              </svg>
              `);
            } else if (allItemsAreExcluded) {
              // Create svg
              svg = window.btoa(`
              <svg width="50px" height="50px" viewBox="0 0 65 65" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <circle id="path-1" cx="28" cy="28" r="26"></circle>
                  <filter x="-26.7%" y="-23.3%" width="153.3%" height="153.3%" filterUnits="objectBoundingBox"
                    id="filter-2">
                    <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix"
                      in="shadowBlurOuter1"></feColorMatrix>
                  </filter>
                </defs>
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(4, 3)">
                    <g id="Oval-2">
                      <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                      <use fill="#2f83c3" fill-rule="evenodd" xlink:href="#path-1"></use>
                    </g>
                    <g id="adam-icon" transform="translate(13, 10)" fill="#FFFFFF">
                      <path
                        d="M4.61999261,26.6050296 L6.35571118,22.8831682 C5.29301715,21.241951 4.38979479,19.5188754 3.94877001,17.5889047 C3.40098029,15.1898221 3.65775672,12.8501093 4.6470217,10.6102459 C5.8128768,7.97143496 7.73419518,6.04281361 10.3735866,4.84732024 C11.9412743,4.13713062 13.5693269,3.78540909 15.2365718,3.83758262 L15.2654028,3.77551411 L15.2951348,3.84163056 C15.815445,3.86052098 16.3389085,3.91584291 16.8655254,4.01524247 C21.3397421,4.85901431 24.3521351,7.47128875 25.7972909,11.7576137 C26.6860977,14.3941758 26.5243736,17.0451306 25.4062699,19.6268206 C24.940018,20.7022249 24.3899758,21.7214077 23.7777668,22.6987618 L25.478798,26.4795433 C26.6523113,24.872059 27.7019413,23.1845153 28.5416453,21.3570926 C29.8210226,18.5712063 30.3093483,15.6697286 29.8034537,12.6423148 C29.1295281,8.61190996 27.1500972,5.33982033 23.8376813,2.91734958 C20.5356265,0.502524943 16.8164226,-0.441995774 12.7760229,0.19173265 C8.18242772,0.912267026 4.59972078,3.28166471 2.20674464,7.26439373 C-0.316872135,11.4657118 -0.669601863,15.9031601 1.11567006,20.4840856 C1.98105168,22.7046088 3.21538048,24.7083421 4.61999261,26.6050296"
                        id="Fill-58"></path>
                      <polygon id="Fill-59"
                        points="17.8243827 29.9195327 14.998941 32.5453003 12.2622448 29.982051 10.5909456 33.3737799 14.9998419 37.2948902 19.433515 33.3638849">
                      </polygon>
                      <polygon id="Fill-60"
                        points="15.1793152 20.4515221 21.5014212 33.0069011 27.3969179 35.1914426 15.2324724 9.61382155 2.62474964 35.1860453 8.56304247 33.0042025">
                      </polygon>
                    </g>
                  </g>
                </g>
              </svg>
              `);
            } else {
              // Create svg
              svg = window.btoa(`
              <svg width="50px" height="50px" viewBox="0 0 65 65" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <circle id="path-1" cx="28" cy="28" r="26"></circle>
                  <filter x="-26.7%" y="-23.3%" width="153.3%" height="153.3%" filterUnits="objectBoundingBox"
                    id="filter-2">
                    <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix"
                      in="shadowBlurOuter1"></feColorMatrix>
                  </filter>
                </defs>
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(4, 3)">
                    <g id="Oval-2">
                      <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                      <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
                    </g>
                    <g id="adam-icon" transform="translate(13, 10)" fill="#00B5B7">
                      <path
                        d="M4.61999261,26.6050296 L6.35571118,22.8831682 C5.29301715,21.241951 4.38979479,19.5188754 3.94877001,17.5889047 C3.40098029,15.1898221 3.65775672,12.8501093 4.6470217,10.6102459 C5.8128768,7.97143496 7.73419518,6.04281361 10.3735866,4.84732024 C11.9412743,4.13713062 13.5693269,3.78540909 15.2365718,3.83758262 L15.2654028,3.77551411 L15.2951348,3.84163056 C15.815445,3.86052098 16.3389085,3.91584291 16.8655254,4.01524247 C21.3397421,4.85901431 24.3521351,7.47128875 25.7972909,11.7576137 C26.6860977,14.3941758 26.5243736,17.0451306 25.4062699,19.6268206 C24.940018,20.7022249 24.3899758,21.7214077 23.7777668,22.6987618 L25.478798,26.4795433 C26.6523113,24.872059 27.7019413,23.1845153 28.5416453,21.3570926 C29.8210226,18.5712063 30.3093483,15.6697286 29.8034537,12.6423148 C29.1295281,8.61190996 27.1500972,5.33982033 23.8376813,2.91734958 C20.5356265,0.502524943 16.8164226,-0.441995774 12.7760229,0.19173265 C8.18242772,0.912267026 4.59972078,3.28166471 2.20674464,7.26439373 C-0.316872135,11.4657118 -0.669601863,15.9031601 1.11567006,20.4840856 C1.98105168,22.7046088 3.21538048,24.7083421 4.61999261,26.6050296"
                        id="Fill-58"></path>
                      <polygon id="Fill-59"
                        points="17.8243827 29.9195327 14.998941 32.5453003 12.2622448 29.982051 10.5909456 33.3737799 14.9998419 37.2948902 19.433515 33.3638849">
                      </polygon>
                      <polygon id="Fill-60"
                        points="15.1793152 20.4515221 21.5014212 33.0069011 27.3969179 35.1914426 15.2324724 9.61382155 2.62474964 35.1860453 8.56304247 33.0042025">
                      </polygon>
                    </g>
                  </g>
                </g>

                ${silencedItems.length &&
                `
                    <circle fill="#6D767B" cx="9" cy="9" opacity=".9" r="9"></circle>
                    <text x="9" y="12.5" fill="white" text-anchor="middle" font-size="0.6em" font-family="Montserrat, Verdana">${silencedItems.length}</text>
                  `
                }

                ${excludedItems.length &&
                `
                    <circle fill="#2f83c3" cx="32" cy="9" opacity=".9" r="9"></circle>
                    <text x="32" y="12.5" fill="white" text-anchor="middle" font-size="0.6em" font-family="Montserrat, Verdana">${excludedItems.length}</text>
                  `
                }

                ${downItems.length &&
                `
                    <circle fill="#EA5455" cx="55" cy="9" opacity=".9" r="9"></circle>
                    <text x="55" y="12.5" fill="white" text-anchor="middle" font-size="0.6em" font-family="Montserrat, Verdana">${downItems.length}</text>
                  `
                }
              </svg>
              `);
            }
            marker = new google.maps.Marker({
              position: item.coordinates as ICoordinates,
              icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(50, 50),
                anchor: new google.maps.Point(25, 25),
              },
              title: item.name,
            });

            marker.data = item;

            // const infoWindow = new google.maps.InfoWindow({
            //   content: this.generateItemInfoWindowContent(item),
            //   pixelOffset: new google.maps.Size(180, 180), // @see https://stackoverflow.com/questions/38167162/google-map-infowindow-position-on-right-side
            // });
            marker.addListener('click', (e: any) => {
              // console.log('e.domEvent', e.domEvent);

              // Update breadcrumb
              // console.log('markerData', marker.data);
              if (marker.data) {
                const site = this.sites.find(
                  (el) => el._id === marker.data._id,
                );
                const plant = this.plants.find(
                  (el) => el._id === marker.data.plantId,
                );
                const customer = this.customers.find(
                  (el) => el._id === plant?.customerId,
                );

                // Close docs area
                this.sidenavFilesOpen = false;

                // Load site docs
                this.breadcrumbPlantDocs.label = '';
                this.breadcrumbSiteDocs.label = '';
                this.subscriptions.push(
                  this.apiService
                    .apiCall('GET', `utils/map-data-plant-docs/${plant?._id}`)
                    .pipe(take(1))
                    .subscribe((res: Response) => {
                      if (!res.status) {
                        return;
                      }

                      if (
                        res.data.plantFiles &&
                        Array.isArray(res.data.plantFiles) &&
                        res.data.plantFiles?.length > 0
                      ) {
                        this.breadcrumbPlantDocs = {
                          label: res.data.plantFiles?.length,
                          onClick: () => {
                            if (this.map) {
                              this.sidenavFilesOpen = !this.sidenavFilesOpen;
                            }
                          },
                        };

                        this.imagePlantFiles = res.data.plantFiles.filter(
                          (el: IPlantFile) => el.fileType === 'Image');
                        this.documentPlantFiles = res.data.plantFiles.filter(
                          (el: IPlantFile) => el.fileType === 'Document');
                      }
                    }),
                );
                this.subscriptions.push(
                  this.apiService
                    .apiCall('GET', `utils/map-data-site-docs/${site?._id}`)
                    .pipe(take(1))
                    .subscribe((res: Response) => {
                      if (!res.status) {
                        return;
                      }

                      if (
                        res.data.siteFiles &&
                        Array.isArray(res.data.siteFiles) &&
                        res.data.siteFiles?.length > 0
                      ) {
                        this.breadcrumbSiteDocs = {
                          label: res.data.siteFiles?.length,
                          onClick: () => {
                            if (this.map) {
                              this.sidenavFilesOpen = !this.sidenavFilesOpen;
                            }
                          },
                        };

                        this.imageSiteFiles = res.data.siteFiles.filter(
                          (el: IPlantFile) => el.fileType === 'Image');
                        this.documentSiteFiles = res.data.siteFiles.filter(
                          (el: IPlantFile) => el.fileType === 'Document');
                      }
                    }),
                );

                const breadCrumbAggregationItem = this.breadcrumbItems[0];

                this.breadcrumbItems = [
                  breadCrumbAggregationItem,
                  {
                    label: plant?.name as string,
                    onClick: () => {
                      if (this.map && plant) {
                        // Remove site from breadcrumb
                        this.breadcrumbItems.pop();

                        // Close docs area
                        this.sidenavFilesOpen = false;

                        // Load plant docs
                        this.breadcrumbPlantDocs.label = '';
                        this.breadcrumbSiteDocs.label = '';
                        this.subscriptions.push(
                          this.apiService
                            .apiCall('GET', `utils/map-data-plant-docs/${plant?._id}`)
                            .pipe(take(1))
                            .subscribe((res: Response) => {
                              if (!res.status) {
                                return;
                              }

                              if (
                                res.data.plantFiles &&
                                Array.isArray(res.data.plantFiles) &&
                                res.data.plantFiles?.length > 0
                              ) {
                                this.breadcrumbPlantDocs = {
                                  label: res.data.plantFiles?.length,
                                  onClick: () => {
                                    if (this.map) {
                                      this.sidenavFilesOpen = !this.sidenavFilesOpen;
                                    }
                                  },
                                };

                                this.imagePlantFiles = res.data.plantFiles.filter(
                                  (el: IPlantFile) => el.fileType === 'Image');
                                this.documentPlantFiles = res.data.plantFiles.filter(
                                  (el: IPlantFile) => el.fileType === 'Document');
                              }
                            }),
                        );

                        // Center plant on map
                        const { coordinates } = plant;
                        this.onCenterMap(coordinates?.lat, coordinates?.lng);
                        this.runFilters();
                      }
                    },
                  },
                  {
                    label: site?.name as string,
                    onClick: () => {
                      if (site) {
                        const { coordinates } = site;
                        this.onCenterMap(coordinates?.lat, coordinates?.lng);
                        this.runFilters();
                      }
                    },
                  },
                ];
              }
              const lat = e?.latLng.lat();
              const lng = e?.latLng.lng();
              this.onCenterMap(lat, lng);
              this.map.setZoom(20);
              this.runFilters();
              google.maps.event.trigger(this.map, 'zoom_changed');
            });
            return marker;
          }
          return undefined;
        })
        .filter((mrk) => mrk !== undefined);

      // Add markers to map
      this.markers.map((el) => el.setMap(this.map));
    } else {
      // console.log('MOSTRO MARKER APPARATI', this.items.length);
      // Remove all previous markers
      this.markers.map((el) => el.setMap(null));

      // Create marker-clustered markers
      this.markers = this.items
        .map((item: IItem) => {
          if (item.coordinates?.lat && item.coordinates.lng) {
            const marker: any = new google.maps.Marker({
              position: item.coordinates as ICoordinates,
              icon: {
                url: this.helperService.getImageForItem(item),
                scaledSize: {
                  equals: () => true,
                  height: 50,
                  width: 50,
                },
              },

              title: item.type,
            });
            marker.data = item;

            return marker;
          }
          return undefined;
        })
        .filter((mrk) => mrk !== undefined);

      // @see https://github.com/jawj/OverlappingMarkerSpiderfier
      const options = {
        legWeight: 2,
        circleFootSeparation: 50,
        spiralFootSeparation: 5,
        maxNudgeCount: 20,
      }; // Just an example of options - please set your own if necessary
      this.oms = new (window as any).OverlappingMarkerSpiderfier(
        this.map,
        options,
      );
      const infoWindow = new google.maps.InfoWindow({
        pixelOffset: new google.maps.Size(180, 180), // @see https://stackoverflow.com/questions/38167162/google-map-infowindow-position-on-right-side
      });

      let domReadyListener!: google.maps.MapsEventListener;
      let closeClickListener!: google.maps.MapsEventListener;
      let spiderifyListener!: google.maps.MapsEventListener;

      // Handle click on spiral cluster
      this.oms.addListener('click', (marker: any, event: any) => {
        // Remove previous listeners
        domReadyListener?.remove();

        domReadyListener = infoWindow.addListener('domready', () => {
          // Listen for "Silence this item" button
          this.document
            .getElementById('infoWindowSilenceItemButton')
            ?.addEventListener('click', () => {
              const dialogRef = this.dialog.open(
                SilenceItemReasonDialogComponent,
                {
                  width: '500px',
                  disableClose: false,
                },
              );
              dialogRef
                .beforeClosed()
                .pipe(take(1))
                .subscribe((res: any) => {
                  if (!res.reason) {
                    return;
                  }
                  const { reason } = res;

                  this.itemsService
                    .updateFields(marker.data._id, {
                      silenced: true,
                      silencedReason: reason,
                    })
                    .subscribe((res2: Response) => {
                      if (!res2.status) {
                        const title = 'Errore';
                        let message =
                          res2.message ||
                          "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                        this.toastrService.error(message, title);
                        return;
                      }

                      // Update marker data
                      marker.data = res2.data;
                      const newInfoContentData =
                        this.generateItemInfoWindowContent(res2.data);
                      infoWindow.setContent(newInfoContentData);

                      // Update marker icon in the map
                      const markerOnMap = this.markers.find(
                        (el: any) => el.data?._id === res2?.data?._id,
                      );
                      const newMarkerIcon: google.maps.Icon = {
                        url: this.helperService.getImageForItem(res2?.data),
                        scaledSize: {
                          equals: () => true,
                          height: 50,
                          width: 50,
                        },
                      };
                      if (markerOnMap) {
                        markerOnMap.setIcon(newMarkerIcon);
                      }

                      this.toastrService.success(res2.message);
                    });
                });
            });

          // Listen for "Guasto noto" link (used to view/edit already insered reason)
          this.document
            .getElementById('infoWindowSilenceItemInfoButton')
            ?.addEventListener('click', () => {
              const dialogRef = this.dialog.open(
                SilenceItemReasonDialogComponent,
                {
                  width: '400px',
                  disableClose: false,
                  data: marker.data,
                },
              );
              dialogRef
                .beforeClosed()
                .pipe(take(1))
                .subscribe((res: any) => {
                  if (!res.reason) {
                    return;
                  }

                  const { reason } = res;

                  this.itemsService
                    .updateFields(marker.data._id, {
                      silenced: true,
                      silencedReason: reason,
                    })
                    .subscribe((res2: Response) => {
                      if (!res2.status) {
                        const title = 'Errore';
                        let message =
                          res2.message ||
                          "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                        this.toastrService.error(message, title);
                        return;
                      }

                      this.toastrService.success(res2.message);
                    });
                });
            });

          // Listen for "Unsilence this item" button
          this.document
            .getElementById('infoWindowUnSilenceItemButton')
            ?.addEventListener('click', () => {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                  title: 'Ripristina apparato',
                  message:
                    "Sei sicuro di voler ripristinare questo apparato segnalato come 'Guasto noto'?",
                  btnOkText: 'Si, sono sicuro',
                  btnCancelText: 'Annulla',
                },
                width: '500px',
                disableClose: true,
              });
              dialogRef
                .beforeClosed()
                .pipe(take(1))
                .subscribe((res: any) => {
                  if (!res) {
                    return;
                  }

                  this.itemsService
                    .updateFields(marker.data._id, {
                      silenced: false,
                      silencedReason: '',
                    })
                    .subscribe((res2: Response) => {
                      if (!res2.status) {
                        const title = 'Errore';
                        let message =
                          res2.message ||
                          "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                        this.toastrService.error(message, title);
                        return;
                      }

                      // Update marker data
                      marker.data = res2.data;
                      const newInfoContentData =
                        this.generateItemInfoWindowContent(res2.data);
                      infoWindow.setContent(newInfoContentData);

                      // Update marker icon in the map
                      const markerOnMap = this.markers.find(
                        (el: any) => el.data?._id === res2?.data?._id,
                      );
                      const newMarkerIcon: google.maps.Icon = {
                        url: this.helperService.getImageForItem(res2?.data),
                        scaledSize: {
                          equals: () => true,
                          height: 50,
                          width: 50,
                        },
                      };
                      if (markerOnMap) {
                        markerOnMap.setIcon(newMarkerIcon);
                      }

                      this.toastrService.success(res2.message);
                    });
                });
            });
        });

        infoWindow.setContent(this.generateItemInfoWindowContent(marker.data));

        infoWindow.open(this.map, marker);
      });

      if (!closeClickListener) {
        closeClickListener = infoWindow.addListener('closeclick', () => {
          // console.log('close');
          domReadyListener?.remove();
        });
      }

      if (!spiderifyListener) {
        spiderifyListener = this.oms.addListener('spiderfy', (markers: any) => {
          infoWindow.close();
        });
      }

      this.markers.map((el) => this.oms.addMarker(el));
    }

    this.runFilters();

    if (fitToBounds) {
      this.ignoreMapListener = true;

      // Get new markers bounds
      const coordinatesList = this.markers.map((el) =>
        el.getPosition()?.toJSON(),
      );
      if (coordinatesList.length) {
        const [sw, ne] = this.getLatLngBounds(
          coordinatesList as ICoordinates[],
        );

        // Fit map to bounds
        this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));

        // console.log('ripristino listeners');
        this.ignoreMapListener = false;
        // setTimeout(() => {
        // }, 500);
      } else {
        // console.log('ripristino listenerss');
        this.ignoreMapListener = false;
        // setTimeout(() => {
        // }, 500);
      }
    }
  }

  /**
   * Generate infoWindow content for given item
   *
   * @since 1.0.0
   */
  private generateItemInfoWindowContent(item: IItem): string {
    const imageUrl = this.helperService.getImageForItem(item);
    const itemName = this.helperService.getNameForItemType(item.type);
    const itemStatus = this.helperService.getItemStatus(item);
    const site = this.sites.find((el: ISite) => el._id === item.siteId);
    const plant = this.plants.find((el: IPlant) => el._id === site?.plantId);

    let img = `<img src="/assets/img/items/placeholders/placeholder.png" alt="Foto inquadratura" width="300" height="150">`;
    switch (item.type) {
      case 'PC':
      case 'Monitor':
      case 'Software':
      case 'Scheda':
      case 'Registratore':
      case 'Trasduttore':
      case 'Telecamera':
      case 'Terminale':
      case 'Speaker':
      case 'Sensore':
      case 'Centrale':
      case 'Networking':
      case 'DeviceRadio':
      case 'Rack':
      case 'Alimentazione':
        img = `<img src="/assets/img/items/placeholders/${item.type.toLowerCase()}.png" alt="Foto inquadratura" width="300" height="150">`;
    }

    if (item.photo && item.photo.path) {
      const url = `${this.apiHost}${item.photo.path}`;
      img = `<img src="${url}" alt="Foto inquadratura" style="max-width: 300px; max-height: 150px;">`;
    }

    const content = `
      <div class="">
        <div style="height: 25px; margin-top: 10px">
        </div>
        <div style="margin-top: -35px; height: 150px; max-height: 150px; text-align: center; background-color: #ededed;">
          ${img}
        </div>
        <div class="px-3">
          <div class="row mb-2 mt-2">
            <div class="col-12" style="display: flex; align-items: center;">
              <image src="${imageUrl}" width="40px" />

              <h4 style="margin-bottom: 0; margin-left: 10px;">
              ${['admin', 'owner'].includes(
      this.authService?.loggedUser$?.value?.role as string,
    ) ||
        this.authService?.loggedUser$?.value?.sections?.length === 0 ||
        this.authService?.loggedUser$?.value?.sections?.includes(
          'Impianti',
        )
        ? `
                <a target="_blank" href="/plants/${plant?._id}/sites/${site?._id}/items/${item._id}" style="text-decoration: underline; color: black">
                ${itemName}
                </a>`
        : `${itemName}`
      }
              </h4>
            </div> <!-- /.col -->
          </div> <!-- /.row -->

          <div class="row mb-2">
            <div class="col-12">
              <label style="color: var(--color-main)">Impianto</label> <br>
              ${plant?.name || 'NA'}
            </div> <!-- /.col -->
          </div> <!-- /.row -->

          <div class="row mb-2">
            <div class="col-12">
              <label style="color: var(--color-main)">Sito</label> <br>
              ${site?.name || 'NA'}
            </div> <!-- /.col -->
          </div> <!-- /.row -->

          <div class="row mb-2">
            <div class="col-12">
              <label style="color: var(--color-main)">Indirizzo IP</label> <br>
              ${item.ip || 'NA'}
            </div> <!-- /.col -->
          </div> <!-- /.row -->
          <div class="row">
            <div class="col-12 mb-2">
              <label style="color: var(--color-main)">Descrizione</label> <br>
              ${item.description || ''}
            </div> <!-- /.col -->
          </div> <!-- /.row -->

          ${itemStatus === 'silenced'
        ? `
            <div class="row mt-3" style="border-top: 1px solid rgba(0, 0, 0, 0.1); padding-top: 7px;">
              <div class="col-12 text-center">
                <strong id="infoWindowSilenceItemInfoButton" style="cursor: pointer"><u><i class="fal fa-info-circle"></i> Guasto noto</u></strong>
              </div> <!-- /.col -->
            </div> <!-- /.row -->
            `
        : ''
      }

          <div class="row mt-2 mb-2 align-items-center" style="border-top: 1px solid rgba(0, 0, 0, 0.1); padding-top: 10px">
            <div class="col-12 d-flex justify-content-around">
            ${['admin', 'owner'].includes(
        this.authService?.loggedUser$?.value?.role as string,
      ) ||
        this.authService?.loggedUser$?.value?.sections?.length === 0 ||
        this.authService?.loggedUser$?.value?.sections?.includes(
          'Ticketing',
        )
        ? `
                <a target="_blank" href="/tickets/add?plantId=${plant?._id}&itemId=${item._id}" style="padding: 10px 15px; background: #00b5b7; color: white; border-radius: 0; text-decoration: none; display: inline-block; text-align: center">
                  Apri ticket
                </a>
                `
        : ``
      }

            ${(['admin', 'owner'].includes(
        this.authService?.loggedUser$?.value?.role as string,
      ) &&
        itemStatus === 'off') ||
        itemStatus === 'warning'
        ? `
                <button id="infoWindowSilenceItemButton" style="padding: 10px 15px; background: #6D767B; color: white; border: none; text-decoration: none; display: inline-block; text-align: center">
                  <i class="fal fa-circle-xmark"></i> Guasto noto
                </button>
              `
        : ``
      }

            ${['admin', 'owner'].includes(
        this.authService?.loggedUser$?.value?.role as string,
      ) && itemStatus === 'silenced'
        ? `
                <button id="infoWindowUnSilenceItemButton" style="padding: 10px 15px; background: white; border: 1px solid #6D767B; color: #6D767B; text-decoration: none; display: inline-block; text-align: center">
                  Ripristina
                </button>
              `
        : ``
      }
            </div> <!-- /.col -->
          </div> <!-- /.row -->
        </div>
      </div>
      `;
    return content;
  }

  /**
   * Get bounds of given markers
   * This is used to center all markers on the map
   *
   * @since 1.1.0
   */
  private getLatLngBounds = (items: ICoordinates[]) => {
    return items.reduce(
      ([sw, ne], item) => {
        const { lat, lng } = item;
        const newSw = {
          lat: Math.min(sw.lat, lat),
          lng: Math.min(sw.lng, lng),
        };
        const newNe = {
          lat: Math.max(ne.lat, lat),
          lng: Math.max(ne.lng, lng),
        };
        return [newSw, newNe];
      },
      [
        { lat: Infinity, lng: Infinity },
        { lat: -Infinity, lng: -Infinity },
      ],
    );
  };

  /**
   * Helper function used to insert given $value to this.filters inside
   * given $path and than trigger a item filter
   *
   * @since 1.1.0
   */
  public onApplyFilter = (path: string, value: any) => {
    if (path === 'status.linked') {
      this.filters.status.linked = !value;
      this.drawItemsLines();
      return;
    }
    const way = path.replace(/\[/g, '.').replace(/\]/g, '').split('.');
    const last = way.pop();
    if (!last) {
      return;
    }

    way.reduce(
      (o: any, k: any, i: any, kk: any) =>
        (o[k] = o[k] || (isFinite(i + 1 in kk ? kk[i + 1] : last) ? [] : {})),
      this.filters,
    )[last] = value;

    // console.log('recalculate!', JSON.stringify(this.filters));
    // console.log('markers', this.markers);

    // Apply filters to items markers
    if (this.showItemsMarkers) {
      this.markers.map((marker: google.maps.Marker & { data?: IItem }) => {
        const isWarning =
          marker.data?.deadFrom &&
          moment(marker.data.deadFrom).isValid() &&
          moment().diff(marker.data.deadFrom, 'minutes') <=
          this.configService.maxItemsDowntime;
        const isDead =
          marker.data?.deadFrom &&
          moment(marker.data.deadFrom).isValid() &&
          moment().diff(marker.data.deadFrom, 'minutes') >
          this.configService.maxItemsDowntime;

        marker.setVisible(false);

        // Reset markers visibility if not filters
        if (
          !this.filters.status.regular &&
          !this.filters.status.silenced &&
          !this.filters.status.dead &&
          !this.filters.status.excluded
        ) {
          marker.setVisible(true);
          return marker;
        }

        // 1. Handle regular only
        if (
          this.filters.status.regular &&
          !this.filters.status.dead &&
          !this.filters.status.silenced &&
          !this.filters.status.excluded &&
          !marker?.data?.silenced &&
          !isDead &&
          !marker?.data?.pingExcluded
        ) {
          marker.setVisible(true);
        }

        // 2. Handle dead only
        if (
          !this.filters.status.regular &&
          this.filters.status.dead &&
          !this.filters.status.silenced &&
          !this.filters.status.excluded &&
          isDead
        ) {
          marker.setVisible(true);
        }

        // 3. Handle silenced only
        if (
          !this.filters.status.regular &&
          !this.filters.status.dead &&
          this.filters.status.silenced &&
          !this.filters.status.excluded &&
          marker?.data?.silenced
        ) {
          marker.setVisible(true);
        }

        // 4. Handle regular AND dead
        if (
          this.filters.status.regular &&
          this.filters.status.dead &&
          !this.filters.status.silenced &&
          !this.filters.status.excluded &&
          (isDead ||
            (!marker?.data?.silenced && !marker?.data?.pingExcluded && !isDead))
        ) {
          marker.setVisible(true);
        }

        // 5. Handle regular AND silenced
        if (
          this.filters.status.regular &&
          !this.filters.status.dead &&
          this.filters.status.silenced &&
          !this.filters.status.excluded &&
          (marker?.data?.silenced ||
            (!marker?.data?.silenced && !marker?.data?.pingExcluded && !isDead))
        ) {
          marker.setVisible(true);
        }

        // 6. Handle regular, dead AND silenced
        if (
          this.filters.status.regular &&
          this.filters.status.dead &&
          this.filters.status.silenced &&
          !this.filters.status.excluded
        ) {
          marker.setVisible(true);
        }

        // 7. Handle dead and silenced
        if (
          !this.filters.status.regular &&
          this.filters.status.dead &&
          this.filters.status.silenced &&
          !this.filters.status.excluded &&
          (marker?.data?.silenced || isDead)
        ) {
          marker.setVisible(true);
        }

        // 8. Handle excluded only
        if (
          !this.filters.status.regular &&
          !this.filters.status.dead &&
          !this.filters.status.silenced &&
          this.filters.status.excluded &&
          marker?.data?.pingExcluded
        ) {
          marker.setVisible(true);
        }

        // 9. Handle regular and excluded
        if (
          this.filters.status.regular &&
          !this.filters.status.dead &&
          !this.filters.status.silenced &&
          this.filters.status.excluded
        ) {
          marker.setVisible(true);
        }

        // 10. Handle regular, dead and excluded
        if (
          this.filters.status.regular &&
          this.filters.status.dead &&
          !this.filters.status.silenced &&
          this.filters.status.excluded
        ) {
          marker.setVisible(true);
        }

        // 11. Handle regular, silenced and excluded
        if (
          this.filters.status.regular &&
          !this.filters.status.dead &&
          this.filters.status.silenced &&
          this.filters.status.excluded
        ) {
          marker.setVisible(true);
        }

        // 12. Handle regular, dead, silenced and excluded
        if (
          this.filters.status.regular &&
          this.filters.status.dead &&
          this.filters.status.silenced &&
          this.filters.status.excluded
        ) {
          marker.setVisible(true);
        }

        // 13. Handle dead and excluded
        if (
          !this.filters.status.regular &&
          this.filters.status.dead &&
          !this.filters.status.silenced &&
          this.filters.status.excluded &&
          (marker?.data?.pingExcluded || isDead)
        ) {
          marker.setVisible(true);
        }

        // 14. Handle silenced and excluded
        if (
          !this.filters.status.regular &&
          !this.filters.status.dead &&
          this.filters.status.silenced &&
          this.filters.status.excluded &&
          (marker?.data?.pingExcluded || marker?.data?.silenced)
        ) {
          marker.setVisible(true);
        }

        // 15. Handle dead, silenced and excluded
        if (
          !this.filters.status.regular &&
          this.filters.status.dead &&
          this.filters.status.silenced &&
          this.filters.status.excluded &&
          (marker?.data?.pingExcluded || marker?.data?.silenced || isDead)
        ) {
          marker.setVisible(true);
        }

        return marker;
      });
    }

    // Apply filters to sites markers
    if (!this.showItemsMarkers) {
      this.markers.map((marker: google.maps.Marker & { data?: IItem }) => {
        if (!marker.data) {
          return marker;
        }

        const markerItems = this.items.filter(
          (el) => el.siteId === (marker.data as any)._id,
        );

        const hasDeadItems = markerItems.some(
          (el) =>
            !el.silenced &&
            el?.deadFrom &&
            moment(el.deadFrom).isValid() &&
            moment().diff(el.deadFrom, 'minutes') >
            this.configService.maxItemsDowntime,
        );
        const hasRegularItems = markerItems.some(
          (el) => !el?.deadFrom && !el.silenced && !el.pingExcluded,
        );
        const hasSilencedItems = markerItems.some((el) => el.silenced);
        const hasExcludedItems = markerItems.some((el) => el.pingExcluded);

        marker.setVisible(false);

        // Reset markers visibility if not filters
        if (
          !this.filters.status.regular &&
          !this.filters.status.silenced &&
          !this.filters.status.dead &&
          !this.filters.status.excluded
        ) {
          marker.setVisible(true);
          return marker;
        }

        // 1. Handle regular only
        if (
          this.filters.status.regular &&
          !this.filters.status.dead &&
          !this.filters.status.silenced &&
          hasRegularItems
        ) {
          marker.setVisible(true);
        }

        // 2. Handle dead only
        if (
          !this.filters.status.regular &&
          this.filters.status.dead &&
          !this.filters.status.silenced &&
          hasDeadItems
        ) {
          marker.setVisible(true);
        }

        // 3. Handle silenced only
        if (
          !this.filters.status.regular &&
          !this.filters.status.dead &&
          this.filters.status.silenced &&
          hasSilencedItems
        ) {
          marker.setVisible(true);
        }

        // 4. Handle regular AND dead
        if (
          this.filters.status.regular &&
          this.filters.status.dead &&
          !this.filters.status.silenced &&
          (hasRegularItems || hasDeadItems)
        ) {
          marker.setVisible(true);
        }

        // 5. Handle regular AND silenced
        if (
          this.filters.status.regular &&
          !this.filters.status.dead &&
          this.filters.status.silenced &&
          (hasRegularItems || hasSilencedItems)
        ) {
          marker.setVisible(true);
        }

        // 6. Handle regular, dead AND silenced
        if (
          this.filters.status.regular &&
          this.filters.status.dead &&
          this.filters.status.silenced
        ) {
          marker.setVisible(true);
        }

        // 7. Handle dead and silenced
        if (
          !this.filters.status.regular &&
          this.filters.status.dead &&
          this.filters.status.silenced &&
          (hasDeadItems || hasSilencedItems)
        ) {
          marker.setVisible(true);
        }

        // 8. Handle excluded only
        if (
          !this.filters.status.regular &&
          !this.filters.status.dead &&
          !this.filters.status.silenced &&
          this.filters.status.excluded &&
          hasExcludedItems
        ) {
          marker.setVisible(true);
        }

        // 9. Handle regular and excluded
        if (
          this.filters.status.regular &&
          !this.filters.status.dead &&
          !this.filters.status.silenced &&
          this.filters.status.excluded
        ) {
          marker.setVisible(true);
        }

        // 10. Handle regular, dead and excluded
        if (
          this.filters.status.regular &&
          this.filters.status.dead &&
          !this.filters.status.silenced &&
          this.filters.status.excluded
        ) {
          marker.setVisible(true);
        }

        // 11. Handle regular, silenced and excluded
        if (
          this.filters.status.regular &&
          !this.filters.status.dead &&
          this.filters.status.silenced &&
          this.filters.status.excluded
        ) {
          marker.setVisible(true);
        }

        // 12. Handle regular, dead, silenced and excluded
        if (
          this.filters.status.regular &&
          this.filters.status.dead &&
          this.filters.status.silenced &&
          this.filters.status.excluded
        ) {
          marker.setVisible(true);
        }

        // 13. Handle dead and excluded
        if (
          !this.filters.status.regular &&
          this.filters.status.dead &&
          !this.filters.status.silenced &&
          this.filters.status.excluded &&
          (hasExcludedItems || hasDeadItems)
        ) {
          marker.setVisible(true);
        }

        // 14. Handle silenced and excluded
        if (
          !this.filters.status.regular &&
          !this.filters.status.dead &&
          this.filters.status.silenced &&
          this.filters.status.excluded &&
          (hasExcludedItems || hasSilencedItems)
        ) {
          marker.setVisible(true);
        }

        // 15. Handle dead, silenced and excluded
        if (
          !this.filters.status.regular &&
          this.filters.status.dead &&
          this.filters.status.silenced &&
          this.filters.status.excluded &&
          (hasExcludedItems || hasSilencedItems || hasDeadItems)
        ) {
          marker.setVisible(true);
        }

        return marker;
      });
    }
  };

  /**
   * Helper function used from HTML to retrive sitename from siteId
   *
   * @since 1.1.0
   */
  getSiteNameFromId(siteId: string): string {
    const foundSite = this.sites.find((el) => el._id === siteId);
    if (foundSite) {
      return foundSite.name;
    }
    return '';
  }

  /**
   * Helper function used from HTML to retrive plant name from plantId
   *
   * @since 1.1.0
   */
  getPlantNameFromItemId(siteId: string, returnId: boolean = false): string {
    const foundSite = this.sites.find((el) => el._id === siteId);
    if (!foundSite) {
      return '';
    }

    const foundPlant = this.plants.find((el) => el._id === foundSite.plantId);
    if (foundPlant) {
      if (returnId) {
        return foundPlant._id;
      }
      return foundPlant.name;
    }
    return '';
  }

  /**
   * Simple helper getter function used to retrive all-and-only
   * the down items sorted as per sorting settings
   *
   * @since 1.1.0
   */
  getDownItems(): IItem[] {
    // console.log('getDownItems()');

    const deadItems = this.deadItems.sort((a: IItem, b: IItem) => {
      const elapsedA = moment(a.deadFrom).isValid()
        ? moment().diff(moment(a.deadFrom), 'seconds')
        : -Infinity;
      const elapsedB = moment(b.deadFrom).isValid()
        ? moment().diff(moment(b.deadFrom), 'seconds')
        : -Infinity;

      // Sort ASC
      if (this.sidenavAccordion1Sorting) {
        return elapsedA - elapsedB || (a._id > b._id ? 1 : -1);
      } else {
        // Sort DESC
        return elapsedB - elapsedA || (a._id > b._id ? 1 : -1);
      }
    });

    if (
      ['admin', 'owner'].includes(
        this.authService.loggedUser$.value?.role as string,
      )
    ) {
      return deadItems;
    }

    // Remove dead items that are dead within the last X amount of time
    const filteredDeadItems = deadItems
      .filter((el) => {
        if (
          !el.silenced &&
          el?.deadFrom &&
          moment(el.deadFrom).isValid() &&
          moment().diff(el.deadFrom, 'minutes') >
          this.configService.maxItemsDowntime
        ) {
          return el;
        }
        return undefined;
      })
      .filter((el) => el !== undefined);
    return filteredDeadItems;
  }

  /**
   * Reset general search input and dispath a 'keyup' event
   * so that the table re-render his rows
   *
   * @since 1.0.0
   */
  resetInput(): void {
    this.sidenavAccordion3Input.nativeElement.value = '';
    const event = new KeyboardEvent('keyup');
    this.sidenavAccordion3Input.nativeElement.dispatchEvent(event);
  }

  /**
   * Simple helper getter function used to retrive all-and-only
   * the silenced items sorted as per sorting settings
   *
   * @since 1.1.0
   */
  get silencedItems(): IItem[] {
    return this.items
      .filter((el) => el.silenced)
      .sort((a, b) => {
        const elapsedA: number = moment(a.deadFrom).isValid()
          ? moment().diff(moment(a.deadFrom), 'seconds')
          : -Infinity;
        const elapsedB: number = moment(b.deadFrom).isValid()
          ? moment().diff(moment(b.deadFrom), 'seconds')
          : -Infinity;

        // Sort ASC
        if (this.sidenavAccordion2Sorting) {
          return elapsedA - elapsedB;
        } else {
          // Sort DESC
          return elapsedB - elapsedA;
        }
      });
  }

  /**
   * Simple helper getter function used to retrive all-and-only
   * the excluded items sorted as per sorting settings
   *
   * @since 1.1.0
   */
  get excludedItems(): IItem[] {
    return this.items
      .filter((el) => el.pingExcluded)
      .sort((a, b) => {
        const elapsedA: number = moment(a.deadFrom).isValid()
          ? moment().diff(moment(a.deadFrom), 'seconds')
          : -Infinity;
        const elapsedB: number = moment(b.deadFrom).isValid()
          ? moment().diff(moment(b.deadFrom), 'seconds')
          : -Infinity;

        // Sort ASC
        if (this.sidenavAccordion2Sorting) {
          return elapsedA - elapsedB;
        } else {
          // Sort DESC
          return elapsedB - elapsedA;
        }
      });
  }
  /**
   * Simple helper function used to retrive accordion3 sites
   * depending on active search filter
   *
   * @since 1.1.0
   */
  get filteredSites(): ISite[] {
    const search =
      this.sidenavAccordion3Input?.nativeElement?.value?.toLocaleLowerCase();
    if (!search) {
      return this.sites;
    } else {
      return this.sites.filter(
        (el) =>
          el.code?.toLocaleLowerCase().includes(search) ||
          el?.description?.toLocaleLowerCase()?.includes(search) ||
          el.name?.toLocaleLowerCase().includes(search),
      );
    }
  }

  /**
   * Helper function used from sidenav to focus clicked item on map
   *
   * @since 1.1.0
   */
  onFocusItem(item: IItem): void {
    console.log('### onFocusItem ###')
    // Update breadcrumb
    const site = this.sites.find((el) => el._id === item.siteId);
    const plant = this.plants.find((el) => el._id === site?.plantId);

    // Close docs area
    this.sidenavFilesOpen = false;

    // Load site docs
    this.breadcrumbPlantDocs.label = '';
    this.breadcrumbSiteDocs.label = '';
    this.subscriptions.push(
      this.apiService
        .apiCall('GET', `utils/map-data-plant-docs/${plant?._id}`)
        .pipe(take(1))
        .subscribe((res: Response) => {
          if (!res.status) {
            return;
          }

          if (
            res.data.plantFiles &&
            Array.isArray(res.data.plantFiles) &&
            res.data.plantFiles?.length > 0
          ) {
            this.breadcrumbPlantDocs = {
              label: res.data.plantFiles?.length,
              onClick: () => {
                if (this.map) {
                  this.sidenavFilesOpen = !this.sidenavFilesOpen;
                }
              },
            };

            this.imagePlantFiles = res.data.plantFiles.filter(
              (el: IPlantFile) => el.fileType === 'Image');
            this.documentPlantFiles = res.data.plantFiles.filter(
              (el: IPlantFile) => el.fileType === 'Document');
          }
        }),
    );
    this.subscriptions.push(
      this.apiService
        .apiCall('GET', `utils/map-data-site-docs/${site?._id}`)
        .pipe(take(1))
        .subscribe((res: Response) => {
          if (!res.status) {
            return;
          }

          if (
            res.data.siteFiles &&
            Array.isArray(res.data.siteFiles) &&
            res.data.siteFiles?.length > 0
          ) {
            this.breadcrumbSiteDocs = {
              label: res.data.siteFiles?.length,
              onClick: () => {
                if (this.map) {
                  this.sidenavFilesOpen = !this.sidenavFilesOpen;
                }
              },
            };

            this.imageSiteFiles = res.data.siteFiles.filter(
              (el: IPlantFile) => el.fileType === 'Image');
            this.documentSiteFiles = res.data.siteFiles.filter(
              (el: IPlantFile) => el.fileType === 'Document');
          }
        }),
    );

    const breadcrumbAggregationItem = this.breadcrumbItems[0];
    this.breadcrumbItems = [
      breadcrumbAggregationItem,
      {
        label: plant?.name as string,
        onClick: () => {
          if (this.map && plant) {
            // Remove site from breadcrumb
            this.breadcrumbItems.pop();

            // Close docs area
            this.sidenavFilesOpen = false;

            // Load plant docs
            this.breadcrumbPlantDocs.label = '';
            this.breadcrumbSiteDocs.label = '';
            this.subscriptions.push(
              this.apiService
                .apiCall('GET', `utils/map-data-plant-docs/${plant?._id}`)
                .pipe(take(1))
                .subscribe((res: Response) => {
                  if (!res.status) {
                    return;
                  }

                  if (
                    res.data.plantFiles &&
                    Array.isArray(res.data.plantFiles) &&
                    res.data.plantFiles?.length > 0
                  ) {
                    this.breadcrumbPlantDocs = {
                      label: res.data.plantFiles?.length,
                      onClick: () => {
                        if (this.map) {
                          this.sidenavFilesOpen = !this.sidenavFilesOpen;
                        }
                      },
                    };

                    this.imagePlantFiles = res.data.plantFiles.filter(
                      (el: IPlantFile) => el.fileType === 'Image');
                    this.documentPlantFiles = res.data.plantFiles.filter(
                      (el: IPlantFile) => el.fileType === 'Document');
                  }
                }),
            );

            // Center plant on map
            const { coordinates } = plant;
            this.onCenterMap(coordinates?.lat, coordinates?.lng);
            this.runFilters();
          }
        },
      },
      {
        label: site?.name as string,
        onClick: () => {
          if (site) {
            const { coordinates } = site;
            this.onCenterMap(coordinates?.lat, coordinates?.lng);
            this.runFilters();
          }
        },
      },
      {
        label: this.helperService.getNameForItemType(item?.type as string),
        onClick: () => {
          if (item) {
            const { coordinates } = item;
            this.onCenterMap(coordinates?.lat, coordinates?.lng);
            this.runFilters();
          }
        },
      },
    ];

    const { coordinates } = item;
    this.onCenterMap(coordinates?.lat, coordinates?.lng, true);

    // Close sidenav
    this.sidenavOpen = false;
  }

  /**
   * Helper function used from sidenav to focus clicked site on map
   *
   * @since 1.1.0
   */
  onFocusSite(site: ISite): void {
    // Close docs area
    this.sidenavFilesOpen = false;

    // Load site docs
    this.breadcrumbSiteDocs.label = '';
    this.subscriptions.push(
      this.apiService
        .apiCall('GET', `utils/map-data-site-docs/${site._id}`)
        .pipe(take(1))
        .subscribe((res: Response) => {
          if (!res.status) {
            return;
          }

          if (
            res.data.siteFiles &&
            Array.isArray(res.data.siteFiles) &&
            res.data.siteFiles?.length > 0
          ) {
            this.breadcrumbSiteDocs = {
              label: res.data.siteFiles?.length,
              onClick: () => {
                if (this.map) {
                  this.sidenavFilesOpen = !this.sidenavFilesOpen;
                }
              },
            };

            this.imageSiteFiles = res.data.siteFiles.filter(
              (el: IPlantFile) => el.fileType === 'Image');
            this.documentSiteFiles = res.data.siteFiles.filter(
              (el: IPlantFile) => el.fileType === 'Document');
          }
        }),
    );

    // Update breadcrumb
    const plant = this.plants.find((el) => el._id === site?.plantId);
    const breadcrumbAggregationItem = this.breadcrumbItems[0];
    this.breadcrumbItems = [
      breadcrumbAggregationItem,
      {
        label: plant?.name as string,
        onClick: () => {
          if (this.map && plant) {
            // Remove site from breadcrumb
            if (this.breadcrumbItems.length > 2) {
              this.breadcrumbItems.pop();
            }

            // Close docs area
            this.sidenavFilesOpen = false;

            // Load plant docs
            this.breadcrumbPlantDocs.label = '';
            this.breadcrumbSiteDocs.label = '';
            this.subscriptions.push(
              this.apiService
                .apiCall('GET', `utils/map-data-plant-docs/${plant?._id}`)
                .pipe(take(1))
                .subscribe((res: Response) => {
                  if (!res.status) {
                    return;
                  }

                  if (
                    res.data.plantFiles &&
                    Array.isArray(res.data.plantFiles) &&
                    res.data.plantFiles?.length > 0
                  ) {
                    this.breadcrumbPlantDocs = {
                      label: res.data.plantFiles?.length,
                      onClick: () => {
                        if (this.map) {
                          this.sidenavFilesOpen = !this.sidenavFilesOpen;
                        }
                      },
                    };

                    this.imagePlantFiles = res.data.plantFiles.filter(
                      (el: IPlantFile) => el.fileType === 'Image');
                    this.documentPlantFiles = res.data.plantFiles.filter(
                      (el: IPlantFile) => el.fileType === 'Document');
                  }
                }),
            );

            // Center plant on map
            const { coordinates } = plant;
            this.onCenterMap(coordinates?.lat, coordinates?.lng);
            this.runFilters();
          }
        },
      },
      {
        label: site?.name as string,
        onClick: () => {
          if (site) {
            const { coordinates } = site;
            this.onCenterMap(coordinates?.lat, coordinates?.lng);
            this.runFilters();
          }
        },
      },
    ];

    const { coordinates } = site;
    this.onCenterMap(coordinates?.lat, coordinates?.lng, false);

    const getSiteItemsCoordinates = this.items
      .filter((el) => el.siteId === site._id)
      .map((el) => el.coordinates);
    const [sw, ne] = this.getLatLngBounds(
      getSiteItemsCoordinates as ICoordinates[],
    );

    if (sw.lat >= 0 && sw.lng >= 0 && ne.lat >= 0 && ne.lng >= 0) {
      // Fit map to bounds
      this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
    }

    // Close sidenav
    this.sidenavOpen = false;
  }

  /**
   * Handle dialog close
   *
   * @since 1.1.0
   */
  onClose(): void {
    this.dialogRef.close();
  }

  /**
   * Run filters
   * This re-apply all filters
   *
   * @since 1.1.0
   */
  runFilters(): void {
    // Re-apply filters
    if (this.filters.status.regular) {
      this.onApplyFilter('status.regular', true);
    }
    if (this.filters.status.dead) {
      this.onApplyFilter('status.dead', true);
    }
    if (this.filters.status.silenced) {
      this.onApplyFilter('status.silenced', true);
    }
    if (this.filters.status.excluded) {
      this.onApplyFilter('status.excluded', true);
    }
  }

  /**
   * Generate and return appropriate full preview path of given file
   *
   * @since 1.0.0
   */
  getFilePreview(file: IFile): string {
    if (!file) {
      return '';
    }

    const extension = file.originalName.split('.').pop()?.toLocaleLowerCase();

    const imageExtensions = [
      'jpeg',
      'jpg',
      'png',
    ];
    const knownExtensions = [
      'acc',
      'ae',
      'ai',
      'an',
      'avi',
      'bmp',
      'csv',
      'dat',
      'dgn',
      'doc',
      'doch',
      'docm',
      'docx',
      'doth',
      'dw',
      'dwfx',
      'dwg',
      'dxf',
      'dxl',
      'eml',
      'eps',
      'f4a',
      'f4v',
      'flv',
      'fs',
      'gif',
      'html',
      'ind',
      'jpeg',
      'jpg',
      'jpp',
      'log',
      'lr',
      'm4v',
      'mbd',
      'mbox',
      'midi',
      'mkv',
      'mov',
      'mp2',
      'mp3',
      'mp4',
      'mpeg',
      'mpg',
      'mpp',
      'mpt',
      'mpw',
      'mpx',
      'msg',
      'ods',
      'oga',
      'ogg',
      'ogv',
      'one',
      'ost',
      'pdf',
      'php',
      'png',
      'pot',
      'poth',
      'potm',
      'potx',
      'pps',
      'ppsx',
      'ppt',
      'ppth',
      'pptm',
      'pptx',
      'prem',
      'ps',
      'psd',
      'pst',
      'pub',
      'pubh',
      'pubm',
      'pwz',
      'rar',
      'read',
      'rp',
      'rtf',
      'sql',
      'svg',
      'swf',
      'tif',
      'tiff',
      'txt',
      'url',
      'vcf',
      'vds',
      'vdx',
      'vob',
      'vsd',
      'vss',
      'vst',
      'vsx',
      'vtx',
      'wav',
      'wdp',
      'webm',
      'wma',
      'wmv',
      'xd',
      'xls',
      'xlsm',
      'xlsx',
      'xlx',
      'xml',
      'zip',
    ];
    if (extension && imageExtensions.includes(extension)) {
      const previewPath = `${getBEUrl()}${file.path}`;
      return previewPath;
    } else if (extension && knownExtensions.includes(extension)) {
      return `/assets/img/file-types/${extension}.png`;
    }

    let previewPath = `${getBEUrl()}${file.path}`;
    return previewPath;
  }

  /**
   * Show file title 
   *
   * @since 1.0.0
   */
  getTitle(title: string): string {
    let newTitle = '';
    if (title) {
      if (title.length > 16) {
        newTitle = `${title.substring(0, 16)}...`;
      } else {
        newTitle = title;
      }
    }
    return newTitle;
  }

  /**
   * Handle click on a image preview and opens it in a dialog
   *
   * @since 1.0.0
   */
  onOpenFile(file: any): void {
    if (file.createdAt) {
      delete file.createdAt;
    }
    
    this.dialog.open(LightboxDialogExtComponent, {
      data: {
        obj: {
          file
        }
      },
      panelClass: 'lightbox-dialog',
    });
  }
  onOpenImage(obj: IPlantFile): void {
    this.dialog.open(LightboxDialogExtComponent, {
      data: {
        obj
      },
      panelClass: 'lightbox-dialog',
    });
  }

  /**
   * Handle click on a doc preview and opens it in a dialog
   *
   * @since 1.0.0
   */
  onOpenDoc(obj: IPlantFile): void {
    this.dialog.open(LightboxDialogExtComponent, {
      data: {
        obj
      },
      panelClass: 'lightbox-dialog',
    });
  }

  /**
   * Handle click on a doc preview and dawnload it
   *
   * @since 1.0.0
   */
  download(url: string): Observable<any> {
    return this.httpClient.get(url, {
      responseType: 'blob'
    });
  }

  onDownloadDoc(obj: IPlantFile): void {
    const url = `${getBEUrl()}${obj.file.path}`;

    this.subscriptions.push(
      this.download(url).subscribe(
        res => {
          let objectUrl = window.URL.createObjectURL(res);

          let anchor = document.createElement("a");
          document.body.appendChild(anchor);
          anchor.href = objectUrl;
          anchor.download = obj.file.originalName;
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        },
        err => {
          console.log("err", err)
        }
      )
    )
  }

  /**
   * Handle download all images or documents
   *
   * @since 1.0.0
   */
  downloadFileAtTime(file: IFile): void {
    const url = `${getBEUrl()}${file.path}`;

    this.subscriptions.push(
      this.download(url).subscribe(
        res => {
          let objectUrl = window.URL.createObjectURL(res);

          let anchor = document.createElement("a");
          document.body.appendChild(anchor);
          anchor.href = objectUrl;
          anchor.download = file.originalName;
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);

          // loop next file
          this.fileCounter += 1;
          if (
            this.filesToDownload &&
            Array.isArray(this.filesToDownload) &&
            this.filesToDownload.length &&
            this.fileCounter < this.filesToDownload.length
          ) {
            this.downloadFileAtTime(this.filesToDownload[this.fileCounter].file);
          }
        },
        err => {
          console.log("err", err)
        }
      )
    )

  }
  onStaticDownloadAll(action: string): void {
    this.fileCounter = 0;
    if (action === 'images') {
      this.filesToDownload = [];
      for (const image of this.sidenavStaticObject.photos) {
        this.filesToDownload.push({
          _id: image._id,
          plantId: {
            _id: '',
            customerId: '',
            name: '',
            code: '',
            type: '',
            createdAt: image.createdAt,
            updatedAt: image.createdAt,
          },
          title: '',
          file: image,
          fileType: '',

          createdAt: image.createdAt,
          updatedAt: image.createdAt,
        });
      }
    } else {
      this.filesToDownload = [];
      for (const image of this.sidenavStaticObject.files) {
        this.filesToDownload.push({
          _id: image._id,
          plantId: {
            _id: '',
            customerId: '',
            name: '',
            code: '',
            type: '',
            createdAt: image.createdAt,
            updatedAt: image.createdAt,
          },
          title: '',
          file: image,
          fileType: '',

          createdAt: image.createdAt,
          updatedAt: image.createdAt,
        });
      }
    }

    if (
      this.filesToDownload &&
      Array.isArray(this.filesToDownload) &&
      this.filesToDownload.length &&
      this.fileCounter < this.filesToDownload.length
    ) {
      this.downloadFileAtTime(this.filesToDownload[this.fileCounter].file);
    }
  }
  onPlantDownloadAll(action: string): void {
    this.fileCounter = 0;
    if (action === 'images') {
      this.filesToDownload = this.imagePlantFiles;
    } else {
      this.filesToDownload = this.documentPlantFiles;
    }

    if (
      this.filesToDownload &&
      Array.isArray(this.filesToDownload) &&
      this.filesToDownload.length &&
      this.fileCounter < this.filesToDownload.length
    ) {
      this.downloadFileAtTime(this.filesToDownload[this.fileCounter].file);
    }
  }
  onSiteDownloadAll(action: string): void {
    this.fileCounter = 0;
    if (action === 'images') {
      this.filesToDownload = this.imageSiteFiles;
    } else {
      this.filesToDownload = this.documentSiteFiles;
    }

    if (
      this.filesToDownload &&
      Array.isArray(this.filesToDownload) &&
      this.filesToDownload.length &&
      this.fileCounter < this.filesToDownload.length
    ) {
      this.downloadFileAtTime(this.filesToDownload[this.fileCounter].file);
    }
  }

  /**
   * Handle open side bar of docs
   *
   * @since 1.0.0
   */
  onOpenDocsSideBar(type: string): void {
    if (type === 'plant') {
      this.sidenavFilesOpen = true;
      this.isViewPlant = true;
      this.isViewSite = false;
    } else if (type === 'site') {
      this.sidenavFilesOpen = true;
      this.isViewPlant = false;
      this.isViewSite = true;
    }
  }

  /**
   * Draw lines between
   * @since 1.1.0
   */
  drawItemsLines(): void {
    for (const polyLineItem of this.polyLinesList) {
      polyLineItem.setMap(null);
    }
    if (this.filters.status.linked) {
      if (!this.showItemsMarkers) {
        if (this.sites) {
          for (const site of this.sites) {
            if (
              site.ipNextHope !== '' &&
              site.coordinates &&
              site.coordinates.lat &&
              site.coordinates.lng
            ) {
              const item = this.items.find((el: IItem) => el.ip === site.ipNextHope);
              if (
                item &&
                item.coordinates &&
                item.coordinates.lat &&
                item.coordinates.lng
              ) {
                let polyLineColor = site.link !== '' ? site.link : this.configService.map.color.polyLineDefault;
                if (item.deadFrom) {
                  const deadDate = moment(item.deadFrom);
                  if (deadDate.isValid()) {
                    const elapsedMinutes = moment().diff(deadDate, 'minutes');
                    if (elapsedMinutes > this.configService.maxItemsDowntime) {
                      polyLineColor = this.configService.map.color.polyLineDead;
                    }
                  }
                }

                // Define a symbol using a predefined path (an arrow)
                // supplied by the Google Maps JavaScript API.
                const lineSymbol = {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  fillColor: polyLineColor,
                  strokeColor: polyLineColor
                };

                // Create the polyline and add the symbol via the 'icons' property.
                const line = new google.maps.Polyline({
                  path: [
                    { lat: site.coordinates.lat, lng: site.coordinates.lng },
                    { lat: item.coordinates.lat, lng: item.coordinates.lng },
                  ],
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: "90%",
                    },
                  ],
                  strokeColor: polyLineColor,
                  strokeWeight: 1.5,
                  map: this.map,
                });
                this.polyLinesList.push(line);
              }
            }
          }
        }
      } else {
        if (this.items) {
          for (const parentItem of this.items) {
            if (
              parentItem.ipNextHope !== '' &&
              parentItem.coordinates &&
              parentItem.coordinates.lat &&
              parentItem.coordinates.lng
            ) {
              const item = this.items.find((el: IItem) => el.ip === parentItem.ipNextHope);

              if (
                item &&
                item.coordinates &&
                item.coordinates.lat &&
                item.coordinates.lng
              ) {
                let polyLineColor = parentItem.link !== '' ? parentItem.link : this.configService.map.color.polyLineDefault;
                if (item.deadFrom) {
                  const deadDate = moment(item.deadFrom);
                  if (deadDate.isValid()) {
                    const elapsedMinutes = moment().diff(deadDate, 'minutes');
                    if (elapsedMinutes > this.configService.maxItemsDowntime) {
                      polyLineColor = this.configService.map.color.polyLineDead;
                    }
                  }
                }

                // Define a symbol using a predefined path (an arrow)
                // supplied by the Google Maps JavaScript API.
                const lineSymbol = {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  fillColor: polyLineColor,
                  strokeColor: polyLineColor
                };

                // Create the polyline and add the symbol via the 'icons' property.
                const line = new google.maps.Polyline({
                  path: [
                    { lat: parentItem.coordinates.lat, lng: parentItem.coordinates.lng },
                    { lat: item.coordinates.lat, lng: item.coordinates.lng },
                  ],
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: "93%",
                    },
                  ],
                  strokeColor: polyLineColor,
                  strokeWeight: 1.5,
                  map: this.map,
                });
                this.polyLinesList.push(line);
              }
            }
          }
        }
      }
    }
  }

  /**
   * Open Static objet modal
   */
  onAddStaticObject(): void {
    if (this.contextMenuIsopen) {
      if (this.menuBox) {
        this.menuBox.style.display = 'none';

        this.contextMenuIsopen = false;
      }
    }

    const dialogRef = this.dialog.open(MapStaticObjectDialogComponent, {
      data: {
        staticObjectGroups: this.staticObjectGroups,
        staticObjectCategories: this.staticObjectCategories,
        plantId: this.data.params.plantId,
        item: {
          coordinates: this.coordinates,
        },
      },
      width: '700px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }
        this.sidenavStaticObjectOpen = false;
        this.loadData();
      });
  }

  /**
   * Open Static objet modal
   */
  onEditStaticObject(item: any): void {
    if (this.contextMenuIsopen) {
      if (this.menuBox) {
        this.menuBox.style.display = 'none';

        this.contextMenuIsopen = false;
      }
    }
    const dialogRef = this.dialog.open(MapStaticObjectDialogComponent, {
      data: {
        staticObjectGroups: this.staticObjectGroups,
        staticObjectCategories: this.staticObjectCategories,
        plantId: this.data.params.plantId,
        item,
        edit: true
      },
      width: '700px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }
        this.sidenavStaticObjectOpen = false;
        this.loadData();
      });
  }

  /**
   * On delete Static objet
   */
  onDeleteStaticObject(itemId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Elimina oggetto statico',
        message:
          "Sei sicuro di voler eliminare questo oggetto statico?",
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        console.log("res",res)
        if (!res) {
          return;
        }

        // Send data to BE
        this.subscriptions.push(
          this.staticObjectsService
            .delete(itemId)
            .subscribe(
              (res: Response) => {
                if (res.status) {
                  this.toastrService.success(res.message);

                  this.sidenavStaticObjectOpen = false;
                  this.loadData();
                  return;
                }
                this.toastrService.error(
                  "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico",
                  'Errore',
                );
              },
              (err: Response) => {
                console.log(err)
                // Choose one of the following error handling method.
                // 1) First one show a message right under the form fields
                //    (if the form is properly setted)
                // 2) Second one is used to show a mat-error under each field
                //    in the dynamic form (is meant to be used only in dynamicForm)
                // 3) Third one show toastr notifications for each error
                // 4) Fourth one is used to show mat-error under each field
                //    in the ngx-formly form (is mean to be used only with ngx-formly)
                // this.helperService.handleFormError(form, err);
                // this.helperService.handleDynamicFormError(form, err);
                // this.helperService.handleError(err);
                this.toastrService.error(err.message);
              },
            ),
        );
      });
  }

  /**
   * Get categories of group
   */
  getstaticObjectCategories(groupId: string): IStaticObjectCategory[] {
    return this.staticObjectCategories.filter(
      (el: IStaticObjectCategory) => el.staticObjectGroupId === groupId
    )
  }

  /**
   * Get category checked
   */
  getCategoryChecked(categoryId: string): boolean {
    const category = this.categorisCheckox.find((el: CategorisCheckox) => el.id === categoryId);
    if (category){
       return category.selected;
    }
    return false;
  }
  
  /**
   * Show/Hide static object
   */
  onStaticApplyFilter(categoryId: string): void {
    let show = false;
    const category = this.categorisCheckox.find((el: CategorisCheckox) => el.id === categoryId);
    if (category){
      category.selected = !category.selected;
      show = category.selected;
    }
    
    this.staticObjects.forEach((item: any) => {
      if (item.staticObjectCategoryId._id === categoryId) {
        if (item.coordinates?.lat && item.coordinates.lng) {
          let marker: any;
          let url = '';
          if (
            item.staticObjectCategoryId &&
            item.staticObjectCategoryId.icon &&
            item.staticObjectCategoryId.icon.path
          ) {
            url = `${getBEUrl()}${item.staticObjectCategoryId.icon.path}`;
          }
          marker = new google.maps.Marker({
            position: item.coordinates as ICoordinates,
            icon: {
              url,
              scaledSize: new google.maps.Size(20, 20),
              anchor: new google.maps.Point(25, 25),
            },
            title: item.staticObjectCategoryId.name,
          });

          marker.data = item;
          marker.addListener('click', (e: any) => {
            if (marker.data) {
              this.sidenavStaticObjectOpen = !this.sidenavStaticObjectOpen;
              this.sidenavStaticObject = marker.data;
            }
          });

          this.staticObjectMarkers.push(marker);
        }
      }
    });

    this.staticObjectMarkers.forEach((item: google.maps.Marker | any) => {
      if (show) {
        if (item.data.staticObjectCategoryId._id === categoryId) {
          item.setMap(this.map);
        }
      } else {
        if (item.data.staticObjectCategoryId._id === categoryId) {
          item.setMap(null);
        }
      }
    });
  }
}
