<app-page>
  <div class="container-fluid">
    <div class="row">

      <div class="col-12">
        <div class="breadcrumb-wrapper">
          <div class="title">
            Dettagli
          </div>
          <ul class="breadcrumb">
            <li>
              <a routerLink="/">
                <mat-icon fontSet="fal" fontIcon="fa-home"></mat-icon>
              </a>
            </li>
            <li>
              <a routerLink="/languages">
                Lingue
              </a>
            </li>
            <li>
              Aggiungi
            </li>
          </ul> <!-- /.breadcrumb -->
        </div> <!-- /.breadcrumb-wrapper -->
      </div> <!-- /.col -->

      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <div class="card-header">
            <div class="col-12 col-md-9 d-flex align-items-center">
              <mat-card-title>
                Aggiungi una nuova lingua
              </mat-card-title>
              <mat-card-subtitle>
                Compila i campi e salva
              </mat-card-subtitle>
            </div> <!-- /.col -->
            <div class="col-12 col-md-3 d-flex align-items-center">
            </div> <!-- /.col-12 -->
          </div> <!-- /.row -->
          <mat-card-content>
            <div class="form-wrapper mt-3">
              <form [formGroup]="form" (ngSubmit)="onFormSubmit(model)">
                <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
                <div class="mt-3">
                  <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate">
                  </mat-spinner-button>
                </div>
              </form>
            </div> <!-- /.form-wrapper -->
          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </div> <!-- /.container -->
</app-page>
