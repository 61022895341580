<button class="btn-close" (click)="onClose()">
  <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
</button>
<mat-dialog-content>
<div class="preview-box">
  <div class="mb-4" style="position: relative; background-color: #f1f1f1;">
    <div *ngIf="isImage">
      <img src="{{ getDataUrl(true) }}" class="image" style="margin-left: auto;  margin-right: auto;">
    </div>
    <div *ngIf="!isImage">
      <img src="{{ getDataUrl(true) }}" class="imageDoc" style="margin-left: auto;  margin-right: auto;">
    </div>
    <div class="btn-download-wrapper">
      <button
        class="btn-download"
        type="button" mat-fab color="primary" (click)="onDownloadImage()" >
        <i class="fa-sharp fa-regular fa-down-to-bracket"></i>
      </button>
    </div>
  </div> <!-- /.col -->
  <div>
    <dl>
      <dt *ngIf="data.obj.title">Titolo</dt>
      <dd>{{ data.obj.title }}</dd>
      <dt *ngIf="data.obj.description">Descrizione</dt>
      <dd>{{ data.obj.description }}</dd>
      <dt *ngIf="data.obj.plantId && data.obj.plantId.name">Impianto</dt>
      <dd *ngIf="data.obj.plantId && data.obj.plantId.name">{{ data.obj.plantId.name }}</dd>
      <dt *ngIf="siteName !==''">Sito</dt>
      <dd *ngIf="siteName !==''">{{ siteName }}</dd>
    </dl>
  </div>
  <div class="created-at" *ngIf="data.obj.file.createdAt">
    Caricato il
  </div>
  <div class="created-at" *ngIf="data.obj.file.createdAt">
    {{ data.obj.file.createdAt | date:'dd/MM/yyyy HH:mm' }}
  </div>
</div>
</mat-dialog-content>