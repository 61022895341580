// Core packages
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// Third party packages
import { Observable, Subscription } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

// Custom packages
import { HelperService } from 'src/app/shared/services/helper.service';
import { TranslationsService } from '../../translations.service';
import { Translation } from 'src/app/shared/models/translation/translation.model';
import Response from 'src/app/shared/interfaces/response.interface';
import { LanguagesService } from 'src/app/modules/languages/languages.service';
import ILanguage from 'src/app/shared/models/language/language.interface';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-add-translation',
  templateUrl: './add-translation.page.html',
  styleUrls: ['./add-translation.page.scss'],
})
export class AddTranslationComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public translation: Translation = new Translation();
  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Crea',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  // END - Form

  constructor(
    private translationService: TranslationsService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private languagesService: LanguagesService,
    private router: Router,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Set model
    this.model = this.translation.getData();

    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'autocomplete',
            key: `lang`,
            templateOptions: {
              label: 'Lingua',
              placeholder: 'Cerca...',
              required: true,
              labelExtractor: (obj: ILanguage): string => {
                return `${obj.isoName} (${obj.iso6391.toUpperCase()})`;
              },
              valueExtractor: (obj: ILanguage): string => {
                return obj._id;
              },
              multiple: false,
              search$: (
                start?: number,
                limit?: number,
                sort?: string,
                sortVersus?: string,
                search?: any,
              ): Observable<ListApiResponse> =>
                this.languagesService.getList(
                  start,
                  limit,
                  sort,
                  sortVersus,
                  search,
                ),
              clearIcon: 'fa-trash',
              notFoundText: 'Non trovato',
              loadingText: 'Caricamento...',
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'select',
            key: 'section',
            templateOptions: {
              label: 'Sezione',
              placeholder: 'Seleziona',
              required: true,
              options: [
                {
                  value: 'fe',
                  label: 'Front-end',
                },
                {
                  value: 'be',
                  label: 'Back-end',
                },
                {
                  value: 'admin',
                  label: 'Admin panel',
                },
              ],
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'select',
            key: 'confirmed',
            templateOptions: {
              label: 'Confermata',
              placeholder: 'Seleziona',
              required: true,
              options: [
                {
                  value: true,
                  label: 'Si',
                },
                {
                  value: false,
                  label: 'No',
                },
              ],
            },
          },
          {
            className: 'col-12',
            type: 'input',
            key: 'key',
            templateOptions: {
              label: 'Chiave',
              placeholder: 'Inserisci qui...',
              required: true,
              type: 'string',
            },
          },
          {
            className: 'col-12',
            type: 'textarea',
            key: 'value',
            templateOptions: {
              label: 'Valore',
              placeholder: 'Inserisci qui...',
              required: true,
              type: 'string',
              rows: 4,
            },
          },
        ],
      },
    ];
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(model: any): void {
    // Prevent invalid form submit
    if (this.form.invalid) {
      console.warn('this.form', this.form);
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    // Prevent double submit caused by double btn click
    if (this.formBtnOptions.active) {
      return;
    }

    // Activate submit button loading status
    this.formBtnOptions = { ...this.formBtnOptions, active: true };

    // Send data to BE
    this.subscriptions.push(
      this.translationService
        .create(model)
        .pipe(
          finalize(
            () =>
              (this.formBtnOptions = { ...this.formBtnOptions, active: false }),
          ),
        )
        .subscribe(
          (res: Response) => {
            if (res.status) {
              this.toastrService.success(res.message);
              // this.form.reset();
              setTimeout(() => {
                this.router.navigate(['translations']);
              }, 900);
              return;
            }
            this.toastrService.error(
              "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico",
              'Errore',
            );
          },
          (err: Response) => {
            // Choose one of the following error handling method.
            // 1) First one show a message right under the form fields
            //    (if the form is properly setted)
            // 2) Second one is used to show a mat-error under each field
            //    in the dynamic form (is meant to be used only in dynamicForm)
            // 3) Third one show toastr notifications for each error
            // 4) Fourth one is used to show mat-error under each field
            //    in the ngx-formly form (is mean to be used only with ngx-formly)
            // this.helperService.handleFormError(form, err);
            // this.helperService.handleDynamicFormError(form, err);
            // this.helperService.handleError(err);
            this.helperService.handleFormlyError(this.form, err);
          },
        ),
    );
  }
}
