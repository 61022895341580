// Core packages
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Third party packages
import { Subscription } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

// Custom packages
import { HelperService } from 'src/app/shared/services/helper.service';
import { UsersService } from '../../users.service';
import Response from 'src/app/shared/interfaces/response.interface';
import { ConfigService } from 'src/app/shared/services/config.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import IUser from 'src/app/shared/models/user/user.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-change-password-card',
  templateUrl: './change-password-card.component.html',
  styleUrls: ['./change-password-card.component.scss'],
})
export class ChangePasswordCardComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input('userId') userId!: string;
  @Output('updatedPassword') updatedPassword = new EventEmitter<IUser>();

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Salva',
    spinnerSize: 20,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  // END - Form

  constructor(
    private userService: UsersService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    public configService: ConfigService,
    public authService: AuthService,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Set model
    this.model = {};

    // Set fields
    this.fields = [
      // ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'oldPassword',
            templateOptions: {
              label: 'Vecchia password',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'password',
            templateOptions: {
              label: 'Nuova password',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'confirmPassword',
            templateOptions: {
              label: 'Conferma password',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
        ],
      },
    ];
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(): void {
    // Prevent invalid form submit
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    // Get form values
    const formValues = this.form.value;

    // Prevent double submit caused by double btn click
    if (this.formBtnOptions.active) {
      return;
    }

    // Activate submit button loading status
    this.formBtnOptions = { ...this.formBtnOptions, active: true };

    // Send data to BE
    this.subscriptions.push(
      this.userService
        .updatePassword(this.userId, formValues)
        .pipe(
          finalize(
            () =>
              (this.formBtnOptions = { ...this.formBtnOptions, active: false }),
          ),
        )
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              const title = 'Errore';
              let message =
                res.message ||
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
              this.toastrService.error(message, title);
              return;
            }

            this.toastrService.success(res.message);
            this.updatedPassword.emit(res.data);
          },
          (err: Response) => {
            // Choose one of the following error handling method.
            // 1) First one show a message right under the form fields
            //    (if the form is properly setted)
            // 2) Second one is used to show a mat-error under each field
            //    in the dynamic form (is meant to be used only in dynamicForm)
            // 3) Third one show toastr notifications for each error
            // 4) Fourth one is used to show mat-error under each field
            //    in the ngx-formly form (is mean to be used only with ngx-formly)
            // this.helperService.handleFormError(form, err);
            // this.helperService.handleDynamicFormError(form, err);
            // this.helperService.handleError(err);
            this.helperService.handleFormlyError(this.form, err);
          },
        ),
    );
  }
}
