// Core packages
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Third party packages
import { Subscription } from 'rxjs';
import { finalize } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';

// Custom packages
import File from 'src/app/shared/interfaces/file.interface';
import { HelperService } from 'src/app/shared/services/helper.service';
import Response from 'src/app/shared/interfaces/response.interface';
import { StaticObjectsService } from 'src/app/modules/staticObjects/staticObject.service';
import { StaticObject } from 'src/app/shared/models/staticObject/staticObject.model';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';
import { CustomersService } from 'src/app/modules/customers/customers.service';
import { PlantsService } from 'src/app/modules/plants/plants.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import IUser from 'src/app/shared/models/user/user.interface';


/**
 * Script start
 */
@Component({
  selector: 'app-map-static-object-dialog',
  templateUrl: './map-static-object-dialog.component.html',
  styleUrls: ['./map-static-object-dialog.component.scss'],
})
export class MapStaticObjectDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public staticObject: StaticObject = new StaticObject();

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Salva', // 'Aggiungi',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  // END - Form

  userLoggedData: IUser | undefined = undefined;
  plants: any[] = [];
  modalTitle = '';

  staticObjectCategories: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<MapStaticObjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private staticObjectsService: StaticObjectsService,
    private customersService: CustomersService,
    private plantService: PlantsService,
    private toastrService: ToastrService,
    private helperService: HelperService,
    private authService: AuthService,
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    this.userLoggedData = this.authService?.loggedUser$.value;

    if (this.data.item) {
      this.staticObject.setData(this.data.item);
    }
    
    if (
      this.data.staticObjectCategories && 
      Array.isArray(this.data.staticObjectCategories)) {
      for (const item of this.data.staticObjectCategories) {
        if (this.userLoggedData?.role === 'customer'){
          if (
            this.userLoggedData && 
            this.userLoggedData.staticObjectGroupIds &&
            this.userLoggedData.staticObjectGroupIds.includes(item.staticObjectGroupId.toString())
          ) {
            this.staticObjectCategories.push({
              label: item.name,
              value: item._id
            });
          }
        } else {
          this.staticObjectCategories.push({
            label: item.name,
            value: item._id
          });
        }
      }
    }
    if (this.data.edit) {
      this.modalTitle = 'Modifca oggetto statico';
      // this.formBtnOptions.text = 'Salva';

      this.staticObject.set('staticObjectCategoryId', this.data.item.staticObjectCategoryId._id);
      if (this.data.item.plantId._id) {
        this.staticObject.set('plantId', this.data.item.plantId._id);
      }

      this.initForm();
    } else {
      this.modalTitle = 'Aggiungi oggetto statico';

      this.staticObject.set('plantId', this.data.plantId);
      this.plantService.get(this.data.plantId)
      .subscribe((res: Response) => {
        if (res.status) {
          const customerId = res?.data?.customerId?._id;
          if (customerId) {
            this.staticObject.set('customerId', customerId);
            this.customersService.get(customerId)
            .subscribe((res: Response) => {
              if (res.status) {
                const aggregationId = res?.data?.aggregationId?._id;
                this.staticObject.set('aggregationId', aggregationId);

                this.initForm();
              }
            });
          }
        }
      });
    }
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Set model
    this.model = this.staticObject.getData();
    
    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            template:
              '<strong class="d-block mt-4">Informazioni generali</strong>',
          },
          {
            className: 'col-12 col-md-12',
            type: 'select',
            key: `staticObjectCategoryId`,
            templateOptions: {
              label: 'Categoria oggetti statici',
              placeholder: 'Seleziona',
              required: true,
              multiple: false,
              options: this.staticObjectCategories
            },
          },

          {
            key: `aggregationId`,
            templateOptions: {
              type: 'Hidden'
            },
          },
          {
            key: `customerId`,
            templateOptions: {
              type: 'Hidden'
            },
          },
          {
            key: `plantId`,
            templateOptions: {
              type: 'Hidden'
            },
          },
          {
            key: `coordinates.lat`,
            defaultValue: this.data.item.coordinates.lat,
            templateOptions: {
              type: "hidden",
            },
          },
          {
            key: `coordinates.lng`,
            defaultValue: this.data.item.coordinates.lng,
            templateOptions: {
              type: "hidden",
            },
          },
          {
            className: 'col-12',
            template:
              '<strong class="d-block mt-3">Informazioni aggiuntive</strong>',
          },
          {
            className: 'col-12 col-md-6',
            type: 'datepicker',
            key: `date`,
            templateOptions: {
              label: 'Data',
              placeholder: 'Seleziona...',
              required: false,
              type: 'string',
            },
          },
          {
            className: 'col-12',
            type: 'textarea',
            key: `notes`,
            templateOptions: {
              label: 'Note',
              placeholder: 'Inserisci qui...',
              required: false,
              type: 'string',
              rows: 3,
            },
          },
          {
            className: 'col-12',
            type: 'file-gallery',
            key: `photos`,
            templateOptions: {
              label: 'Foto associate (max 5)',
              required: false,
              type: 'string',
              allowedFilesNumber: 5,
              allowedFilesExtensions:
                'jpeg,jpg,png',
            },
          },
          {
            className: 'col-12',
            type: 'file-gallery',
            key: `files`,
            templateOptions: {
              label: 'File associati (max 5)',
              required: false,
              type: 'string',
              allowedFilesNumber: 5,
              allowedFilesExtensions:
                'pdf,doc,docx,xls,xlsx,csv,zip,rar',
            },
          },
        ],
      },
    ];
  
  }


  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(model: any): void {
    // Prevent invalid form submit
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    // Prevent double submit caused by double btn click
    if (this.formBtnOptions.active) {
      return;
    }

    // Activate submit button loading status
    this.formBtnOptions = { ...this.formBtnOptions, active: true };

    if (model?.photos) {
      if (Array.isArray(model?.photos)) {
        if (model.photos.length) {
          model.photos = model.photos.map((el: File) =>
            typeof el === 'string' ? el : el._id,
          );
        } else {
          model.photos = null;
        }
      } else {
        delete model.photos;
      }
    } else {
      model.photos = [];
    }

    if (model?.files) {
      if (Array.isArray(model?.files)) {
        if (model.files.length) {
          model.files = model.files.map((el: File) =>
            typeof el === 'string' ? el : el._id,
          );
        } else {
          model.files = null;
        }
      } else {
        delete model.files;
      }
    } else {
      model.files = [];
    }

    if (this.data.edit) {
      // Send data to BE
      this.subscriptions.push(
        this.staticObjectsService
          .updateFields(this.staticObject.get('_id'), model)
          .pipe(
            finalize(
              () =>
                (this.formBtnOptions = { ...this.formBtnOptions, active: false }),
            ),
          )
          .subscribe(
            (res: Response) => {
              if (res.status) {
                this.toastrService.success(res.message);
                // this.form.reset();
                setTimeout(() => {
                  this.dialogRef.close(true);
                }, 900);
                return;
              }
              this.toastrService.error(
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico",
                'Errore',
              );
            },
            (err: Response) => {
              // Choose one of the following error handling method.
              // 1) First one show a message right under the form fields
              //    (if the form is properly setted)
              // 2) Second one is used to show a mat-error under each field
              //    in the dynamic form (is meant to be used only in dynamicForm)
              // 3) Third one show toastr notifications for each error
              // 4) Fourth one is used to show mat-error under each field
              //    in the ngx-formly form (is mean to be used only with ngx-formly)
              // this.helperService.handleFormError(form, err);
              // this.helperService.handleDynamicFormError(form, err);
              // this.helperService.handleError(err);
              this.helperService.handleFormlyError(this.form, err);
            },
          ),
      );
    } else {
      // Send data to BE
      this.subscriptions.push(
        this.staticObjectsService
          .create(model)
          .pipe(
            finalize(
              () =>
                (this.formBtnOptions = { ...this.formBtnOptions, active: false }),
            ),
          )
          .subscribe(
            (res: Response) => {
              if (res.status) {
                this.toastrService.success(res.message);
                // this.form.reset();
                setTimeout(() => {
                  this.dialogRef.close(true);
                }, 900);
                return;
              }
              this.toastrService.error(
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico",
                'Errore',
              );
            },
            (err: Response) => {
              // Choose one of the following error handling method.
              // 1) First one show a message right under the form fields
              //    (if the form is properly setted)
              // 2) Second one is used to show a mat-error under each field
              //    in the dynamic form (is meant to be used only in dynamicForm)
              // 3) Third one show toastr notifications for each error
              // 4) Fourth one is used to show mat-error under each field
              //    in the ngx-formly form (is mean to be used only with ngx-formly)
              // this.helperService.handleFormError(form, err);
              // this.helperService.handleDynamicFormError(form, err);
              // this.helperService.handleError(err);
              this.helperService.handleFormlyError(this.form, err);
            },
          ),
      );
    }

  }

  getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig | undefined {
    for (let i = 0, len = fields.length; i < len; i++) {
      const f = fields[i];
      if (f.key === key) {
        return f;
      }
      
      if (f.fieldGroup && !f.key) {
        const cf = this.getField(key, f.fieldGroup);
        if (cf) {
          return cf;
        }
      }
    }
    return;
  }
}
