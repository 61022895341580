<app-page>
  <div class="container-fluid">
    <div class="row">

      <div class="col-12">
        <div class="breadcrumb-wrapper">
          <div class="title">
            Dettagli <br>
            <small class="itemId">ID: {{ translation.getId() }}</small>
          </div>
          <ul class="breadcrumb">
            <li>
              <a routerLink="/">
                <mat-icon fontSet="fal" fontIcon="fa-home"></mat-icon>
              </a>
            </li>
            <li>
              <a routerLink="/translations">
                Traduzioni
              </a>
            </li>
            <li>
              {{ translation.get('key') }}
            </li>
          </ul> <!-- /.breadcrumb -->
        </div> <!-- /.breadcrumb-wrapper -->
      </div> <!-- /.col -->

      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <div class="card-header">
            <div class="col-12 col-md-9 d-flex align-items-center">
              <mat-card-title>
                Informazioni <span class="d-none d-md-inline-block">traduzione</span>
              </mat-card-title>
            </div> <!-- /.col -->
            <div class="col-12 col-md-3 d-flex align-items-center">

            </div> <!-- /.col-12 -->
          </div> <!-- /.row -->
          <mat-card-content class="pt-0">
            <div class="form-wrapper mt-3">
              <form [formGroup]="form" (ngSubmit)="onFormSubmit(model)">
                <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
                <div class="row mt-3" *ngIf="authService.loggedUser$?.value?.role !== 'customer'">
                  <div class="col-md-6">
                    <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate">
                    </mat-spinner-button>
                  </div> <!-- /.col -->
                  <div class="col-md-6 mt-5 mt-md-0 text-right">
                    <button
                      *ngIf="configService.settings.deletePermittedRoles.includes(authService.loggedUser$?.value?.role)"
                      type="button" mat-stroked-button color="warn" (click)="onDelete()" class="w-100 w-md-auto">
                      Elimina
                    </button>
                  </div> <!-- /.col -->
                </div> <!-- /.row -->
              </form>
            </div> <!-- /.form-wrapper -->
          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </div> <!-- /.container -->
</app-page>
