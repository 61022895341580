// Core packages
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

// Third party packages
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

// Custom packages
import TableConfig from 'src/app/shared/interfaces/tableConfig.interface';
import { HelperService } from 'src/app/shared/services/helper.service';
import { LanguagesService } from '../../languages.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { TableDataSource } from 'src/app/shared/components/table/table.dataSource';
import ILanguage from 'src/app/shared/models/language/language.interface';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';
import { AuthService } from 'src/app/modules/auth/auth.service';

/**
 * Script start
 */
@Component({
  selector: 'app-languages-list',
  templateUrl: './languages-list.page.html',
  styleUrls: ['./languages-list.page.scss'],
})
export class LanguagesListComponent implements OnInit {
  public tableConfig!: TableConfig<ILanguage>;
  public totalLanguages: string = '0';

  constructor(
    private languagesService: LanguagesService,
    private helperService: HelperService,
    private configService: ConfigService,
    public authService: AuthService,
    private router: Router,
  ) {
    this.initTable();
    this.getCounters();
  }

  /**
   * Get counters for current page stat cards
   *
   * @since 1.0.0
   */
  async getCounters(): Promise<void> {
    try {
      this.languagesService
        .getList(0, 1, 'createdAt', 'desc')
        .subscribe((res: ListApiResponse) => {
          if (res.status) {
            this.totalLanguages = res.totalCount.toString();
          }
        });
    } catch (error) {
      console.warn('Error in getCounters(): ', error);
    }
  }

  /**
   * Init table
   *
   * @since 1.0.0
   */
  initTable(): void {
    this.tableConfig = {
      dataSource: new TableDataSource(
        this.languagesService,
        this.helperService,
      ),
      selectable: false,
      columns: [
        {
          key: 'iso6391',
          label: 'ISO 639-1',
          sortable: true,
          visible: true,
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        {
          key: 'isoName',
          label: 'Nome ISO',
          sortable: true,
          visible: true,
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        // {
        //   key: 'nativeName',
        //   label: 'Nome nativo',
        //   sortable: true,
        //   visible: true,
        //   filter: {
        //     control: new FormControl(''),
        //     type: 'input',
        //     dataType: 'string',
        //     label: 'Cerca...',
        //   },
        // },
        {
          key: 'active',
          label: 'Attiva',
          sortable: true,
          visible: true,
          render: (row: any, colKey: string): string => {
            const colVal = row[colKey];
            if (colVal) {
              return 'Si';
            }
            return 'No';
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'select',
            dataType: 'string',
            label: 'Seleziona',
            items: [
              {
                label: 'Si',
                value: 'true',
              },
              {
                label: 'No',
                value: 'false',
              },
            ],
          },
        },
        {
          key: 'createdAt',
          label: 'Creazione',
          sortable: true,
          visible: true,
          render: (row: any, colKey: string): string => {
            const colVal = row[colKey];
            const dateIsValid = moment(colVal).isValid();
            if (dateIsValid && colVal) {
              return moment(colVal).format(this.configService.dateTimeFormat);
            }
            return colVal;
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'datepicker',
            dataType: 'date',
            label: 'Seleziona',
          },
        },
      ],
      refresh$: new BehaviorSubject<boolean>(false),
      reset$: new BehaviorSubject<boolean>(false),
      options: [
        {
          title: 'Cancella filtri',
          name: '',
          icon: 'fa-filter-circle-xmark',
          callback: () => {
            this.tableConfig.reset$.next(!this.tableConfig.reset$.value);
          },
        },
        {
          title: 'Ricarica',
          name: '',
          icon: 'fa-sync',
          callback: () => {
            this.tableConfig.refresh$.next(!this.tableConfig.refresh$.value);
          },
        },
      ],
    };
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {}

  /**
   * Navigate to Add Translation page
   *
   * @since 1.0.0
   */
  onAddTranslation(): void {
    this.router.navigate(['/', 'translations', 'add']);
  }
}
