export const environment = {
  production: false,

  // API settings
  apiProtocol: 'https://',
  apiHost: 'devapi.skp-adam.it',
  apiPort: '',
  apiVersion: 'v1',

  // Admin panel settings
  adminPanelProtocol: 'https://',
  adminPanelHost: 'dev.skp-adam.it',
  adminPanelPort: '',
  adminPanelCookieSecure: true,

  // Front-end settings
  frontEndProtocol: 'https://',
  frontEndHost: 'dev.skp-adam.it',
  frontEndPort: '',
  frontEndCookieSecure: true,

  googleMapsApiKey: 'AIzaSyCZekP8ZQRBnXe3VIKVDCf65zKAYaS0VlU',
};

/**
 * Get the FE URL for current environment
 *
 * @since 1.0.0
 */
export function getFeUrl(): string {
  let url = `${environment.frontEndProtocol}${environment.frontEndHost}`;
  if (environment.frontEndPort) {
    url = `${environment.frontEndProtocol}${environment.frontEndHost}:${environment.frontEndPort}`;
  }
  return url;
}

/**
 * Get the API URL for current environment
 *
 * @since 1.0.0
 */
export function getApiUrl(): string {
  let url = `${environment.apiProtocol}${environment.apiHost}/api/${environment.apiVersion}`;
  if (environment.apiPort) {
    url = `${environment.apiProtocol}${environment.apiHost}:${environment.apiPort}/api/${environment.apiVersion}`;
  }
  return url;
}

/**
 * Get the back-end URL for current environment
 *
 * @since 1.0.0
 */
export function getBEUrl(): string {
  let url = `${environment.apiProtocol}${environment.apiHost}`;
  if (environment.apiPort) {
    url = `${environment.apiProtocol}${environment.apiHost}:${environment.apiPort}`;
  }
  return url;
}

/**
 * Get current env admin panel host
 *
 * @since 1.0.0
 */
export function getAdminPanelHost(): string {
  return `${environment.adminPanelHost}`;
}

/**
 * Get current env front-end host
 *
 * @since 1.0.0
 */
export function getFrontEndHost(): string {
  return `${environment.frontEndHost}`;
}

/**
 * Get current config for cookie secure flat
 *
 * @since 1.0.0
 */
export function getAdminPanelCookieSecure(): boolean {
  return environment.adminPanelCookieSecure;
}

/**
 * Get current config for cookie secure flat
 *
 * @since 1.0.0
 */
export function getFrontEndCookieSecure(): boolean {
  return environment.frontEndCookieSecure;
}
