// Core packages
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';

// Third party packages
import { Subscription } from 'rxjs';

// Custom packages

/**
 * Script start
 */
@Component({
  selector: 'app-add-item-card',
  templateUrl: './add-item-card.component.html',
  styleUrls: ['./add-item-card.component.scss'],
})
export class AddItemCardComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input('descriptor') descriptor!: string;
  @Input('icon') icon!: string;

  /**
   * Class constructor
   */
  constructor(private router: Router) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {}

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
