// Core packages
import { Component, OnInit, Inject, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

// Third party packages
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  startWith,
} from "rxjs/operators";
import moment from 'moment';

// Custom packages
import { HelperService } from 'src/app/shared/services/helper.service';
import { DeadItemsService } from 'src/app/modules/deadItems/deadItems.service';
import { CustomersService } from 'src/app/modules/customers/customers.service';
import Response from 'src/app/shared/interfaces/response.interface';
import ISite from 'src/app/shared/models/site/site.interface';
import IItem from 'src/app/shared/models/item/item.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-highlight-item-reason-dialog',
  templateUrl: './highlight-item-reason-dialog.component.html',
  styleUrls: ['./highlight-item-reason-dialog.component.scss'],
})
export class HighlightItemReasonDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model!: {
    items: any[];
  };
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Conferma',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  // END - Form
  formLoading = true;

  customerName = '';
  customerEmail = '';

  subjectEmail = 'Dispositivi attenzionati';
  bodyEmail = '';
  messageticket = '';

  constructor(
    public dialogRef: MatDialogRef<HighlightItemReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private deadItemsService: DeadItemsService,
    private customersService: CustomersService,
    private router: Router,
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    // Get customer info
    this.subscriptions.push(
      this.customersService
        .get(this.data.customerId)
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Something bad happened
            }

            this.customerName = res.data.name;
            this.customerEmail = res.data.email ? res.data.email : '';
          },
          (err: Response) => {
            this.helperService.handleError(err);
          },
        ),
    );

    // Get dead item list
    this.subscriptions.push(
      this.deadItemsService
        .getList(0, 100, 'createdAt', 'desc', { siteId: this.data.siteId })
        // .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Something bad happened
            }
            this.initForm(res.data);
          },
          (err: Response) => {
            this.helperService.handleError(err);
          },
        ),
    );
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(data: any[]): void {
    // Set model
    if (data && Array.isArray(data)) {
      for (const el of data) {
        if (el.highlightDate && moment(el.highlightDate).isValid()) {
          el.check = true;
        }
        const name = this.helperService.getNameForItemType(el.type);
        el.itemName = `${name} - ${el.ip}`;
        el.name = `${name} - ${el.ip}`;
      }
    }
    this.model = {
      items: data,
    };

    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        className: 'col-12',
        key: "items",
        type: "repeat-section",
        hooks: {
          onInit: (): void => {
            this.handleHighlightItems();
          },
        },
        fieldArray: {
          fieldGroupClassName: "row",
          fieldGroup: [
            {
              key: "_id",
              defaultValue: "",
              templateOptions: {
                type: "hidden",
              },
            },
            {
              key: 'name',
              templateOptions: {
                type: "hidden",
              }
            },
            {
              className: "col-1",
              type: "checkbox",
              key: "check",
              defaultValue: false,
              templateOptions: {
                required: false,
              },
            },
            {
              className: "col-5",
              type: 'input',
              key: 'itemName',
              templateOptions: {
                disabled: true,
              }
            },
            {
              className: 'col-3',
              type: 'datepicker',
              key: 'highlightDate',
              defaultValue: moment().toDate(),
              templateOptions: {
                label: 'Data attenzione',
                placeholder: 'Inserisci qui...',
                required: false,
                type: 'string',
              },
              expressionProperties: {
                "templateOptions.disabled": "!model.check",
                "templateOptions.required": "model.check",
              },
            },
            {
              className: 'col-3',
              type: 'textarea',
              key: 'highlightNote',
              templateOptions: {
                label: 'Inserisci nota',
                placeholder: 'Inserisci qui...',
                required: false,
                type: 'string',
                // rows: 5,
              },
              expressionProperties: {
                "templateOptions.disabled": "!model.check",
              },
            },
          ]
        }
      },
    ];

    this.formLoading = false;
  }

  /**
   * Handle highlight itmes
   */
  handleHighlightItems(): void {
    const itemsControl = this.form.controls.items;

    if (itemsControl) {
      itemsControl.valueChanges
        .pipe(distinctUntilChanged(), startWith(itemsControl.value))
        .subscribe(
          (res) => {
            if (!res) {
              return;
            }
            let highlightItems = '';
            if (Array.isArray(res)) {
              for (const el of res) {
                if (el.check) {
                  highlightItems = `${highlightItems}\n${el.name}`;
                }
              }
            }
            if (highlightItems !== '') {
              this.bodyEmail = `Spett.le ${this.customerName}\n
                in elenco i dispositivi guasti attenzionati:\n
                ${highlightItems}\n\n
                Cordiali saluti`;
              this.messageticket = `Elenco dei dispositivi guasti attenzionati: ${highlightItems}`;
            }
          },
          (err) => {
            this.helperService.handleError(err);
          }
        );
    }
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(model?: any): void {
    model = this.form.getRawValue();

    // Prevent invalid form submit
    if (this.form.invalid) {
      console.warn('this.form', this.form);
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    this.dialogRef.close(model);
  }
  
  /**
   * Handle create new ticket
   */
  onCreateticket(): void {
    this.dialogRef.close(false);

    this.router.navigate(['/', 'tickets', 'add'], {
      queryParams: {
        plantId: this.data.plantId,
        itemId: this.data.itemId,
        message: this.messageticket
      },
    });
  }

  /**
   * Handle open client email
   */
  OnSendEmail(): void {
    window.location.href = `mailto:${this.customerEmail}?subject=${this.subjectEmail}&body=${this.bodyEmail}`;
  }
}
