// Core components
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Third party components
import { FieldType } from '@ngx-formly/material';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

// Custom components
import { AddressFinderDialogComponent } from '../../dialogs/address-finder-dialog/address-finder-dialog.component';
import { FormGroup } from '@angular/forms';

/**
 * Script start
 */
@Component({
  selector: 'app-formly-address-finder-type',
  templateUrl: './address-finder-type.component.html',
  styleUrls: ['./address-finder-type.component.scss'],
})
export class AddressFinderTypeComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  private subscriptions: Subscription[] = [];

  constructor(public dialog: MatDialog) {
    super();
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * After view init logic
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Open dialog
   *
   * @since 1.0.0
   */
  onAdd(): void {
    const form: FormGroup = this.form;
    const controls = form.controls;
    let coordinatesControl!: FormGroup;

    if (
      controls.coordinates &&
      (controls.coordinates as FormGroup).controls?.lat
    ) {
      coordinatesControl = controls.coordinates as FormGroup;
    }

    const dialogRef = this.dialog.open(AddressFinderDialogComponent, {
      data: {
        withElevation: this.to.altitude || false,
        center: {
          lat: coordinatesControl?.controls?.lat?.value || null,
          lng: coordinatesControl?.controls?.lng?.value || null,
          ele: coordinatesControl?.controls?.ele?.value || null,
          alt: coordinatesControl?.controls?.alt?.value || null,
        },
      },
      width: '550px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }

        const { lat, lng, alt } = res;

        const latControl = coordinatesControl.controls.lat;
        const lngControl = coordinatesControl.controls.lng;
        const altControl = coordinatesControl.controls.alt;
        if (latControl) {
          latControl.setValue(lat);
        }
        if (lngControl) {
          lngControl.setValue(lng);
        }
        if (altControl) {
          altControl.setValue(alt);
        }

        console.log('res', res);
        console.log('this', this);
      });
  }
}
