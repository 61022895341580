<div class="close-button" (click)="onDenial()">
  <mat-icon fontSet="fal" fontIcon="fa-times" style="color: #fff;"></mat-icon>
</div>
<div mat-dialog-title>
  <div class="head">
    <div class="title">{{ data.title }}</div>
    <div class="message">{{ data.message }}</div>
  </div>
  <div class="section">
    <div class="sectionTitle">{{ data.sectionTitle }}</div>  
  </div>
</div>

<mat-dialog-content class="mb-3">
  <ng-container *ngFor="let item of data.warnings">
    <div class="content">
      <div class="aggregation">{{ item.plantId.name }}</div>
      <div class="palnt">{{ item.siteId.name }}</div>
      <div class="item">{{ item.ip }} - {{ item.type}}</div>
      <div class="date">
        <span class="text-danger">{{ getDateFromNow(item.date) }}</span>
        <br>
        <small>{{ getDateFormat(item.date) }}</small>
      </div>
    </div>
  </ng-container>
  
</mat-dialog-content>
