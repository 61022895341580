<app-page>
  <div class="container-fluid">
    <div class="row">

      <div class="col-12">
        <div class="breadcrumb-wrapper">
          <div class="title">
            Aggiungi
          </div>
          <ul class="breadcrumb">
            <li>
              <mat-icon fontSet="fal" fontIcon="fa-home"></mat-icon>
            </li>
            <li>
              <a routerLink="/users">
                Utenti
              </a>
            </li>
            <li>
              Aggiungi
            </li>
          </ul> <!-- /.breadcrumb -->
        </div> <!-- /.breadcrumb-wrapper -->
      </div> <!-- /.col -->

      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <div class="card-header">
            <div class="col-12 col-md-9 d-flex align-items-center">
              <mat-card-title>
                Informazioni <span class="d-none d-md-inline-block">utente</span>
              </mat-card-title>
            </div> <!-- /.col -->
            <div class="col-12 col-md-3 d-flex align-items-center">
            </div> <!-- /.col-12 -->
          </div> <!-- /.row -->
          <mat-card-content class="pt-0">
            <div class="form-wrapper mt-3">
              <div class="row">
                <div class="col-4">
                  <strong>Tipologia utenza</strong>
                  <div class="options-wrapper">
                    <div class="option" (click)="setUserType('admin')" [ngClass]="{'active': userType === 'admin' }">
                      Admin</div>
                    <div class="option" (click)="setUserType('customer')"
                      [ngClass]="{'active': userType === 'customer' }">
                      Cliente</div>
                  </div>
                </div>
                <div class="col-4" *ngIf="userType === 'customer'">
                  <strong>Profilo permessi</strong>
                  <div class="options-wrapper">
                    <div class="option" (click)="setUserSubtype('demo')"
                      [ngClass]="{'active': userSubtype === 'demo' }">
                      Demo</div>
                    <div class="option" (click)="setUserSubtype('light')"
                      [ngClass]="{'active': userSubtype === 'light' }">
                      Light</div>
                    <div class="option" (click)="setUserSubtype('pro')" [ngClass]="{'active': userSubtype === 'pro' }">
                      Pro</div>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <form [formGroup]="form" (ngSubmit)="onFormSubmit()"
                *ngIf="userType === 'admin' || (userType === 'customer' && userSubtype !== '') ">
                <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
                <div class="mt-3">
                  <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate">
                  </mat-spinner-button>
                </div>
              </form>
            </div> <!-- /.form-wrapper -->
          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->

    </div> <!-- /.row -->
  </div> <!-- /.container -->
</app-page>
