// Core packages
import { Pipe, PipeTransform } from '@angular/core';

// Third party packages

// Custom packages

/**
 * Script start
 */
@Pipe({
  name: 'truncate',
})
export default class TruncatePipe implements PipeTransform {
  // @see https://stackoverflow.com/questions/44669340/how-to-truncate-text-in-angular2
  transform(value: string, args: any[] = []): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
