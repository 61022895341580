// Core packages
import { Component, OnInit } from '@angular/core';

// Third party packages
import { FieldArrayType } from '@ngx-formly/core';

// Custom packages

/**
 * Script start
 *
 * @see https://formly.dev/examples/advanced/repeating-section
 */
@Component({
  selector: 'formly-repeat-section',
  template: `
    <ng-container *ngIf="!to.hidden">
      <div
        *ngFor="let field of field.fieldGroup; let i = index"
        [class]="to.wrapperClass"
      >
        <formly-field [field]="field"></formly-field>
        <ng-container *ngIf="!to.removeHandler">
          <a
            mat-button
            mat-raised-button
            color="warn"
            type="button"
            (click)="remove(i)"
            class="remove-btn"
          >
            <mat-icon
              *ngIf="to.removeIconLeft"
              fontSet="fal"
              [fontIcon]="this.to.removeIconLeft"
            ></mat-icon>
            {{ to.removeText }}
            <mat-icon
              *ngIf="to.removeIconRight"
              fontSet="fal"
              [fontIcon]="this.to.removeIconRight"
            ></mat-icon>
          </a>
        </ng-container>
        <ng-container *ngIf="to.removeHandler">
          <a
            mat-button
            mat-raised-button
            color="warn"
            type="button"
            (click)="to.removeHandler(i)"
            class="remove-btn"
          >
            <mat-icon
              *ngIf="to.removeIconLeft"
              fontSet="fal"
              [fontIcon]="this.to.removeIconLeft"
            ></mat-icon>
            {{ to.removeText }}
            <mat-icon
              *ngIf="to.removeIconRight"
              fontSet="fal"
              [fontIcon]="this.to.removeIconRight"
            ></mat-icon>
          </a>
        </ng-container>
      </div>
      <div style="">
        <a
          mat-button
          mat-raised-button
          color="primary"
          type="button"
          (click)="add()"
        >
          <mat-icon
            *ngIf="to.iconLeft"
            fontSet="fal"
            [fontIcon]="this.to.iconLeft"
          ></mat-icon>
          {{ to.addText }}
          <mat-icon
            *ngIf="to.iconRight"
            fontSet="fal"
            [fontIcon]="this.to.iconRight"
          ></mat-icon>
        </a>
      </div>
    </ng-container>
  `,
})
export class RepeatSectionTypeComponent
  extends FieldArrayType
  implements OnInit
{
  constructor() {
    super();
    // console.log('field', this.field);
    // console.log('THIS', this);
  }

  ngOnInit(): void {
    // console.log('field', this.field);
  }
}
