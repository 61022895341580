// Core packages
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';

// Third party packages
import { Subscription, BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import Response from 'src/app/shared/interfaces/response.interface';
import IUser from 'src/app/shared/models/user/user.interface';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { TicketsService } from 'src/app/modules/tickets/tickets.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { PageService } from 'src/app/shared/services/loading.service';
import ITicket from 'src/app/shared/models/ticket/ticket.interface';
import IItem from 'src/app/shared/models/item/item.interface';
import IPlant from 'src/app/shared/models/plant/plant.interface';
import ISite from 'src/app/shared/models/site/site.interface';
import { TableDataSource } from 'src/app/shared/components/table/table.dataSource';
import TableConfig from 'src/app/shared/interfaces/tableConfig.interface';
import { PlantsService } from 'src/app/modules/plants/plants.service';
import IAggregation from 'src/app/shared/models/aggregation/aggregation.interface';
import { ConfigService } from 'src/app/shared/services/config.service';

/**
 * Script start
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public data: any = {};
  public loggedUser!: IUser | undefined;
  public tickets: ITicket<IItem<ISite<IPlant>>, IAggregation>[] = [];
  public ticketsCounter: number = 0;
  public tableConfig!: TableConfig<IPlant>;

  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private ticketsService: TicketsService,
    private router: Router,
    public helperService: HelperService,
    private pageService: PageService,
    private plantsService: PlantsService,
    private configService: ConfigService,
  ) {
    this.getData();
    this.getTickets();
    this.initTable();
    this.loggedUser = this.authService.loggedUser$?.value;
  }

  async getData(): Promise<void> {
    this.apiService.apiCall('GET', 'dashboard').subscribe((res: Response) => {
      if (res.status) {
        const { data } = res;
        this.data = data;
      }
    });
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Get plant data
   *
   * @since 1.0.0
   */
  getTickets(): void {
    this.subscriptions.push(
      this.ticketsService
        .getList(0, 3, 'createdAt', 'desc')
        .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Something bad happened
              this.router.navigate(['plants']);
            }

            // Set data
            this.tickets = res.data;
            this.ticketsCounter = (res as any).totalCount;
          },
          (err: Response) => {
            this.helperService.handleError(err);
            this.router.navigate(['errors', '404']);
          },
        ),
    );
  }

  /**
   * Init table
   *
   * @since 1.0.0
   */
  initTable(): void {
    this.tableConfig = {
      dataSource: new TableDataSource(this.plantsService, this.helperService),
      selectable: false,
      generalSearch: false,
      actionsColumnCallback: (element: IPlant) => {
        this.router.navigate(['/', 'plants', element._id]);
      },
      columns: [
        {
          key: 'name',
          label: 'Impianto',
          sortable: true,
          visible: true,
          onClick: (row: any, colKey: string) => {
            this.router.navigate(['/', 'plants', row._id]);
          },
          render: (row: any, colKey: string): string => {
            const colVal = row[colKey];
            if (colVal) {
              return `<u>${colVal}</u>`;
            }
            return '';
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        {
          key: 'customerId.name',
          label: 'Cliente',
          sortable: false,
          visible: true,
          render: (row: any, colKey: string): string => {
            const colVal = row?.customerId?.name;
            if (colVal) {
              return colVal;
            }
            return colVal;
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        {
          key: 'withDeadDevices',
          label: 'With Dead Devices',
          sortable: false,
          visible: false,
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
            defaultValue: true,
          },
        },
        {
          key: '_id',
          label: 'Mappa',
          sortable: false,
          visible: true,
          onClick: (row: any, colKey: string) => {
            this.router.navigate(['/', 'plants', 'map', row._id]);
          },
          render: (row: any, colKey: string): string => {
            const colVal = '<span class="clickableLink">Mappa</span>';
            return colVal;
          },
        },
      ],
      sort: 'name',
      sortVersus: 'asc',
      refresh$: new BehaviorSubject<boolean>(false),
      reset$: new BehaviorSubject<boolean>(false),
      options: [
        // {
        //   title: 'Cancella filtri',
        //   name: '',
        //   icon: 'fa-filter-circle-xmark',
        //   callback: () => {
        //     this.tableConfig.reset$.next(!this.tableConfig.reset$.value);
        //   },
        // },
        // {
        //   title: 'Ricarica',
        //   name: '',
        //   icon: 'fa-sync',
        //   callback: () => {
        //     this.tableConfig.refresh$.next(!this.tableConfig.refresh$.value);
        //   },
        // },
      ],
    };
  }

  getTiketStatus(status: string): string {
    const foundStatus =
      this.configService.settings.modules?.tickets?.statusesOptions?.find(
        (el: any) => el.value == status,
      );
    if (foundStatus) {
      return `<span class="ticket-status-${status}">${foundStatus.label}</span>`;
    }
    return '';
  }

  getPercentage(fromZero: boolean = false): number {
    const percentage =
      ((this.data?.tickets?.total - this.data?.tickets?.open) /
        this.data?.tickets?.total) *
      100;
    if (fromZero) {
      return 270 - percentage;
    }
    return percentage;
  }
}
