<app-page>
  <div class="container-fluid">
    <div class="row">

      <div class="col-12 col-lg-6">
        <div class="breadcrumb-wrapper">
          <div class="title">
            Dettaglio utente
          </div>
          <ul class="breadcrumb">
            <li>
              <a routerLink="/">
                <mat-icon fontSet="fal" fontIcon="fa-home"></mat-icon>
              </a>
            </li>
            <li>
              <a routerLink="/users">
                Utenti
              </a>
            </li>
            <li>
              {{ user.get('firstName') }} {{ user.get('lastName') }}
            </li>
          </ul> <!-- /.breadcrumb -->
        </div> <!-- /.breadcrumb-wrapper -->
      </div> <!-- /.col -->

      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <div class="card-header py-2">
            <div class="col-12 col-md-9 d-flex align-items-center">
              <mat-card-title>
                <h1>{{ user.get('fullName') }}</h1>
              </mat-card-title>
            </div> <!-- /.col -->
            <div class="col-12 col-md-3 d-flex align-items-center justify-content-end">

              <ng-container *ngIf="authService.loggedUser$?.value?.role !== 'customer'">
                <mat-form-field appearance="standard">
                  <mat-label>Stato</mat-label>
                  <mat-select [ngModel]="user.get('active')" (selectionChange)="onActiveChange($event)">
                    <mat-option [value]="true">
                      Attivo
                    </mat-option>
                    <mat-option [value]="false">
                      Disattivo
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

            </div> <!-- /.col-12 -->
          </div> <!-- /.row -->
          <mat-card-content class="pt-0">
            <div class="form-wrapper mt-3">

              <div class="row" *ngIf="isAdmin">
                <div class="col-4">
                  <strong>Tipologia utenza</strong>
                  <div class="options-wrapper">
                    <div class="option" (click)="user.set('role', 'owner')"
                      [ngClass]="{'active': user.get('role') === 'owner' }">
                      Admin</div>
                    <div class="option" (click)="user.set('role', 'customer')"
                      [ngClass]="{'active': user.get('role') === 'customer' }">
                      Cliente</div>
                  </div>
                </div>
                <div class="col-4" *ngIf="user.get('role') === 'customer'">
                  <strong>Profilo permessi</strong>
                  <div class="options-wrapper">
                    <div class="option" (click)="user.set('profile', 'demo')"
                      [ngClass]="{'active': user.get('profile') === 'demo' }">
                      Demo</div>
                    <div class="option" (click)="user.set('profile', 'light'); onFormManageLightUser()"
                      [ngClass]="{'active': user.get('profile') === 'light' }">
                      Light</div>
                    <div class="option" (click)="user.set('profile', 'pro')"
                      [ngClass]="{'active': user.get('profile') === 'pro' }">
                      Pro</div>
                  </div>
                </div>
              </div>

              <mat-divider *ngIf="isAdmin"></mat-divider>

              <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
                <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
                <div class="row mt-3" *ngIf="authService.loggedUser$?.value?.role !== 'customer'">
                  <div class="col-md-6">
                    <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate"
                      *ngIf="!readOnlyMode">
                    </mat-spinner-button>
                  </div> <!-- /.col -->
                  <div class="col-md-6 mt-5 mt-md-0 text-right">
                    <button
                      *ngIf="configService.settings.deletePermittedRoles.includes(authService.loggedUser$?.value?.role)"
                      type="button" mat-stroked-button color="warn" (click)="onDelete()" class="w-100 w-md-auto">
                      Elimina
                    </button>
                  </div> <!-- /.col -->
                </div> <!-- /.row -->
              </form>
            </div> <!-- /.form-wrapper -->
          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->

      <div class="col-12 mt-4" *ngIf="authService.loggedUser$?.value?.role === 'customer'">
        <app-change-password-card [userId]="itemId"></app-change-password-card>
      </div>

    </div> <!-- /.row -->
  </div> <!-- /.container -->
</app-page>
