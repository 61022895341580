// Core packages

// Third party packages

// Custom packages
import { BaseModel } from '../_base/model.class';

/**
 * Script start
 */
export class Language extends BaseModel {
  constructor(
    private _id?: string,
    private iso6391?: string,
    private iso6392?: string,
    private isoName?: string,
    private nativeName?: string,
    private active?: boolean,
    private createdAt?: Date,
    private createdBy?: string,
    private updatedAt?: Date,
    private updatedBy?: string,
  ) {
    super();

    this.setRequiredFields([
      '_id',
      'iso6391',
      'iso6392',
      'isoName',
      'nativeName',
      'createdAt',
      'createdBy',
    ]);
  }
}
