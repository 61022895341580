// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable } from 'rxjs';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class NvrAlarmsService {
  /**
   * Class constructor
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get items list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall('GET', 'nvr-alarms', params);
  }

    /**
   * Update item fields with given itemId with given data
   *
   * @since 1.0.0
   *
   * @param nvrAlarmId ID of item to update
   * @param data New item's data
   */
    updateFields(nvrAlarmId: string, data: object): Observable<any> {
      return this.apiService.apiCall('PATCH', `nvr-alarms/${nvrAlarmId}`, data);
    }
}
