// Core packages
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatHeaderRow } from '@angular/material/table';
import { DOCUMENT } from '@angular/common';

// Third party packages
import { fromEvent, merge, Subscription } from 'rxjs';
import { skip, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import moment from 'moment';

// Custom packages
import TableConfig, {
  TableFilter,
} from '../../interfaces/tableConfig.interface';
import { ConfigService } from '../../services/config.service';

/**
 * Script start
 */
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input() config!: TableConfig<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('input', { static: false }) input!: ElementRef;
  public tableHeaderRow!: Element;

  public generalSearch!: string;
  public displayedColumns: string[] = [];
  public selection = new SelectionModel<any>(true, []);
  public showFilters: boolean = false;
  private rows: any[] = [];
  private interval!: NodeJS.Timer;

  constructor(
    public configService: ConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) document: Document,
  ) {}

  get hasActions(): boolean {
    return this.config.hideActionsColumn !== true;
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // By default show general search
    if (this.config && typeof this.config?.generalSearch === 'undefined') {
      this.config.generalSearch = true;
    }

    // Calculate displayed cols
    if (this.config.selectable) {
      this.displayedColumns = [
        'select',
        ...this.config.columns
          .filter((col: any) => col.visible)
          .map((col: any) => col.key),
      ];
    } else {
      this.displayedColumns = [
        ...this.config.columns
          .filter((col) => col.visible)
          .map((col) => col.key as string),
      ];
    }
    if (this.hasActions) {
      this.displayedColumns.push('actions');
    }

    // If at least 1 col has a filter, than enable filters
    this.showFilters = this.config.columns.some((col) => col.filter);

    // BEGIN - Get filters by query string
    const initialParams = this.activatedRoute.snapshot.queryParams;
    if (
      Object.prototype.hasOwnProperty.call(initialParams, 'search[general]')
    ) {
      const generalSearch = initialParams['search[general]'];
      this.generalSearch = generalSearch;
    }

    // console.log('initialParams', initialParams);

    let index = 0;
    for (const col of this.config.columns) {
      const colKey = col.key?.toString();
      const paramKey = `search[${colKey}]`;
      if (Object.prototype.hasOwnProperty.call(initialParams, paramKey)) {
        const value = initialParams[paramKey];
        // console.log('filter', this.config.columns[index].filter);

        if (this.config.columns[index].filter?.type === 'select') {
          let parsedValue = value;
          if (parsedValue === 'true') {
            parsedValue = true;
          }
          if (parsedValue === 'false') {
            parsedValue = false;
          }
          (this.config.columns[index].filter as TableFilter).control.setValue(
            parsedValue,
          );
        } else if (this.config.columns[index].filter?.type === 'autocomplete') {
          // console.log('filter', this.config.columns[index].filter);
          this.config.columns[index].filter?.control.setValue(value);
        } else {
          (this.config.columns[index].filter as TableFilter).control.setValue(
            value,
          );
        }
      }
      index += 1;
    }
    // console.log('this.config.columns', this.config.columns);

    this.config.extraFilters?.forEach((extraFilter) => {
      const colKey = extraFilter.filterKey;
      const paramKey = `search[${colKey}]`;
      if (Object.prototype.hasOwnProperty.call(initialParams, paramKey)) {
        const value = initialParams[paramKey];
        console.log('value', value);
        extraFilter.control.setValue(value);
      }
    });

    // END - Get filters by query string

    // Handle refresh rate (auto-refresh)
    if (this.config.refreshRate) {
      this.interval = setInterval(() => {
        this.loadPage();
      }, this.config.refreshRate * 1000);
    }
  }

  /**
   * Invoked immediately after Angular has completed
   * initialization of a component's view.
   */
  ngAfterViewInit(): void {
    const headerRow = document.getElementById('table-header-row');
    // console.log('headerRow', headerRow);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry: any) => {
          const { isVisible } = entry;
          const delta = entry.boundingClientRect.top;
          // console.log('entry', entry);
          // console.log('delta', delta);
          // if (!isVisible && entry.isIntersecting) {
          //   headerRow?.setAttribute(
          //     'style',
          //     `transform: translate(0px, ${-delta}px)`,
          //   );
          //   // headerRow?.style.transform = `translate(0px, ${delta}px)`;
          // }
        });
      },
      { threshold: [0, 0.1, 0.9, 1] },
    );
    observer.observe(headerRow as Element);

    // BEGIN - Get filters by query string
    setTimeout(() => {
      const initialParams = this.activatedRoute.snapshot.queryParams;
      if (Object.prototype.hasOwnProperty.call(initialParams, 'pageIndex')) {
        const pageIndex = initialParams['pageIndex'];
        this.paginator.pageIndex = pageIndex;
      }
      if (Object.prototype.hasOwnProperty.call(initialParams, 'pageSize')) {
        const pageSize = initialParams['pageSize'];
        this.paginator.pageSize = pageSize;
      }

      // Load initial data
      this.loadPage();
    });
    // END - Get filters by query string

    // Reset pagination after sorting
    this.subscriptions.push(
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0)),
    );

    // Once user change sorting OR change page
    // let's reload the page data
    this.subscriptions.push(
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          tap((next: any) => {
            const { length, pageIndex, pageSize, previousPageIndex } = next;

            // BEGIN - Update query string
            const queryParams: Params = {
              pageIndex: pageIndex,
              pageSize: pageSize,
            };

            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: queryParams,
              queryParamsHandling: 'merge',
            });
            // END - Update query string

            this.loadPage();
          }),
        )
        .subscribe(),
    );

    // Subscribe to rows
    this.subscriptions.push(
      this.config.dataSource.connect().subscribe((res) => (this.rows = res)),
    );

    // Handle server-side filtering through general search
    if (this.input?.nativeElement) {
      this.subscriptions.push(
        fromEvent(this.input.nativeElement, 'keyup')
          .pipe(
            debounceTime(350), // max 1 query every 350 milliseconts
            distinctUntilChanged(), // Eliminate duplicate values
            tap((next: any) => {
              // BEGIN - Update query string
              const newVal = this.generalSearch;

              const queryParams: Params = {
                'search[general]': newVal,
                pageIndex: 0,
              };

              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge',
              });
              // END - Update query string

              this.paginator.pageIndex = 0; // Reset page
              this.loadPage();
            }),
          )
          .subscribe(),
      );
    }

    // Handle server-side filtering through column-specific search
    // Why do we use "null as string"? --> @see https://github.com/ReactiveX/rxjs/issues/4772
    this.config.columns.forEach((col) => {
      if (col.filter) {
        const subscription = col.filter.control.valueChanges
          .pipe(
            debounceTime(350), // max 1 query every 350 milliseconts
            distinctUntilChanged(), // Eliminate duplicate values
            tap((next) => {
              // BEGIN - Update query string
              const colKey = col.key?.toString();
              const newVal = next;
              const queryKey = `search[${colKey}]`;

              const queryParams: Params = {
                [queryKey]: newVal,
                pageIndex: 0,
              };

              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge',
              });
              // END - Update query string

              this.paginator.pageIndex = 0; // Reset page
              this.loadPage();
            }),
          )
          .subscribe();
        this.subscriptions.push(subscription);
      }
    });

    // Reset page once "refresh$" receive a new value
    this.subscriptions.push(
      this.config.refresh$.pipe(skip(1)).subscribe((val: boolean) => {
        this.loadPage();
      }),
    );

    // Reset filters once "reset$" receive a new value
    this.subscriptions.push(
      this.config.reset$.pipe(skip(1)).subscribe((val: boolean) => {
        this.onResetFilters();
      }),
    );

    // Handle server-side filtering through extraFilters
    if (this.config.extraFilters) {
      for (const extraFilter of this.config.extraFilters) {
        const subscription = extraFilter.control.valueChanges
          .pipe(
            debounceTime(350), // max 1 query every 350 milliseconts
            distinctUntilChanged(), // Eliminate duplicate values
            tap((next) => {
              // BEGIN - Update query string
              const colKey = extraFilter.filterKey;
              const newVal = next;
              const queryKey = `search[${colKey}]`;

              const queryParams: Params = {
                [queryKey]: newVal,
                pageIndex: 0,
              };

              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge',
              });
              // END - Update query string

              this.paginator.pageIndex = 0; // Reset page
              this.loadPage();
            }),
          )
          .subscribe();
        this.subscriptions.push(subscription);
      }
    }
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  /**
   * Load requested data page
   *
   * @since 1.0.0
   */
  loadPage(): void {
    const start = this.paginator.pageIndex * this.paginator.pageSize;
    const length = this.paginator.pageSize;

    // BEGIN - Handle search & filters
    let search: any = {};

    // Add default search
    if (this.config.defaultSearch) {
      search = { ...this.config.defaultSearch };
    }

    // Handle columns filters
    this.config.columns.forEach((col) => {
      const filterKey = col?.filter?.filterKey || col?.key;

      if (col.filter) {
        let val = col.filter.control.value;

        if (col.filter.dataType === 'date' && val && moment(val).isValid()) {
          search[filterKey] = moment(val).toISOString(); // .format('YYYY/MM/DD');
        } else if (val !== '' && val !== null) {
          search[filterKey] = val;
        } else if (typeof col.filter.defaultValue !== 'undefined') {
          search[filterKey] = col.filter.defaultValue;
        }
      }
    });

    // Handle general search
    if (this.generalSearch) {
      search.general = this.generalSearch;
    }

    // Handle extra filters
    this.config.extraFilters?.forEach((extraFilter) => {
      const filterKey = extraFilter.filterKey as string;
      let val = extraFilter.control.value;
      if (extraFilter.dataType === 'date' && val && moment(val).isValid()) {
        search[filterKey] = moment(val).toISOString(); // .format('YYYY/MM/DD');
      } else if (val !== '' && val !== null) {
        search[filterKey] = val;
      } else if (typeof extraFilter.defaultValue !== 'undefined') {
        search[filterKey] = extraFilter.defaultValue;
      }
    });
    // BEGIN - Handle search & filters

    this.config.dataSource.loadItems(
      start,
      length,
      this.sort.active,
      this.sort.direction,
      search,
    );
  }

  /**
   * Check whether the number of selected elements
   * matches the total number of rows.
   *
   * @since 1.0.0
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows?.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected;
   * otherwise clear selection.
   *
   * @since 1.0.0
   */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.rows.forEach((row) => this.selection.select(row));
  }

  /**
   * The label for the checkbox on the passed row
   *
   * @since 1.0.0
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  /**
   * Reset given filter value and reload the page
   *
   * @since 1.0.0
   */
  onResetFilter(filter: UntypedFormControl, emitChanges: boolean = true): void {
    filter.reset(null, {
      emitEvent: emitChanges,
    });
    if (emitChanges) {
      this.loadPage();
    }
  }

  /**
   * Reset table's filters
   *
   * @since 1.0.0
   */
  onResetFilters(): void {
    // Reset general filters
    if (this.generalSearch) {
      this.generalSearch = '';
    }

    // Reset column filters
    this.config.columns.forEach((col) => {
      if (col.filter) {
        this.onResetFilter(col.filter.control, false);
      }
    });

    // Reset extra filters
    this.config.extraFilters?.forEach((extraFilter) => {
      this.onResetFilter(extraFilter.control, false);
    });

    // Reset tables' sorting versus and column
    this.sort.direction = 'desc';
    this.sort.active = 'createdAt';
    this.sort._stateChanges.next();

    // Reset paginator
    this.paginator.pageIndex = 0;
    this.paginator.pageSize =
      this.config.pageSize || this.configService.defaultPageSize;

    // BEGIN - Update query string
    const queryParams: Params = {
      pageIndex: 0,
      pageSize: this.configService.defaultPageSize,
      'search[general]': undefined,
    };
    this.config.columns.forEach((col) => {
      if (col.filter) {
        const key = `search[${col.key?.toString()}]`;
        queryParams[key] = undefined;
      }
    });
    this.config.extraFilters?.forEach((extraFilter) => {
      const key = `search[${extraFilter.filterKey?.toString()}]`;
      queryParams[key] = undefined;
    });

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
    // END - Update query string

    // Reload table's data
    this.loadPage();
  }

  /**
   * Reset general search input and dispath a 'keyup' event
   * so that the table re-render his rows
   *
   * @since 1.0.0
   */
  resetInput(): void {
    this.generalSearch = '';

    // BEGIN - Update query string
    const newVal = this.generalSearch;

    const queryParams: Params = {
      'search[general]': '',
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
    // END - Update query string

    const event = new KeyboardEvent('keyup');
    this.input.nativeElement.dispatchEvent(event);
  }
}
