// Core packages
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

// Third party packages
import { FieldType } from '@ngx-formly/material';

// Custom packages

/**
 * Script start
 */

@Component({
  selector: 'app-formly-password-type',
  template: `
    <div class="inputWrapper d-flex">
      <input
        matInput
        #passwordField
        [id]="id"
        [type]="passwordFieldType"
        [readonly]="to.readonly"
        [(ngModel)]="this.value"
        [formlyAttributes]="field"
        [tabindex]="to.tabindex || 0"
        [placeholder]="to.label || ''"
      />

      <mat-icon
        matSuffix
        class="toggle-password"
        fontSet="fal"
        [fontIcon]="
          passwordFieldType === 'password' ? 'fa-eye' : 'fa-eye-slash'
        "
        (click)="showHidePassword()"
      ></mat-icon>
    </div>
  `,
  styles: ['.toggle-password { color: #ccc; cursor: pointer; }'],
})
export class MaterialPasswordTypeComponent
  extends FieldType
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('passwordField', { static: false })
  passwordField!: ElementRef<HTMLInputElement>;
  passwordFieldType: string = 'password';

  public showHidePassword() {
    if (this.passwordFieldType === 'text') {
      this.passwordFieldType = 'password';
    } else {
      this.passwordFieldType = 'text';
    }
    // this.passwordField.nativeElement.type =
    //   this.passwordField.nativeElement.type == 'password' ? 'text' : 'password';
  }

  constructor() {
    super();
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * Handle afterViewInit of this component
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Handle ngOnDestroy of this component
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
