<mat-card class="mat-elevation-z shadow">
  <div class="card-header">
    <div class="col-12 col-md-9 d-flex align-items-center">
      <mat-card-title>
        <h1>Cambia password</h1>
      </mat-card-title>
    </div> <!-- /.col -->
    <div class="col-12 col-md-3 d-flex align-items-center">
    </div> <!-- /.col-12 -->
  </div> <!-- /.row -->
  <mat-card-content class="pt-0">
    <div class="form-wrapper mt-3">
      <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
        <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
        <div class="row mt-3">
          <div class="col-md-6">
            <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate">
            </mat-spinner-button>
          </div> <!-- /.col -->
          <div class="col-md-6 mt-5 mt-md-0 text-right">
          </div> <!-- /.col -->
        </div> <!-- /.row -->
      </form>
    </div> <!-- /.form-wrapper -->
  </mat-card-content>
</mat-card>
