// Core packages
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';

// Third party packages
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';

// Custom packages
import { FileService } from 'src/app/shared/services/file.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { PlantFilesService } from 'src/app/modules/plants/plantFiles.service';
import Response from 'src/app/shared/interfaces/response.interface';
import ISite from 'src/app/shared/models/site/site.interface';
import File from 'src/app/shared/interfaces/file.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-add-file-ext-dialog',
  templateUrl: './add-file-ext-dialog.component.html',
  styleUrls: ['./add-file-ext-dialog.component.scss'],
})
export class AddFileExtDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public messageOnly = false;
  public allowedFilesNumber: number = 1;
  private defaultAllowedFileExtentions = [
    'jpg',
    'jpeg',
    'png',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'csv',
    'ppt',
    'pptx',
    'webp',
  ];
  public allowedFilesExtensions: string =
    this.defaultAllowedFileExtentions.join(',');
  public fileType: string = 'Image';
  public titleDialog: string = '';
  public action: string = 'add';

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Conferma',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  // END - Form

  constructor(
    public dialogRef: MatDialogRef<AddFileExtDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      plantFileId: string;
      action: string;
      plantId: string;
      siteId?: string;
      allowedFilesExtensions?: string;
      fileType?: string,
      sameSite?: boolean;
      sites?: any[];
      title?: string;
      description?: string;
      file?: File;
    },
    public fileService: FileService,
    public helperService: HelperService,
    public toastrService: ToastrService,
    public plantFilesService: PlantFilesService,
  ) {
    dialogRef.disableClose = true;
    if (this.data.allowedFilesExtensions) {
      this.allowedFilesExtensions = this.data.allowedFilesExtensions;
    }
    if (this.data.fileType) {
      this.fileType = this.data.fileType;
    }
    if (this.data.action === 'show') {
      this.action = 'show';
      if (this.fileType === 'Image') {
        this.titleDialog = 'Foto';
      } else {
        this.titleDialog = 'Documento';
      }
    } else {
      if (this.fileType === 'Image') {
        this.titleDialog = 'Inserisci foto';
      } else {
        this.titleDialog = 'Inserisci documento';
      }
    }
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    // Init form
    this.initForm();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }
  
  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {

    const disabled = this.action ==='show' ? true : false;
    const disabledSites = this.action ==='show' || this.data.sameSite ? true : false;
    const siteId = this.data.sites && this.data.sites.length ? this.data.sites[0].value : '';

    // Set model
    this.model = {};
    if (
      this.data.title &&
      this.data.file
    ) {
      this.model = {
        siteId: this.data.siteId,
        title: this.data.title,
        description: this.data.description,
        file: this.data.file,
      };
    }

    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: "plantId",
            defaultValue: this.data.plantId,
            templateOptions: {
              type: "hidden",
              required: true,
            },
          },
          {
            key: "fileType",
            defaultValue: this.fileType,
            templateOptions: {
              type: "hidden",
              required: true,
            },
          },
          {
            className: 'col-12',
            type: 'file-uploader-ext',
            key: `file`,
            templateOptions: {
              placeholder: 'Inserisci qui...',
              required: true,
              type: 'string',
              allowedFilesNumber: this.allowedFilesNumber,
              allowedFilesExtensions: this.allowedFilesExtensions,
              action: this.action,
              disabled
            },
          },
          {
            className: 'col-12',
            template:
              `<strong class="d-block mt-4">
                Informazioni
              </strong>`,
          },
          {
            className: 'col-12',
            type: 'input',
            key: `title`,
            templateOptions: {
              required: true,
              label: 'Titolo',
              placeholder: 'Titolo',
              type: 'string',
              // disabled
            },
          },
          {
            className: 'col-12',
            type: 'textarea',
            key: `description`,
            templateOptions: {
              label: 'Descrizione',
              placeholder: 'Descrizione',
              required: false,
              type: 'string',
              rows: 5,
              // disabled
            },
          },
          {
            className: 'col-12',
            template:
              `<strong class="d-block mt-4">
                Associazione
              </strong>`,
          },
          {
            className: 'col-12',
            type: 'select',
            key: `siteId`,
            defaultValue: this.data.sameSite ? siteId : '',
            templateOptions: {
              label: 'Seleziona Sito',
              placeholder: 'Seleziona Sito',
              required: false,
              type: 'string',
              multiple: false,
              options: this.data.sites,
              disabled: disabledSites,
            },
          },
        ],
      },
    ];
  }

  
  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(model: any): void {
    // Prevent invalid form submit
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    // Prevent double submit caused by double btn click
    if (this.formBtnOptions.active) {
      return;
    }

    // Activate submit button loading status
    this.formBtnOptions = { ...this.formBtnOptions, active: true };

    // Clear data
    if (typeof model.customerId === 'object') {
      model.customerId = model.customerId._id;
    }
    if (model.siteId === '') {
      delete model.siteId;
    }

    if (this.action === 'add') {
      // Send data to BE
      this.subscriptions.push(
        this.plantFilesService
          .create(model)
          .pipe(
            finalize(
              () =>
                (this.formBtnOptions = { ...this.formBtnOptions, active: false }),
            ),
          )
          .subscribe(
            (res: Response) => {
              if (!res.status) {
                const title = 'Errore';
                let message =
                  res.message ||
                  "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                this.toastrService.error(message, title);
                return;
              }

              this.toastrService.success(res.message);
              this.dialogRef.close({
                status: true,
                data: res.data
              });
            },
            (err: any) => {
              // Choose one of the following error handling method.
              // 1) First one show a message right under the form fields
              //    (if the form is properly setted)
              // 2) Second one is used to show a mat-error under each field
              //    in the dynamic form (is meant to be used only in dynamicForm)
              // 3) Third one show toastr notifications for each error
              // 4) Fourth one is used to show mat-error under each field
              //    in the ngx-formly form (is mean to be used only with ngx-formly)
              // this.helperService.handleFormError(form, err);
              // this.helperService.handleDynamicFormError(form, err);
              // this.helperService.handleError(err);
              this.helperService.handleFormlyError(this.form, err);
            },
          ),
      );
    } else if (this.action === 'show') {
      const sendData = {
        title: model.title,
        description: model.description
      };
      // Send data to BE
      this.subscriptions.push(
        this.plantFilesService
          .updateFields(this.data.plantFileId,sendData)
          .pipe(
            finalize(
              () =>
                (this.formBtnOptions = { ...this.formBtnOptions, active: false }),
            ),
          )
          .subscribe(
            (res: Response) => {
              if (!res.status) {
                const title = 'Errore';
                let message =
                  res.message ||
                  "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                this.toastrService.error(message, title);
                return;
              }

              this.toastrService.success(res.message);
              this.dialogRef.close({
                status: true,
                data: res.data
              });
            },
            (err: any) => {
              // Choose one of the following error handling method.
              // 1) First one show a message right under the form fields
              //    (if the form is properly setted)
              // 2) Second one is used to show a mat-error under each field
              //    in the dynamic form (is meant to be used only in dynamicForm)
              // 3) Third one show toastr notifications for each error
              // 4) Fourth one is used to show mat-error under each field
              //    in the ngx-formly form (is mean to be used only with ngx-formly)
              // this.helperService.handleFormError(form, err);
              // this.helperService.handleDynamicFormError(form, err);
              // this.helperService.handleError(err);
              this.helperService.handleFormlyError(this.form, err);
            },
          ),
      );
    }
  }
}
