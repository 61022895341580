<!-- <h1 mat-dialog-title>Elimina cliente</h1> -->

<div class="close-button" (click)="onDenial()">
  <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
</div>

<!-- <button mat-fab color="primary" class="close-button" (click)="onDenial()">
  <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
</button> -->

<div mat-dialog-title class="text-center" style="border-bottom: 1px solid #ddd;">
  <h3 style="color:#CD4C4C">
    <mat-icon fontSet="fal" fontIcon="fa-ban"></mat-icon>
    Elimina Cliente
  </h3>
</div>

<mat-dialog-content>
  <mat-card class="mat-elevation-z-2 text-center mb-3" style="font-weight: bold;">
    <mat-card-content class="p-0">
      <strong>ATTENZIONE!</strong><br>
      {{data.message}}
    </mat-card-content>
  </mat-card>
</mat-dialog-content> <!-- mat-dialog-content -->

<!-- <mat-dialog-actions align="end">
  <button role="button" mat-raised-button color="primary" (click)="onConfirmation()">
    <mat-icon fontSet="fal" fontIcon="fa-check"></mat-icon>
    Ho capito
  </button>
</mat-dialog-actions> -->
