// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class PlantFilesService {
  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
  ) {}

  /**
   * Create a new plantFile
   *
   * @since 1.0.0
   *
   * @param plantFile Plant file data
   * @returns Observable<any>
   */
  create(plantFile: any): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      'plant-files',
      plantFile,
    );
  }

  /**
   * Get plantFiles list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    plantId: string,
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall(
      'GET',
      `plant-files/${plantId}/list`,
      params,
    );
  }

  /**
   * Get plantFiles list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getSiteList(
    plantId: string,
    siteId: string,
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall(
      'GET',
      `plant-files/${plantId}/list/${siteId}`,
      params,
    );
  }
  /**
   * Get given plantFileId data from back-end
   *
   * @since 1.0.0
   *
   * @param plantFileId ID of the plantFile to get
   */
  get(plantFileId: string): Observable<any> {
    return this.apiService
      .apiCall('GET', `plant-files/${plantFileId}`)
      .pipe(
        catchError((err) => {
          this.helperService.handleError(err);
          return throwError(err);
        }),
      );
  }

  /**
   * Update plantFile with given plantFileId with given data
   *
   * @since 1.0.0
   *
   * @param plantFileId ID of plantFile to update
   * @param data New plantFile's data
   */
  update(plantFileId: string, data: object): Observable<any> {
    return this.apiService.apiCall(
      'PUT',
      `plant-files/${plantFileId}`,
      data,
    );
  }

  /**
   * Update plantFile fields with given plantFileId with given data
   *
   * @since 1.0.0
   *
   * @param plantFileId ID of plantFile to update
   * @param data New plantFile's data
   */
  updateFields(plantFileId: string, data: object): Observable<any> {
    return this.apiService.apiCall(
      'PATCH',
      `plant-files/${plantFileId}`,
      data,
    );
  }

  /**
   * Delete permanently an plantFile
   *
   * @since 1.0.0
   *
   * @param plantFileId ID of the plantFile
   */
  delete(plantFileId: string): Observable<any> {
    return this.apiService.apiCall(
      'DELETE',
      `plant-files/${plantFileId}`,
    );
  }
  
  /**
   * Delete permanently all plantFiles of a plant
   *
   * @since 1.0.0
   *
   * @param plantId ID of the plant
   */
  deleteAll(plantId: string): Observable<any> {
    return this.apiService.apiCall('DELETE', `plant-files/${plantId}/all`);
  }

}
