// Core packages
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';

// Third party packages
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

// Custom packages
import { AuthService } from 'src/app/modules/auth/auth.service';
import IUser from '../models/user/user.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class SectionGuard implements CanActivate {
  private loggedUser: IUser | undefined;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    this.loggedUser = this.authService.loggedUser$?.value;
    if (!this.loggedUser) {
      console.warn(
        'loggedUser is undefined',
        this.authService.loggedUser$?.value,
      );
      return false;
    }
    const { sections, role } = this.loggedUser as IUser;

    // Sections guard only affect some roles
    const rolesWithLimitations = ['customer'];
    if (!rolesWithLimitations.includes(role)) {
      return true;
    }

    const { requiredSections } = route.data;

    // Not sections means all sections
    if (!sections) {
      return true;
    }

    const routeIsPermitted = requiredSections.every((requiredSection: string) =>
      sections.includes(requiredSection),
    );
    if (!routeIsPermitted && !route.url.length) {
      this.router.navigate(['dashboard']);
    } else if (!routeIsPermitted) {
      this.toastrService.error(
        "Contatta l'amministratore del sistema per farti abilitare",
        'Non puoi accedere',
      );
      return false;
    }

    return true;
  }
}
