<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav mode="side" opened class="app-sidenav app-sidenav-dark" [fixedInViewport]="options.fixed"
    [fixedTopGap]="options.topGap" [fixedBottomGap]="options.bottomGap" [(opened)]="options.defaultOpen">
    <mat-nav-list>
      <a class="logoLink" routerLink="/">
        <img src="/assets/img/logo/logo2.svg" alt="{{ configService.settings.owner?.nicename }}" width="170" />
      </a>

      <ng-container *ngIf="currentRole === 'customer' && authService.loggedUser$?.value?.profile !== 'light'">
        <button class="customer-map-btn" (click)="onViewMap()">
          <mat-icon fontSet="fal" fontIcon="fa-location-dot"></mat-icon>
          Visualizza mappa
        </button>
      </ng-container>
      <!-- <a mat-list-item routerLink="/{{ item.route }}" [ngClass]="{ active: activeRoute === '/' }">
        <div class="item-content">
          <mat-icon aria-hidden="false" *ngIf="item.icon" fontSet="fal" fontIcon="{{ item.icon }}"></mat-icon>
          {{ item.name }}
        </div>
      </a> -->

      <ng-container *ngFor="let item of navItems">
        <ng-container *ngIf="!item.hidden">
          <a mat-list-item *ngIf="!item.items && item.route === '' && item.permittedRoles.includes(currentRole)"
            routerLink="/{{ item.route }}" [ngClass]="{ active: activeRoute === '/' }">
            <div class="item-content">
              <mat-icon aria-hidden="false" *ngIf="item.icon" fontSet="fal" fontIcon="{{ item.icon }}"></mat-icon>
              {{ item.name }}
            </div>
          </a>

          <a mat-list-item
            *ngIf="!item.items && item.route && item.route !== '' && item.permittedRoles.includes(currentRole)"
            routerLink="{{ item.route }}" [ngClass]="{ active: routeIsActive(item) }">
            <div class="item-content">
              <mat-icon aria-hidden="false" *ngIf="item.icon" fontSet="fal" fontIcon="{{ item.icon }}"></mat-icon>
              {{ item.name }}
              <ng-container *ngIf="item?.badge?.value">
                <div class="item-badge" [style]="item?.badge?.style">
                  {{ item?.badge?.value }}
                </div>
              </ng-container>
            </div>
          </a>

          <div *ngIf="!item.items && !item.route && item.permittedRoles.includes(currentRole)" class="divider">
            <div class="item-content">
              <mat-icon aria-hidden="false" *ngIf="item.icon" fontSet="fal" fontIcon="{{ item.icon }}"></mat-icon>
              <span class="item-content-title">{{ item.name }}</span>
            </div>
          </div>

          <mat-accordion *ngIf="item.items && item.permittedRoles.includes(currentRole)">
            <mat-expansion-panel class="mat-elevation-z shadow"
              [ngClass]="{'active': activeRoute.includes(item.route || '')}"
              [expanded]="activeRoute.includes(item.route || '')" (closed)="(false)">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  <div class="item-content">
                    <mat-icon aria-hidden="false" *ngIf="item.icon" fontSet="fal" fontIcon="{{ item.icon }}"></mat-icon>
                    {{ item.name }}
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list>
                <ng-container *ngFor="let subItem of item.items">
                  <a mat-list-item routerLink="/{{ subItem.route }}" [routerLinkActive]="'active'">
                    <div class="item-content">
                      <mat-icon aria-hidden="false" *ngIf="subItem.icon" fontSet="fal" fontIcon="{{ subItem.icon }}">
                      </mat-icon>
                      {{ subItem.name }}
                    </div>
                  </a>
                </ng-container>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="content-wrapper">
      <app-header></app-header>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="loader-wrapper" *ngIf="appLoading">
  <div class="loader">
    <mat-spinner></mat-spinner>
    <p class="mt-4 text-center">Caricamento...</p>
  </div> <!-- /.loader -->
</div> <!-- /.loader-wrapper -->
