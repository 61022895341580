// Core packages
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Third party packages
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subscription, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

// Custom packages
import { ApiLoaderService } from 'src/app/apiLoader.service';

/**
 * Script start
 */
@Component({
  selector: 'app-address-finder-dialog',
  templateUrl: './address-finder-dialog.component.html',
  styleUrls: ['./address-finder-dialog.component.scss'],
})
export class AddressFinderDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @ViewChild('input', { static: false }) input!: ElementRef;
  @ViewChild('placesRef', { static: false }) placesRef!: GooglePlaceDirective;

  // BEGIN - Maps data
  @ViewChild('map') mapElement: any;
  public center: google.maps.LatLngLiteral = {
    lat: 45.46416185378709,
    lng: 9.191627740859992,
  };
  public apiLoaded!: Observable<boolean>;
  public map!: google.maps.Map;
  public openWindow!: google.maps.InfoWindow | null;
  public markers: any;
  public mapOptions: google.maps.MapOptions = {
    zoom: 10,
    minZoom: 7,
    center: this.center,
    draggableCursor: 'default',
    draggingCursor: 'pointer',
    streetViewControl: true,
    mapTypeControl: false,
    scaleControl: true,
  };
  public marker!: google.maps.Marker;
  public elevationService!: google.maps.ElevationService;
  // END - Maps data
  public withElevation: boolean = false;
  public currentCoords!: {
    lat: number;
    lng: number;
    ele?: number;
    alt?: number;
  };

  constructor(
    public dialogRef: MatDialogRef<AddressFinderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiLoaderService: ApiLoaderService,
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * Init component
   *
   * @since 1.1.0
   */
  ngOnInit() {
    if (this.data.center) {
      if (this.data.center.lat && this.data.center.lng) {
        this.center = this.data.center;
        this.mapOptions.center = this.center;
      }
    }

    if (this.data.withElevation) {
      this.withElevation = true;
    }

    this.apiLoaded = this.apiLoaderService.loadApi();
    this.apiLoaded.pipe(delay(10)).subscribe(() => {
      this.map = new google.maps.Map(
        this.mapElement.nativeElement,
        this.mapOptions,
      );

      // BEGIN - Add "Posizione corrente"
      let infoWindow = new google.maps.InfoWindow();
      const locationButton = document.createElement('button');
      locationButton.textContent = 'Posizione corrente';
      locationButton.classList.add('custom-map-control-button');
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
        locationButton,
      );
      locationButton.addEventListener('click', () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              infoWindow.setPosition(pos);
              infoWindow.setContent('Location found.');
              infoWindow.open(this.map);
              this.map.setCenter(pos);
            },
            () => {
              this.handleLocationError(true, infoWindow, this.map.getCenter()!);
            },
          );
        } else {
          // Browser doesn't support Geolocation
          this.handleLocationError(false, infoWindow, this.map.getCenter()!);
        }
      });
      // END - Add "Posizione corrente"

      this.marker = new google.maps.Marker({
        position: this.center,
        map: this.map,
        draggable: true,
      });

      this.elevationService = new google.maps.ElevationService();

      google.maps.event.addListener(this.marker, 'dragend', (marker: any) => {
        // BEGIN - Get coordinates
        const latLng = marker.latLng;
        const currentLatitude = latLng.lat();
        const currentLongitude = latLng.lng();
        const newCoords = {
          lat: currentLatitude,
          lng: currentLongitude,
        };
        this.currentCoords = newCoords;
        // END - Get coordinates

        // BEGIN - Get elevation
        if (this.withElevation) {
          const requestElevation: google.maps.LocationElevationRequest = {
            locations: [this.marker.getPosition() as google.maps.LatLng],
          };

          this.elevationService.getElevationForLocations(
            requestElevation,
            (
              results: google.maps.ElevationResult[] | null,
              status: google.maps.ElevationStatus,
            ) => {
              console.log('status', status);
              console.log('results', results);
              if (status == google.maps.ElevationStatus.OK) {
                // if (results[0]) {
                //   document.getElementById('denivele_circuit').value =
                //     parseFloat(results[0].elevation.toFixed(1)) + ' mètres';
                // }
              }
            },
          );
        }
        // BEGIN - Get elevation
      });
    });
  }

  /**
   * Handle component destroy
   *
   * @since 1.1.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.1.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }

  /**
   * Handle "confirm" btn
   *
   * @since 1.1.0
   */
  onConfirm(): void {
    this.dialogRef.close(this.currentCoords);
  }

  /**
   * Reset general search input and dispath a 'keyup' event
   * so that the table re-render his rows
   *
   * @since 1.1.0
   */
  resetInput(): void {
    this.input.nativeElement.value = '';
    const event = new KeyboardEvent('keyup');
    this.input.nativeElement.dispatchEvent(event);
  }

  handleLocationError(
    browserHasGeolocation: boolean,
    infoWindow: google.maps.InfoWindow,
    pos: google.maps.LatLng,
  ) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? 'Error: The Geolocation service failed.'
        : "Error: Your browser doesn't support geolocation.",
    );
    infoWindow.open(this.map);
  }

  public handleAddressChange(address: Address) {
    console.log('address', address);
    if (!address.geometry) {
      return;
    }
    const coordinates = {
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
    };
    if (coordinates.lat && coordinates.lng) {
      this.map.setCenter(coordinates);
      this.map.setZoom(16);
      this.marker.setPosition(coordinates);
    }
    this.currentCoords = coordinates;
  }
}
