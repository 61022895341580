<app-page>
  <div class="container-fluid">

    <div class="row align-items-center justify-content-between">
      <div class="col-12 col-md-4">
        <app-stat-card [value]="totalTranslations" descriptor="Traduzioni" icon="fa-pen-alt"
          background-color="transparent">
        </app-stat-card>
      </div> <!-- /.col -->
      <div class="col-12 col-md-4 col-xxl-3 d-flex justify-content-end"
        *ngIf="authService.loggedUser$?.value?.role === 'admin'">
        <button mat-raised-button color="primary" (click)="onAddTranslation()">
          Aggiungi nuovo
        </button>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div class="row mt-3 mt-sm-0">
      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <mat-card-content class="p-0">
            <app-table [config]="tableConfig"></app-table>
          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </div> <!-- /.container -->
</app-page>
