// Core packages
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Third party packages
import { Subscription, BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { finalize, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

// Custom packages
import TableConfig from 'src/app/shared/interfaces/tableConfig.interface';
import { HelperService } from 'src/app/shared/services/helper.service';
import { UsersService } from '../../users.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { TableDataSource } from 'src/app/shared/components/table/table.dataSource';
import IUser from 'src/app/shared/models/user/user.interface';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { PageService } from 'src/app/shared/services/loading.service';
import Response from 'src/app/shared/interfaces/response.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.page.html',
  styleUrls: ['./users-list.page.scss'],
})
export class UsersListComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public tableConfig!: TableConfig<IUser>;
  public totalUsers: string = '0';

  constructor(
    private userService: UsersService,
    private helperService: HelperService,
    private configService: ConfigService,
    private router: Router,
    private dialog: MatDialog,
    private pageService: PageService,
    private toastrService: ToastrService,
  ) {
    this.initTable();
    this.getCounters();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Get counters for current page stat cards
   *
   * @since 1.0.0
   */
  async getCounters(): Promise<void> {
    try {
      this.userService
        .getList(0, 1, 'createdAt', 'desc')
        .subscribe((res: ListApiResponse) => {
          if (res.status) {
            this.totalUsers = res.totalCount.toString();
          }
        });
    } catch (error) {
      console.warn('Error in getCounters(): ', error);
    }
  }

  /**
   * Handle deactivation
   *
   * @since 1.1.0
   */
  onDeactivate(user: IUser): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message: "Procedendo disattiverai l'utente e non potrà più accedere.",
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }

        // Start loading
        this.pageService.loading$.next(true);

        // Ok, so user really want to delete.
        // Let's do it!
        this.subscriptions.push(
          this.userService
            .updateFields(user._id, {
              active: false,
            })

            .pipe(finalize(() => this.pageService.loading$.next(false)))
            .subscribe(
              (res: Response) => {
                if (!res.status) {
                  const title = 'Errore';
                  let message =
                    res.message ||
                    "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                  this.toastrService.error(message, title);
                  return;
                }

                this.toastrService.success(res.message);
                this.refresh();
              },
              (err: any) => {
                // Choose one of the following error handling
                // method. The first one shows a message right
                // under the form fields (if the form is properly
                // setted), the second one shows toastr
                // notifications for each error
                this.helperService.handleError(err);
              },
            ),
        );
      });
  }

  /**
   * Handle activation
   *
   * @since 1.1.0
   */
  onActivate(user: IUser): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message: "Procedendo attiverai l'utente e potrà accedere.",
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }

        // Start loading
        this.pageService.loading$.next(true);

        // Ok, so user really want to delete.
        // Let's do it!
        this.subscriptions.push(
          this.userService
            .updateFields(user._id, {
              active: true,
            })

            .pipe(finalize(() => this.pageService.loading$.next(false)))
            .subscribe(
              (res: Response) => {
                if (!res.status) {
                  const title = 'Errore';
                  let message =
                    res.message ||
                    "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                  this.toastrService.error(message, title);
                  return;
                }

                this.toastrService.success(res.message);
                this.refresh();
              },
              (err: any) => {
                // Choose one of the following error handling
                // method. The first one shows a message right
                // under the form fields (if the form is properly
                // setted), the second one shows toastr
                // notifications for each error
                this.helperService.handleError(err);
              },
            ),
        );
      });
  }

  /**
   * Handle delete
   *
   * @since 1.1.0
   */
  onDelete(user: IUser): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message:
          "Procedendo elimineari e tutti i suoi dati. L'operazione è irreversibile.",
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }

        // Start loading
        this.pageService.loading$.next(true);

        // Ok, so user really want to delete.
        // Let's do it!
        this.subscriptions.push(
          this.userService
            .delete(user._id)
            .pipe(finalize(() => this.pageService.loading$.next(false)))
            .subscribe(
              (res: Response) => {
                if (!res.status) {
                  const title = 'Errore';
                  let message =
                    res.message ||
                    "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                  this.toastrService.error(message, title);
                  return;
                }

                this.toastrService.success(res.message);
                this.refresh();
              },
              (err: any) => {
                // Choose one of the following error handling
                // method. The first one shows a message right
                // under the form fields (if the form is properly
                // setted), the second one shows toastr
                // notifications for each error
                this.helperService.handleError(err);
              },
            ),
        );
      });
  }

  /**
   * Init table
   *
   * @since 1.0.0
   */
  initTable(): void {
    this.tableConfig = {
      dataSource: new TableDataSource(this.userService, this.helperService),
      selectable: false,
      columns: [
        {
          key: 'fullName',
          label: 'Nome',
          sortable: true,
          visible: true,
          onClick: (row: any, colKey: string) => {
            this.router.navigate(['/', 'users', row._id]);
          },
          render: (row: any, colKey: string): string => {
            const colVal = row[colKey];
            if (colVal) {
              return `<u>${colVal}</u>`;
            }
            return '';
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          visible: true,
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        {
          key: 'role',
          label: 'Ruolo',
          sortable: false, // NB: it's a nonsense sorting by this field since we are using a key (instead of the label) inside the database
          visible: true,
          render: (row: IUser, key: keyof IUser): string | undefined => {
            const colVal = row[key];
            const foundItem =
              this.configService.settings?.modules?.users?.rolesOptions?.find(
                (el: { value: string; label: string }) => el.value === colVal,
              );
            if (foundItem) {
              return foundItem.label;
            }

            return colVal?.toString();
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'select',
            dataType: 'string',
            label: 'Seleziona',
            items:
              this.configService.settings?.modules?.users?.rolesOptions || [],
          },
        },
        {
          key: 'profile',
          label: 'Profilo',
          sortable: false, // NB: it's a nonsense sorting by this field since we are using a key (instead of the label) inside the database
          visible: true,
          render: (row: IUser, key: keyof IUser): string | undefined => {
            const colVal = row[key];
            const foundItem =
              this.configService.settings?.modules?.users?.profilesOptions?.find(
                (el: { value: string; label: string }) => el.value === colVal,
              );
            if (foundItem) {
              return foundItem.label;
            }

            return colVal?.toString();
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'select',
            dataType: 'string',
            label: 'Seleziona',
            items:
              this.configService.settings?.modules?.users?.profilesOptions ||
              [],
          },
        },
        {
          key: 'expiryDate',
          label: 'Scadenza',
          sortable: true,
          visible: true,
          render: (row: IUser, key: keyof IUser): string | undefined => {
            const colVal = row[key] as string;
            if (row.profile !== 'demo') {
              return;
            }
            const dateIsValid = moment(colVal).isValid();
            if (dateIsValid && colVal) {
              const days = moment(colVal).diff(moment(), 'days');
              if (days < 8) {
                return `<span class="text-danger">${days} giorni</span>`;
              }
              return `${days} giorni`;
            }
            return colVal;
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'datepicker',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        // {
        //   key: 'aggregationId.name',
        //   label: 'Aggregazione',
        //   sortable: false,
        //   visible: true,
        //   render: (row: IUser, key: keyof IUser): string | undefined => {
        //     const colVal = row?.aggregationId?.name;
        //     if (colVal) {
        //       return colVal;
        //     }
        //     return '';
        //   },
        //   filter: {
        //     control: new UntypedFormControl(''),
        //     type: 'input',
        //     dataType: 'string',
        //     label: 'Cerca...',
        //   },
        // },
      ],
      sort: 'fullName',
      sortVersus: 'asc',
      refresh$: new BehaviorSubject<boolean>(false),
      reset$: new BehaviorSubject<boolean>(false),
      options: [
        {
          title: 'Cancella filtri',
          name: '',
          icon: 'fa-filter-circle-xmark',
          callback: () => {
            this.tableConfig.reset$.next(!this.tableConfig.reset$.value);
          },
        },
        {
          title: 'Ricarica',
          name: '',
          icon: 'fa-sync',
          callback: () => {
            this.tableConfig.refresh$.next(!this.tableConfig.refresh$.value);
          },
        },
      ],
      customActionColumn: [
        {
          button: {
            iconFontSet: 'fa',
            icon: 'fa-ellipsis-v',
          },
          options: [
            {
              text: 'Disattiva',
              action: (row: IUser) => {
                this.onDeactivate(row);
              },
              hidden: (row: IUser) => !row.active,
            },
            {
              text: 'Attiva',
              action: (row: IUser) => {
                this.onActivate(row);
              },
              hidden: (row: IUser) => row.active,
            },
            // {
            //   text: 'Elimina',
            //   action: (row: IUser) => {
            //     this.onDelete(row);
            //   },
            // },
          ],
        },
      ],
    };
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {}

  /**
   * Refresh table
   *
   * @since 1.0.0
   */
  refresh(): void {
    this.tableConfig.refresh$.next(!this.tableConfig.refresh$.value);
  }

  onAddUser(): void {
    this.router.navigate(['/', 'users', 'add']);
  }
}
