// Core packages
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';

// Third party packages
import { Observable } from 'rxjs';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';

// Custom packages
import { AuthService } from 'src/app/modules/auth/auth.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import IUser from '../models/user/user.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private loggedUser: IUser | undefined;
  private sessionExpiresAt!: number;

  constructor(
    private router: Router,
    private authService: AuthService,
    private helperService: HelperService,
    private cookieService: CookieService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    this.loggedUser = this.authService.loggedUser$?.value;
    this.sessionExpiresAt = +this.cookieService.get('s.expiresAt'); // this.authService.sessionExpiresAt$.value;

    // Check if token is expired
    if (
      this.loggedUser &&
      this.loggedUser._id &&
      moment.unix(moment.now()) > moment.unix(this.sessionExpiresAt)
    ) {
      console.warn('Token has expired');
      // Users seems to be logged, but his token is expired.
      // Log him out (remove data from cookie) and return false
      this.authService.logout(false);
      return false;
    }

    // Check if user is already logged in authService
    if (this.loggedUser && this.loggedUser._id) {
      // User is logged and his token is not expired. Let's return true.
      return true;
    }

    // Check if users is logged with cookie
    // (if we came here: (i) user is logged but requested
    // brand new page through page refresh, (ii) user is
    // not logged in)
    return new Observable((observer) => {
      this.authService.checkLogin().subscribe(
        (res: any) => {
          if (res instanceof Error || !res.status) {
            // If back-end is down we recive an error!
            this.helperService.handleError(res);

            // this.authService.logout();
            this.router.navigate(['auth', 'login'], {
              queryParams: { returnUrl: state.url },
            });
            return observer.next(false);
          }
          // User logged,
          observer.next(true);
        },
        (err: any) => {
          console.warn('err', err);
          // User is not logged in, redirect him
          this.authService.logout();
          this.router.navigate(['auth', 'login'], {
            queryParams: { returnUrl: state.url },
          });
          observer.next(false);
        },
      );
    });
  }
}
