<h1 mat-dialog-title>Seleziona il periodo di riferimento</h1>

<mat-dialog-content>
  <div class="form-wrapper mt-3 mb-3">
    <form [formGroup]="form" (ngSubmit)="onFormSubmit(model)" class="">
      <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options" class="d-block mb-3">
      </formly-form>
      <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate mr-3" (click)="onFormSubmit()">
      </mat-spinner-button>
      <a mat-raised-button color="warn" (click)="onDenial()">
        <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
        Annulla
      </a>
    </form>
  </div> <!-- /.form-wrapper -->
</mat-dialog-content>

<!-- <mat-dialog-actions align="end">
</mat-dialog-actions> -->
