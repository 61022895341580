// Core components
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Third party components
import { FieldType } from '@ngx-formly/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

// Custom components
import { HelperService } from 'src/app/shared/services/helper.service';
import { LightboxDialogComponent } from '../../dialogs/lightbox-dialog/lightbox-dialog.component';
import IFile from 'src/app/shared/interfaces/file.interface';
import { FileService } from 'src/app/shared/services/file.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { AddFileDialogComponent } from '../../dialogs/add-file-dialog/add-file-dialog.component';
import { getBEUrl } from 'src/environments/environment';

/**
 * Script start
 */
@Component({
  selector: 'app-formly-file-gallery-type',
  templateUrl: './file-gallery-type.component.html',
  styleUrls: ['./file-gallery-type.component.scss'],
})
export class FileGalleryTypeComponent
  extends FieldType
  implements OnInit, OnDestroy, AfterViewInit
{
  private subscriptions: Subscription[] = [];
  public submitBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Importa',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'mt-2',
  };

  constructor(
    private helperService: HelperService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    private fileService: FileService,
  ) {
    super();
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    super.ngOnInit();

    // // Handle form value changes (these are the uploaded files)
    // this.subscriptions.push(
    //   this.formControl.valueChanges.subscribe((res) => {
    //     if (res === this.previousValue) {
    //       return;
    //     }
    //     this.previousValue = res;
    //     this.initFiles(res);
    //   }),
    // );
  }

  /**
   * After view init logic
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Remove file
   *
   * @since 1.0.0
   */
  onRemove(file: IFile): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message:
          'Procedendo rimuoverai il file. Una volta rimosso, ricordati di salvare',
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }

        // Remove file from value
        const idToRemove = file._id;
        this.value = this.value.filter((el: IFile) => el._id !== idToRemove);
      });
  }

  /**
   * Handle click on a image preview and opens it in a dialog
   *
   * @since 1.0.0
   */
  onOpenImage(file: IFile): void {
    this.dialog.open(LightboxDialogComponent, {
      data: {
        url: file.path,
        data: file,
      },
      panelClass: 'lightbox-dialog',
    });
  }

  /**
   * Generate and return appropriate full preview path of given file
   *
   * @since 1.0.0
   */
  getFilePreview(file: IFile): string {
    if (!file) {
      return '';
    }

    

    const extension = file.originalName.split('.').pop()?.toLocaleLowerCase();

    const imageExtensions = [
      'gif',
      'jpeg',
      'jpg',
      'png',
    ];
    if (extension && imageExtensions.includes(extension)) {
      let previewPath = `${getBEUrl()}${file.path}`;
      if (previewPath) {
        return previewPath;
      }
    }

    const knownExtensions = [
      'acc',
      'ae',
      'ai',
      'an',
      'avi',
      'bmp',
      'csv',
      'dat',
      'dgn',
      'doc',
      'doch',
      'docm',
      'docx',
      'doth',
      'dw',
      'dwfx',
      'dwg',
      'dxf',
      'dxl',
      'eml',
      'eps',
      'f4a',
      'f4v',
      'flv',
      'fs',
      'gif',
      'html',
      'ind',
      'jpeg',
      'jpg',
      'jpp',
      'log',
      'lr',
      'm4v',
      'mbd',
      'mbox',
      'midi',
      'mkv',
      'mov',
      'mp2',
      'mp3',
      'mp4',
      'mpeg',
      'mpg',
      'mpp',
      'mpt',
      'mpw',
      'mpx',
      'msg',
      'ods',
      'oga',
      'ogg',
      'ogv',
      'one',
      'ost',
      'pdf',
      'php',
      'png',
      'pot',
      'poth',
      'potm',
      'potx',
      'pps',
      'ppsx',
      'ppt',
      'ppth',
      'pptm',
      'pptx',
      'prem',
      'ps',
      'psd',
      'pst',
      'pub',
      'pubh',
      'pubm',
      'pwz',
      'rar',
      'read',
      'rp',
      'rtf',
      'sql',
      'svg',
      'swf',
      'tif',
      'tiff',
      'txt',
      'url',
      'vcf',
      'vds',
      'vdx',
      'vob',
      'vsd',
      'vss',
      'vst',
      'vsx',
      'vtx',
      'wav',
      'wdp',
      'webm',
      'wma',
      'wmv',
      'xd',
      'xls',
      'xlsm',
      'xlsx',
      'xlx',
      'xml',
      'zip',
    ];
    if (extension && knownExtensions.includes(extension)) {
      return `/assets/img/file-types/${extension}.png`;
    }
    return '';
  }

  onAddFile(): void {
    const dialogRef = this.dialog.open(AddFileDialogComponent, {
      data: {
        allowedFilesNumber: this.to.allowedFilesNumber,
        allowedFilesExtensions: this.to.allowedFilesExtensions,
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: IFile[]) => {
        if (!res) {
          return;
        }
        if (this.value) {
          this.value = [...this.value, ...res];
        } else {
          this.value = res;
        }
      });
  }
}
