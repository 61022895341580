// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { FormlyFieldConfig, FormlyConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

// Custom packages

/**
 * Script start
 *
 * @see https://github.com/asc-lab/ngx-formly-playground/blob/master/app-formly-playground/src/app/shared/services/validations.loader.ts
 */
@Injectable()
export class ValidationLoaderService {
  constructor(
    private formlyConfig: FormlyConfig,
    private translationService: TranslateService,
  ) {}

  init(): void {
    // Error message without params
    this.formlyConfig.addValidatorMessage('required', (err, field) =>
      this.translationService.instant('general.validations.required'),
    );

    // Error message with params
    this.formlyConfig.addValidatorMessage('minlength', (err, field) =>
      this.minlengthValidationMessage(err, field),
    );
    this.formlyConfig.addValidatorMessage('maxlength', (err, field) =>
      this.maxlengthValidationMessage(err, field),
    );
    this.formlyConfig.addValidatorMessage('min', (err, field) =>
      this.minValidationMessage(err, field),
    );
    this.formlyConfig.addValidatorMessage('max', (err, field) =>
      this.maxValidationMessage(err, field),
    );

    // Back-end error
    this.formlyConfig.addValidatorMessage('server-error', (err, field) => err);
  }

  private minlengthValidationMessage(
    err: any,
    field: FormlyFieldConfig,
  ): string {
    if (
      field.templateOptions?.maxLength &&
      field.templateOptions?.minLength &&
      field.templateOptions?.minLength === field.templateOptions?.maxLength
    ) {
      const message = this.translationService.instant(
        'general.validations.invalidExactLength',
        {
          length: field.templateOptions?.minLength,
        },
      );
      return message;
    }
    const message = this.translationService.instant(
      'general.validations.invalidMinLength',
      {
        length: field.templateOptions?.minLength,
      },
    );
    return message;
  }

  private maxlengthValidationMessage(
    err: any,
    field: FormlyFieldConfig,
  ): string {
    if (
      field.templateOptions?.maxLength &&
      field.templateOptions?.minLength &&
      field.templateOptions?.minLength === field.templateOptions?.maxLength
    ) {
      const message = this.translationService.instant(
        'general.validations.invalidExactLength',
        {
          length: field.templateOptions?.maxLength,
        },
      );
      return message;
    }
    const message = this.translationService.instant(
      'general.validations.invalidMaxLength',
      {
        length: field.templateOptions?.maxLength,
      },
    );
    return message;
  }

  private minValidationMessage(err: any, field: FormlyFieldConfig): string {
    const message = this.translationService.instant(
      'general.validations.invalidMin',
      {
        min: field.templateOptions?.min,
      },
    );
    return message;
  }

  private maxValidationMessage(err: any, field: FormlyFieldConfig): string {
    const message = this.translationService.instant(
      'general.validations.invalidMax',
      {
        max: field.templateOptions?.max,
      },
    );
    return message;
  }
}
