<mat-card class="mat-elevation-z shadow">
  <div class="card-header">
    <div class="col-12 col-md-9 d-flex align-items-center">
      <mat-card-title>
        <h1>Statistiche di funzionamento delle installazioni</h1>
      </mat-card-title>
    </div> <!-- /.col -->
    <div class="col-12 col-md-3 d-flex align-items-center">

    </div> <!-- /.col-12 -->
  </div> <!-- /.row -->

  <mat-card-content class="pt-0">
    <div class="row">
      <div class="col">

        <div *ngIf="!lineChartData?.datasets?.length">
          <div class="d-flex justify-content-center py-5">
            <mat-spinner></mat-spinner>
          </div>
        </div>

        <div style="display: block;" class="pt-2" *ngIf="lineChartData?.datasets?.length">
          <canvas baseChart style="width: 100%" height="500" [type]="'line'" [data]="lineChartData"
            [options]="lineChartOptions" [legend]="lineChartLegend">
          </canvas>
        </div>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div class="row mt-5">
      <div class="col">
        <p class="">Il grafico soprastante riporta l'andamento del funzionamento delle installazioni. Per
          ciascuna installazione è presente una linea nel grafico che mostra la percentuale media di
          funzionamento dell'installazione in quel giorno.</p>
      </div>
    </div> <!-- /.row -->
  </mat-card-content>
</mat-card>
