// Core packages
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Third party packages
import { ToastrModule } from 'ngx-toastr';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgChartsModule } from 'ng2-charts';

// Custom packages
import { MaterialModule } from '../material.module';
import { PageComponent } from '../shared/components/page/page.component';
import { ConfigService } from '../shared/services/config.service';
import { PageService } from '../shared/services/loading.service';
import { TableComponent } from '../shared/components/table/table.component';
import SafeHtmlPipe from '../shared/pipes/safeHtml.pipe';
import { SidenavService } from '../shared/services/sidenav.service';
import { ApiService } from '../shared/services/api.service';
import { CreatorUpdatorBoxComponent } from '../shared/components/creator-updator-box/creator-updator-box.component';
import { ValidationLoaderService } from '../shared/services/validations-loader.service';
import { CellDirective } from '../shared/components/table/cell.directive';
import { FileUploaderComponent } from '../shared/components/file-uploader/file-uploader.component';
import { FileService } from '../shared/services/file.service';
import { StatCardComponent } from '../shared/components/stat-card/stat-card.component';
import { AddItemCardComponent } from '../shared/components/add-item-card/add-item-card.component';
import { FormAutocompleteTypeComponent } from '../shared/components/table/autocomplete-type.component';
import { InViewDirective } from '../shared/directives/inView/inview.directive';
import TruncatePipe from '../shared/pipes/truncate.pipe';

/**
 * Script start
 */
export const CUSTOM_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

// export function MatPaginatorIntlCustomFactory(translate: TranslateService): any {
//   const service = new PaginatorIntlService();
//   service.injectTranslateService(translate);
//   return service;
// }

@NgModule({
  imports: [
    // Core modules
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // MatNativeDateModule,

    // Third party modules
    MaterialModule,
    // TranslateModule.forChild(),
    ToastrModule.forRoot(),
    FormlyModule.forChild(),
    // FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    MatProgressButtonsModule.forRoot(),
    NgSelectModule,
    NgxMatSelectSearchModule,
    NgxFileDropModule,

    NgChartsModule,

    // Custom
  ],
  declarations: [
    // Utils
    SafeHtmlPipe,
    TruncatePipe,
    CellDirective,
    InViewDirective,
    // DateFormatPipe,
    // DateTimeFormatPipe,
    // ObjToKeysPipe,

    // Components
    PageComponent,
    FormAutocompleteTypeComponent,
    TableComponent,
    CreatorUpdatorBoxComponent,
    // CardSpinnerComponent,
    FileUploaderComponent,
    StatCardComponent,
    AddItemCardComponent,
  ],
  exports: [
    // Core modules
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    // Third party modules
    MaterialModule,
    TranslateModule,
    ToastrModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    NgSelectModule,
    NgxMatSelectSearchModule,
    MatProgressButtonsModule,
    NgxFileDropModule,

    // Utils
    SafeHtmlPipe,
    TruncatePipe,
    CellDirective,
    InViewDirective,
    // DateFormatPipe,
    // DateTimeFormatPipe,
    // ObjToKeysPipe,

    // Components
    PageComponent,
    FormAutocompleteTypeComponent,
    TableComponent,
    CreatorUpdatorBoxComponent,
    // CardSpinnerComponent,
    FileUploaderComponent,
    StatCardComponent,
    AddItemCardComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        // Misc
        {
          provide: MatPaginatorIntl,
          // useFactory: MatPaginatorIntlCustomFacto,
          // deps: [TranslateService],
          useValue: getItalianPaginatorIntl(),
        },
        // {
        //   provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
        //   useValue: { useUtc: true },
        // },
        // {
        //   provide: DateAdapter,
        //   useClass: MomentDateAdapter,
        //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        // },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS },

        // Services
        SidenavService,
        // AuthService,
        ApiService,
        FileService,
        ConfigService,
        PageService,
        // EnvironmentService,
        // ListsService,
        // AuthGuard,
        // LangGuard,
        ValidationLoaderService,
      ],
    };
  }
}

const italianRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 di ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return `Visualizzati da ${startIndex + 1} a ${endIndex} di ${length}`;
};

export function getItalianPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Risultati per pagina:';
  paginatorIntl.nextPageLabel = 'Pagina successiva';
  paginatorIntl.previousPageLabel = 'Pagina precedente';
  paginatorIntl.getRangeLabel = italianRangeLabel;

  return paginatorIntl;
}
