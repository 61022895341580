// Core packages
import { Component, Inject, OnInit } from '@angular/core';

// Third party packages
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import File from 'src/app/shared/interfaces/file.interface';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// Custom packages
import { ConfigService } from 'src/app/shared/services/config.service';
import { environment, getBEUrl } from 'src/environments/environment';
import IPlantFile from 'src/app/shared/models/plantFile/plantFile.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-lightbox-dialog-ext',
  templateUrl: './lightbox-dialog-ext.component.html',
  styleUrls: ['./lightbox-dialog-ext.component.scss'],
})
export class LightboxDialogExtComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public apiHost: string = getBEUrl();
  public isImage: boolean = false;
  public siteName: string = '';

  constructor(
    public dialogRef: MatDialogRef<LightboxDialogExtComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      obj: IPlantFile;
    },
    public configService: ConfigService,
    public httpClient: HttpClient,
  ) {
    
  }

  /**
   * Init component
   *
   * @since 1.1.0
   */
  ngOnInit(): void {
    if (
      this.data.obj?.file.mimetype.includes('image') &&
      !this.data.obj?.file.mimetype.includes('pdf')
    ) {
      this.isImage = true;
    }
    
    if (
      this.data.obj.siteId && 
      this.data.obj.siteId?.name
    ) {
      this.siteName = this.data.obj.siteId?.name;
    }
  }

  /**
   * Handle dialog close
   */
  onClose(): void {
    this.dialogRef.close();
  }

  getDataUrl(preview: boolean = false): string {
    if (!preview) {
      return this.apiHost + this?.data?.obj?.file.path;
    }

    if (
      this.data.obj?.file.mimetype.includes('image') &&
      !this.data.obj?.file.mimetype.includes('pdf')
    ) {
      if (this?.data?.obj?.file.path.includes(this.apiHost)) {
        return this.data?.obj?.file.path;
      }

      return this.apiHost + this?.data?.obj?.file.path;
    }

    // Choose the apprpriate image
    if (this.data.obj?.file.mimetype.includes('pdf')) {
      return '/assets/img/previewImage/pdf.png';
    }
    if (
      this.data.obj?.file.mimetype.includes('octet-stream') ||
      this.data.obj?.file.mimetype.includes('msword')
    ) {
      return '/assets/img/previewImage/doc.png';
    }

    return ''; // @todo
  }

  getWeight(): string {
    const weight = parseFloat(this.data?.obj?.file.size) / 1000;
    return weight.toPrecision(2).toString();
  }

  /**
   * Handle click on a doc preview and dawnload it
   *
   * @since 1.0.0
   */
  download(url: string): Observable<any> {
    return this.httpClient.get(url, {
      responseType: 'blob'
    });
  }
  
  onDownloadImage(): void {
    const url = `${getBEUrl()}${this.data?.obj?.file.path}`;
  
    this.subscriptions.push(
      this.download(url).subscribe(
        res => {
          let objectUrl = window.URL.createObjectURL(res);

          let anchor = document.createElement("a");
          document.body.appendChild(anchor);
          anchor.href = objectUrl;
          anchor.download = this.data?.obj?.file.originalName;
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        },
        err => {
          console.log("err", err)
        }
      )    
    )
  }
}
