// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class PlantsService {
  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
  ) {}

  /**
   * Create a new plant
   *
   * @since 1.0.0
   *
   * @param plant Plant data
   * @returns Observable<any>
   */
  create(plant: any): Observable<any> {
    return this.apiService.apiCall('POST', 'plants', plant);
  }

  /**
   * Get plants list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall('GET', 'plants', params);
  }

  /**
   * Get plant list of given aggregaionId for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getListOfAggregation(
    aggregaionId: string,
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall(
      'GET',
      `aggregations/${aggregaionId}/plants`,
      params,
    );
  }

  /**
   * Get list of plant items for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getItemsList(
    plantId: string,
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall('GET', `plants/${plantId}/items`, params);
  }

  /**
   * Get given plantId data from back-end
   *
   * @since 1.0.0
   *
   * @param plantId ID of the plant to get
   */
  get(plantId: string): Observable<any> {
    return this.apiService.apiCall('GET', `plants/${plantId}`).pipe(
      catchError((err) => {
        this.helperService.handleError(err);
        return throwError(err);
      }),
    );
  }

  /**
   * Update plant with given plantId with given data
   *
   * @since 1.0.0
   *
   * @param plantId ID of pos to update
   * @param data New plant's data
   */
  update(plantId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PUT', `plants/${plantId}`, data);
  }

  /**
   * Update plant fields with given plantId with given data
   *
   * @since 1.0.0
   *
   * @param plantId ID of plant to update
   * @param data New plant's data
   */
  updateFields(plantId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PATCH', `plants/${plantId}`, data);
  }

  /**
   * Delete permanently an plant
   *
   * @since 1.0.0
   *
   * @param plantId ID of the plant
   */
  delete(plantId: string): Observable<any> {
    return this.apiService.apiCall('DELETE', `plants/${plantId}`);
  }

  /**
   * Delete permanently an plant and all it's descendants
   *
   * @since 1.0.0
   *
   * @param plantId ID of the plant
   */
  deleteWithDescendants(plantId: string): Observable<any> {
    return this.apiService.apiCall(
      'DELETE',
      `plants/${plantId}/with-descendants`,
    );
  }

  /**
   * Silence all items of the plant
   *
   * @since 1.0.0
   *
   * @param plantId ID of the plant
   */
  silenceAllItems(plantId: string): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `plants/${plantId}/silence-all-items`,
    );
  }

  /**
   * Unilence all items of the plant
   *
   * @since 1.0.0
   *
   * @param plantId ID of the plant
   */
  unsilenceAllItems(plantId: string): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `plants/${plantId}/unsilence-all-items`,
    );
  }

  /**
   * Exclude from ping all items of the plant
   *
   * @since 1.1.0
   *
   * @param plantId ID of the plant
   */
  pingExcludeAllItems(plantId: string): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `plants/${plantId}/ping-exclude-all-items`,
    );
  }

  /**
   * Include from ping all items of the plant
   *
   * @since 1.1.0
   *
   * @param plantId ID of the plant
   */
  pingIncludeAllItems(plantId: string): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `plants/${plantId}/ping-include-all-items`,
    );
  }

  /**
   * Reset status of all items of the plant
   *
   * @since 1.1.0
   *
   * @param plantId ID of the plant
   */
  resetAllItems(plantId: string): Observable<any> {
    return this.apiService.apiCall('POST', `plants/${plantId}/reset-all-items`);
  }
}
