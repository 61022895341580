// Core packages
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SwiperComponent } from 'swiper/angular';

// Third party packages
import { Subscription, BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';
import { ChartOptions } from 'chart.js';
import { ToastrService } from 'ngx-toastr';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import Response from 'src/app/shared/interfaces/response.interface';
import IUser from 'src/app/shared/models/user/user.interface';
import { AuthService } from 'src/app/modules/auth/auth.service';
import IAggregation from 'src/app/shared/models/aggregation/aggregation.interface';
import File from 'src/app/shared/interfaces/file.interface';
import { getBEUrl } from 'src/environments/environment';
import TableConfig from 'src/app/shared/interfaces/tableConfig.interface';
import IPlant from 'src/app/shared/models/plant/plant.interface';
import { ConfigService } from 'src/app/shared/services/config.service';
import { TableDataSource } from 'src/app/shared/components/table/table.dataSource';
import { PlantsService } from 'src/app/modules/plants/plants.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import ITicket from 'src/app/shared/models/ticket/ticket.interface';
import IItem from 'src/app/shared/models/item/item.interface';
import ISite from 'src/app/shared/models/site/site.interface';
import { TicketsService } from 'src/app/modules/tickets/tickets.service';
import { PageService } from 'src/app/shared/services/loading.service';
import { MapDialogComponent } from 'src/app/shared/components/dialogs/map-dialog/map-dialog.component';
import ICustomer from 'src/app/shared/models/customer/customer.interface';
import { CustomersService } from 'src/app/modules/customers/customers.service';
import { NotificationsService } from 'src/app/modules/notifications/notifications.service';
import INotification from 'src/app/shared/models/notification/notification.interface';
import { DeadInstallationsDialogComponent } from 'src/app/shared/components/dialogs/dead-installations-dialog/dead-installations-dialog.component';
import { InstallationsService } from 'src/app/modules/installations/installations.service';
import { WarningDialogComponent } from 'src/app/shared/components/dialogs/warning-dialog/warning-dialog.component';
import { NvrAlarmsService } from 'src/app/modules/nvrAlarms/nvrAlarms.service';


/**
 * Script start
 */
SwiperCore.use([Navigation]);
@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss'],
})
export class CustomerDashboardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscriptions: Subscription[] = [];
  public data: any = {};
  public loggedUser!: IUser<IAggregation> | undefined;
  public tableConfig!: TableConfig<IPlant>;
  public totalPlants: string = '0';
  public tickets: ITicket<IItem<ISite<IPlant>>, IAggregation>[] = [];
  public ticketsCounter: number = 0;
  public customers: ICustomer<IAggregation>[] = [];
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: false,
    // pagination: {
    //   clickable: true,
    // },
    scrollbar: {
      draggable: true,
    },
  };
  public notifications: INotification[] = [];
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  // Charts variables
  public loading: boolean = false;
  public lineChartData: {
    labels: string[];
    datasets: {
      [key: string]: any;
      label: string;
      data: number[];
    }[];
  } = {
    labels: [],
    datasets: [],
  };
  public lineChartOptions: ChartOptions = {
    responsive: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max: 1,
        ticks: {
          callback: (te: any) => 100 * parseFloat(te?.toString()) + ' %',
        },
        title: {
          display: true,
          text: 'Percentuale di funzionamento',
        },
      },
      x: {
        title: {
          display: !0,
          text: 'Giorno',
        },
      },
    },
  };
  public lineChartLegend = true;

  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    public authService: AuthService<IAggregation>,
    private router: Router,
    private configService: ConfigService,
    private plantsService: PlantsService,
    public helperService: HelperService,
    private ticketsService: TicketsService,
    private pageService: PageService,
    private dialog: MatDialog,
    private customersService: CustomersService,
    private notificationsService: NotificationsService,
    private toastrService: ToastrService,
    private installationsService: InstallationsService,
    private nvrAlarmsService: NvrAlarmsService,
  ) {
    this.getData();
    this.getTickets();
    this.getNotifications();
    this.loggedUser = this.authService.loggedUser$?.value;
  }

  async getData(): Promise<void> {
    this.subscriptions.push(
      this.apiService.apiCall('GET', 'dashboard').subscribe((res: Response) => {
        // console.log('res', res);
        if (res.status) {
          const { data } = res;
          this.data = data;
          
          // if (
          //   this.loggedUser?.profile === 'pro' && 
          //   this.data.totals && 
          //   this.data.totals.nvrAlarms &&
          //   this.data.totals.nvrAlarms > 0
          // ) {
          //   this.subscriptions.push(
          //     this.apiService.apiCall('GET', 'nvr-alarms').subscribe((res: Response) => {
          //       if (res.status) {
          //         const dialogRef = this.dialog.open(WarningDialogComponent, {
          //           data: {
          //             title: 'ATTENZIONE!',
          //             message: "I seguenti NVR risultano non funzionanti.",
          //             sectionTitle: 'IMPIANTO/SITO',
          //             warnings: res.data,
          //             btnCancelText: 'Chiudi',
          //           },
          //           width: '400px',
          //           disableClose: true,
          //           panelClass: 'my-custom-dialog-class'
          //         });
          //       }
          //     }),
          //   );

          // }
        }
      }),
    );

    this.subscriptions.push(
      this.customersService
        .getList(0, 100, 'name', 'asc')
        .subscribe((res: Response) => {
          if (!res.status) {
            return;
          }

          this.customers = res.data;
        }),
    );
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    this.initTable();
    this.getChartData();
  }

  ngAfterViewInit(): void {}

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  getLogo(file?: File): string {
    if (!file || !file.path) {
      return '/assets/img/user.jpg';
    }

    const url = `${getBEUrl()}${file.path}`;
    return url;
  }

  /**
   * Get tickets list
   *
   * @since 1.0.0
   */
  getTickets(): void {
    this.subscriptions.push(
      this.ticketsService
        .getList(0, 3, 'createdAt', 'desc')
        .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Something bad happened
              // this.router.navigate(['plants']);
            }

            // Set data
            this.tickets = res.data;
            this.ticketsCounter = (res as any).totalCount;
          },
          (err: Response) => {
            this.helperService.handleError(err);
            // this.router.navigate(['errors', '404']);
          },
        ),
    );
  }

  /**
   * Get notifications list
   *
   * @since 1.0.0
   */
  getNotifications(): void {
    this.subscriptions.push(
      this.notificationsService
        .getList(0, 10, 'createdAt', 'desc')
        .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Something bad happened
              // this.router.navigate(['plants']);
            }

            // Set data
            this.notifications = res.data;
          },
          (err: Response) => {
            this.helperService.handleError(err);
            // this.router.navigate(['errors', '404']);
          },
        ),
    );
  }

  /**
   * Init table
   *
   * @since 1.0.0
   */
  initTable(): void {
    this.tableConfig = {
      dataSource: new TableDataSource(this.plantsService, this.helperService),
      selectable: false,
      generalSearch: false,
      actionsColumnCallback: (element: IPlant) => {
        this.router.navigate(['/', 'plants', element._id]);
      },
      columns: [
        {
          key: 'name',
          label: 'Impianto',
          sortable: true,
          visible: true,
          onClick: (row: any, colKey: string) => {
            this.router.navigate(['/', 'plants', row._id]);
          },
          render: (row: any, colKey: string): string => {
            const colVal = row[colKey];
            if (colVal) {
              return `<u>${colVal}</u>`;
            }
            return '';
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        // {
        //   key: 'type',
        //   label: 'Tipo',
        //   sortable: true,
        //   visible: true,
        //   render: (row: any, colKey: string): string => {
        //     const colVal = row[colKey];
        //     const foundItem =
        //       this.configService.settings?.modules?.plants?.typesOptions?.find(
        //         (el: { value: string; label: string }) => el.value === colVal,
        //       );
        //     if (foundItem) {
        //       return foundItem.label;
        //     }
        //     return '';
        //   },
        //   filter: {
        //     control: new FormControl(''),
        //     type: 'select',
        //     dataType: 'string',
        //     label: 'Cerca...',
        //     items:
        //       this.configService.settings?.modules?.plants?.typesOptions || [],
        //   },
        // },

        {
          key: 'customerId.name',
          label: 'Cliente',
          sortable: false,
          visible: true,
          // visible: this.authService.loggedUser$?.value?.role !== 'customer',
          render: (row: any, colKey: string): string => {
            const colVal = row?.customerId?.name;
            if (colVal) {
              return colVal;
            }
            return colVal;
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        {
          key: '_id',
          label: 'Mappa',
          sortable: false,
          visible:
            this.authService.loggedUser$?.value?.role !== 'customer' ||
            !this.authService?.loggedUser$?.value?.sections?.length ||
            this.authService?.loggedUser$?.value?.sections?.includes('Mappa'),
          onClick: (row: any, colKey: string) => {
            this.router.navigate(['/', 'plants', 'map', row._id]);
          },
          render: (row: any, colKey: string): string => {
            const colVal = '<span class="clickableLink">Mappa</span>';
            return colVal;
          },
        },
      ],
      sort: 'name',
      sortVersus: 'asc',
      refresh$: new BehaviorSubject<boolean>(false),
      reset$: new BehaviorSubject<boolean>(false),
      options: [
        // {
        //   title: 'Cancella filtri',
        //   name: '',
        //   icon: 'fa-filter-circle-xmark',
        //   callback: () => {
        //     this.tableConfig.reset$.next(!this.tableConfig.reset$.value);
        //   },
        // },
        // {
        //   title: 'Ricarica',
        //   name: '',
        //   icon: 'fa-sync',
        //   callback: () => {
        //     this.tableConfig.refresh$.next(!this.tableConfig.refresh$.value);
        //   },
        // },
      ],
    };
  }

  getTiketStatus(status: string): string {
    const foundStatus =
      this.configService.settings.modules?.tickets?.statusesOptions?.find(
        (el: any) => el.value == status,
      );
    if (foundStatus) {
      return `<span class="ticket-status-${status}">${foundStatus.label}</span>`;
    }
    return '';
  }

  getPercentage(): number {
    const a = this?.data?.totals?.items;
    const b = this?.data?.totals?.deadItems;

    // Falback to 100% if cannot calculate real percentage
    if (!a) {
      return 100;
    }

    const percentage = (1 - b / a) * 100;
    return percentage;
  }

  /**
   * Triggered when customer click on "View map" button
   *
   * @since 1.1.0
   */
  onViewMap(): void {
    const aggregationId =
      typeof (this.authService.loggedUser$.value as any)?.aggregationId ===
      'string'
        ? (this.authService.loggedUser$.value as any)?.aggregationId
        : (this.authService.loggedUser$.value as any)?.aggregationId._id;
    const dialogRef = this.dialog.open(MapDialogComponent, {
      data: {
        params: {
          aggregationId: aggregationId,
        },
      },
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
      panelClass: 'md-custom-panel',
      role: 'dialog',
    });

    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }
      });
  }

  onSwiper(event: any) {
    console.log('event: ', event);
  }
  onSlideChange() {
    console.log('slide change');
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext(100);
  }
  slidePrev() {
    this.swiper?.swiperRef.slidePrev(100);
  }

  /**
   * Handle tap on DeadInstallations icon and open a dialog with dead installations details
   *
   * @since 1.1.0
   */
  onViewDeadInstallations(): void {
    const dialogRef = this.dialog.open(DeadInstallationsDialogComponent, {
      data: {
        deadInstallations: this.data.deadInstallations,
      },
      width: '450px',
      disableClose: false,
      role: 'dialog',
    });

    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }
      });
    }

   /**
   * Get charts data
   */
  getChartData() {
    this.loading = true;

    this.subscriptions.push(
      this.installationsService
        .getStats()
        .pipe(
          finalize(() => {
            this.pageService.loading$.next(false);
            this.loading = false;
          }),
        )
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              return this.toastrService.error(
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico",
                'Errore',
              );
            }

            this.lineChartData.labels = res.data.labels;
            const datasets = res.data.datasets;
            let O = 0;

            for (const dataset of datasets) {
              const newColor = this.getRandomColorRGBA(0.8);
              const newColorOpacity = this.changeOpacity(newColor, 0.3);

              this.lineChartData.datasets.push({
                data: [...dataset.data],
                label: dataset.label,
                fill: true,
                tension: 0.5,
                borderColor: newColor,
                borderWidth: 0.5,
                backgroundColor: newColorOpacity,
                hidden: 0 !== O,
                pointBackgroundColor: newColor,
              });

              O++;
            }
            return true;
          },
          (err: Response) => {
            this.helperService.handleError(err),
              this.toastrService.error(
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico",
                'Errore',
              );
          },
        ),
    );
  }

  private getRandomColorRGBA(t = 1) {
    const u = () => Math.floor(256 * Math.random());
    return `rgba(${u()}, ${u()}, ${u()}, ${t})`;
  }

  private changeOpacity(color: string, opacity: number) {
    const D = color.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);
    if (!D) throw new Error('Invalid RGBA format');
    const O = parseInt(D[1], 10),
      te = parseInt(D[2], 10),
      oe = parseInt(D[3], 10);
    return parseFloat(D[4]), `rgba(${O}, ${te}, ${oe}, ${opacity})`;
  }
}
