<div class="page auth-page">
  <div class="left">
    <div class="col-content">
      <img src="/assets/img/logo/logo.svg" alt="{{ configService.settings.owner?.nicename }}" class="logo"
        width="300">
    </div>
  </div>
  <div class="right">
    <div class="col-content">
      <img src="/assets/img/logo/logo.svg" alt="{{ configService.settings.owner?.nicename }}" class="logo"
        width="300">
      <h1 class="h4">{{ 'pages.login.title' | translate }}</h1>
      <div class="form-wrapper">
        <form [formGroup]="form" (ngSubmit)="onFormSubmit(model)" class="mb-3">
          <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
          <div class="mt-3">
            <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate">
            </mat-spinner-button>
          </div>
        </form>
        <p class="text-left mt-5"><a routerLink="../recover-password">{{ 'pages.login.recoverPassword' |
            translate }}</a></p>
      </div> <!-- /.form-wrapper -->
    </div> <!-- /.col-content -->
  </div>

</div> <!-- /.page -->
