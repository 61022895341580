<div class="phoneWrapper" role="group" [formGroup]="parts">
  <!-- <input matInput type="string" [readonly]="to.readonly" [placeholder]="to.prefixLabel || ''" class="prefix"
    formControlName="prefix" maxlength="4" (focus)="onFocus(this, 'in')" (focusout)="onFocus(this, 'out')" /> -->

  <!-- <mat-select [placeholder]="to.prefixLabel || ''" class="prefix" formControlName="prefix" maxlength="4"
    (focus)="onFocus(this, 'in')" (focusout)="onFocus(this, 'out')">
    <mat-option *ngFor="let prefix of prefixes" [value]="prefix.value">
      {{ prefix.value }}
    </mat-option>
  </mat-select> -->

  <!-- [disabled]="to.disabled || false" -->
  <mat-select formControlName="prefix" [placeholder]="to.prefixLabel || ''" class="prefix" #singleSelect>
    <mat-option>
      <ngx-mat-select-search [clearSearchInput]="true" [formControl]="prefixFilterCtrl"
        noEntriesFoundLabel="Nessun risultato" placeholderLabel="Cerca... (es: +39)">
        <mat-icon fontSet="fal" fontIcon="fa-trash" ngxMatSelectSearchClear></mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let prefix of filteredPrefixes | async" [value]="prefix.value">
      {{prefix.label}}
    </mat-option>
  </mat-select>

  <span *ngIf="showDash">&ndash;</span>

  <input matInput type="string" [readonly]="to.readonly" [placeholder]="to.label || ''" class="number"
    formControlName="number" maxlength="11" (focus)="onFocus(this, 'in')" (focusout)="onFocus(this, 'out')" />

</div>
