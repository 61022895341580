<div class="table-wrapper">
  <div class="spinner-container" *ngIf="config.dataSource.loading$ | async">
    <mat-spinner strokeWidth="3" [diameter]="50" mode="indeterminate"></mat-spinner>
  </div> <!-- /.spinner-container -->

  <div class="table-title" *ngIf="config?.title">
    <div class="row">
      <div class="col-12">
        <h2 class="">{{config.title}}</h2>
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </div> <!-- /.table-title -->

  <div class="table-toolbar" *ngIf="config?.generalSearch || config?.options?.length">
    <div class="row align-items-center">
      <div class="col-12 col-sm-6 col-md-6">
        <ng-container *ngFor="let item of config?.leftOptions">
          <mat-form-field class="left-option-field">
            <mat-label>{{item?.label}}</mat-label>
            <mat-select (selectionChange)="item.callback($event)">
              <mat-option value="">Tutti</mat-option>
              <mat-option *ngFor="let el of item.data" [value]="el.id">
                <small>{{el.name}}</small>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div> <!-- /.col-12.col-md-4 -->
      <div class="col-12 col-sm-6 col-md-6 d-flex justify-content-end">
        <ng-container *ngFor="let item of config?.options">
          <button mat-button (click)="item.callback()" class="d-none d-sm-inline-block me-2"
            [title]="item?.title || ''">
            <ng-container *ngIf="item?.icon">
              <mat-icon fontSet="fal" [fontIcon]="item.icon || ''" class="fa-lg"></mat-icon>
            </ng-container>
            {{ item.name }}
          </button>
        </ng-container>

        <!-- BEGIN - Extra filters -->
        <ng-container *ngIf="config?.extraFilters">
          <ng-container *ngFor="let extraFilter of config.extraFilters">

            <mat-form-field class="filter" *ngIf="extraFilter && extraFilter.type !== 'autocomplete'">
              <mat-label>{{extraFilter.label || "Cerca..."}}</mat-label>

              <!-- TYPE === 'input' -->
              <ng-container *ngIf="extraFilter.type === 'input'">
                <input matInput [type]="extraFilter.dataType" [formControl]="extraFilter.control">
              </ng-container>

              <!-- TYPE === 'select' -->
              <ng-container *ngIf="extraFilter.type === 'select'">
                <mat-select [formControl]="extraFilter.control" [value]="extraFilter.control.value">
                  <mat-option value="">--</mat-option>
                  <mat-option *ngFor="let item of extraFilter.items" [value]="item.value">
                    <small>{{item.label}}</small>
                  </mat-option>
                </mat-select>
              </ng-container>

              <!-- TYPE === 'datepicker' -->
              <ng-container *ngIf="extraFilter.type === 'datepicker'">
                <input matInput [matDatepicker]="dp" (focus)="dp.open()" (click)="dp.open()" readonly
                  [placeholder]="extraFilter.label || 'Seleziona'" [formControl]="extraFilter.control"
                  style="cursor: pointer" />
                <!-- <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle> -->
                <mat-datepicker #dp></mat-datepicker>
              </ng-container>

              <mat-icon matSuffix fontSet="fal" fontIcon="fa-times" class="reset-input-icon"
                *ngIf="extraFilter.control.value" (click)="onResetFilter(extraFilter.control)">
              </mat-icon>


              <mat-hint *ngIf="extraFilter.description" id="matHintDescription">{{ extraFilter.description }}
              </mat-hint>
            </mat-form-field>

            <!-- TYPE === 'autocomplete' -->
            <ng-container class="filter" *ngIf="extraFilter && extraFilter.type === 'autocomplete'">
              <app-autocomplete-type [filter]="extraFilter" [onResetFilter]="onResetFilter.bind(this)">
              </app-autocomplete-type>
            </ng-container>

          </ng-container>
        </ng-container>
        <!-- END - Extra filters -->

        <ng-container *ngIf="config?.generalSearch">
          <mat-form-field appear class="ms-3">
            <mat-label>Cerca...</mat-label>
            <input matInput [(ngModel)]="generalSearch" placeholder="Scrivi qui..." #input>
            <mat-icon matSuffix fontSet="fal" fontIcon="fa-search" *ngIf="!generalSearch" style="font-size: 1.1rem;">
            </mat-icon>
            <mat-icon matSuffix fontSet="fal" fontIcon="fa-times" (click)="resetInput()" class="reset-input-icon"
              *ngIf="generalSearch" style="font-size: 1.1rem;"></mat-icon>
          </mat-form-field>
        </ng-container>
      </div> <!-- /.col-12.col-md-8 -->
    </div> <!-- /.row -->
  </div> <!-- /.table-toolbar -->

  <div class="table-responsive">
    <table mat-table [dataSource]="config.dataSource" matSort [matSortActive]="config.sort || 'createdAt'"
      [matSortDirection]="config.sortVersus || 'desc'" matSortDisableClear>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
        <ng-container *ngIf="showFilters">
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let col of config.columns">
        <ng-container [matColumnDef]="col.key.toString() || ''">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!col.sortable" [class]="col.headerClass"
            [style]="col.headerStyle">
            {{ col.label }}</th>

          <ng-container *ngIf="col.render">
            <td mat-cell *matCellDef="let row" class="{{ col?.columnClass }}">
              <div style="display: inline" (click)="col?.onClick ? col?.onClick(row, col.key) : null"
                [ngClass]="{ 'clickable': col?.onClick !== undefined }"
                [innerHTML]="col.render(row, col.key) | safeHtml"></div>
            </td>
          </ng-container>

          <ng-container *ngIf="!col.render">
            <td mat-cell *matCellDef="let row" [innerHTML]="row[col.key]" class="{{ col?.columnClass }}"></td>
          </ng-container>

          <ng-container *ngIf="showFilters">
            <td mat-footer-cell *matFooterCellDef>
              <mat-form-field class="filter" *ngIf="col.filter && col.filter.type !== 'autocomplete'">
                <mat-label>{{col.filter.label || "Cerca..."}}</mat-label>

                <!-- TYPE === 'input' -->
                <ng-container *ngIf="col.filter.type === 'input'">
                  <input matInput [type]="col.filter.dataType" [formControl]="col.filter.control">
                </ng-container>

                <!-- TYPE === 'select' -->
                <ng-container *ngIf="col.filter.type === 'select'">
                  <mat-select [formControl]="col.filter.control" [value]="col.filter.control.value">
                    <mat-option value="">--</mat-option>
                    <mat-option *ngFor="let item of col.filter.items" [value]="item.value">
                      <small>{{item.label}}</small>
                    </mat-option>
                  </mat-select>
                </ng-container>

                <!-- TYPE === 'datepicker' -->
                <ng-container *ngIf="col.filter.type === 'datepicker'">
                  <input matInput [matDatepicker]="dp" (focus)="dp.open()" (click)="dp.open()" readonly
                    [placeholder]="col.filter.label || 'Seleziona'" [formControl]="col.filter.control"
                    style="cursor: pointer" />
                  <!-- <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle> -->
                  <mat-datepicker #dp></mat-datepicker>
                </ng-container>

                <mat-icon matSuffix fontSet="fal" fontIcon="fa-times" class="reset-input-icon"
                  *ngIf="col.filter.control.value" (click)="onResetFilter(col.filter.control)">
                </mat-icon>


                <mat-hint *ngIf="col.filter.description" id="matHintDescription">{{ col.filter.description }}
                </mat-hint>
              </mat-form-field>

              <!-- TYPE === 'autocomplete' -->
              <ng-container class="filter" *ngIf="col.filter && col.filter.type === 'autocomplete'">
                <app-autocomplete-type [filter]="col.filter" [onResetFilter]="onResetFilter.bind(this)">
                </app-autocomplete-type>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="hasActions">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="text-right pr-5"></th>
          <td mat-cell *matCellDef="let element" class="text-right {{config.actionsColumnClass}}">
            <div class="d-flex align-items-center justify-content-end">
              <ng-container *ngIf="!config.customActionColumn">
                <!-- mat-raised-button  color="primary" -->
                <a class="action-btn" [routerLink]="'./' + element._id" *ngIf="!config.actionsColumnCallback">
                  <span [innerHTML]="config.actionsColumnText"></span>
                  <mat-icon fontSet="fal" [fontIcon]="config.actionsColumnIcon || 'fa-chevron-right'"></mat-icon>
                </a>
                <!-- mat-raised-button  color="primary" -->
                <a class="action-btn" *ngIf="config.actionsColumnCallback"
                  (click)="config.actionsColumnCallback(element)">
                  <span [innerHTML]="config.actionsColumnText"></span>
                  <mat-icon fontSet="fal" [fontIcon]="config.actionsColumnIcon || 'fa-chevron-right'"></mat-icon>
                </a>
              </ng-container>

              <ng-container *ngIf="config.customActionColumn?.length">
                <ng-container *ngFor="let customActionColumn of config.customActionColumn">

                  <!-- BEGIN - Single button or renderer -->
                  <ng-container *ngIf="customActionColumn.button.action || customActionColumn.button.renderer">
                    <ng-container
                      *ngIf="customActionColumn.button.hidden == undefined || customActionColumn.button.hidden(element) === false">
                      <ng-container *ngIf="customActionColumn.button.action && !customActionColumn.button.renderer">
                        <a class="action-btn" (click)="customActionColumn.button.action(element)"
                          [class]="customActionColumn.button.class">
                          <mat-icon [fontSet]="customActionColumn.button.iconFontSet || 'fal'" class="fa-fw"
                            [fontIcon]="customActionColumn.button.icon || 'fa-ellipsis-v-alt'">
                          </mat-icon>
                          <span [innerHTML]="customActionColumn.button.text"></span>
                        </a>
                      </ng-container>

                      <ng-container *ngIf="customActionColumn.button.renderer">
                        <div [class]="customActionColumn.button.class"
                          [innerHTML]="customActionColumn.button.renderer(element)"></div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <!-- END - Single button or renderer -->

                  <ng-container *ngIf="!customActionColumn.button.action && !customActionColumn.button.renderer">
                    <button mat-button [matMenuTriggerFor]="actionsMenu" [class]="customActionColumn.button.class">
                      <mat-icon [fontSet]="customActionColumn.button.iconFontSet || 'fal'" class="fa-fw me-2"
                        [fontIcon]="customActionColumn.button.icon || 'fa-ellipsis-v-alt'">
                      </mat-icon>
                      <span [innerHTML]="customActionColumn.button.text"></span>
                    </button>
                    <mat-menu #actionsMenu="matMenu" xPosition="before">
                      <ng-container *ngFor="let customActionOption of customActionColumn.options">
                        <button *ngIf="!customActionOption?.hidden || !customActionOption?.hidden(element)"
                          mat-menu-item (click)="customActionOption.action(element)">
                          <!-- <mat-icon fontSet="fal" class="fa-fw me-2"
                            [fontIcon]="customActionOption.icon || 'fa-chevron-right'">
                          </mat-icon> -->
                          {{ customActionOption.text }}
                        </button>
                      </ng-container>
                    </mat-menu>
                  </ng-container>


                </ng-container>
              </ng-container>
            </div> <!-- /.d-flex -->
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" id="table-header-row"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns" [class]="config.rowClass ? config.rowClass(row) : ''">
      </tr>

      <ng-container *ngIf="showFilters">
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </ng-container>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length + 1">Nessun dato presente</td>
      </tr>
    </table>
  </div> <!-- /.table-responsive -->

  <mat-paginator [hidePageSize]="config.hidePageSize" [length]="config.dataSource.totalCount$ | async"
    [pageSizeOptions]="config.pageSizeOptions || configService.pageSizeOptions"
    [pageSize]="config.pageSize || configService.defaultPageSize" showFirstLastButtons></mat-paginator>
</div>
