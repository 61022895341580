<div class="close-button" (click)="onDenial()">
  <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
</div>

<div mat-dialog-title class="text-center" style="border-bottom: 1px solid #ddd;">
  <h3>
    Localizza impianto
  </h3>
</div>


<mat-dialog-content>
  <div class="row justify-content-center">
    <div class="col-12 col-md-11 col-lg-10 col-xl-8">
      <div *ngIf="(apiLoaded | async)">
        <mat-form-field appear style="width: 100%">
          <mat-label>Indirizzo impianto</mat-label>
          <input matInput ngx-google-places-autocomplete #placesRef="ngx-places"
            (onAddressChange)="handleAddressChange($event)" />
          <mat-icon matSuffix fontSet="fal" fontIcon="fa-search" *ngIf="!placesRef.place"></mat-icon>
          <mat-icon matSuffix fontSet="fal" fontIcon="fa-times" (click)="resetInput()" class="reset-input-icon"
            *ngIf="placesRef.place"></mat-icon>
        </mat-form-field>
      </div>
    </div> <!-- /.col -->
  </div> <!-- /.row -->

  <div *ngIf="(apiLoaded | async)">
    <div #map class="maps"></div>
  </div>

  <div style="min-height: 72px;">
    <ng-container *ngIf="data.center?.lat && !currentCoords?.lat">
      <small class="d-block mt-3">Latitudine: {{ data?.center?.lat }} | Longitudine: {{
        data?.center?.lng }}</small>
    </ng-container>

    <ng-container *ngIf="currentCoords?.lat">
      <small class="d-block mt-3">Latitudine: {{ currentCoords.lat }} | Longitudine: {{
        currentCoords.lng }}</small>
      <button type="button" mat-stroked-button color="primary" (click)="onConfirm()" class="mt-3 ms-auto me-auto">
        Conferma
      </button>

    </ng-container>
  </div>
</mat-dialog-content>

<!-- <mat-dialog-actions align="end">
</mat-dialog-actions> -->
