// Core packages
import { Directive, ViewContainerRef } from '@angular/core';

// Third party packages

// Custom packages

/**
 * Script start
 */
@Directive({
  selector: 'hostCell',
})
export class CellDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
