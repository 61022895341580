<h1 mat-dialog-title>{{ modalTitle }}</h1>

<mat-dialog-content class="mb-3">
  <form [formGroup]="form" (ngSubmit)="onFormSubmit(model)">
    <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
    <div class="row mt-3">
      <div class="col-md-6">
        <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate">
        </mat-spinner-button>
      </div> <!-- /.col -->
      <div class="col-md-6 text-right">
        <button type="button" mat-stroked-button color="warn" class="w-100 w-md-auto" (click)="onDenial()">
          Annulla
        </button>
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </form>
</mat-dialog-content> <!-- mat-dialog-content -->

<mat-dialog-actions>
  <div class="col-12 col-md-6">

  </div> <!-- /.col -->
  <div class="col-12 col-md-6 mt-4 mt-md-0 text-right">
    
  </div> <!-- /.col -->
</mat-dialog-actions> <!-- /.mat-dialog-actions -->
