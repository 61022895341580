// Core packages
import { Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

// Third party packages
import { Observable, Subscription } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs/operators';

// Custom packages
import { HelperService } from 'src/app/shared/services/helper.service';
import { PageService } from 'src/app/shared/services/loading.service';
import { UsersService } from '../../users.service';
import { User } from 'src/app/shared/models/user/user.model';
import Response from 'src/app/shared/interfaces/response.interface';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { ConfigService } from 'src/app/shared/services/config.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';
import { AggregationsService } from 'src/app/modules/aggregations/aggregations.service';
import IAggregation from 'src/app/shared/models/aggregation/aggregation.interface';
import IPlant from 'src/app/shared/models/plant/plant.interface';
import { PlantsService } from 'src/app/modules/plants/plants.service';
import moment from 'moment';
import { MatSelectChange } from '@angular/material/select';
import IStaticObjectGroup from 'src/app/shared/models/staticObjectGroup/staticObjectGroup.interface';
import { StaticObjectGroupsService } from 'src/app/modules/staticObjectGroups/staticObjectGroups.service';
import IUser from 'src/app/shared/models/user/user.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.page.html',
  styleUrls: ['./user-detail.page.scss'],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public user: User = new User();
  public itemId = this.activatedRoute.snapshot.params.userId;
  public readOnlyMode = false;
  private plants: IPlant[] = [];
  private staticObjectGroups: any[] = [];

  public isAdmin = false;
  public fisrtPassword = false;
  public passwordExpired = false;

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Salva',
    spinnerSize: 20,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  // END - Form

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pageService: PageService,
    private userService: UsersService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    public configService: ConfigService,
    public authService: AuthService,
    private aggregationsService: AggregationsService,
    private plantsService: PlantsService,
    private staticObjectGroupsService: StaticObjectGroupsService,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    this.pageService.loading$.next(true);
    if (
      ['admin', 'owner'].includes(
        this.authService?.loggedUser$?.value?.role as string,
      )
    ) {
      this.isAdmin = true;
    }
    if (this.authService?.loggedUser$?.value?.firstPassword) {
      this.fisrtPassword =  true;
    }
    if (
      this.authService?.loggedUser$?.value?.lastPasswordChange &&
      moment(this.authService?.loggedUser$?.value?.lastPasswordChange).isValid()
    ) {
      if (
        moment().diff(moment(this.authService?.loggedUser$?.value?.lastPasswordChange).add(6, 'months')) > 0
      ) {
        this.passwordExpired = true;
      }
    }

    this.activatedRoute.params.subscribe(async (params) => {
      const { userId } = params;
      this.itemId = userId;
      const loggedId = this.authService?.loggedUser$?.value?._id;
      const currentId = this.model._id;

      if (currentId && currentId !== loggedId) {
        console.log('AGGIORNO');
        await this.getUserData();
      }
    });

    setTimeout(() => {
      this.getUserData();
    }, 100);
  }

  async getUserData(): Promise<void> {
    this.fields = [];

    this.subscriptions.push(
      this.userService
        .get(this.itemId)
        .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Something bad happened
              this.router.navigate(['users']);
            }

            // Set data
            let staticObjectGroupIds = [];
            if (
              res?.data.staticObjectGroupIds &&
              Array.isArray(res?.data.staticObjectGroupIds) &&
              res?.data.staticObjectGroupIds.length
            ) {
              staticObjectGroupIds = res?.data.staticObjectGroupIds.map((item: any) => item._id);
            }
            this.user?.setData(res?.data);
            this.user?.set('staticObjectGroupIds', staticObjectGroupIds);

            this.staticObjectGroupsService.getList(0, 100, 'name', 'asc')
            .subscribe((res: Response) => {
              if(res.status) {
                if (res.data && Array.isArray(res.data)) {
                  for (const item of res.data) {
                    this.staticObjectGroups.push({
                      label: item.name,
                      value: item._id
                    });
                  }
                }
                this.initForm(); // Init main form
              }
            });
            
          },
          (err: Response) => {
            this.helperService.handleError(err);
            this.router.navigate(['errors', '404']);
          },
        ),
    );
  }

  /**
   * Load a list of plants for given aggregationId
   *
   * @since 1.0.0
   */
  async getPlantsOfAggregation(aggregationId: string): Promise<void> {
    if (!aggregationId) {
      return;
    }

    this.subscriptions.push(
      this.plantsService
        .getListOfAggregation(aggregationId, 0, 100, 'name', 'asc')
        .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (res.status) {
              // Reset previous value
              // if (aggregationId !== this.model.aggregaionId) {
              //   console.log('resetto valori impianti');
              //   this.form.get('plantIds')?.setValue([]);
              //   this.model.plantIds = null;
              // }

              if (res.data?.length) {
                this.plants = res.data.map((plant: IPlant) => ({
                  label: plant.name,
                  value: plant._id,
                }));
              } else {
                this.plants = [];
              }
              this.model.plantsOptions = this.plants;
            }
          },
          (err: Response) => {
            console.warn('error: ', err);
          },
        ),
    );
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Set model
    this.model = { ...this.user.getData(), plantsOptions: [] };
    console.log(this.model)

    this.readOnlyMode =
      this.authService?.loggedUser$?.value?._id?.toString() !==
        this.itemId?.toString() &&
      !['admin', 'owner'].includes(
        this.authService?.loggedUser$?.value?.role as string,
      );

    // Set fields
    this.fields = [
      // ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          // {
          //   className: 'col-12 col-md-4',
          //   type: 'input',
          //   key: '_id',
          //   templateOptions: {
          //     label: 'ID',
          //     placeholder: 'Inserisci qui...',
          //     required: true,
          //     disabled: true,
          //   },
          //   hideExpression: !['admin', 'owner'].includes(
          //     this.authService?.loggedUser$?.value?.role as string,
          //   ),
          // },
          {
            className: 'col-12',
            template: '<strong class="d-block mt-4">Permessi aggiuntivi</strong>',
            hideExpression: this.model.role !== "customer" || !this.isAdmin
          },
          {
            className: 'col-12 col-md-4',
            key: 'plafond',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: { 
              label: 'Visualizza plafond' 
            },
            expressionProperties: {
              'templateOptions.disabled': () => {
                return this.user.get('role') !== "customer" || 
                  (this.user.get('profile') !== 'demo' && this.user.get('profile') !== 'pro');
              },
            },
            hideExpression: this.model.role !== "customer" || !this.isAdmin
          },
          {
            className: 'col-12 col-md-4',
            key: 'technician',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: { 
              label: 'Utente tecnico' 
            },
            expressionProperties: {
              'templateOptions.disabled': () => {
                return this.user.get('role') !== "customer" || 
                  (this.user.get('profile') !== 'demo' && this.user.get('profile') !== 'pro');
              },
            },
            hideExpression: this.model.role !== "customer" || !this.isAdmin
          },
          {
            className: 'col-12',
            template: '<strong class="d-block mt-4">Oggetti statici</strong>',
            hideExpression: this.model.role !== "customer" || !this.isAdmin
          },
          {
            className: 'col-12 col-md-4',
            type: 'select',
            key: `staticObjectGroupIds`,
            templateOptions: {
              label: 'Elenco gruppi di oggetti statici che può vedere',
              placeholder: 'Seleziona',
              required: false,
              options: this.staticObjectGroups,
              multiple: true,
            },
            expressionProperties: {
              'templateOptions.disabled': () => {
                return this.user.get('role') !== "customer" || 
                  (this.user.get('profile') !== 'demo' && this.user.get('profile') !== 'pro');
              },
              // 'templateOptions.options': () => {
              //   return this.staticObjectGroups;
              // }
            },
            hideExpression: this.model.role !== "customer" || !this.isAdmin
          },
          // {
          //   className: 'col-12 col-md-4',
          //   key: 'staticObjects',
          //   type: 'checkbox',
          //   defaultValue: false,
          //   templateOptions: { 
          //     label: 'Elenco gruppi di oggetti statici che può vedere',
              
          //   },
          //   expressionProperties: {
          //     'templateOptions.disabled': () => {
          //       return this.user.get('role') !== "customer" || 
          //         (this.user.get('profile') !== 'demo' && this.user.get('profile') !== 'pro');
          //     },
          //   },
          //   hideExpression: this.model.role !== "customer" || !this.isAdmin
          // },
          {
            className: 'col-12 col-md-4',
            key: 'staticObjectManagement',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: { 
              label: 'Gestione oggetti statici' 
            },
            expressionProperties: {
              'templateOptions.disabled': () => {
                return this.user.get('role') !== "customer" || 
                  (this.user.get('profile') !== 'demo' && this.user.get('profile') !== 'pro');
              },
            },
            hideExpression: this.model.role !== "customer" || !this.isAdmin
          },
          {
            className: 'col-12',
            template: '<strong class="d-block mt-4">Dati utente</strong>',
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'firstName',
            templateOptions: {
              label: 'Nome',
              placeholder: 'Inserisci qui...',
              required: true,
              disabled: this.readOnlyMode,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'lastName',
            templateOptions: {
              label: 'Cognome',
              placeholder: 'Inserisci qui...',
              required: true,
              disabled: this.readOnlyMode,
            },
          },
          {
            className: 'col-12 col-md-4',
          },

          {
            className: 'col-12',
            template: '<strong class="d-block mt-4">Dati accesso</strong>',
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'email',
            templateOptions: {
              label: 'Email',
              placeholder: 'Inserisci qui...',
              required: true,
              disabled:
                !['admin', 'owner'].includes(
                  this.authService.loggedUser$?.value?.role as string,
                ) || this.readOnlyMode,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'password',
            templateOptions: {
              label: 'Password',
              placeholder: 'Inserisci qui...',
              required: false,
              disabled: this.readOnlyMode,
            },
            hide:
              !['admin', 'owner'].includes(
                this.authService.loggedUser$?.value?.role as string,
              ) || this.readOnlyMode,
          },

          {
            className: 'col-12 col-md-4',
          },

          {
            className: 'col-12',
            template:
              '<strong class="d-block mt-4">Monitoraggio impianti</strong>',
            hideExpression: `!['admin', 'owner'].includes("${this.authService?.loggedUser$?.value?.role}") || ['admin', 'owner'].includes(model.role)`,
          },

          // {
          //   className: 'col-12 col-md-4',
          //   type: 'select',
          //   key: 'role',
          //   templateOptions: {
          //     label: 'Ruolo',
          //     placeholder: 'Seleziona',
          //     required: true,
          //     type: 'string',
          //     options:
          //       this.authService.loggedUser$?.value?.role === 'admin'
          //         ? this.configService.settings.modules.users.rolesOptions
          //         : this.configService.settings.modules.users.rolesOptions?.filter(
          //             (el: any) => el?.value !== 'admin',
          //           ),
          //     disabled:
          //       !['admin', 'owner'].includes(
          //         this.authService.loggedUser$?.value?.role as string,
          //       ) || this.readOnlyMode,
          //   },
          //   hideExpression: !['admin', 'owner'].includes(
          //     this.authService?.loggedUser$?.value?.role as string,
          //   ),
          // },
          {
            className: 'col-12 col-md-4',
            type: 'autocomplete',
            key: `aggregationId`,
            templateOptions: {
              label: 'Aggregazione',
              placeholder: 'Cerca...',
              labelExtractor: (obj: IAggregation): string => {
                return obj.name;
              },
              valueExtractor: (obj: IAggregation): string => {
                return obj._id;
              },
              multiple: false,
              search$: (
                start?: number,
                limit?: number,
                sort?: string,
                sortVersus?: string,
                search?: any,
              ): Observable<ListApiResponse> =>
                this.aggregationsService.getList(
                  start,
                  limit,
                  sort,
                  sortVersus,
                  search,
                ),
              clearIcon: 'fa-trash',
              notFoundText: 'Non trovato',
              loadingText: 'Caricamento...',
              change: (field, newVal) => {
                this.getPlantsOfAggregation(newVal);
              },
              disabled:
                !['admin', 'owner'].includes(
                  this.authService.loggedUser$?.value?.role as string,
                ) || this.readOnlyMode,
              staticOptions: [
                {
                  value: null,
                  label: 'Nessuna',
                },
              ],
            },
            expressionProperties: {
              'templateOptions.required':
                "!['admin', 'owner'].includes(model.role)",
            },
            hideExpression: `!['admin', 'owner'].includes("${this.authService?.loggedUser$?.value?.role}") || ['admin', 'owner'].includes(model.role)`,
          },
          {
            className: 'col-12 col-md-4',
            type: 'select',
            key: `plantIds`,
            templateOptions: {
              label: 'Impianti',
              placeholder: 'Seleziona',
              required: false,
              type: 'string',
              multiple: true,
              options: this.plants,
              disabled:
                !['admin', 'owner'].includes(
                  this.authService.loggedUser$?.value?.role as string,
                ) || this.readOnlyMode,
            },
            expressionProperties: {
              'templateOptions.options': 'model.plantsOptions',
            },
            hideExpression: `!['admin', 'owner'].includes("${this.authService?.loggedUser$?.value?.role}") || ['admin', 'owner'].includes(model.role)`,
          },

          {
            className: 'col-12 col-md-4',
          },

          {
            className: 'col-12',
            template: '<strong class="d-block mt-4">Durata</strong>',
            hideExpression: () => this.user.liveData.profile !== 'demo',
          },
          {
            className: 'col-12 col-md-4',
            type: 'datepicker',
            key: 'expiryDate',
            templateOptions: {
              label: 'Data di scadenza',
              placeholder: 'Seleziona...',
              required: false,
              type: 'string',
              datepickerOptions: {
                min: moment().add('1', 'day').toDate(),
                // max: moment().add('365', 'days').toDate(),
              },
            },
            expressionProperties: {
              'templateOptions.description': () => {
                if (!this.model.expiryDate) {
                  return '';
                }
                return `${moment(this.model.expiryDate).diff(
                  moment(),
                  'days',
                )} giorni`;
              },
              'templateOptions.required': () =>
                this.user.liveData.profile === 'demo',
            },
            hideExpression: () => this.user.liveData.profile !== 'demo',
          },
          // {
          //   className: 'col-12 col-md-4',
          //   type: 'select',
          //   key: 'sections',
          //   templateOptions: {
          //     label: 'Sezioni',
          //     placeholder: 'Seleziona',
          //     required: false,
          //     type: 'string',
          //     multiple: true,
          //     options: [
          //       {
          //         value: 'InfoCliente',
          //         label: 'Info Cliente',
          //       },
          //       {
          //         value: 'Impianti',
          //         label: 'Impianti',
          //       },
          //       {
          //         value: 'Ticketing',
          //         label: 'Ticketing',
          //       },
          //       {
          //         value: 'Mappa',
          //         label: 'Mappa',
          //       },
          //     ],
          //     disabled:
          //       !['admin', 'owner'].includes(
          //         this.authService.loggedUser$?.value?.role as string,
          //       ) || this.readOnlyMode,
          //   },
          //   hideExpression: `!['admin', 'owner'].includes("${this.authService?.loggedUser$?.value?.role}") || ['admin', 'owner'].includes(model.role)`,
          // },
          // {
          //   className: 'col-12 col-md-4',
          //   type: 'select',
          //   key: 'active',
          //   templateOptions: {
          //     label: 'Attivo',
          //     placeholder: 'Seleziona',
          //     type: 'boolean',
          //     required: true,
          //     options: [
          //       {
          //         value: false,
          //         label: 'No',
          //       },
          //       {
          //         value: true,
          //         label: 'Si',
          //       },
          //     ],
          //     disabled: this.readOnlyMode,
          //   },
          //   hideExpression: !['admin', 'owner'].includes(
          //     this.authService?.loggedUser$?.value?.role as string,
          //   ),
          // },
        ],
      },
    ];
  }

  onActiveChange(newValue: MatSelectChange): void {
    this.user.set('active', newValue.value);
  }

  /**
   * Handle user params on light profile
   *
   * @since 1.1.0
   */
  onFormManageLightUser(): void {
    Object.keys(this.form.controls).forEach((key) => {
      switch (key) {
        case 'technician':
        case 'plafond':
        case 'staticObjects':
        case 'staticObjectManagement':
          this.form.controls[key].setValue(false);
        break;
      }
    });
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(): void {
    // Prevent invalid form submit
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    // Get form values
    const formValues = this.form.value;

    // Add other fields
    if (
      ['admin', 'owner'].includes(
        this.authService?.loggedUser$?.value?.role as string,
      )
    ) {
      formValues.role = this.user.get('role');
      formValues.profile = this.user.get('profile');
      formValues.active = this.user.get('active');
    }

    // Prevent double submit caused by double btn click
    if (this.formBtnOptions.active) {
      return;
    }

    // Activate submit button loading status
    this.formBtnOptions = { ...this.formBtnOptions, active: true };

    // Send data to BE
    this.subscriptions.push(
      this.userService
        .updateFields(this.user.getId() as string, formValues)
        .pipe(
          finalize(
            () =>
              (this.formBtnOptions = { ...this.formBtnOptions, active: false }),
          ),
        )
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              const title = 'Errore';
              let message =
                res.message ||
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
              this.toastrService.error(message, title);
              return;
            }

            this.toastrService.success(res.message);
          },
          (err: Response) => {
            // Choose one of the following error handling method.
            // 1) First one show a message right under the form fields
            //    (if the form is properly setted)
            // 2) Second one is used to show a mat-error under each field
            //    in the dynamic form (is meant to be used only in dynamicForm)
            // 3) Third one show toastr notifications for each error
            // 4) Fourth one is used to show mat-error under each field
            //    in the ngx-formly form (is mean to be used only with ngx-formly)
            // this.helperService.handleFormError(form, err);
            // this.helperService.handleDynamicFormError(form, err);
            // this.helperService.handleError(err);
            this.helperService.handleFormlyError(this.form, err);
          },
        ),
    );
  }

  /**
   * Handle delete
   *
   * @since 1.0.0
   */
  onDelete(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message:
          "Procedendo eliminerai l'elemento. L'operazione è irreversibile",
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }

        // Start loading
        this.pageService.loading$.next(true);

        // Ok, so user really want to delete.
        // Let's do it!
        this.subscriptions.push(
          this.userService
            .delete(this.user.getId() as string)
            .pipe(finalize(() => this.pageService.loading$.next(false)))
            .subscribe(
              (res: Response) => {
                if (!res.status) {
                  const title = 'Errore';
                  let message =
                    res.message ||
                    "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                  this.toastrService.error(message, title);
                  return;
                }

                this.toastrService.success(res.message);
                this.router.navigate(['users']);
              },
              (err: any) => {
                // Choose one of the following error handling
                // method. The first one shows a message right
                // under the form fields (if the form is properly
                // setted), the second one shows toastr
                // notifications for each error
                this.helperService.handleError(err);
              },
            ),
        );
      });
  }

  /**
   * Handle password updated
   *
   * @since 1.0.0
   */
  GetPasswordUpdated(user: IUser): void {
    if (!user.firstPassword) {
      this.fisrtPassword =  false;
    }
    if (
      user.lastPasswordChange &&
      moment(user.lastPasswordChange).isValid()
    ) {
      if (
        moment().diff(moment(user.lastPasswordChange).add(6, 'months')) > 0
      ) {
        this.passwordExpired = true;
      } else {
        this.passwordExpired = false;
      }
    }

  }
}
