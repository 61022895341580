// Core packages
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Third party packages

// Custom packages
import { Error404Component } from 'src/app/modules/errors/pages/error-404/error-404.component';

/**
 * Script start
 */
const routes: Routes = [
  {
    path: '404',
    component: Error404Component,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorsRoutingModule {}
