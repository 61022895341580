<mat-toolbar class="app-footer">
  <div class="row w-100" style="width: 100%;">
    <div class="col-12 col-sm-6">
      <div style="display: inline-block; margin-right: 10px; margin-left: 15px;">
        <img src="/assets/img/logo/logo-skp.png" alt="SKP Technology" style="width: 60px;">
      </div>
      <strong>COPYRIGHT &copy; {{ year }} {{ configService.settings.owner?.nicename }}</strong>
    </div>
    <!-- /.col-sm-6 -->
    <div class="col-12 col-sm-6 text-right">
      <a href="{{ configService.settings.owner?.url }}" target="_blank" style="color: #6D767B">{{ configService.settings.owner?.domain
        }}</a>
      <!-- | P.IVA: {{ configService.settings.owner?.vatNumber }} -->
    </div>
    <!-- /.col-sm-6 -->
  </div>
  <!-- /.row -->
</mat-toolbar>
