// Core packages
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Third party packages
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

// Custom packages

/**
 * Script start
 */
@Component({
  selector: 'app-silence-item-reason-dialog',
  templateUrl: './silence-item-reason-dialog.component.html',
  styleUrls: ['./silence-item-reason-dialog.component.scss'],
})
export class SilenceItemReasonDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model!: {
    reason: string;
  };
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Conferma',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  // END - Form

  constructor(
    public dialogRef: MatDialogRef<SilenceItemReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastrService: ToastrService,
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    this.initForm();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    console.log('data', this.data);

    // Set model
    this.model = {
      reason: this.data?.silencedReason || '',
    };

    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'textarea',
            key: 'reason',
            templateOptions: {
              label: 'Inserisci nota',
              placeholder: 'Inserisci qui...',
              required: true,
              type: 'string',
              rows: 5,
            },
          },
        ],
      },
    ];
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(model?: any): void {
    model = this.form.getRawValue();

    // Prevent invalid form submit
    if (this.form.invalid) {
      console.warn('this.form', this.form);
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    this.dialogRef.close(model);
  }
}
