// Core packages
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';

// Third party packages
import { Subscription } from 'rxjs';

// Custom packages
import { ConfigService } from 'src/app/shared/services/config.service';
import { HelperService } from 'src/app/shared/services/helper.service';

/**
 * Script start
 */
@Component({
  selector: 'app-dead-installations-dialog',
  templateUrl: './dead-installations-dialog.component.html',
  styleUrls: ['./dead-installations-dialog.component.scss'],
})
export class DeadInstallationsDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<DeadInstallationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public configService: ConfigService,
    public helperService: HelperService,
  ) {
    // console.log('data', this.data);
  }

  /**
   * Init component
   *
   * @since 1.1.0
   */
  ngOnInit(): void {}

  /**
   * Handle component destroy
   *
   * @since 1.1.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Handle dialog close
   *
   * @since 1.1.0
   */
  onClose(): void {
    this.dialogRef.close();
  }

  getElapsed(date?: string) {
    if (!date) {
      return;
    }

    const theDate = moment(date);
    if (!theDate.isValid()) {
      return;
    }

    return theDate.fromNow();
  }
}
