// Core components
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Third party components
import { FieldType } from '@ngx-formly/material';
import { Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  finalize,
} from 'rxjs/operators';

// Custom components
import Response from 'src/app/shared/interfaces/response.interface';

/**
 * Script start
 *
 * @see https://www.npmjs.com/package/ngx-mat-select-search
 * @see https://stackblitz.com/edit/ngx-mat-select-search?file=src%2Fapp%2Fexamples%2F01-single-selection-example%2Fsingle-selection-example.component.ts
 */
@Component({
  selector: 'app-formly-autocomplete-type',
  template: `
    <mat-form-field
      [hideRequiredMarker]="true"
      [floatLabel]="to.floatLabel"
      [appearance]="to.appearance"
      [color]="to.color"
      style="width: 100%"
    >
      <mat-label *ngIf="to.label && to.hideLabel !== true">
        {{ to.label }}
        <span
          *ngIf="to.required && to.hideRequiredMarker !== true"
          class="mat-form-field-required-marker"
          >*</span
        >
      </mat-label>

      <mat-select
        [id]="id"
        [formControl]="selectControl"
        [required]="this.to.required || false"
        [multiple]="this.to.multiple || false"
        [formlyAttributes]="field"
        [tabindex]="to.tabindex || 0"
        [disabled]="to.disabled || false"
        #singleSelect
      >
        <mat-option>
          <ngx-mat-select-search
            [searching]="loading"
            [clearSearchInput]="true"
            [noEntriesFoundLabel]="to.notFoundText || ''"
            [placeholderLabel]="to.placeholder || ''"
            [formControl]="selectFilterControl"
          >
            <mat-icon
              *ngIf="to.clearIcon"
              ngxMatSelectSearchClear
              fontSet="fal"
              [fontIcon]="to.clearIcon"
            ></mat-icon>
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let staticOption of to?.staticOptions"
          [value]="staticOption.value"
          >{{ staticOption.label }}</mat-option
        >
        <mat-option
          *ngFor="let option of filteredItems$ | async"
          [value]="this.to.valueExtractor(option)"
        >
          <span [innerHTML]="this.to.labelExtractor(option)"></span>
        </mat-option>
      </mat-select>

      <ng-container matPrefix *ngIf="to.prefix || to._matPrefix">
        <ng-container
          *ngTemplateOutlet="to.prefix ? to.prefix : to._matPrefix"
        ></ng-container>
      </ng-container>

      <ng-container matSuffix *ngIf="to.suffix || to._matSuffix">
        <ng-container
          *ngTemplateOutlet="to.suffix ? to.suffix : to._matSuffix"
        ></ng-container>
      </ng-container>

      <mat-error id="matError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>

      <mat-hint
        *ngIf="to.description"
        id="matHintDescription"
        [innerHTML]="to.description"
      ></mat-hint>
    </mat-form-field>
  `,
})
export class AutocompleteTypeComponent
  extends FieldType
  implements OnInit, AfterViewInit, OnDestroy
{
  public filteredItems$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  onDestroy$ = new Subject<void>();
  loading$ = new BehaviorSubject<boolean>(false);
  loading: boolean = false;
  selectControl: UntypedFormControl = new UntypedFormControl();
  selectFilterControl: UntypedFormControl = new UntypedFormControl();

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    super.ngOnInit();

    // Preload some values
    this.getValues('', true);

    // Listen for value changes
    this.selectControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val?: string) => {
        this.value = val;
        if (typeof this.to.change !== 'undefined') {
          this.to.change(this, val);
        }
      });

    // Listen for filter changes
    this.selectFilterControl.valueChanges
      .pipe(
        takeUntil(this.onDestroy$), // auto-unsubscribe on onDestroy()
        debounceTime(350), // max 1 query every 350 milliseconts
        distinctUntilChanged(), // Eliminate duplicate values
      )
      .subscribe((term: string) => {
        this.getValues(term);
        // if (term) {
        // }
        // console.log('term', term);
        // console.log('this.showError', this.showError);
        // console.log('this._errorState', this._errorState);
      });

    if (this.to.valueRefresher$) {
      this.to.valueRefresher$.subscribe(() => {
        // console.log('value');
        this.value = undefined;
        this.filteredItems$.next(undefined);
        this.selectControl.setValue(undefined);
        this.selectFilterControl.setValue(undefined);
        this.getValues();
      });
    }

    // Listen for loading
    this.loading$.subscribe((res: boolean) => (this.loading = res));
  }

  /**
   * Handle afterViewInit of this component
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Handle ngOnDestroy of this component
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onDestroy$.complete();
  }

  /**
   * Get items from the back-end
   *
   * @since 1.0.0
   *
   * @param [term] Optional filtering string
   */
  getValues(term?: string, parseValue: boolean = false): void {
    // console.log(`getValues(${this.value}, ${parseValue})`);
    // console.log(`getValues(${term}, ${parseValue})`);

    let options: any = {};
    if (term) {
      options.general = term;
    } else if (this.value) {
      if (typeof this.value === 'string') {
        options['_id'] = this.value;
      } else if (Object.prototype.hasOwnProperty.call(this.value, '_id')) {
        options['_id'] = this.value._id;
      }
    }

    this.loading$.next(true);
    this.to
      .search$(0, 10, '', '', options)
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe((res: Response) => {
        if (res.status) {
          const items = res.data;
          this.filteredItems$.next(items);

          if (parseValue && this.value) {
            // console.log('value', this.value);
            if (typeof this.value !== 'object') {
              this.selectControl.setValue(this.value);
              return;
            }

            if (Array.isArray(this.value)) {
              this.value = this.value.map((el) => this.to.valueExtractor(el));
              this.selectControl.setValue(this.value);
              return;
            }

            this.value = this.to.valueExtractor(this.value);
            this.selectControl.setValue(this.value);
          }
        }
      });
  }

  /**
   * Fired every time model data changes
   *
   * @param {*} data new model data
   */
  onChange(data: any): void {
    if (typeof this.to.callback === 'function') {
      this.to.callback(data);
    }
  }
}
