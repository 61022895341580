// Core packages
import { Component, ElementRef, OnInit } from '@angular/core';

// Third party packages
import { TranslateService } from '@ngx-translate/core';

// Custom packages
import { ValidationLoaderService } from './shared/services/validations-loader.service';
import { ConfigService } from './shared/services/config.service';

/**
 * Script start
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private validationLoaderService: ValidationLoaderService,
    private configService: ConfigService,
    private translateService: TranslateService,
    private _elementRef: ElementRef,
  ) {
    this.validationLoaderService.init();

    const settings = this.configService.settings;

    this.translateService.setDefaultLang(settings.i18n.defaultLang);
    this.translateService.use(settings.i18n.defaultLang);
  }

  /**
   * Remove Angular version from DOM to prevent tools like Wappalyzer to see software version
   */
  ngOnInit(): void {
    this._elementRef.nativeElement.removeAttribute('ng-version');
  }
}
