// Core packages

// Third party packages

// Custom packages
import ICoordinates from '../coordinates.interface';
import File from '../../interfaces/file.interface';
import { BaseModel } from '../_base/model.class';

/**
 * Script start
 */
export class StaticObject extends BaseModel {
  constructor(
    private _id?: string,
    private staticObjectCategoryId?: string,
    private aggregationId?: string,
    private customerId?: string,
    private plantId?: string,
    private notes?: string,
    private date?: Date,
    private photos?: File[],
    private files?: File[],
    private coordinates?: ICoordinates,
    
    private createdAt?: Date,
    private createdBy?: string,
    private updatedAt?: Date,
    private updatedBy?: string,
  ) {
    super();

    this.setRequiredFields([
      '_id',
      'staticObjectCategoryId',
      'aggregationId',
      'customerId',
      'plantId',
      'createdAt',
      'createdBy',
    ]);
  }
}
