// Core packages
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';

// Third party packages
import { Subscription } from 'rxjs';

// Custom packages

/**
 * Script start
 */
@Component({
  selector: 'app-stat-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss'],
})
export class StatCardComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input('type') type: string = '1';
  @Input('value') value!: string;
  @Input('descriptor') descriptor!: string;
  @Input('item') item!: string;
  @Input('icon') icon!: string;
  @Input('background-color') backgroundColor!: string;
  @Input('shadow') shadow: boolean = false;
  @Input('icon-background-color') iconBackgroundColor!: string;
  @Input('border-color') borderColor!: string;
  @Input('color') color!: string;
  @Input('url') url!: string;

  /**
   * Class constructor
   */
  constructor(private router: Router) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {}

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onClick(): void {
    if (!this.url) {
      return;
    }
    this.router.navigate([this.url]);
  }
}
