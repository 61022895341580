// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class AggregationsService {
  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
  ) {}

  /**
   * Create a new aggregation
   *
   * @since 1.0.0
   *
   * @param aggregation Aggregation data
   * @returns Observable<any>
   */
  create(aggregation: any): Observable<any> {
    return this.apiService.apiCall('POST', 'aggregations', aggregation);
  }

  /**
   * Get aggregations list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall('GET', 'aggregations', params);
  }

  /**
   * Get given aggregationId data from back-end
   *
   * @since 1.0.0
   *
   * @param aggregationId ID of the aggregation to get
   */
  get(aggregationId: string): Observable<any> {
    return this.apiService.apiCall('GET', `aggregations/${aggregationId}`).pipe(
      catchError((err) => {
        this.helperService.handleError(err);
        return throwError(err);
      }),
    );
  }

  /**
   * Update aggregation with given aggregationId with given data
   *
   * @since 1.0.0
   *
   * @param aggregationId ID of pos to update
   * @param data New aggregation's data
   */
  update(aggregationId: string, data: object): Observable<any> {
    return this.apiService.apiCall(
      'PUT',
      `aggregations/${aggregationId}`,
      data,
    );
  }

  /**
   * Update aggregation fields with given aggregationId with given data
   *
   * @since 1.0.0
   *
   * @param aggregationId ID of aggregation to update
   * @param data New aggregation's data
   */
  updateFields(aggregationId: string, data: object): Observable<any> {
    return this.apiService.apiCall(
      'PATCH',
      `aggregations/${aggregationId}`,
      data,
    );
  }

  /**
   * Delete permanently an aggregation
   *
   * @since 1.0.0
   *
   * @param aggregationId ID of the aggregation
   */
  delete(aggregationId: string): Observable<any> {
    return this.apiService.apiCall('DELETE', `aggregations/${aggregationId}`);
  }

  /**
   * Reset all items status og aggregation with given ID
   *
   * @since 1.0.0
   *
   * @param aggregationId ID of the aggregation
   */
  resetItemsStatus(aggregationId: string): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `aggregations/${aggregationId}/reset-items-status`,
    );
  }

  /**
   * Silence all items of aggregation with given ID
   *
   * @since 1.0.0
   *
   * @param aggregationId ID of the aggregation
   */
  silenceAllItems(aggregationId: string): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `aggregations/${aggregationId}/silence-all-items`,
    );
  }

  /**
   * De-Silence all items of aggregation with given ID
   *
   * @since 1.0.0
   *
   * @param aggregationId ID of the aggregation
   */
  unsilenceAllItems(aggregationId: string): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `aggregations/${aggregationId}/unsilence-all-items`,
    );
  }

  /**
   * Exclude from ping all items of aggregation with given ID
   *
   * @since 1.0.0
   *
   * @param aggregationId ID of the aggregation
   */
  excludeFromPingAllItems(aggregationId: string): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `aggregations/${aggregationId}/ping-exclude-all-items`,
    );
  }

  /**
   * Include from ping all items of aggregation with given ID
   *
   * @since 1.0.0
   *
   * @param aggregationId ID of the aggregation
   */
  includeFromPingAllItems(aggregationId: string): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `aggregations/${aggregationId}/ping-include-all-items`,
    );
  }

  /**
   * Get aggregations plants list
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getPlantsList(
    aggregationId: string,
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall(
      'GET',
      `aggregations/${aggregationId}/plants`,
      params,
    );
  }
}
