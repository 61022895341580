// Core components
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Third party components
import { FieldType } from '@ngx-formly/material';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

// Custom components
import { HelperService } from 'src/app/shared/services/helper.service';
import { FileService } from 'src/app/shared/services/file.service';
import { LightboxDialogComponent } from '../../dialogs/lightbox-dialog/lightbox-dialog.component';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { getBEUrl } from 'src/environments/environment';
import Response from 'src/app/shared/interfaces/response.interface';
import BeFile from 'src/app/shared/interfaces/file.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-formly-file-uploader-type',
  templateUrl: './file-uploader-type.component.html',
  styleUrls: ['./file-uploader-type.component.scss'],
})
export class FileUploaderTypeComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  private subscriptions: Subscription[] = [];
  triggerFileUpload = false;
  triggerFileReset = false;
  uploadedFiles: any[] = [];
  fileIsUploading = false;

  constructor(
    private helperService: HelperService,
    private toastrService: ToastrService,
    private fileService: FileService,
    private configService: ConfigService,
    public dialog: MatDialog,
  ) {
    super();
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    super.ngOnInit();

    if (this.formControl.value) {
      this.updateUpdatedFiles(this.formControl.value, false);
    }

    if (typeof this.to.allowedFilesNumber === 'undefined') {
      this.to.allowedFilesNumber = 1;
    }

    if (typeof this.to.allowedFilesExtensions === 'undefined') {
      this.to.allowedFilesExtensions = '*';
    }
  }

  /**
   * After view init logic
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Handle event "uploadFiles" emitted from file-uploader component
   *
   * @since 1.0.0
   *
   * @param files Files to upload
   * @returns undefined
   */
  onUploadFiles(files: NgxFileDropEntry[]): void {
    if (this.options?.formState.disabled) {
      console.warn('Block upload because field is disabled');
      return;
    }

    this.fileIsUploading = true;

    // You could upload it like this:
    const formData = new FormData();

    let counter = 1;
    for (const givenFile of files) {
      const fileEntry = givenFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        // const name = `file-${counter}`;
        const name = `file[]`;
        formData.append(name, file, givenFile.relativePath);
        counter++;
        if (counter === files.length + 1) {
          const uploadFilesSubscription = this.fileService
            .uploadTempFile(formData)
            .subscribe(
              (res: Response) => {
                console.log('res', res);
                // Accept all-and-only last (this.to.allowedFilesNumber)
                // files (this is need! do not remove!)
                if (res.status) {
                  const files = res.data.slice(-this.to.allowedFilesNumber);
                  res.data = files;
                }

                // If available, call the callback
                if (typeof this.to.callback === 'function') {
                  this.to.callback(res, this.model);
                }

                // Handle res status
                if (res.status) {
                  this.triggerFileReset = !this.triggerFileReset; // Reset drop-zone area
                  this.updateUpdatedFiles(res.data, true);
                  this.fileIsUploading = false;
                  return;
                }
                if (res.message) {
                  this.toastrService.error('Attenzione', res.message);
                  this.fileIsUploading = false;
                  return;
                }
                this.fileIsUploading = false;
                this.toastrService.error('Errore sconosciuto');
              },
              (err: Response) => {
                // Choose one of the following error handling
                // method. The first one show a message right
                // under the form fields (if the form is properly
                // setted), the second one show toastr
                // notifications for each error
                // this.helperService.handleFormError(this.form, err);
                this.helperService.handleError(err);
              },
            );
          this.subscriptions.push(uploadFilesSubscription);
        }
      });
    }
  }

  /**
   * Update current updatedFiles
   *
   * @since 1.0.0
   */
  updateUpdatedFiles(files: BeFile[] | BeFile, setValue = false): void {
    // console.log('files', files);

    // Update image previews list
    if (files) {
      if (Array.isArray(files)) {
        files.forEach((file: any) => {
          this.uploadedFiles.push(file);
        });
      } else {
        this.uploadedFiles.push(files);
      }

      // Prevent visualization of more previews than allowed
      this.uploadedFiles = this.uploadedFiles.slice(
        -this.to.allowedFilesNumber,
      );

      if (setValue) {
        const fileIds = this.uploadedFiles.map((el: BeFile) => el._id);
        if (this.to.allowedFilesNumber === 1) {
          this.value = fileIds[0];
        } else {
          this.value = fileIds;
        }
      }
    }
  }

  getTempFilePreview(file: BeFile): string {
    if (!file) {
      return '';
    }

    let previewPath = `${getBEUrl()}${file.path}`;
    return previewPath;
  }

  /**
   * Remove given item from:
   * 1) File previews
   * 2) Field's value
   *
   * @since 1.0.0
   */
  onRemove(file: any): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message:
          'Procedendo rimuoverai il file. Una volta rimosso, ricordati di salvare',
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }

        // Remove file from previews
        this.uploadedFiles = this.uploadedFiles.filter(
          (item: any) => item._id !== file._id,
        );

        // Remove file from field value
        if (
          typeof file._id !== 'undefined' &&
          this.model[this.key as string] === file._id
        ) {
          this.model[this.key as string] = null;
        }
        if (Array.isArray(this.model[this.key as string])) {
          this.model[this.key as string] = this.model[
            this.key as string
          ].filter((item: any) => item._id !== file._id);
        }
      });
  }

  /**
   * Handle click on a image preview and opens it in a dialog
   *
   * @since 1.0.0
   */
  onOpenImage(file: any): void {
    this.dialog.open(LightboxDialogComponent, {
      data: {
        url: file.path,
        data: file,
      },
      panelClass: 'lightbox-dialog',
    });
  }

  /**
   * Transform given size in bytes to
   * a kb string
   *
   * @since 1.0.0
   *
   * @param size The size to transform
   * @return Transformed size string with 2 decimals
   */
  getFormattedSize(size: number): string {
    const sizeInKb = size / 1024;
    return sizeInKb.toFixed(2);
  }
}
