// Core components
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Third party components
import { FieldType } from '@ngx-formly/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

// Custom components
import { HelperService } from 'src/app/shared/services/helper.service';
import IFile from 'src/app/shared/interfaces/file.interface';
import { FileService } from 'src/app/shared/services/file.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { AddFileExtDialogComponent } from '../../dialogs/add-file-ext-dialog/add-file-ext-dialog.component';
import { getBEUrl } from 'src/environments/environment';
import IPlantFile from 'src/app/shared/models/plantFile/plantFile.interface';
import { PlantFilesService } from 'src/app/modules/plants/plantFiles.service';
import Response from 'src/app/shared/interfaces/response.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-formly-file-gallery-ext-type',
  templateUrl: './file-gallery-ext-type.component.html',
  styleUrls: ['./file-gallery-ext-type.component.scss'],
})
export class FileGalleryExtTypeComponent
  extends FieldType
  implements OnInit, OnDestroy, AfterViewInit
{
  private subscriptions: Subscription[] = [];
  public submitBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Importa',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'mt-2',
  };

  constructor(
    private helperService: HelperService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    private fileService: FileService,
    private plantFilesService: PlantFilesService
  ) {
    super();
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * After view init logic
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Remove file
   *
   * @since 1.0.0
   */
  onRemove(obj: IPlantFile): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message:
          'Procedendo rimuoverai il file. Una volta rimosso, ricordati di salvare',
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }
        // Send data to BE
        this.subscriptions.push(
          this.plantFilesService
            .delete(obj._id)
            .subscribe(
              (res: Response) => {
                if (!res.status) {
                  const title = 'Errore';
                  let message =
                    res.message ||
                    "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                  this.toastrService.error(message, title);
                  return;
                }

                // Remove file from value
                const idToRemove = obj._id;
                this.value = this.value.filter((el: IPlantFile) => el._id !== idToRemove);
                
                this.toastrService.success(res.message);
              }
            ),
        );
        
      });
  }

  /**
   * Handle click on a image preview and opens it in a dialog
   *
   * @since 1.0.0
   */
  onOpenImage(obj: IPlantFile): void {
    const dialogRef = this.dialog.open(AddFileExtDialogComponent, {
      data: {
        plantFileId: obj._id,
        action: 'show',
        allowedFilesExtensions: this.to.allowedFilesExtensions,
        plantId: this.to.plantId,
        fileType: this.to.fileType,
        sameSite: this.to.sameSite ? true : false,
        sites: this.to.sites,
        siteId: obj.siteId,
        file: obj.file,
        title: obj.title,
        description: obj.description
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (!res || !res.status) {
          return;
        }
        if (this.value && res.data) {
          const item = this.value.find(
            (el: any) => el._id.toString() === res.data._id.toString());
          item.title = res.data.title;
          item.description = res.data.description;
        }
      });
  }
  /**
   * 
   * Check file is image
   */
  getTempFileIsImage(file: IFile): boolean {
    if (!file) {
      return false;
    }

    const extension = file.originalName.split('.').pop()?.toLocaleLowerCase();

    const imageExtensions = [
      'jpeg',
      'jpg',
      'png',
    ];
    if (extension && imageExtensions.includes(extension)) {
      return true;
    }
    return false;
  }

  /**
   * Generate and return appropriate full preview path of given file
   *
   * @since 1.0.0
   */
  getFilePreview(file: IFile): string {
    if (!file) {
      return '';
    }

    const extension = file.originalName.split('.').pop()?.toLocaleLowerCase();

    const imageExtensions = [
      'jpeg',
      'jpg',
      'png',
    ];
    const knownExtensions = [
      'acc',
      'ae',
      'ai',
      'an',
      'avi',
      'bmp',
      'csv',
      'dat',
      'dgn',
      'doc',
      'doch',
      'docm',
      'docx',
      'doth',
      'dw',
      'dwfx',
      'dwg',
      'dxf',
      'dxl',
      'eml',
      'eps',
      'f4a',
      'f4v',
      'flv',
      'fs',
      'gif',
      'html',
      'ind',
      'jpeg',
      'jpg',
      'jpp',
      'log',
      'lr',
      'm4v',
      'mbd',
      'mbox',
      'midi',
      'mkv',
      'mov',
      'mp2',
      'mp3',
      'mp4',
      'mpeg',
      'mpg',
      'mpp',
      'mpt',
      'mpw',
      'mpx',
      'msg',
      'ods',
      'oga',
      'ogg',
      'ogv',
      'one',
      'ost',
      'pdf',
      'php',
      'png',
      'pot',
      'poth',
      'potm',
      'potx',
      'pps',
      'ppsx',
      'ppt',
      'ppth',
      'pptm',
      'pptx',
      'prem',
      'ps',
      'psd',
      'pst',
      'pub',
      'pubh',
      'pubm',
      'pwz',
      'rar',
      'read',
      'rp',
      'rtf',
      'sql',
      'svg',
      'swf',
      'tif',
      'tiff',
      'txt',
      'url',
      'vcf',
      'vds',
      'vdx',
      'vob',
      'vsd',
      'vss',
      'vst',
      'vsx',
      'vtx',
      'wav',
      'wdp',
      'webm',
      'wma',
      'wmv',
      'xd',
      'xls',
      'xlsm',
      'xlsx',
      'xlx',
      'xml',
      'zip',
    ];
    if (extension && imageExtensions.includes(extension)) {
      const previewPath = `${getBEUrl()}${file.path}`;
      return previewPath;
    } else if (extension && knownExtensions.includes(extension)) {
      return `/assets/img/file-types/${extension}.png`;
    }

    let previewPath = `${getBEUrl()}${file.path}`;
    return previewPath;
  }

  onAddFile(): void {
    const dialogRef = this.dialog.open(AddFileExtDialogComponent, {
      data: {
        action: 'add',
        allowedFilesExtensions: this.to.allowedFilesExtensions,
        plantId: this.to.plantId,
        fileType: this.to.fileType,
        sameSite: this.to.sameSite ? true : false,
        sites: this.to.sites
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (!res || !res.status) {
          return;
        }
        if (this.value) {
          this.value = [...this.value, res.data];
        } else {
          this.value = res.data;
        }
      });
  }

  getTitle(title: string): string {
    let newTitle = '';
    if (title) {
      if (title.length > 14) {
        newTitle = `${title.substring(0, 14)}...`;
      } else {
        newTitle = title;
      }
    }
    return newTitle;
  }
}
