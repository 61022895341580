<div class="page">
  <div class="page-content align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-5 p-5 d-flex align-items-center justify-content-center left-col">
          <div class="col-content text-center">
            <h3 class="the-title">Oops! Pagina non trovata"</h3>
            <p class="lead">La pagina che stai cercando non è stata trovata</p>
            <p>Generalmente questo accade quando hai digitato a mano l'URL o quando la pagina è stata eliminata</p>
            <!-- <button mat-raised-button color="primary" routerLink="/">
              <mat-icon fontSet="fal" fontIcon="fa-long-arrow-left"></mat-icon>
              Vai alla dashboard
            </button> -->
          </div> <!-- /.col-content -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div> <!-- /.page-content -->
</div> <!-- /.page -->
