// Core packages
import { Component } from '@angular/core';

// Third party packages
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from '../../services/config.service';

// Custom packages

/**
 * Script start
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  year: number = new Date().getFullYear();

  constructor(public configService: ConfigService, public dialog: MatDialog) {}
}
