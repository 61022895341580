// Core components
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Third party components
import { Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  finalize,
} from 'rxjs/operators';

// Custom components
import Response from 'src/app/shared/interfaces/response.interface';
import { TableFilter } from '../../interfaces/tableConfig.interface';

/**
 * Script start
 *
 * @see https://www.npmjs.com/package/ngx-mat-select-search
 * @see https://stackblitz.com/edit/ngx-mat-select-search?file=src%2Fapp%2Fexamples%2F01-single-selection-example%2Fsingle-selection-example.component.ts
 */
@Component({
  selector: 'app-autocomplete-type',
  template: `
    <mat-form-field class="filter">
      <mat-label>{{ filter.label || 'Cerca...' }}</mat-label>
      <mat-select [formControl]="filter.control" #singleSelect>
        <mat-option>
          <ngx-mat-select-search
            [searching]="loading"
            [clearSearchInput]="true"
            [noEntriesFoundLabel]="filter.notFoundText || ''"
            [placeholderLabel]="'Cerca...'"
            [formControl]="selectFilterControl"
          >
            <mat-icon
              *ngIf="filter.clearIcon"
              ngxMatSelectSearchClear
              fontSet="fal"
              [fontIcon]="filter.clearIcon"
            ></mat-icon>
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let staticOption of filter?.staticOptions"
          [value]="staticOption.value"
          >{{ staticOption.label }}</mat-option
        >
        <mat-option
          *ngFor="let option of filteredItems$ | async"
          [value]="filter.valueExtractor ? filter.valueExtractor(option) : ''"
        >
          {{
            option && filter.labelExtractor ? filter.labelExtractor(option) : ''
          }}
        </mat-option>
      </mat-select>
      <mat-icon
        matSuffix
        fontSet="fal"
        fontIcon="fa-times"
        class="reset-input-icon"
        *ngIf="filter.control.value"
        (click)="onResetFilter(filter.control)"
      >
      </mat-icon>
    </mat-form-field>
  `,
})
export class FormAutocompleteTypeComponent implements OnInit, OnDestroy {
  @Input('filter') filter!: TableFilter;
  @Input('onResetFilter') onResetFilter!: any;

  public filteredItems$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  onDestroy$ = new Subject<void>();
  loading$ = new BehaviorSubject<boolean>(false);
  loading: boolean = false;
  selectControl: UntypedFormControl = new UntypedFormControl();
  selectFilterControl: UntypedFormControl = new UntypedFormControl();

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Preload some values
    this.getValues('', true);

    // Listen for value changes
    // this.selectControl.valueChanges
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe((val?: string) => {
    //     this.value = val;
    //   });

    // Listen for filter changes
    this.selectFilterControl.valueChanges
      .pipe(
        takeUntil(this.onDestroy$), // auto-unsubscribe on onDestroy()
        debounceTime(350), // max 1 query every 350 milliseconts
        distinctUntilChanged(), // Eliminate duplicate values
      )
      .subscribe((term: string) => {
        this.getValues(term);
      });

    // Listen for loading
    this.loading$.subscribe((res: boolean) => (this.loading = res));
  }

  /**
   * Handle ngOnDestroy of this component
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    this.onDestroy$.complete();
  }

  /**
   * Get items from the back-end
   *
   * @since 1.0.0
   *
   * @param [term] Optional filtering string
   */
  getValues(term?: string, parseValue: boolean = false): void {
    if (
      typeof this.filter === 'undefined' ||
      typeof this.filter.search$ === 'undefined' ||
      typeof this.filter.labelExtractor === 'undefined' ||
      typeof this.filter.valueExtractor === 'undefined'
    ) {
      return;
    }

    const options: any = {
      general: term,
    };
    this.loading$.next(true);
    this.filter
      .search$(0, 10, '', '', options)
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe((res: Response) => {
        if (res.status) {
          const items = res.data;
          this.filteredItems$.next(items);

          if (parseValue && this.filter.control.value) {
            if (typeof this.filter.control.value !== 'object') {
              this.selectControl.setValue(this.filter.control.value);
              return;
            }

            if (this.filter.valueExtractor) {
              this.filter.control.setValue(
                this.filter.valueExtractor(this.filter.control.value),
              );
            }
          }
        }
      });
  }
}
