// Core packages

// Third party packages

// Custom packages
import File from '../../interfaces/file.interface';
import { BaseModel } from '../_base/model.class';

/**
 * Script start
 */
export class User extends BaseModel {
  constructor(
    private _id?: string,

    private role?: string,
    private superOwner?: boolean,
    private plafond?: boolean,
    private technician?: boolean,
    private staticObjectGroupIds?: string[],
    private staticObjects?: boolean,
    private staticObjectManagement?: boolean,
    private email?: string,
    private firstName?: string,
    private lastName?: string,
    private fullName?: string,
    private password?: string,
    private active?: boolean,
    private aggregationId?: string,
    private plantIds?: string[],
    private sections?: string[],
    private profileImage?: File,
    private expiryDate?: Date,
    private profile?: Date,

    private createdAt?: Date,
    private createdBy?: string,
    private updatedAt?: Date,
    private updatedBy?: string,
  ) {
    super();

    this.setRequiredFields([
      '_id',
      'role',
      'email',
      'firstName',
      'lastName',
      'fullName',
      'active',
      'createdAt',
      'createdBy',
    ]);
  }
}
