// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
  ) {}

  /**
   * Create a new customer
   *
   * @since 1.0.0
   *
   * @param customer Customer data
   * @returns Observable<any>
   */
  create(customer: any): Observable<any> {
    return this.apiService.apiCall('POST', 'customers', customer);
  }

  /**
   * Get customers list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall('GET', 'customers', params);
  }

  /**
   * Get given customerId data from back-end
   *
   * @since 1.0.0
   *
   * @param customerId ID of the customer to get
   */
  get(customerId: string): Observable<any> {
    return this.apiService.apiCall('GET', `customers/${customerId}`).pipe(
      catchError((err) => {
        this.helperService.handleError(err);
        return throwError(err);
      }),
    );
  }

  /**
   * Update customer with given customerId with given data
   *
   * @since 1.0.0
   *
   * @param customerId ID of pos to update
   * @param data New customer's data
   */
  update(customerId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PUT', `customers/${customerId}`, data);
  }

  /**
   * Update customer fields with given customerId with given data
   *
   * @since 1.0.0
   *
   * @param customerId ID of customer to update
   * @param data New customer's data
   */
  updateFields(customerId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PATCH', `customers/${customerId}`, data);
  }

  /**
   * Delete permanently an customer
   *
   * @since 1.0.0
   *
   * @param customerId ID of the customer
   */
  delete(customerId: string): Observable<any> {
    return this.apiService.apiCall('DELETE', `customers/${customerId}`);
  }

  /**
   * Create a new customer contact
   *
   * @since 1.0.0
   *
   * @param customer Customer data
   * @returns Observable<any>
   */
  createContact(customerId: string, contact: any): Observable<any> {
    return this.apiService.apiCall(
      'POST',
      `customers/${customerId}/contacts`,
      contact,
    );
  }

  /**
   * Get customer contacts list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getContactsList(
    customerId: string,
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall(
      'GET',
      `customers/${customerId}/contacts`,
      params,
    );
  }

  /**
   * Get given customerId+contactId data from back-end
   *
   * @since 1.0.0
   *
   * @param customerId ID of the customer to get
   */
  getContact(customerId: string, contactId: string): Observable<any> {
    return this.apiService
      .apiCall('GET', `customers/${customerId}/contacts/${contactId}`)
      .pipe(
        catchError((err) => {
          this.helperService.handleError(err);
          return throwError(err);
        }),
      );
  }

  /**
   * Update customer fields with given customerId with given data
   *
   * @since 1.0.0
   *
   * @param customerId ID of customer to update
   * @param data New customer's data
   */
  updateContactFields(
    customerId: string,
    contactId: string,
    data: object,
  ): Observable<any> {
    return this.apiService.apiCall(
      'PATCH',
      `customers/${customerId}/contacts/${contactId}`,
      data,
    );
  }

  /**
   * Delete permanently an customer
   *
   * @since 1.0.0
   *
   * @param customerId ID of the customer
   */
  deleteContact(customerId: string, contactId: string): Observable<any> {
    return this.apiService.apiCall(
      'DELETE',
      `customers/${customerId}/contacts/${contactId}`,
    );
  }
}
