// Core packages
import {
  Injectable,
  Renderer2,
  RendererFactory2,
  ElementRef,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

// Third party packages

// Custom packages
import { ConfigService } from 'src/app/shared/services/config.service';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer: Renderer2;

  /**
   * Class constctor
   */
  constructor(
    rendererFactory: RendererFactory2,
    private titleService: Title,
    private configService: ConfigService,
    private meta: Meta,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Set given string as page title
   *
   * @since 1.0.0
   */
  setPageTitle(title: string, withPostFix = true): void {
    if (!withPostFix) {
      this.titleService.setTitle(title);
    } else {
      this.titleService.setTitle(
        `${title} | ${this.configService.settings.owner?.nicename}`,
      );
    }
  }

  /**
   * Set given string as page meta description
   *
   * @since 1.0.0
   */
  setPageMetaDescription(desc: string): void {
    this.meta.updateTag({
      name: 'description',
      content: desc,
    });
  }

  /**
   * Set given class as body class
   *
   * @since 1.0.0
   *
   * @param className the class to add
   * @returns undefined
   */
  setClassToBody(className: string): void {
    this.renderer.addClass(document.body, className);
  }

  /**
   * Remove given class from body tag
   *
   * @since 1.0.0
   *
   * @param className the class to remove
   */
  removeClassToBody(className: string): void {
    this.renderer.removeClass(document.body, className);
  }

  /**
   * Set given class to given element
   *
   * @since 1.0.0
   *
   * @param element The target element
   * @param className The class to add
   */
  setClassToElement(element: ElementRef, className: string): void {
    this.renderer.addClass(element, className);
  }

  /**
   * Remove given class to given element
   *
   * @since 1.0.0
   *
   * @param element The target element
   * @param className The class to remove
   */
  removeClassToElement(element: ElementRef, className: string): void {
    this.renderer.removeClass(element, className);
  }
}
