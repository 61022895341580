// Core packages
import { Component, Inject } from '@angular/core';

// Third party packages
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import File from 'src/app/shared/interfaces/file.interface';

// Custom packages
import { ConfigService } from 'src/app/shared/services/config.service';
import { environment, getBEUrl } from 'src/environments/environment';

/**
 * Script start
 */
@Component({
  selector: 'app-lightbox-dialog',
  templateUrl: './lightbox-dialog.component.html',
  styleUrls: ['./lightbox-dialog.component.scss'],
})
export class LightboxDialogComponent {
  public apiHost: string = getBEUrl();

  constructor(
    public dialogRef: MatDialogRef<LightboxDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      url: string;
      data: File;
    },
    public configService: ConfigService,
  ) {
    console.log('data', this.data);
  }

  /**
   * Handle dialog close
   */
  onClose(): void {
    this.dialogRef.close();
  }

  getDataUrl(preview: boolean = false): string {
    if (!preview) {
      return this.apiHost + this?.data?.url;
    }

    if (
      this.data.data.mimetype.includes('image') &&
      !this.data.data.mimetype.includes('pdf')
    ) {
      if (this?.data?.url?.includes(this.apiHost)) {
        return this.data.url;
      }

      return this.apiHost + this?.data?.url;
    }

    // Choose the apprpriate image
    if (this.data.data.mimetype.includes('pdf')) {
      return '/assets/img/previewImage/pdf.png';
    }
    if (
      this.data.data.mimetype.includes('octet-stream') ||
      this.data.data.mimetype.includes('msword')
    ) {
      return '/assets/img/previewImage/doc.png';
    }

    return ''; // @todo
  }

  getWeight(): string {
    const weight = parseFloat(this.data?.data?.size) / 1000;
    return weight.toPrecision(2).toString();
  }
}
