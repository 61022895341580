<h1 mat-dialog-title>{{ data.title }}</h1>

<mat-dialog-content [innerHTML]="data.message" class="mb-3"></mat-dialog-content> <!-- mat-dialog-content -->

<mat-dialog-actions>
  <div class="col-12 col-md-6">
    <button role="button" mat-stroked-button [color]="data.btnCancelColor" (click)="onDenial()" class="w-100 w-md-auto">
      <mat-icon fontSet="fal" [fontIcon]="data.btnCancelIcon"></mat-icon>
      {{ data.btnCancelText }}
    </button>
  </div> <!-- /.col -->
  <div class="col-12 col-md-6 mt-4 mt-md-0 text-right">
    <button mat-raised-button [color]="data.btnOkColor" (click)="onConfirmation()" *ngIf="!messageOnly">
      <mat-icon fontSet="fal" [fontIcon]="data.btnOkIcon"></mat-icon>
      {{ data.btnOkText }}
    </button>
  </div> <!-- /.col -->
</mat-dialog-actions> <!-- /.mat-dialog-actions -->
