// Core packages
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import localeIt from '@angular/common/locales/it';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Third party packages
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  NgxMatNativeDateModule,
  NgxMatDatetimePickerModule,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
// import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SwiperModule } from 'swiper/angular';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';

// Custom packages
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppWrapperComponent } from './components/app-wrapper/app-wrapper.component';
import { SharedModule } from './modules/shared.module';
import { LanguagesModule } from './modules/languages/languages.module';
import { TranslationsModule } from './modules/translations/translations.module';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { SidenavComponent } from './shared/components/sidenav/sidenav.component';
import { UsersModule } from './modules/users/users.module';
import { AuthModule } from './modules/auth/auth.module';
import { ConfirmDialogComponent } from './shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { CannotDeleteDialogComponent } from './shared/components/dialogs/cannot-delete-dialog/cannot-delete-dialog.component';
import { AutocompleteTypeComponent } from './shared/components/formly/autocomplete-type/autocomplete-type.component';
import { ErrorsModule } from './modules/errors/errors.module';
import { LightboxDialogComponent } from './shared/components/dialogs/lightbox-dialog/lightbox-dialog.component';
import { ImagesUploaderTypeComponent } from './shared/components/formly/images-uploader-type/images-uploader-type.component';
import { FileUploaderTypeComponent } from './shared/components/formly/file-uploader-type/file-uploader-type.component';
import { TextareaWithCounterTypeComponent } from './shared/components/formly/textarea-with-counter/textarea-with-counter-type.component';
import { FormlyWrapperAddons } from './shared/components/formly/addon-wrapper/addon-wrapper';
import { addonsExtension } from './shared/components/formly/addon-wrapper/addon-extension';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InputWithCounterTypeComponent } from './shared/components/formly/input-with-counter/input-with-counter-type.component';
import { AddressDialogComponent } from './shared/components/dialogs/address-dialog/address-dialog.component';
import { RepeatSectionTypeComponent } from './shared/components/formly/repeat-section-type/repeat-section.type';
import { ExportDialogComponent } from './shared/components/dialogs/export-dialog/export-dialog.component';
import { AddFileDialogComponent } from './shared/components/dialogs/add-file-dialog/add-file-dialog.component';
import { ConfigService } from './shared/services/config.service';
import { MaterialPasswordTypeComponent } from './shared/components/formly/password-type/password-type.component';
import { environment, getBEUrl } from 'src/environments/environment';
import { MaterialPhoneTypeComponent } from './shared/components/formly/phone-type/phone-type.component';
import { SettingsComponent } from './components/settings/settings.component';
import { MaterialDatetimepickerTypeComponent } from './shared/components/formly/datetimepicker-type/datetimepicker-type.component';
// import { TinyMCETypeComponent } from './shared/components/formly/tinymce-type/tinymce-type.component';
import { CustomerDashboardComponent } from './components/customer-dashboard/customer-dashboard.component';
import { FileGalleryTypeComponent } from './shared/components/formly/file-gallery-type/file-gallery-type.component';
import { AddressFinderTypeComponent } from './shared/components/formly/address-finder-type/address-finder-type.component';
import { AddressFinderDialogComponent } from './shared/components/dialogs/address-finder-dialog/address-finder-dialog.component';
import { MapDialogComponent } from './shared/components/dialogs/map-dialog/map-dialog.component';
import { SilenceItemReasonDialogComponent } from './shared/components/dialogs/silence-item-reason-dialog/silence-item-reason-dialog.component';
import { DeadInstallationsDialogComponent } from './shared/components/dialogs/dead-installations-dialog/dead-installations-dialog.component';
import { CustomerDashboardChartComponent } from './components/customer-dashboard-chart/customer-dashboard-chart.component';
import { AddFileExtDialogComponent } from './shared/components/dialogs/add-file-ext-dialog/add-file-ext-dialog.component';
import { FileGalleryExtTypeComponent } from './shared/components/formly/file-gallery-ext-type/file-gallery-ext-type.component';
import { FileUploaderExtTypeComponent } from './shared/components/formly/file-uploader-ext-type/file-uploader-ext-type.component';
import { LightboxDialogExtComponent } from './shared/components/dialogs/lightbox-dialog-ext/lightbox-dialog-ext.component';
import { SilenceNvrReasonDialogComponent } from './shared/components/dialogs/silence-nvr-reason-dialog/silence-nvr-reason-dialog.component';
import { WarningDialogComponent } from './shared/components/dialogs/warning-dialog/warning-dialog.component';
import { MapStaticObjectDialogComponent } from './shared/components/dialogs/map-static-object-dialog/map-static-object-dialog.component';

/**
 * Script start
 */

export function createTranslateLoader(http: HttpClient) {
  const translationPath = `${getBEUrl()}/i18n/admin/`;
  // console.log('translationPaths', translationPath);
  return new TranslateHttpLoader(http, translationPath, '.json');
}

// Register the localization
registerLocaleData(localeIt, 'it-IT');

// Load translations
// @see https://github.com/ngx-translate/core/issues/517#issuecomment-299637956
export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null),
      );
      locationInitialized.then(() => {
        console.log('initialized with env', environment);

        const langToSet = 'it';
        translate.setDefaultLang(langToSet);
        translate.use(langToSet).subscribe(
          () => {
            // console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(
              `Problem with '${langToSet}' language initialization.'`,
            );
          },
          () => {
            resolve(null);
          },
        );
      });
    });
}

@NgModule({
  imports: [
    // Core
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    // GoogleMapsModule,

    SharedModule.forRoot(),

    // Third party
    // EditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FormlyMaterialModule,
    FormlyModule.forRoot({
      wrappers: [
        {
          name: 'addons',
          component: FormlyWrapperAddons,
        },
      ],
      extensions: [
        { name: 'addons', extension: { onPopulate: addonsExtension } },
      ],
      types: [
        {
          name: 'autocomplete',
          component: AutocompleteTypeComponent,
          // wrappers: ['form-field'],
        },
        {
          name: 'file-uploader',
          component: FileUploaderTypeComponent,
        },
        {
          name: 'file-uploader-ext',
          component: FileUploaderExtTypeComponent,
        },
        {
          name: 'images-uploader',
          component: ImagesUploaderTypeComponent,
        },
        {
          name: 'file-gallery',
          component: FileGalleryTypeComponent,
        },
        {
          name: 'file-gallery-ext',
          component: FileGalleryExtTypeComponent,
        },
        {
          name: 'textarea-with-counter',
          component: TextareaWithCounterTypeComponent,
        },
        {
          name: 'input-with-counter',
          component: InputWithCounterTypeComponent,
        },
        {
          name: 'repeat-section',
          component: RepeatSectionTypeComponent,
        },
        {
          name: 'formly-password',
          component: MaterialPasswordTypeComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'formly-phone',
          component: MaterialPhoneTypeComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'formly-datetimepicker',
          component: MaterialDatetimepickerTypeComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'address-finder',
          component: AddressFinderTypeComponent,
          wrappers: [],
        },
        // {
        //   name: 'formly-tinymce',
        //   component: TinyMCETypeComponent,
        //   wrappers: ['form-field'],
        // },
      ],
    }),
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    GooglePlaceModule,
    SwiperModule,
    NgChartsModule,

    NgChartsModule,
    // NgxMatTimepickerModule,

    // Custom
    UsersModule,
    AuthModule,
    TranslationsModule,
    LanguagesModule,
    ErrorsModule,
  ],
  declarations: [
    AppComponent,
    AppWrapperComponent,
    SidenavComponent,
    FooterComponent,
    HeaderComponent,
    DashboardComponent,
    CustomerDashboardComponent,
    CustomerDashboardChartComponent,
    SettingsComponent,

    // Dialogs
    ConfirmDialogComponent,
    LightboxDialogComponent,
    AddressDialogComponent,
    ExportDialogComponent,
    AddFileDialogComponent,
    CannotDeleteDialogComponent,
    AddressFinderDialogComponent,
    MapDialogComponent,
    SilenceItemReasonDialogComponent,
    DeadInstallationsDialogComponent,
    AddFileExtDialogComponent,
    LightboxDialogExtComponent,
    SilenceNvrReasonDialogComponent,
    WarningDialogComponent,
    MapStaticObjectDialogComponent,


    // Formly custom types
    FormlyWrapperAddons,
    AutocompleteTypeComponent,
    FileUploaderTypeComponent,
    ImagesUploaderTypeComponent,
    FileGalleryTypeComponent,
    TextareaWithCounterTypeComponent,
    InputWithCounterTypeComponent,
    MaterialPasswordTypeComponent,
    MaterialPhoneTypeComponent,
    RepeatSectionTypeComponent,
    MaterialDatetimepickerTypeComponent,
    AddressFinderTypeComponent,
    // TinyMCETypeComponent,
    FileGalleryExtTypeComponent,
    FileUploaderExtTypeComponent,

    // Utils
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => () => config.load(),
      deps: [ConfigService],
      multi: true,
    },
    // {
    //   provide: LOCALE_ID,
    //   useValue: 'it-IT',
    // },
    // {
    //   provide: DEFAULT_CURRENCY_CODE,
    //   useValue: 'EUR',
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    // { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    // { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
